
const INITIAL_STATE = {
    AllStudents:[],
    student:{}

};

const StudentReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'GET_ALL_STUDENT':
            return {
                ...state,
                AllStudents:action.payload
            };
        case 'GET_ONE_STUDENT':
            return {
                ...state,
                student:action.payload
            };

        default:
            return state;
    }
};

export default StudentReducer;
