import React, { useRef } from 'react'
import { Paper } from '@material-ui/core'
import { Button, Card, Divider, Empty } from 'antd'
import { useHistory, useParams } from 'react-router-dom'
import { useEffect } from 'react'
import { useState } from 'react'
import firebase from 'firebase'
import { AppBar, Avatar, CardContent, Typography,Card as Carder, CardHeader } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import './pdfandComments.css'
import { toast } from 'react-hot-toast'
import pop from './audio/sound.mp3'
import { SendNotifaction } from '../../../utils/SendNotifaction'
import { CloseOutlined,ArrowLeftOutlined } from "@ant-design/icons";


const useStyles = makeStyles((theme) => ({
    root: {
      maxWidth: 400,
    },
    media: {
      height: 0,
      paddingTop: '56.25%', // 16:9
    },
    expand: {
      transform: 'rotate(0deg)',
      marginLeft: 'auto',
      // transition: theme.transitions.create('transform', {
      //   duration: theme.transitions.duration.shortest,
      // }),
    },
    expandOpen: {
      transform: 'rotate(180deg)',
    },
    avatar: {
      backgroundColor: "red",
    },
  }));
const PDFandComments = () => {
    const classes = useStyles();

    const [pdf,setPdf]=useState()
    const [value,setValue]=useState("")
    const params=useParams()
    const [commentList,setCommentList]=useState([])
    const currentUser=JSON.parse(localStorage.getItem("firebaseUser"))
    const dummy = useRef()
    const [designer,setDesigner]=useState({})
    const [projectUser,setprojectUser]=useState({})



    useEffect(() => {
    getPdf()
    commentsGetter()
    projectDesigner()
    projectCreator()

    }, [])
const history=useHistory()
    const getPdf=()=>{
        firebase.database().ref(`/projects/${params.projectId}/designer/board/files`)
        .child(params.id)
        .on('value', snapshot => {
          if (snapshot.exists()) {
            console.log(snapshot.val())
            setPdf(snapshot.val())
          }
        })
    }
    const addComment = async (e) => {
      e.preventDefault()
        if (value) {
          const commentId =  firebase.database().ref(`projects`).push().key;
          firebase.database().ref(`projects`).child(params.projectId)
            .child('comments')
            .child(params.id)
            .child(commentId)
            .set({
                comment: value,
              commentId,
              timeStamp: Date.now(),
              userName: currentUser.userName,
              uid: currentUser?.userId,
              profileImage: currentUser.image || "",
            })
            .then(() => {
                firebase.database().ref(`projects`).child(params.projectId)
              .child('AllComments')
              .child(commentId)
              .set({
                comment: value,
                commentId,
                timeStamp: Date.now(),
                userName: currentUser.userName,
                uid: currentUser?.userId,
                profileImage: currentUser.image || "",
              })
              .then(() => {
                toast.success("Comment Send successfully")
                setValue("")
                const sound = document.getElementById("sound");
                sound.play();
                if(currentUser.type=="designer"){
                SendNotifaction(`${currentUser?.userName} added the comment`,value,projectUser.token,"")
                }else{
                  SendNotifaction(`${currentUser?.userName} added the comment`,value,designer.token,"")
                }
                // if (boardData.userId == auth().currentUser?.uid) {
                //   boardUsersDataGetter(commentId);
                // } else {
                //   sendNotificaionToBoardOwner(commentId);
                // }
              })
              .catch(err => {
                console.log(err);
              })
            })
            .catch(err => {
              console.log(err);
            });
        } else {
      
        }
      };
    const commentsGetter = async () => {
        firebase
        .database()
        .ref(`/projects/${params.projectId}/comments/${params.id}`)
        .on("value", (snapshot) => {
          let arr = [];
          if (snapshot.exists()) {
            snapshot.forEach((item) => {
              let value = item.val();
              value.id = item.key;
              arr.push(value);
            });
          }
          setCommentList(arr.reverse());
        });
      };
      const projectDesigner = async () => {
        firebase
        .database()
        .ref(`/projects/${params.projectId}/designer`)
        .on("value", (snapshot) => {
          if (snapshot.exists()) {
                setDesigner(snapshot.val())
          }
    
        });
      };
      const projectCreator = async () => {
        firebase
        .database()
        .ref(`/projects/${params.projectId}`)
        .on("value", (snapshot) => {
          if (snapshot.exists()) {
            firebase
            .database()
            .ref(`/users/${snapshot.val().projectCreatorId}`)
            .on("value", (snapshot) => {
              if (snapshot.exists()) {
                    console.log(snapshot.val(),"user")
                    setprojectUser(snapshot.val(),"user")
              }
        
            });
          }
    
        });
      };

      const timeChanger=(timestamp)=>{

        var date = new Date(timestamp);

        // Format the date in Pacific Time (PT) format
        var formattedDate = new Intl.DateTimeFormat('en-US', {
          timeZone: 'America/Los_Angeles',
          month: '2-digit',
          day: '2-digit',
          year: 'numeric',
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
          hour12: true
        }).format(date);
        
console.log(formattedDate)

return formattedDate;
      }
    
  return (
    <>
    <Paper>
    <Card
                  size="small"
                  title={
                    <>
                       <a target="_blank" href={pdf?.uri}>
                      <p style={{ color: "black", margin: 0 }}>
                        {" "}
                        {pdf?.name}
                      </p>
                    </a>
                    {/* <a target="_blank" href={pdf?.uri}>
                      <button className="clickherebutton">
                        Click here to open
                      </button>
                    </a> */}
                    </>
                 
                  }
                  extra={
<>


<Button onClick={()=>window.history.back()} type="primary" shape="round" icon={<ArrowLeftOutlined style={{position:"relative",top:"-14%"}} />} size="middle">
Go Back
</Button>
</>
                 
                  
                  }
                  style={{
                    width: "auto",
                    height: 800,
                    marginTop: 13,
                  }}
                >
                  <div className="filesvg text-center">
                    <iframe
                      style={{ height: "800px", width: "100%" }}
                      src={pdf?.uri}
                    ></iframe>
                  </div>
                </Card>
           {currentUser.type=="Admin" ? '' :     <div className="" style={{marginTop:"50px",padding:"5px"}}>
            <form onSubmit={addComment}>
              <div className="d-flex mt-4">
            <input
                  value={value}
                  onChange={(e) => setValue(e.target.value)}
                  className="form-control commentInput"
                  placeholder="write comment"
                />
                <button
                  onClick={addComment}
                  type='submit'
                  className="addCommentButton"
                  style={{ marginRight: "10px" }}
                  disabled={currentUser.type=="Admin" ? true : false}
                >
                  Add Comment
                </button>
              </div>
            </form>
            </div>}
            {/* <Paper className="col-md-8 pt-3 hereScroll" style={{overflowY:"scroll",height:"100vh",minHeight:"100vh"}}>
            <div>
            <h3 className="commmentHeading mt-2">
               All Comments
             </h3>
             <Divider/>
            </div>

   

        </Paper> */}
        <div className='reportsandcomment hereScroll'>

        {commentList.length>0?
<>


{

commentList.map((item,i)=>(
    <div id={commentList?.length==i+1?'yes':''} className={item.uid==currentUser?.userId?"row mt-4 mb-3 maraCard endedit":"row mt-4 mb-3"}>
       <div className="col-md-6">
       <Carder className={classes.root}>
       <CardHeader
         avatar={
         //   <Avatar aria-label="recipe" className={classes.avatar}>
         //     <img  src={item.profileImage} alt="profileimg"/>
         //   </Avatar>
           <Avatar alt="Remy Sharp"  src={item.profileImage} />
         }
         // action={
         //   <IconButton aria-label="settings">
         //     <MoreVertIcon />
         //   </IconButton>
         // }
         title={item.userName}
         subheader={timeChanger(item?.timeStamp)}
       />
       <Divider style={{margin:0}}/>
 
       <CardContent>
         <Typography variant="body2" color="textSecondary" component="p" style={{fontSize:"18px"}}>
           {item.comment}
         </Typography>
       </CardContent>
    
     
     </Carder>
       </div>
<div ref={dummy} />
    </div>
   )) 
}

</>
 
 
  :
  <div className="noboardFound">
    <Empty description="No Comments Yet"/>
  </div>

    
    }
        </div>
    </Paper>
    </>
  )
}

export default PDFandComments