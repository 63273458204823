// please be familiar with react-bootstrap-table-next column formaters
// https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html?selectedKind=Work%20on%20Columns&selectedStory=Column%20Formatter&full=0&addons=1&stories=1&panelRight=0&addonPanel=storybook%2Factions%2Factions-panel
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../../../../_metronic/_helpers";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

export function NotiFommater(
    cellContent,
    row,
    rowIndex,
    { openNotificationModal }
) {
    return (
        <>
            {/* <OverlayTrigger
                overlay={<Tooltip id="products-delete-tooltip">Update Status</Tooltip>}
            > */}
                <a
                    title="Update Status"
                    className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                    onClick={() => openNotificationModal(row)}
                >
                    <span className="svg-icon svg-icon-md svg-icon-primary">
                        <SVG
                          title="Send Notification"
                          src={toAbsoluteUrl("/media/svg/icons/General/Notifications1.svg")}
                        />
                    </span>
                </a>
            {/* </OverlayTrigger> */}

        </>
    );
}
