import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import { useParams, Link } from "react-router-dom";
import firebase from "firebase";
import { Spinner } from "react-bootstrap";
import { Modal as AntModal, Divider, Empty } from "antd";
import Upload from "../../../../app/Images/upload.png";
import { CloseOutlined } from "@ant-design/icons";
import { toast } from "react-hot-toast";
import { LinearProgress } from "@material-ui/core";
import Slider from "react-slick";
import { Card as ReactbootCard } from "react-bootstrap";
import { Card as AntCard, Tooltip } from 'antd'
import { DownloadOutlined, FilePdfOutlined } from "@ant-design/icons";
import { Player, PosterImage } from "video-react";
import axios from "axios";
import { PhoneOutlined,FileAddOutlined,VideoCameraAddOutlined, MailOutlined, FileZipOutlined, BorderRightOutlined, VerticalAlignMiddleOutlined } from '@ant-design/icons'
import './viewDetail.css'
import InviteUserToProject from "./InviteUserToproject";
import { PlusCircleOutlined, RiseOutlined, SearchOutlined } from '@ant-design/icons'

const ViewUserProject = () => {
  const { id } = useParams();
  const [projectDetails, setProjectDetails] = useState({});
  const [loading, setLoading] = useState(false);
  const [uploadLoading, setuploadLoading] = useState(false);
  const [imageModal, setImageModal] = useState(false);
  const [addOnModal, setAddOnModal] = useState(false);
  const [inviteUser, setInviteUser] = useState(false);
  const [images, setImages] = useState([]);
  const [video, setVideo] = useState(null);
  const [image, setImage] = React.useState(null);
  const [projectImages, setProjectImages] = useState([]);
  const [projectFiles, setProjectFiles] = useState([]);

  useEffect(() => {
    getProjectDetails();
  }, []);

  const getProjectDetails = () => {
    setLoading(true);
    firebase
      .database()
      .ref("projects")
      .child(id)
      .on("value", (snapshot) => {
        if (snapshot.exists()) {
          setProjectDetails(snapshot.val());
          setProjectImages(snapshot.val()?.ImagesArray);
          getProjectReports()
          setLoading(false);
        }
      });
  };

  const getProjectReports = () => {
    firebase
      .database()
      .ref("customerReports")
      .on("value", (snapshot) => {
        let arr = [];
        if (snapshot.exists()) {
          snapshot.forEach((item) => {
            let value = item.val();
            value.id = item.key;
            arr.push(value);
          });
        }
        let fil = arr.filter((ele) => ele.projectId == id);
        setProjectFiles(fil);
        setLoading(false);
      });
  };

  // const handleImageUpload = (img) => {
  //   setuploadLoading(true);
  //   const storageRef = firebase.storage().ref();
  //   storageRef
  //     .child(`projectimages/${img.name}`)
  //     .put(img)
  //     .then(async (snapshot) => {
  //       snapshot.ref.getDownloadURL().then((val) => {
  //         if (val) {
  //           setuploadLoading(false);

  //         }
  //         let arr = []
  //         arr.push(val)
  //         firebase
  //           .database()
  //           .ref(`/projects/${id}/ImagesArray`)
  //           .set(arr);
  //       });
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  const handleInputChange = (event) => {
    const files = event.target.files;

    for (const file of files) {
      setImages((prevImages) => [...prevImages, file]);
    }
    console.log(images);
  };

  const handleImageRemove = (index) => {
    let data = document.getElementById("imagesupload").value
    console.log(data)
    console.log(index);
    images.splice(index, 1);
    setImages([...images]);
  };

  const handleUpload = async () => {
    setuploadLoading(true);
    const promises = [];

    for (let i = 0; i < images.length; i++) {
      const file = images[i];
      const uploadTask = firebase
        .storage()
        .ref(`projectImages/${file.name}`)
        .put(file);
      promises.push(uploadTask);
    }

    Promise.all(promises).then(async () => {
      console.log("Uploaded all files");
      const urls = [];
      for (let i = 0; i < images.length; i++) {
        const url = await getDownloadURL(images[i]);
        urls.push(url);
      }
      console.log("Download URLs:", urls);

      await firebase
        .database()
        .ref(`/projects/${id}`)
        .child("ImagesArray")
        .set(urls)
        .then(() => {
          setImages([])
          setuploadLoading(false);
          setImageModal(false)
          document.getElementById("imagesupload").value = "";
          toast.success('Uploaded Successfully')
        })
        .catch((err) => {
          setuploadLoading(false);
        });
    });
  };
  const getDownloadURL = async (file) => {
    const url = await firebase
      .storage()
      .ref(`projectImages/${file.name}`)
      .getDownloadURL();
    return url;
  };


  const handleVedioUpload = async (vedio) => {
    setVideo(vedio);
    setuploadLoading(true);
    try {
      // get the frame at 1.5 seconds of the video file
      const cover = await getVideoCover(vedio, 1.5);
      // print out the result image blob
      const myFile = new File([cover], "thumbnail", { type: cover.type });
      console.log(myFile, "this is f");

      const storageRef = firebase.storage().ref();
      storageRef
        .child(`boardimages/${"thumbnail"}`)
        .put(myFile)
        .then(async (snapshot) => {
          snapshot.ref.getDownloadURL().then((thu) => {
            console.log(thu, "this is thumbnail");

            if (thu) {
              const storageRef = firebase.storage().ref();
              storageRef
                .child(`projectImages/${vedio.name}`)
                .put(vedio)
                .then(async (snapshot) => {
                  snapshot.ref.getDownloadURL().then((val) => {
                    if (val) {
                      setuploadLoading(false);
                    }
                    console.log(cover, "this is cover");
                    firebase
                      .database()
                      .ref(`/projects/${id}`)
                      .child("videoUrl")
                      .set(val);
                    firebase
                      .database()
                      .ref(`/projects/${id}`)
                      .child("thumbnailUrl")
                      .set(thu);
                    toast.success("Vedio Uploaded Successfully")
                  });
                })
                .catch((err) => {
                  console.log(err);
                });
            }
          });
        });

      setImage(cover);
    } catch (ex) {
      console.log("ERROR: ", ex);
    }
  };


  const handleDownloadVideo = async () => {
    const videoUrl = projectDetails.videoUrl;
    const link = document.createElement("a");
    link.href = videoUrl;
    link.target = "_blank";
    link.setAttribute("download", "projectVideo.mp4");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  function getVideoCover(file, seekTo = 0.0) {
    console.log("getting video cover for file: ", file);
    return new Promise((resolve, reject) => {
      // load the file to a video player
      const videoPlayer = document.createElement("video");
      videoPlayer.setAttribute("src", URL.createObjectURL(file));
      videoPlayer.load();
      videoPlayer.addEventListener("error", (ex) => {
        reject("error when loading video file", ex);
      });
      // load metadata of the video to get video duration and dimensions
      videoPlayer.addEventListener("loadedmetadata", () => {
        // seek to user defined timestamp (in seconds) if possible
        if (videoPlayer.duration < seekTo) {
          reject("video is too short.");
          return;
        }
        // delay seeking or else 'seeked' event won't fire on Safari
        setTimeout(() => {
          videoPlayer.currentTime = seekTo;
        }, 200);
        // extract video thumbnail once seeking is complete
        videoPlayer.addEventListener("seeked", () => {
          console.log("video is now paused at %ss.", seekTo);
          // define a canvas to have the same dimension as the video
          const canvas = document.createElement("canvas");
          canvas.width = videoPlayer.videoWidth;
          canvas.height = videoPlayer.videoHeight;
          // draw the video frame to canvas
          const ctx = canvas.getContext("2d");
          ctx.drawImage(videoPlayer, 0, 0, canvas.width, canvas.height);
          // return the canvas image as a blob
          ctx.canvas.toBlob(
            (blob) => {
              resolve(blob);
            },
            "image/jpeg",
            0.75 /* quality */
          );
        });
      });
    });
  }

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
   
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 2.2,
          slidesToScroll: 2.2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
    
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const downloadFile = async (fileURL) => {
    const response = await axios.get(fileURL.reportUrl, {
      responseType: "blob", // automatically detect the response type
    });

    const blob = new Blob([response.data], { type: response.data.type });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "your-file-name";
    link.click();
  };
  const onHide=()=>{
    setInviteUser(false)
  }
  return (
    <>

      {loading || uploadLoading ? <LinearProgress /> : ''}
      <Card>
        <input
          onChange={(e) => handleVedioUpload(e.target.files[0])}
          style={{ display: "none" }}
          className="btn btn-base"
          type="file"
          id="vedioUpload"
          accept=".mp4"
        />
        <CardHeader title="Projects Details">
          <CardHeaderToolbar>
            {
              projectDetails.projectCreatorId == firebase.auth().currentUser?.uid ?
                <>
                  {/* <button
                    onClick={() => setImageModal(true)}
                    type="button"
                    className="btn btn-primary"
                  >
                    Upload Images
                  </button> */}
                   <Tooltip placement="top" title={projectDetails.status=='approved'?"Invite Users":"Only in approved project you will b able to invite the users"}>
                        <button  disabled={projectDetails.status=='approved'?false:true} className={projectDetails.status=='approved'?"btn btn-primary":"disabledbutton"} onClick={() => {
                          setInviteUser(true)
                        }}>Invite Users</button> 
                      </Tooltip>
                  <button
                    onClick={() => setAddOnModal(true)}
                    type="button"
                    className="btn btn-primary mx-2"
                  >
                   Add On
                  </button>
                  {/* <button type="button" className="btn btn-primary mx-2">
                    <label htmlFor="vedioUpload" style={{ margin: 0 }}>

                      Upload Video
                    </label>
                  </button> */}
                </>
                : null

            }
          </CardHeaderToolbar>
        </CardHeader>
        {loading ? '' : <CardBody>

          <>
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="projectDetailCard">
                    <div className="row">
                      <div className="col-md-4 imageContainerCol">
                        <div className="imageContainerProject">
                        {projectDetails.image ? <img src={projectDetails.image} /> : 
                          <div style={{display:"flex",justifyContent:"center",width:"100%",flexDirection:"column",alignItems:"center",height:"100%"}}>
                          <Empty/>
                         </div>
                          }
                        </div>
                      </div>
                      <div className="col-md-8">
                        <div className="row mt-2">
                          <div className="col-md-12">
                            <h3 className="headingProject">Project Title</h3>

                          </div>
                          <div className="col-md-12">
                            <p className="subheadingProject">
                              {projectDetails.projectTitle}
                            </p>
                          </div>
                        </div>
                        <div className="row mt-2">
                          <div className="col-md-12">
                            <h3 className="headingProject">Project Description</h3>

                          </div>
                          <div className="col-md-12">
                            <p className="subheadingProject">
                              {projectDetails.description}

                            </p>
                          </div>
                        </div>
                        <div className="row mt-2">
                          <div className="col-md-12 mt-2 d-flex">
                            <MailOutlined style={{ fontSize: "1.5rem" }} />
                            <p className="subheadingProject pl-2">{projectDetails.email}</p>

                          </div>
                          <div className="col-md-12 mt-2 d-flex">
                            <PhoneOutlined style={{ fontSize: "1.5rem" }} />
                            <p className="subheadingProject pl-2">
                              {projectDetails.phoneNumber}
                            </p>
                          </div>
                          <div className="col-md-12 mt-2 d-flex">
                            <FileZipOutlined style={{ fontSize: "1.5rem" }} />
                            <p className="subheadingProject pl-2">
                              {projectDetails.zipCode}
                            </p>
                          </div>
                          <div className="col-md-12 mt-2 d-flex">
                            <BorderRightOutlined style={{ fontSize: "1.5rem" }} />
                            <p className="subheadingProject pl-2">
                              {projectDetails.faxNumber}
                            </p>
                          </div>
                          <div className="col-md-12 mt-2 d-flex">
                            <VerticalAlignMiddleOutlined style={{ fontSize: "1.5rem" }} />
                            <p className="subheadingProject pl-2">
                              {projectDetails.address}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr />
            {console.log(projectImages,"my Image array")}
            <div className="mt-3">
               <div className="row">
               <div className="col-md-12">
               <h3 className="headingProject">Project Images</h3>
               </div>
               </div>
               </div>
            <hr />

            {
              projectImages && (
                projectImages.length>2 ?
                <div className='carsolemasin'>
                <Slider {...settings}>
                  {projectImages.map((img) => (
                    <div>
                      <ReactbootCard
                        style={{ width: '25rem', borderRadius: "10px", height: "300px" }}
                        className="mb-2 slidercard"
                      >
                        <ReactbootCard.Body style={{ padding: 0 }}>
                          <div>
                            <img style={{ height: "300px" }} className='sliderCarimg' src={img} alt="" />
                          </div>
                        </ReactbootCard.Body>
                      </ReactbootCard>
                    </div>
                  ))}
                </Slider>
              </div>:
              <div className="d-flex">
  {projectImages.map((img) => (
                    <div className="mx-2">
                      <ReactbootCard
                        style={{ width: '25rem', borderRadius: "10px", height: "300px" }}
                        className="mb-2 slidercard"
                      >
                        <ReactbootCard.Body style={{ padding: 0 }}>
                          <div>
                            <img style={{ height: "300px" }} className='sliderCarimg' src={img} alt="" />
                          </div>
                        </ReactbootCard.Body>
                      </ReactbootCard>
                    </div>
                  ))}
              </div>
              )
            }
  
            <hr />
            <div className="container">
          <div className="row">
            <div className="col-md-12">
            <h3 className="headingProject">Add On</h3>
            <p className="mt-2">{projectDetails?.addOnText}</p>
            </div>
            {!projectDetails?.addOnText &&   <div style={{display:"flex",justifyContent:"center",width:"100%"}}>
          <Empty/>
         </div>}
         </div>
          </div>
          <hr />


            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <h3 className="headingProject">Admin Project Reports</h3>
                  <div className="row">
                    {projectFiles.length>0? projectFiles.map((filee, i) => (
                      <div className="col-md-6">
                         <a target="_blank" href={filee.reportUrl}>
                        <AntCard
                          size="small"
                          title={
                            filee?.name.length > 10
                              ? filee?.name.substr(0, 10) + "..."
                              : filee?.name
                          }
                          extra={
                            <section className="d-flex">
                              <DownloadOutlined
                                onClick={() => downloadFile(filee)}
                                className="text-primary mx-2"
                              />
                            </section>
                          }
                          style={{
                            width: 400,
                            height: 300,
                            marginTop: 13,
                          }}
                        >
                          <div className="filesvg text-center">
                            {/* <FilePdfOutlined
                              style={{ fontSize: "7rem", color: "red" }}
                            /> */}
                           <iframe  style={{height:"230px",width:"350px"}} src={filee.reportUrl}></iframe>
                          </div>
                        </AntCard>
                        </a>
                      </div>


                    )
                    )
                  :
                    <div className="noReportdiv">
                      <p>No Project Reports Yet</p>
                    </div>}
                  </div>

                </div>
                {/* <div className="col-md-4">
                  <div className="row">
                    <div className="col-md-12">
                      <AntCard size="small" title="Project Video" extra={
                        <Tooltip placement="topLeft" title="Download Video">
                          <DownloadOutlined onClick={handleDownloadVideo} />
                        </Tooltip>

                      } style={{ width: 300 }}>
                     

                      </AntCard>
                    </div>
                    <div className="col-md-12">

                    </div>
                  </div>
                </div> */}

              </div>

            </div>
            {projectDetails?.GeneralForm?.formStatus?
            <div className="mt-3">
               <div className="col-md-12">
               <h3 className="headingProject">General Forms Information</h3>
               <h6>Below you can see that the project general Forms Details</h6>
               </div>
               <div className="container">
               <div className="row gx-0 mt-4">
                 <div className="col-md-4 mainLabelform">
                  <div className="lableformfield">
                    <p>Site Area/SQFT</p>
                  </div>
                  <div className="lableformfield">
                    <p>Budget (Not Precise)</p>
                  </div>
                  <div className="lableformfield">
                    <p>1st Floor Preferences</p>
                  </div>
                  <div className="lableformfield">
                    <p>  2st Floor Preferences</p>
                  </div>
                  <div className="lableformfield">
                    <p>3st Floor Preferences</p>
                  </div>
                  <div className="lableformfield">
                    <p> Entrance</p>
                  </div>
                  <div className="lableformfield">
                    <p>Floors</p>
                  </div>
                  <div className="lableformfield">
                    <p>Walls</p>
                  </div>
                 </div>
                 <div className="col-md-8 keysofform">
                 <div className="lableformfield2">
                    <p>{projectDetails?.GeneralForm?.SiteArea || "-"}</p>
                  </div>
                  <div className="lableformfield2">
                    <p>{projectDetails?.GeneralForm?.Budget || "-"}</p>
                  </div>
                  <div className="lableformfield2">
                    <p>{projectDetails?.GeneralForm?.FirstFloor || "-"}</p>
                  </div>
                  <div className="lableformfield2">
                    <p>{projectDetails?.GeneralForm?.SecondFloor || "-"}</p>
                  </div>
                  <div className="lableformfield2">
                    <p>{projectDetails?.GeneralForm?.ThirdFloor || "-"}</p>
                  </div>
                  <div className="lableformfield2">
                    <p>{projectDetails?.GeneralForm?.Entrance || "-"}</p>
                  </div>
                  <div className="lableformfield2">
                    <p>{projectDetails?.GeneralForm?.Floors || "-"}</p>
                  </div>
                  <div className="lableformfield2">
                    <p>{projectDetails?.GeneralForm?.Walls || "-"}</p>
                  </div>
                 </div>
               </div>
               </div>
            </div>
       :''
              }
               {
          projectDetails?.formStatus?
          <>
            <Divider/>
            <div className="mt-3">
               <div className="col-md-12">
               <h3 className="headingProject">Project Forms Information</h3>
               <h6>Below you can see that the project  Forms Details</h6>
               </div>
               {
                projectDetails?.floor=="" && projectDetails?.Walls ==""&& projectDetails?.doors=="" && projectDetails?.Roof ==""  && projectDetails?.window =="" &&projectDetails?.Kitchen =="" && projectDetails?.Bedrooms=="" && projectDetails?.Ceilings=="" && projectDetails?.Washrooms=="" && projectDetails?.Garage==""?
                ""
                :
              <>
                <div className="col-md-12">
               <h5 className="mt-5" style={{fontWeight:"bold"}}>Basic Information</h5>

                </div>
                <div className="container">
                <div className="row gx-0 mt-4">
                  <div className="col-md-4 mainLabelform">
                   <div className="lableformfield">
                     <p>Floor</p>
                   </div>
                   <div className="lableformfield">
                     <p>Walls</p>
                   </div>
                   <div className="lableformfield">
                     <p>Doors</p>
                   </div>
                   <div className="lableformfield">
                     <p>Windows</p>
                   </div>
                   <div className="lableformfield">
                     <p>Kitchen</p>
                   </div>
                   <div className="lableformfield">
                     <p> Bedrooms</p>
                   </div>
                   <div className="lableformfield">
                     <p>Roof</p>
                   </div>
                   <div className="lableformfield">
                     <p>Ceilings</p>
                   </div>
                   <div className="lableformfield">
                     <p>Washrooms</p>
                   </div>
                   <div className="lableformfield">
                     <p>Garage</p>
                   </div>
                  </div>
                  <div className="col-md-8 keysofform">
                  <div className="lableformfield2">
                     <p>{projectDetails?.floor || "-"}</p>
                   </div>
                   <div className="lableformfield2">
                     <p>{projectDetails?.Walls || "-"}</p>
                   </div>
                   <div className="lableformfield2">
                     <p>{projectDetails?.doors || "-"}</p>
                   </div>
                   <div className="lableformfield2">
                     <p>{projectDetails?.window || "-"}</p>
                   </div>
                   <div className="lableformfield2">
                     <p>{projectDetails?.Kitchen || "-"}</p>
                   </div>
                   <div className="lableformfield2">
                     <p>{projectDetails?.Bedrooms || "-"}</p>
                   </div>
                   <div className="lableformfield2">
                     <p>{projectDetails?.Roof || "-"}</p>
                   </div>
                   <div className="lableformfield2">
                     <p>{projectDetails?.Ceilings || "-"}</p>
                   </div>
                   <div className="lableformfield2">
                     <p>{projectDetails?.Washrooms || "-"}</p>
                   </div>
                   <div className="lableformfield2">
                     <p>{projectDetails?.Garage || "-"}</p>
                   </div>
                  </div>
                </div>
                </div>
              </>
               }
              
            </div>
            <Divider/>
            {
                projectDetails?.lift=="" && projectDetails?.BallRoom ==""&& projectDetails?.Home_Theater=="" && projectDetails?.Home_Bar ==""  && projectDetails?.Tennis_Court =="" &&projectDetails?.gym =="" && projectDetails?.office=="" && projectDetails?.Library=="" && projectDetails?.Swimming_pool=="" && projectDetails?.Panic_room_Bunker=="" && projectDetails?.Walk_in_closets=="" && projectDetails?.Stairs==""?
                ""
                :
            <>
              <div className="mt-3">
               <div className="col-md-12">
               <h5 className="mt-5" style={{fontWeight:"bold"}}>Amenties Information</h5>
               </div>
               <div className="container">
               <div className="row gx-0 mt-4">
                 <div className="col-md-4 mainLabelform">
                  <div className="lableformfield">
                    <p>Stairs</p>
                  </div>
                  <div className="lableformfield">
                    <p>Walk in closets</p>
                  </div>
                  <div className="lableformfield">
                    <p>Panic room / Bunker</p>
                  </div>
                  <div className="lableformfield">
                    <p>Swimming pool</p>
                  </div>
                  <div className="lableformfield">
                    <p>Library</p>
                  </div>
                  <div className="lableformfield">
                    <p> Office</p>
                  </div>
                  <div className="lableformfield">
                    <p>Gym</p>
                  </div>
                  <div className="lableformfield">
                    <p>Tennis Court</p>
                  </div>
                  <div className="lableformfield">
                    <p>Home Bar</p>
                  </div>
                  <div className="lableformfield">
                    <p>Home Theater</p>
                  </div>
                  <div className="lableformfield">
                    <p>BallRoom</p>
                  </div>
                  <div className="lableformfield">
                    <p>lift</p>
                  </div>
                 </div>
                 <div className="col-md-8 keysofform">
                 <div className="lableformfield2">
                    <p>{projectDetails?.Stairs || "-"}</p>
                  </div>
                  <div className="lableformfield2">
                    <p>{projectDetails?.Walk_in_closets || "-"}</p>
                  </div>
                  <div className="lableformfield2">
                    <p>{projectDetails?.Panic_room_Bunker || "-"}</p>
                  </div>
                  <div className="lableformfield2">
                    <p>{projectDetails?.Swimming_pool || "-"}</p>
                  </div>
                  <div className="lableformfield2">
                    <p>{projectDetails?.Library || "-"}</p>
                  </div>
                  <div className="lableformfield2">
                    <p>{projectDetails?.office || "-"}</p>
                  </div>
                  <div className="lableformfield2">
                    <p>{projectDetails?.gym || "-"}</p>
                  </div>
                  <div className="lableformfield2">
                    <p>{projectDetails?.Tennis_Court || "-"}</p>
                  </div>
                  <div className="lableformfield2">
                    <p>{projectDetails?.Home_Bar || "-"}</p>
                  </div>
                  <div className="lableformfield2">
                    <p>{projectDetails?.Home_Theater || "-"}</p>
                  </div>
                  <div className="lableformfield2">
                    <p>{projectDetails?.BallRoom || "-"}</p>
                  </div>
                  <div className="lableformfield2">
                    <p>{projectDetails?.lift || "-"}</p>
                  </div>
                 </div>
               </div>
               </div>
            </div>
            <Divider/>
            </>
}

{
                projectDetails?.PartyRoom=="" && projectDetails?.OutdoorKitchen ==""&& projectDetails?.SettingArea=="" && projectDetails?.Aquariums ==""  && projectDetails?.Waterfalls =="" &&projectDetails?.Jacuzzis =="" && projectDetails?.IndoorPlantation=="" && projectDetails?.PrayerArea=="" && projectDetails?.MusicSystem=="" && projectDetails?.Lighting=="" && projectDetails?.Chandeliers=="" ?
                ""
                :
            <div className="mt-3">
               <div className="col-md-12">
               <h5 className="mt-5" style={{fontWeight:"bold"}}>Entertainment</h5>
               </div>
               <div className="container">
               <div className="row gx-0 mt-4">
                 <div className="col-md-4 mainLabelform">
                  <div className="lableformfield">
                    <p>PartyRoom</p>
                  </div>
                  <div className="lableformfield">
                    <p>Out door Kitchen</p>
                  </div>
                  <div className="lableformfield">
                    <p>Setting Area</p>
                  </div>
                  <div className="lableformfield">
                    <p>Aquariums</p>
                  </div>
                  <div className="lableformfield">
                    <p>Waterfalls</p>
                  </div>
                  <div className="lableformfield">
                    <p> Jacuzzis</p>
                  </div>
                  <div className="lableformfield">
                    <p>In Door Plantation</p>
                  </div>
                  <div className="lableformfield">
                    <p>Prayer Area</p>
                  </div>
                  <div className="lableformfield">
                    <p>Music System</p>
                  </div>
                  <div className="lableformfield">
                    <p>Lighting</p>
                  </div>
                  <div className="lableformfield">
                    <p>Chandeliers</p>
                  </div>
                 </div>
                 <div className="col-md-8 keysofform">
                 <div className="lableformfield2">
                    <p>{projectDetails?.PartyRoom || "-"}</p>
                  </div>
                  <div className="lableformfield2">
                    <p>{projectDetails?.OutdoorKitchen || "-"}</p>
                  </div>
                  <div className="lableformfield2">
                    <p>{projectDetails?.SettingArea || "-"}</p>
                  </div>
                  <div className="lableformfield2">
                    <p>{projectDetails?.Aquariums || "-"}</p>
                  </div>
                  <div className="lableformfield2">
                    <p>{projectDetails?.Waterfalls || "-"}</p>
                  </div>
                  <div className="lableformfield2">
                    <p>{projectDetails?.Jacuzzis || "-"}</p>
                  </div>
                  <div className="lableformfield2">
                    <p>{projectDetails?.IndoorPlantation || "-"}</p>
                  </div>
                  <div className="lableformfield2">
                    <p>{projectDetails?.PrayerArea || "-"}</p>
                  </div>
                  <div className="lableformfield2">
                    <p>{projectDetails?.MusicSystem || "-"}</p>
                  </div>
                  <div className="lableformfield2">
                    <p>{projectDetails?.Lighting || "-"}</p>
                  </div>
                  <div className="lableformfield2">
                    <p>{projectDetails?.Chandeliers || "-"}</p>
                  </div>
                 </div>
               </div>
               </div>
            </div>
}
 
          </>
        : ''}
            <div className="mt-3">
               <div className="col-md-12">
               <h3 className="headingProject">Project Video</h3>
               </div>
           {
            projectDetails.videoUrl?
            <div className="container">
            <Player
            
                       fluid={false}
                       height={400}
                       width="100%"
                       poster={projectDetails.thumbnailUrl}
                       src={projectDetails.videoUrl}
                     />
         </div>
         :
         <div style={{paddingTop:"20px"}}>
          <Empty description="No Video Yet"/>
         </div>
           }
               </div>
          </>

        </CardBody>}
        <AntModal
          title="Upload Image"
          centered
          open={imageModal}
          onOk={handleUpload}
          okText="Upload"
          okButtonProps={{ disabled: uploadLoading }}
          onCancel={() => setImageModal(false)}
        >
          <div className="form-group row">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="avatar-upload-input text-center">
                <img src={Upload} alt="img" />
                <h2>Upload Image</h2>
                <div className="avatar-edit-input">
                  <input
                    className="form-control"
                    name="file"
                    id="imagesupload"
                    type={"file"}
                    multiple
                    onChange={(e) => {
                      handleInputChange(e);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            {images.map((item, index) => (
              <div className="col-md-3 mt-2" style={{ position: "relative" }}>
                <CloseOutlined
                  onClick={() => handleImageRemove(index)}
                  style={{
                    position: "absolute",
                    top: "-10px",
                    color: "red",
                    right: "3px",
                    cursor: "pointer",
                  }}
                />
                <img
                  style={{ height: "100px", width: "100px" }}
                  src={URL.createObjectURL(item)}
                />
              </div>
            ))}
          </div>
        </AntModal>
        <AntModal
          title="Upload Images & Vedio"
          centered
          open={addOnModal}
          okButtonProps={{ style:{display:"none"} }}
          onCancel={() => setAddOnModal(false)}
        >
          <div className="mainAddonContainer">

           <div  className="row" style={{flexDirection:'column'}}>
              <div className="col-md-12 pt-5">
                    <div style={{cursor:'pointer'}} onClick={()=>{
            setAddOnModal(false)
            setImageModal(true)
            }
           }
            className="addonCard">
                    <FileAddOutlined  style={{fontSize:"5rem",paddingTop:"25px",paddingBottom:"10px"}}/>
                    <h6>Upload Images</h6>
                    </div>
              </div>
              <div className="col-md-12 pt-5">
               <label onClick={()=>setAddOnModal(false)} htmlFor="vedioUpload" style={{ margin: 0 }}>
              <div className="addonCard">
                    <VideoCameraAddOutlined style={{fontSize:"5rem",paddingTop:"25px",paddingBottom:"10px"}}/>
                    <h6>Upload Vedio</h6>
                    </div>
                    </label>
              </div>

           </div>
          </div>
        </AntModal>
      </Card>
      <InviteUserToProject selectedProject={projectDetails} inviteModal={inviteUser} hide={onHide}/>
    </>

  );
};

export default ViewUserProject;
