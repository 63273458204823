import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector, shallowEqual, connect, useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ModalProgressBar } from "../../../_metronic/_partials/controls";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import firebase from "firebase";
import { toast } from "react-hot-toast";
import  {useParams,useHistory} from 'react-router-dom'
import { Progress } from "antd";
import { Card as AntCard, Divider, Tooltip } from "antd";
const ViewConstructionProjects=()=> {
  // Fields
  const [loading, setloading] = useState(false);
  const [pic, setPic] = useState("");
  const userdetal=JSON.parse(localStorage.getItem("firebaseUser"))
const [newLoading,setNewLoading]=useState(false)
const [oldImage,setOldImage]=useState("")
  const dispatch = useDispatch();
  const [project, setProject] = useState({})
  const [file,setFile]=useState("")
  const [fileLoading,setFileLoading]=useState(false)
  const [fileLoading2,setFileLoading2]=useState(false)
  const [progress, setProgress] = useState(0)
  const [progress2, setProgress2] = useState(0)
const [boxes, setBoxes] = useState([{ input: '', key: '' }]);

  const params=useParams()
const history=useHistory()


  useEffect(() => {
    getUser()
  }, [])



  const getUser = () => {
    setNewLoading(true)
        firebase.database().ref('constructionprojects')
          .child(params.id)
          .on('value', snapshot => {
            if (snapshot.exists()) {
              console.log(snapshot.val())
              setProject(snapshot.val())
                if(snapshot.val().materialList){
                  setBoxes(snapshot.val().materialList)
                }
              formik.setFieldValue("numberOfWorkers",snapshot.val().numberOfWorkers)
              formik.setFieldValue("liveView",snapshot.val().liveView)
              setNewLoading(false)
            }
          })
  }
  const initialValues = {
    numberOfWorkers: project.numberOfWorkers,
    liveView:  project.liveView,
  };

  // Methods
  const saveUser = (values, setStatus, setSubmitting) => {
    setloading(true);
    console.log(values);
        firebase.database().ref('constructionprojects')
        .child(params.id)
          .update({
            numberOfWorkers: values.numberOfWorkers || "",
            materialList: boxes,
            liveView: values.liveView || "",
          }).then((res) => {
            toast.success('Updated successfully')
            setloading(false)
            history.push('/cont-project')
            // console.log(res)
            // firebase.database().ref('users')
            // .child(userdetal?.userId)
            // .on('value', snapshot => {
            //   if (snapshot.exists()) {
            //     console.log(snapshot.val())
            //     setuser(snapshot.val())
            //      localStorage.setItem('firebaseUser', JSON.stringify(snapshot.val()))  
            //   }
            // })
          }).catch(err => {
            toast.error(err.message)
          })
  };
  // UI Helpers

  const Schema = Yup.object().shape({
    numberOfWorkers: Yup.string().notRequired("Number Of Workers is required").nullable(),
    materialList: Yup.string().notRequired("Material Listis required").nullable(),
    payment: Yup.string().notRequired("Payment is required").nullable(),
    paperWork: Yup.string().notRequired("Paper Work is required").nullable(),
    liveView: Yup.string().notRequired("Live View is required").nullable(),

  });
  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };
  const formik = useFormik({
    initialValues,
    validationSchema: Schema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      saveUser(values, setStatus, setSubmitting);
    },
    onReset: (values, { resetForm }) => {
      resetForm();
    },
  });
const handleUploadFile=(e)=>{
  const file=e.target.files[0]
      setFileLoading(true)
      const storageRef = firebase.storage().ref();
      storageRef.child(`constructionprojects/${file.name}`)
        .put(file)
        .then((async (snapshot) => {
          let percentComplete = 100 * (snapshot.bytesTransferred / snapshot.totalBytes)
          setProgress(percentComplete)
          snapshot.ref.getDownloadURL().then((val) => {
            firebase
            .database()
            .ref(`/constructionprojects/${params.id}`)
              .update(
                {
                  payment:{
                    date:Date.now(),
                    uri: val,
                    fileType: file.type,
                    name: file.name
                  }
              }).then(() => {
                toast.success('File uploaded successfully')
                setFileLoading(false)
                setProgress(0)
              }).catch(err => {
                setFileLoading(false)
                toast.error(err.message)
              })

          })
        }))
   
  
}
const handleUploadFile2=(e)=>{
  const file=e.target.files[0]
      setFileLoading2(true)
      const storageRef = firebase.storage().ref();
      storageRef.child(`constructionprojects/${file.name}`)
        .put(file)
        .then((async (snapshot) => {
          let percentComplete = 100 * (snapshot.bytesTransferred / snapshot.totalBytes)
          setProgress2(percentComplete)
          snapshot.ref.getDownloadURL().then((val) => {
            firebase
            .database()
            .ref(`/constructionprojects/${params.id}`)
              .update(
                {
                  paperWork:{
                    date:Date.now(),
                    uri: val,
                    fileType: file.type,
                    name: file.name
                  }
              }).then(() => {
                toast.success('File uploaded successfully')
                setFileLoading2(false)
                setProgress(0)
              }).catch(err => {
                setFileLoading2(false)
                toast.error(err.message)
              })

          })
        }))
   
  
}
const dateChnage = (timestamp) => {

    var date = new Date(timestamp);

    // Format the date in Pacific Time (PT) format
    var formattedDate = new Intl.DateTimeFormat('en-US', {
      timeZone: 'America/Los_Angeles',
      month: '2-digit',
      day: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: true
    }).format(date);
    
console.log(formattedDate)

return formattedDate;
  
};

const addInputBox = () => {
  setBoxes(prevBoxes => [...prevBoxes, { input: '', key: '' }]);
};

const handleInputChange = (index, value) => {
  const updatedBoxes = [...boxes];
  updatedBoxes[index].input = value;
  setBoxes(updatedBoxes);
};

const handleKeyChange = (index, value) => {
  const updatedBoxes = [...boxes];
  updatedBoxes[index].key = value;
  setBoxes(updatedBoxes);
};

const removeInputBox = (index) => {
  setBoxes(prevBoxes => prevBoxes.filter((box, i) => i !== index));
};
  return (
    <form
      className="card card-custom card-stretch"
      onSubmit={formik.handleSubmit}
    >
      {loading && <ModalProgressBar />}

     {
      newLoading?
      "":
      <>
       <div className="card-header py-3">
        <div className="card-title align-items-start flex-column">
          <h3 className="card-label font-weight-bolder text-dark">
           {project.name}
          </h3>
          {/* <span className="text-muted font-weight-bold font-size-sm mt-1">
            Update your personal informaiton
          </span> */}
        </div>
        <div className="card-toolbar">
          <button
            type="submit"
            className="btn btn-primary mr-2"
            // disabled={
            //   formik.isSubmitting || (formik.touched && !formik.isValid)
            // }
          >
            Save Changes
            {formik.isSubmitting}
          </button>
          <Link
            to="/cont-project"
            className="btn btn-secondary"
          >
            Cancel
          </Link>
        </div>
      </div>
      <div className="form">
        {/* begin::Body */}
        <div className="card-body">
          <div className="form-group row">
            <label className="col-xl-3 col-lg-3 col-form-label">
              Number Of Workers
            </label>
            <div className="col-lg-9 col-xl-6">
              <input
                type="text"
                placeholder="Number Of Workers"
                className={`form-control form-control-lg form-control-solid ${getInputClasses(
                  "numberOfWorkers"
                )}`}
                value={formik.values.numberOfWorkers}
                defaultValue={project?.numberOfWorkers}
                name="numberOfWorkers"
                {...formik.getFieldProps("numberOfWorkers")}
              />
              {formik.touched.numberOfWorkers && formik.errors.numberOfWorkers ? (
                <div className="invalid-feedback">
                  {formik.errors.numberOfWorkers}
                </div>
              ) : null}
            </div>
          </div>
          {/* <div className="form-group row">
            <label className="col-xl-3 col-lg-3 col-form-label">
            Material List
            </label>
            <div className="col-lg-9 col-xl-6">
              <input
                defaultValue={project?.materialList}
                value={formik.values.materialList}
                type="text"
                placeholder="Material List"
                className={`form-control form-control-lg form-control-solid ${getInputClasses(
                  "materialList"
                )}`}
                name="materialList"
                {...formik.getFieldProps("materialList")}
              />
              {formik.touched.materialList && formik.errors.materialList ? (
                <div className="invalid-feedback">
                  {formik.errors.materialList}
                </div>
              ) : null}
            </div>
          </div> */}
          <div className="form-group row">
            <label className="col-xl-3 col-lg-3 col-form-label">
              Live View
            </label>
            <div className="col-lg-9 col-xl-6">
              <input
                defaultValue={project?.liveView}
                value={formik.values.liveView}
                type="text"
                placeholder="Live View"
                className={`form-control form-control-lg form-control-solid`}
                name="liveView"
                {...formik.getFieldProps("liveView")}
              />
            </div>
          </div>
          <div className="form-group row">
            <label className="col-xl-3 col-lg-3 col-form-label">
             Payment
            </label>
            <div className="col-lg-9 col-xl-6">
              {/* <input
                defaultValue={project?.payment}
                value={formik.values.payment}

                type="text"
                placeholder="Payment"
                className={`form-control form-control-lg form-control-solid ${getInputClasses(
                  "payment"
                )}`}
                name="payment"
                {...formik.getFieldProps("payment")}
              />
              {formik.touched.payment && formik.errors.payment ? (
                <div className="invalid-feedback">{formik.errors.payment}</div>
              ) : null} */}
               {
            fileLoading ?
              <Progress type="line" percent={progress} /> : 
                <input
              onChange={handleUploadFile}
              type={'file'}
              accept=".txt, .pdf, .doc, .docx"
            />

          }
          {
            project?.payment?
               <AntCard
                      size="small"
                      title={
                        project.payment?.name?.length > 25
                          ? project.payment?.name.substr(0, 25) + "..."
                          : project.payment?.name
                      }
                      extra={<section className="d-flex"></section>}
                      style={{
                        width: 280,
                        height: 250,
                        marginTop: 13,
                      }}
                    >
                      <div className="filesvg text-center">
                        {/* <FilePdfOutlined
                          style={{ fontSiz
                            e: "7rem", color: "red" }}
                        /> */}
                         <iframe  style={{height:"160px",width:"240px"}} src={project?.payment?.uri}></iframe>
                        <p style={{ paddingTop: "10px" }}>
                          {dateChnage(project?.payment?.date)}
                        </p>
                      </div>
                    </AntCard>:
                    ''}
            </div>
          </div>
          <div className="form-group row">
            <label className="col-xl-3 col-lg-3 col-form-label">
              Paper Work
            </label>
            <div className="col-lg-9 col-xl-6">
              {/* <input
                defaultValue={project?.payment}
                value={formik.values.payment}

                type="text"
                placeholder="Payment"
                className={`form-control form-control-lg form-control-solid ${getInputClasses(
                  "payment"
                )}`}
                name="payment"
                {...formik.getFieldProps("payment")}
              />
              {formik.touched.payment && formik.errors.payment ? (
                <div className="invalid-feedback">{formik.errors.payment}</div>
              ) : null} */}
               {
            fileLoading2 ?
              <Progress type="line" percent={progress2} /> : 
                <input
              onChange={handleUploadFile2}
              type={'file'}
              accept=".txt, .pdf, .doc, .docx"
            />

          }
          {project.paperWork?
               <AntCard
                      size="small"
                      title={
                        project.paperWork?.name?.length > 25
                          ? project.paperWork?.name.substr(0, 25) + "..."
                          : project.paperWork?.name
                      }
                      extra={<section className="d-flex"></section>}
                      style={{
                        width: 280,
                        height: 250,
                        marginTop: 13,
                      }}
                    >
                      <div className="filesvg text-center">
                        {/* <FilePdfOutlined
                          style={{ fontSiz
                            e: "7rem", color: "red" }}
                        /> */}
                         <iframe  style={{height:"160px",width:"240px"}} src={project?.paperWork?.uri}></iframe>
                        <p style={{ paddingTop: "10px" }}>
                          {dateChnage(project?.paperWork?.date)}
                        </p>
                      </div>
                    </AntCard>:
                    ''}
            </div>
          </div>
          <div className="row">
            <label className="col-xl-3"></label>
            <div className="col-lg-9 col-xl-6">
              <h5 className="font-weight-bold mt-10 mb-6">Material List</h5>
            </div>
          </div>
          {boxes?.map((box, index) => (
          <div className="form-group row">
           <div className="col-lg-3 col-xl-3">
            <input
            type="text"
            value={box.key}
            onChange={e => handleKeyChange(index, e.target.value)}
            placeholder="name"
            className="form-control"
          />
             </div>
            <div className="col-lg-5 col-xl-5">
            <input
            className="form-control"
            type="text"
            value={box.input}
            onChange={e => handleInputChange(index, e.target.value)}
            placeholder="value"
          />
            </div>
            <div className="col-lg-2 col-xl-2">
            {boxes.length>1 && (
            <button className="btn btn-danger" onClick={() => removeInputBox(index)}>Remove</button>
          )}
            </div>
            <div className="col-lg-2 col-xl-2">
            {index==boxes.length-1 && (
            <button className="btn btn-primary" onClick={addInputBox}>Add More</button>
          )}
            </div>
          </div>
        
        ))}
        </div>
        {/* end::Body */}
      </div>
      </>
     }
    </form>
  );
}

export default ViewConstructionProjects;
