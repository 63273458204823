import React from 'react'
import { Paper } from '@material-ui/core'
import './form.css'
import { Card, CardBody, CardHeader, CardHeaderToolbar } from '../../../../_metronic/_partials/controls'
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from '../../../../_metronic/_helpers';
import icon from './form.png'
import { Link, useParams,useHistory } from 'react-router-dom';
const Forms = () => {
  const params = useParams();
  const history = useHistory();
      
  return (
    <>
     <Card>
        <CardHeader title="Project Forms">
        <CardHeaderToolbar>
        <Link
                      to={'/user-projects'}
                      className="btn btn-secondary"
                    >
                      Go Back
                    </Link>
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
      <div className='row'>
         <div className='col-md-6 text-center'>
         <Link to={`/general-form/${params.id}`}>

            <div className='formCardContainer'>
            <div className='formCard text-center'>
      <img  src={icon} alt='formIocn' className='formIcon'/>
      <h4  className='pt-2 formHeading'>General Input Form</h4>
      <p className='formsubHeading'>CLick here to add general information</p>
             </div>
            </div>
         </Link>

         </div>
         <div className='col-md-6 text-center'>
         <Link to={`/project-form/${params.id}`}>
         <div className='formCardContainer'>

             <div className='formCard text-center'>
    <img  src={icon} alt='formIocn' className='formIcon'/>
      <h4 className='pt-2 formHeading'>Custom Home Input Form</h4>
      <p className='formsubHeading'>CLick here to add custom form information</p>
             </div>
             </div>
         </Link>
         </div>
        
      </div>
      </CardBody>
      </Card>
    </>
  )
}

export default Forms