import React from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
  Pagination,
} from "../../../../../_metronic/_partials/controls";
import "./Policy.css";
export function PrivacyPolicy() {
  return (
    <Card>
      {/* <CardHeader title="Privacy Policy"></CardHeader> */}
      <CardBody>
        <h1 className="text-center m-4 text-bold">
          <strong>Privacy Policy</strong>
        </h1>
        <p style={{ color: "black" }}>
          Protecting your private information is our priority. This Statement of
          Privacy applies to Constructiya, and Boulevard Construction, Inc. and
          governs data collection and usage. For the purposes of this Privacy
          Policy, unless otherwise noted, all references to Boulevard
          Construction, Inc. include www.theboulevard.us. The Boulevard
          Construction, Inc. application is a Construction and Design automation
          application. By using the Boulevard Construction, Inc. application,
          you consent to the data practices described in this statement.
        </p>
        <h3>Collection of your Personal Information</h3>
        <p>
          In order to better provide you with products and services offered,
          Boulevard Construction, Inc. may collect personally identifiable
          information, such as your:
        </p>
        <ul style={{ listStyle: "none" }}>
          <li>-First and Last Name</li>
          <li>-Mailing Address</li>
          <li>-E-mail Address</li>
          <li>-Phone Number</li>
          <li>-Job Title</li>
        </ul>
        <p>
          We do not collect any personal information about you unless you
          voluntarily provide it to us. However, you may be required to provide
          certain personal information to us when you elect to use certain
          products or services. These may include: (a) registering for an
          account.(b) entering a sweepstakes or contest sponsored by us or one
          of our partners. (c) signing up for special offers from selected third
          parties. (d) sending us an email message. (e) submitting your credit
          card or other payment information when ordering and purchasing
          products and services. To wit, we will use your information for, but
          not limited to, communicating with you in relation to services and/or
          products you have requested from us. We also may gather additional
          personal or non-personal information in the future.
        </p>
        <h3>Use of your Personal Information</h3>
        <p>
          Boulevard Construction, Inc. collects and uses your personal
          information to operate and deliver the services you have requested.
        </p>
        <p>
          Boulevard Construction, Inc. may also use your personally identifiable
          information to inform you of other products or services available from
          Boulevard Construction, Inc. and its affiliates.
        </p>
        <h3>Sharing Information with Third Parties</h3>
        <p>
          Boulevard Construction, Inc. does not sell, rent or lease its customer
          lists to third parties.
        </p>
        <p>
          Boulevard Construction, Inc. may share data with trusted partners to
          help perform statistical analysis, send you email or postal mail,
          provide customer support, or arrange for deliveries. All such third
          parties are prohibited from using your personal information except to
          provide these services to Boulevard Construction, Inc., and they are
          required to maintain the confidentiality of your information.
        </p>
        <p>
          Boulevard Construction, Inc. may disclose your personal information,
          without notice, if required to do so by law or in the good faith
          belief that such action is necessary to: (a) conform to the edicts of
          the law or comply with legal process served on Boulevard Construction,
          Inc. or the site. (b) protect and defend the rights or property of
          Boulevard Construction, Inc. (c) act under exigent
          circumstances to protect the personal safety of users of Boulevard
          Construction, Inc., or the public.
        </p>
        <h3>Right to Deletion</h3>
        <p>
          Subject to certain exceptions set out below, on receipt of a
          verifiable request from you, we will:
        </p>
        <ul>
          <li>Delete your personal information from our records.</li>
          <li>
            Direct any service providers to delete your personal information
            from their records.
          </li>
        </ul>
        <p>
          Please note that we may not be able to comply with requests to delete
          your personal information if it is necessary to:
        </p>
        <ul>
          <li>
            Complete the transaction for which the personal information was
            collected, fulfill the terms of a written warranty or product recall
            conducted in accordance with federal law, provide a good or service
            requested by you, or reasonably anticipated within the context of
            our ongoing business relationship with you, or otherwise perform a
            contract between you and us.
          </li>
          <li>
            Detect security incidents, protect against malicious, deceptive,
            fraudulent, or illegal activity; or prosecute those responsible for
            that activity.
          </li>
          <li>
            Debug to identify and repair errors that impair existing intended
            functionality.
          </li>
          <li>
            Exercise free speech, ensure the right of another consumer to
            exercise his or her right of free speech, or exercise another right
            provided for by law.
          </li>
          <li>
            Comply with the California Electronic Communications Privacy Act.
          </li>
          <li>
            Engage in public or peer-reviewed scientific, historical, or
            statistical research in the public interest that adheres to all
            other applicable ethics and privacy laws, when our deletion of the
            information is likely to render impossible or seriously impair the
            achievement of such research, provided we have obtained your
            informed consent.
          </li>
          <li>
            Enable solely internal uses that are reasonably aligned with your
            expectations based on your relationship with us.
          </li>
          <li>Comply with an existing legal obligation.</li>
          <li>
            Otherwise use your personal information, internally, in a lawful
            manner that is compatible with the context in which you provided the
            information.
          </li>
        </ul>
        <h3>Children Under Thirteen</h3>
        <p>
          Boulevard Construction, Inc. does not knowingly collect personally
          identifiable information from children under the age of thirteen. If
          you are under the age of thirteen, you must ask your parent or
          guardian for permission to use this application.
        </p>
        <h3>
          Disconnecting your Boulevard Construction, Inc. Account from Third
          Party Websites{" "}
        </h3>
        <p>
          You will be able to connect your Boulevard Construction, Inc. account
          to third party accounts. BY CONNECTING YOUR BOULEVARD CONSTRUCTION,
          INC. ACCOUNT TO YOUR THIRD PARTY ACCOUNT, YOU ACKNOWLEDGE AND AGREE
          THAT YOU ARE CONSENTING TO THE CONTINUOUS RELEASE OF INFORMATION ABOUT
          YOU TO OTHERS (IN ACCORDANCE WITH YOUR PRIVACY SETTINGS ON THOSE THIRD
          PARTY SITES). IF YOU DO NOT WANT INFORMATION ABOUT YOU, INCLUDING
          PERSONALLY IDENTIFYING INFORMATION, TO BE SHARED IN THIS MANNER, DO
          NOT USE THIS FEATURE. You may disconnect your account from a third
          party account at any time. contact us via email or telephone
        </p>
        <h3>E-mail Communications</h3>
        <p>
          From time to time, Boulevard Construction, Inc. may contact you via
          email for the purpose of providing announcements, promotional offers,
          alerts, confirmations, surveys, and other general communication.
        </p>
        <p>
          If you would like to stop receiving marketing or promotional
          communications via email from Boulevard Construction, Inc., you may
          opt out of such communications by Customers may unsubscribe from
          emails by "replying STOP" or clicking on the UNSUBSCRIBE button.
        </p>
        <h3>Changes to this Statement</h3>
        <p>
          Boulevard Construction, Inc. reserves the right to change this Privacy
          Policy from time to time. We will notify you about significant changes
          in the way we treat personal information by sending a notice to the
          primary email address specified in your account, by placing a
          prominent notice on our application, and/or by updating any privacy
          information. Your continued use of the application and/or Services
          available after such modifications will constitute your: (a)
          acknowledgment of the modified Privacy Policy. (b) agreement to
          abide and be bound by that Policy.
        </p>
        <h3>Contact Information</h3>
        <p>
          Boulevard Construction, Inc. welcomes your questions or comments
          regarding this Statement of Privacy. If you believe that Boulevard
          Construction, Inc. has not adhered to this Statement, please contact
          Boulevard Construction, Inc. at:
        </p>
        <div className="m-2 contactinfo">
          Boulevard Construction, Inc.
          <br />
          4080 Truxel Road
          <br />
          Sacramento, California 95834
        </div>
        <div className="m-2 contactinfo">
          Email Address:
          <br />
          info@theboulevard.us
        </div>
        <div className="m-2 contactinfo">
          Telephone number:
          <br />
          +1(916) 330-4200
        </div>
        <p>Effective as of June 20, 2023</p>
      </CardBody>
    </Card>
  );
}

export default PrivacyPolicy;
