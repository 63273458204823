import React from "react";
import {  Avatar, CardContent, Typography,Card as Carder, CardHeader,Backdrop,CircularProgress,Paper, LinearProgress } from "@material-ui/core";

import { Button, Divider, Empty, message, Select, Space, Spin, Upload } from "antd";
import { makeStyles } from "@material-ui/core/styles";
import { useEffect } from "react";
import { useState } from "react";
import { Modal } from "antd";
import { Link, useHistory, useParams } from "react-router-dom";
import firebase from "firebase";
import { DownloadOutlined } from "@ant-design/icons";
import {
  UploadOutlined,
  FilePdfOutlined,
  DeleteOutlined,
  CloseOutlined
} from "@ant-design/icons";
import axios from "axios";
import { Card as AntCard } from "antd";
import SVG from "react-inlinesvg";
import $ from "jquery";
import { toast } from "react-hot-toast";
import { Player } from "video-react";
import Slider from 'react-slick'
import { useRef } from "react";
import { Card } from "react-bootstrap";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#333",
  },
  root: {
    maxWidth: 400,
    backgroundColor:"#333",
    opacity:0.5,
  },
}));
const ViewAdminReports = () => {
  const [board, setBoard] = useState([]);
  const params = useParams();
  const [allFiles, setAllFiles] = useState([]);
  const [allImages, setAllImages] = useState([]);
  const [allVideos, setAllVideos] = useState({});
  const [loading, setLoading] = useState(false);
  const [video, setVideo] = useState(null);
  const [thumbnail, setThumbnail] = useState(null);
  const [uploadLoading, setuploadLoading] = useState(false);
  const [image, setImage] = React.useState(null);
  const [deletePicmodal, setDeletePicModal] = useState(false);
  const [deleteFileModal, setDeleteFileModal] = useState(false);
  const [selectedId, setSelectedId] = useState("");
  const [noBoardFound, setNoBoardFound] = useState(false);
  const [commentList,setCommentList]=useState([])
  const [value, setValue] = useState('');
  const [buttonLoading,setButtonLoading]=useState(false)
  const [showMore, setShowMore] = useState(true)
  const [showLess, setShowLess] = useState(false)
  const [projectDetail, setProjectDetails] = useState({})
  const [imageModal, setImageModal] = useState(false);
  const [images, setImages] = useState([]);
  const [selectedImageName,setSelectedImageName]=useState("")
  const [viewingDetails, setViewingDetails] = useState();
  const currentUser = JSON.parse(localStorage.getItem("firebaseUser"));
  /* Demo purposes only */
  const dummy = useRef()
  $("figure").mouseleave(function() {
    $(this).removeClass("hover");
  });
  const history=useHistory()
  useEffect(() => {

    getProject()
    // .on('value', snapshot => {
    //    snapshot.val().;
    // });
  }, []);
  const getProject = () => {
    setLoading(true);

    firebase
      .database()
      .ref(`/projects/${params.id}`)
      .on("value", (snapshot) => {
        if (snapshot.val().reports) {
          setLoading(false);
          if (snapshot.val().reports.images) {
            firebase
              .database()
              .ref(`/projects/${params.id}/reports/images`)
              .on("value", (snapshot) => {
                let arr = [];
                if (snapshot.exists()) {
                  snapshot.forEach((item) => {
                    let value = item.val();
                    value.id = item.key;
                    arr.push(value);
                  });
                }
                setAllImages(arr);
              });
          }
          if (snapshot.val().reports.details) {
            setViewingDetails(snapshot.val().reports.details);
            console.log(snapshot.val().reports.details);
          }
          if (snapshot.val().reports.files) {
            firebase
              .database()
              .ref(`/projects/${params.id}/reports/files`)
              .on("value", (snapshot) => {
                let arr = [];
                if (snapshot.exists()) {
                  snapshot.forEach((item) => {
                    let value = item.val();
                    value.id = item.key;
                    arr.push(value);
                  });
                }
                setAllFiles(arr);
              });
          }
          if (snapshot.val().reports.video) {
            console.log(snapshot.val().reports.video);

            setAllVideos(snapshot.val().reports.video);
          }
        } else {
          setLoading(false);
        }
      });
  };







  const classes = useStyles();


  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 2.2,
    slidesToScroll: 2.2,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };




  return (
    <>
 
        <>
          {loading ? (
            <Backdrop className={classes.backdrop} open={loading}>
              {/* <Space size="middle"> */}
              <Spin tip="Loading...." size="large" />
              {/* </Space> */}
            </Backdrop>
          ) : (
            <>
              <Paper>       
                  <>
                    <div className="row">
                      <div className="col-md-12 barcontainer">
                        <h2>Admin Reports</h2>
                        <div className="bar__"></div>
                      </div>
                    </div>
                    <Divider />
                    <div className="row">
                      <div className="col-md-12">
                        <div class="folded">
                          <h2>Details</h2>
                        </div>
                       
                      </div>
                    
                    </div>
                    <div className="row" style={{marginTop:"50px",marginLeft:"8px"}}>
                    {
                      viewingDetails?.html?
                     <>  
                     <div className="col-md-12">
                     <div dangerouslySetInnerHTML={{ __html: viewingDetails?.html }}></div>
                     </div>
                     </>
                    :
                    <div className="bordempter">
                      <Empty/>
                    </div>
                    
                     }
                    </div>
                    <Divider />
                    <div className="row">
                      <div className="col-md-6">
                        <div class="folded">
                          <h2>Reports</h2>
                        </div>
                       
                      </div>
                      
               
                     {
                      allFiles.length>0?
                     <>
                      <div
                      style={{
                        paddingLeft: "20px",
                        paddingRight: "20px",
                        paddingTop: "30px",
                        paddingBottom: "20px",
                        width:"100%"
                      }}
                    >
                      <AntCard
                        size="small"
                        title={
                          // <a target="_blank" href={allFiles[0]?.uri}>
                            <p
                            style={{ color: "black", margin: 0 }}>
                              {" "}
                              {allFiles[0]?.name}
                            </p>
                          // </a>
                        }
                        extra={
                     <>
                      
                          <a target="_blank" href={allFiles[0]?.uri}>
                            <button
                            className="clickherebutton">
                              Click here to open
                            </button>
                          </a>
                     </>
                        }
                        style={{
                          width: "auto",
                          height: 700,
                          marginTop: 13,
                          position:"relative"

                        }}
                      >
                        <div className="filesvg text-center">
                          <iframe
                            style={{ height: "700px", width: "100%" }}
                            src={allFiles[0]?.uri}
                          ></iframe>
                        </div>
                        {/* <div style={{position:"absolute",top:"18%",left:0,width:"100%",height:"600px",opacity:0}} 
                      onClick={()=>history.push(`/pdfandcomments/${allFiles[0]?.id}/${params.id}`)}
                      ></div> */}
                      </AntCard>
                    </div>
                     </>
                    :
                    <div className="bordempter">
                      <Empty/>
                    </div>
                    
                     }
                    
                    </div>
 
                    {showMore && allFiles.length>1 ? (
                      <div className="row buttonContainerShow">
                        <div className="">
                          <button
                            className="showButton"
                            onClick={() => {
                              setShowMore(false);
                              setShowLess(true);
                            }}
                          >
                            Show More
                          </button>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}

                    { allFiles &&
                        allFiles.map((item, index) =>
                          index > 0 ? (
                            
                            showLess
                      ?
                            <div
                              style={{
                                paddingLeft: "20px",
                                paddingTop: "30px",
                              paddingRight: "20px",
                                paddingBottom: "20px",
                              }}
                            >
                              <AntCard
                                size="small"
                                title={
                                //   <a target="_blank" href={item?.uri}>
                                    <p
                                    style={{ color: "black", margin: 0 }}>
                                      {" "}
                                      {item?.name}
                                    </p>
                                //    </a>
                                }
                                extra={
                               <>
                              
                                  <a target="_blank" href={item?.uri}>
                                    <button 
                                    onClick={()=>history.push(`/pdfandcomments/${item?.id}/${params.id}`)}
                                    className="clickherebutton">
                                      Click here to open
                                    </button>
                                  </a>
                               </>
                                }
                                style={{
                                  width: "auto",
                                  height: 700,
                                  marginTop: 13,
                                }}
                              >
                                <div className="filesvg text-center">
                                  <iframe
                                    id="desginerPdf"
                                    style={{ height: "700px", width: "100%" }}
                                    src={item?.uri}
                                  ></iframe>
                                </div>
                                {/* <div style={{position:"absolute",top:"18%",left:0,width:"100%",height:"600px",opacity:0}} 
                                onClick={()=>history.push(`/pdfandcomments/${item?.id}/${params.id}`)}
                      ></div> */}
                              </AntCard>
                            </div>
                            : ""
                          ) : (
                            ""
                          )
                        )
                          }
                    {showLess ? (
                      <div className="row buttonContainerShow">
                        <div className="">
                          <button
                            className="showButton"
                            onClick={() => {
                              setShowLess(false);
                              setShowMore(true);
                            }}
                          >
                            Show Less
                          </button>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    <Divider />
                    <div className="row mt-5">
                    <div className="col-md-6">
                        <div class="folded">
                          <h2>Images</h2>
                        </div>
                      </div>
                    </div>
                    {allImages && allImages.length ? (
                      <div className="carsolemasin mt-3">
                        <Slider {...settings}>
                          {allImages.map((img) => (
                            <div>
                              <Card
                                style={{
                                  width: "25rem",
                                  borderRadius: "10px",
                                  height: "300px",
                                }}
                                className="mb-2 slidercard"
                              >
                                <Card.Body style={{ padding: 0 }}>
                                  <div>
                             
                                    <img
                                      style={{ height: "300px" }}
                                      className="sliderCarimg"
                                      src={img.val}
                                      alt=""
                                    />
                                  </div>
                                </Card.Body>
                              </Card>
                            </div>
                          ))}
                        </Slider>
                      </div>
                    ) : 
                    <div className="bordempter">
                      <Empty/>
                    </div>
                    }
                    <Divider />
                    <div className="mt-3">
                   <div className="row">
                   <div className="col-md-6">
                        <div class="folded">
                          <h2>Video</h2>
                        </div>
                      </div>
                   </div>
                   {
                    allVideos.uri?
                    <div className="container" style={{ paddingTop: "50px",paddingBottom:"40px" }}>
                    <Player
                      fluid={false}
                      height={400}
                      width="100%"
                      poster={allVideos.thumbnailUrl}
                      src={allVideos.uri}
                    />
                  </div>
                  :
                  <div className="bordempter">
                  <Empty/>
                </div>
                   }
                    </div>
                  </>
               
              </Paper>
       
            </>
          )}

        </>
     
    </>
  );
};

export default ViewAdminReports;
