
import * as firebase from "firebase";


export const push_key = 'BHOP3wAkzQuvoL1B-a5WCzYzMOlvD6FLSdVRUU6QW4SFwXAziItNEZEOuHtYyg6YKilLUel3Y5lDOziAVIioDik'
// export const push_key = 'BHrvqgclKNmluNyuzrjI8V2laWKI5jD_QzSdyNkZWXT2t__oJJdP4oeKFlSNpeBJ0-CjPjmTJjluz-zSaZudKhE'

export const firebaseConfig = {
  apiKey: "AIzaSyBL8awqNSY090RHuyLJIeZQgNbmwu0r5JE",
  authDomain: "designapp-1cad4.firebaseapp.com",
  databaseURL: "https://designapp-1cad4-default-rtdb.firebaseio.com",
  projectId: "designapp-1cad4",
  storageBucket: "designapp-1cad4.appspot.com",
  messagingSenderId: "851962571399",
  appId: "1:851962571399:web:024d4be70f42d39968e622",
  measurementId: "G-WN6EJELWMW"
};
// export const firebaseConfig = {
//   apiKey: "AIzaSyC7tv6B_blid8jzxwD3GB6UhX44jq9tKDc",
//   authDomain: "dummy-317a9.firebaseapp.com",
//   projectId: "dummy-317a9",
//   storageBucket: "dummy-317a9.appspot.com",
//   messagingSenderId: "227516050779",
//   appId: "1:227516050779:web:1102a01e1ea76f050a3e7e",
//   measurementId: "G-PKQ6TPHHV1"
// };


if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig)
}





export const getTokenfirebase = (setTokenFound) => {
  return firebase.messaging().getToken({ vapidKey: push_key }).then((currentToken) => {
    if (currentToken) {
      console.log('current token for client: ', currentToken);
      setTokenFound(true);
      // Track the token -> client mapping, by sending to backend server
      // show on the UI that permission is secured
    } else {
      console.log('No registration token available. Request permission to generate one.');
      setTokenFound(false);
      // shows on the UI that permission is required 
    }
  }).catch((err) => {
    console.log('An error occurred while retrieving token. ', err);
    // catch error while creating client token
  });
}



export const onMessageListener = () =>
  new Promise((resolve) => {
    firebase.messaging().onMessage((payload) => {
      resolve(payload);
    });
  });


  export const firebaseAuth = firebase.auth();

  export const googleAuthProvider  = new firebase.auth.GoogleAuthProvider()