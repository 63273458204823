import {
    Avatar,
    Card,
    CardContent,
    CardHeader,
    Paper,
  } from "@material-ui/core";
  import { Button, Divider, Input, Card as Carder, Empty, Switch, Popconfirm } from "antd";
  import React, { useEffect, useRef, useState } from "react";
//   import pop from "./audio/sound.mp3";
  import {
    SearchOutlined,
    SendOutlined,
    PhoneOutlined,
    MailOutlined,
    CloseOutlined
  } from "@ant-design/icons";
  import firebase, { database } from "firebase";
  import { useParams } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import toast from "react-hot-toast";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import SVG from "react-inlinesvg";

//   import { SendNotifaction, SendNotifactionChat } from "../../../../../utils/SendNotifaction";
  const AdminChat = () => {
    const [viewChat, setViewChat] = useState([]);
    const [receiverDetails, setReceiverDetails] = useState({});
    const [selectedId, setSelectedID] = useState("");
    const [message, setMessage] = useState("");
    const [currentUser,setCurrentUser]=useState({})
    const [loading,setLoading]=useState(false)
    const [isChecked,setIschecked]=useState(false)
    const [disableConfirm,setDisableConfirm]=useState(false)
    const [projectData,setProjectData]=useState()
    const [chat2,setChat2]=useState([])
    const dummy = useRef();
  const chatRef = useRef();

    useEffect(() => {
        getProjectUser();
      // getAllchatofcurrentUser()
    }, []);
  
    const params = useParams();
    // const currentUser = JSON.parse(localStorage.getItem("firebaseUser"));

    const getProjectUser = () => {
        setLoading(true)
      database()
        .ref("projects")
        .child(params.id)
        .on("value", (snapshot) => {
          if (snapshot.exists()) {
            console.log(snapshot.val(), "this is project");
            setProjectData(snapshot.val())
            database()
              .ref("users")
              .child(snapshot.val().projectCreatorId)
              .on("value", (snapshot) => {
                if (snapshot.exists()) {
                  console.log(snapshot.val() ,"this is user details")
                  setReceiverDetails(snapshot.val());
                  getDesigner(snapshot.val())
                  setCurrentUser(snapshot.val())
                  console.log("this is project user", snapshot.val());
        setLoading(false)
                }else{
        setLoading(false)
                }
              });
          } else {
        setLoading(false)
            console.log("no chat");
          }
        });
    };
    const getDesigner=(projectUSer)=>{
      firebase
      .database()
      .ref(`/projects/${params.id}/designer`)
      .on("value", (snapshot) => {
        if (snapshot.exists()) {
        console.log(snapshot.val(),"this is desginer")
        setReceiverDetails(snapshot.val())



        firebase
        .database()
        .ref(
          `/ChatMessages/${projectUSer.userId}/${
            snapshot.val().userId
          }`
        )
        .on("value", (snapshot) => {
          let arr = [];
          if (snapshot.exists()) {
            snapshot.forEach((item) => {
              let value = item.val();
              value.id = item.key;
              arr.push(value);
            });
          }
          console.log(arr);
          setViewChat(arr);
        });




        firebase
        .database()
        .ref(
          `/ChatMessages/${snapshot.val().userId}/${
            projectUSer.userId
          }`
        )
        .on("value", (snapshot) => {
          let arr = [];
          if (snapshot.exists()) {
            snapshot.forEach((item) => {
              let value = item.val();
              value.id = item.key;
              arr.push(value);
            });
          }
          console.log(arr);
          setChat2(arr);
        });


        }
      });
    }

  
  
   
    // const getProjectUser = () => {
    //   database()
    //     .ref("projects")
    //     .child(params.id)
    //     .on("value", (snapshot) => {
    //       if (snapshot.exists()) {
    //         console.log(snapshot.val(), "this is project");
    //         database()
    //           .ref("users")
    //           .child(snapshot.val().projectCreatorId)
    //           .on("value", (snapshot) => {
    //             if (snapshot.exists()) {
    //               console.log(snapshot.val() ,"this is user details")
    //               setReceiverDetails(snapshot.val());
    //               console.log("this is project user", snapshot.val());
    //               firebase
    //                 .database()
    //                 .ref(
    //                   `/ChatMessages/${currentUser.userId}/${
    //                     snapshot.val().userId
    //                   }`
    //                 )
    //                 .on("value", (snapshot) => {
    //                   let arr = [];
    //                   if (snapshot.exists()) {
    //                     snapshot.forEach((item) => {
    //                       let value = item.val();
    //                       value.id = item.key;
    //                       arr.push(value);
    //                     });
    //                   }
    //                   console.log(arr);
    //                   setViewChat(arr);
    //                 });
    //             }
    //           });
    //       } else {
    //         console.log("no chat");
    //       }
    //     });
    // };
  
    /////send messgae
  
    // const getReceiverUserData = (id) => {
  
    //   firebase
    //   .database()
    //   .ref('users')
    //     .child(id)
    //     .once('value')
    //     .then(snapshot => {
    //       console.log("mubarak ho",snapshot.val())
    //       setReceiverDetails(snapshot.val())
  
    //     });
    // }
  
    // const sendMessages = async (e) => {
    //   e.preventDefault();
    //   if (message == "") {
    //     return;
    //   }
    //   database()
    //     .ref("ChatMessages")
    //     .child(currentUser.userId)
    //     .child(receiverDetails.userId)
    //     .push({
    //       senderUID: currentUser.userId,
    //       receiverUID: receiverDetails.userId,
    //       message: message,
    //     })
    //     .then(() => {
    //       const sound = document.getElementById("sound");
    //       sound.play();
    //       SendNotifactionChat(currentUser.userId,`${currentUser?.userName} sent you the chat`,message,receiverDetails.userId,receiverDetails.userId, "chat_action","hello",receiverDetails.token,"")
    //       setMessage("");
    //       dummy.current.scrollIntoView();
    //       console.log(receiverDetails.userId,"user ki id")
    //       console.log(currentUser.userId,"mari ki id")
    //    firebase.database()
    //         .ref("ChatMessages")
    //         .child(receiverDetails.userId)
    //         .child(currentUser.userId)
    //         .push({
    //           senderUID: currentUser.userId,
    //           receiverUID: receiverDetails.userId,
    //           message: message,
    //         });
  
    //         firebase
    //         .database()
    //         .ref(`/ChatList/${currentUser.userId}/${receiverDetails.userId}`)
    //         .set({
    //           receiverUserName: receiverDetails.userName,
    //           receiverUserImage: receiverDetails.image ? receiverDetails.image:'',
    //           receiverUserEmail: receiverDetails.email,
    //           timeStamp: Date.now(),
    //           senderUID: currentUser.userId,
    //           receiverUID: receiverDetails.userId,
    //           lastmessage: message,
    //           Type: "Send",
    //         });
  
    //       database()
    //         .ref("ChatList")
    //         .child(receiverDetails.userId)
    //         .child(currentUser.userId)
    //         .set({
    //           senderUserName: currentUser.userName,
    //           senderUserImage: currentUser.image ? currentUser.image:'',
    //           senderUserEmail: currentUser.email,
    //           timeStamp: Date.now(),
    //           senderUID: currentUser.userId,
    //           receiverUID: receiverDetails.userId,
    //           lastmessage: message,
    //           Type: "received",
    //         });
        
    //       // // push notification Work
    //       // const receiverToken = receiverDetails ? receiverDetails?.token : 'no token'
    //       // sendPushNotification(currentUser.userId, "Send a new message", senderUserName, receiverUID, receiverUID, "chat_action", "hello", receiverToken)
    //     })
    //     .catch((err) => {
    //       // ToastAndroid.show(err, ToastAndroid.SHORT)
    //     });
    // };
    const changeChatStatus = () => {
            firebase.database().ref('projects')
                .child(params.id)
                .update({
                    isChatDisabled: !isChecked
                }).then(() => {
                    toast.success(`Chat ${isChecked ? "enabled" : "disabled"} successfully`)
                    setDisableConfirm(false)

                    // sendNotification()
                }).catch(err => {
                    toast.error(err.message)
                })
    }
    const scrollToLastMessage = () => {
        // messagesEndRef.current.scrollIntoView();
        chatRef.current.scrollIntoView({ behavior: "smooth" });
      };
      const handleDelete = (id,index) => {
        console.log(receiverDetails.userId,"desginer")
        console.log(currentUser.userId,"user")
        firebase
          .database()
          .ref(`ChatMessages`)
          .child(receiverDetails.userId)
          .child(currentUser.userId)
          .child(chat2[index].id)
          .remove()
          .then(() => {
            console.log("ya success huva?/")
            firebase
            .database()
            .ref(`ChatMessages`)
            .child(currentUser.userId)
            .child(receiverDetails.userId)
            .child(id)
            .remove()
            .then(() => {
              toast.success("Deleted Successfully");
              database()
              .ref('ChatList')
              .child(receiverDetails.userId)
              .child(currentUser.userId)
              .update({
                  lastmessage: viewChat.length>1 ? viewChat[viewChat.length-2].message:'',
              });
        
          database()
              .ref('ChatList')
              .child(currentUser.userId)
              .child(receiverDetails.userId)
              .update({
                  lastmessage: viewChat.length>1 ? viewChat[viewChat.length-2].message:'',
              })
            });
            
          });

        
      };
    
    return (
      <>
        {/* <audio id="sound" src={pop}></audio> */}
       {loading ? 
         <div className="notFoundDesginer">
         <Spinner animation='border' />
       </div>

       :
       <div
          className=" pt-3 hereScroll"
          style={{
            overflowY: "scroll",
            height: "100vh",
            minHeight: "100vh",
            zIndex: "-1",
          }}
        >
          <div className="container" style={{ paddingRight: "0px" }}>
            <div className="row">
              <div className="col-md-4">
                <Carder
                  title="Project Designer"
                  bordered={false}
                  style={{marginBottom:"20px"}}
                >
                  <div className="mainuserprofilediv">
                    <img
                      className="projectUserImg"
                      alt="profilepic"
                      src={receiverDetails.image? receiverDetails.image:"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS2TgOv9CMmsUzYKCcLGWPvqcpUk6HXp2mnww&usqp=CAU"}
                    />
                    <h3 className="profileuserName">
                      {receiverDetails.userName}
                    </h3>
                  </div>
                  <div className="row" style={{ marginTop: "50px" }}>
                    <div className="col-md-1">
                      <PhoneOutlined className="profileIcons" />
                    </div>
                    <div className="col-md-10">
                      <p className="profileSubDetails">
                        {receiverDetails.phoneNumber}
                      </p>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-md-1">
                      <MailOutlined className="profileIcons" />
                    </div>
                    <div className="col-md-10">
                      <p className="profileSubDetails">{receiverDetails.email}</p>
                    </div>
                  </div>
                </Carder>

                <Carder
                  title="Project User"
                  bordered={false}
                >
                  <div className="mainuserprofilediv">
                    <img
                      className="projectUserImg"
                      alt="profilepic"
                      src={currentUser.image? currentUser.image:"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS2TgOv9CMmsUzYKCcLGWPvqcpUk6HXp2mnww&usqp=CAU"}
                    />
                    <h3 className="profileuserName">
                      {currentUser.userName}
                    </h3>
                  </div>
                  <div className="row" style={{ marginTop: "50px" }}>
                    <div className="col-md-1">
                      <PhoneOutlined className="profileIcons" />
                    </div>
                    <div className="col-md-10">
                      <p className="profileSubDetails">
                        {currentUser.phoneNumber}
                      </p>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-md-1">
                      <MailOutlined className="profileIcons" />
                    </div>
                    <div className="col-md-10">
                      <p className="profileSubDetails">{currentUser.email}</p>
                    </div>
                  </div>
                </Carder>
              </div>
              <div className="col-md-8">
                <Card
                  className="herethefixheader"
                  style={{
                    backgroundColor: "#3699FF",
                    boxShadow:
                      "rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px",
                  }}
                >
                  <CardHeader
                    className="herethefixheader"
                    style={{
                      paddingTop: "5px",
                      paddingBottom: "5px",
                      color: "#fff",
                    }}
                  
                    title={
                        <div style={{paddingTop:"10px",paddingBottom:"10px",display:"flex",justifyContent:"space-between"}}>
                            <h6  className='mt-2'>Project Chat</h6>

                            <div>
                          <button className="mr-2" onClick={() => scrollToLastMessage()} style={{border:"none",borderRadius:"5px",padding:"8px",fontSize:"14px"}}>Scroll Down</button>
                            <Popconfirm
        title={isChecked ? "Enable Chat" : "Disable Chat"}
        description={`Are you sure you want to ${isChecked ? "enable chat ?" : "disable chat ?"}`}
        open={disableConfirm}
        // onOpenChange={handleOpenChange}
        onConfirm={()=>{
        changeChatStatus()
        }}
        onCancel={()=>setDisableConfirm(false)}
        okText="Yes"
        cancelText="No"
      >
                            <Switch  checked={!projectData?.isChatDisabled} size="default" onChange={(value)=>{setIschecked(value)
                            setDisableConfirm(true)
                            }
                            } style={{ backgroundColor:!projectData?.isChatDisabled ?  '#52c41a' : 'gray', borderColor: '#52c41a' }} checkedChildren="Enable" unCheckedChildren="Disable" defaultChecked />
                            </Popconfirm>
                            </div>
                        </div>
                    }
                  />
                  <Divider style={{ margin: 0 }} />
                </Card>
  
                <Paper
                  className="orignalChatCont"
                  style={{ height: "78vh", overflow: "scroll",paddingBottom:"20px"}}
                >
                  {viewChat.length > 0 ? (
                    viewChat.map((item,index) => (
                        <>
                        {/* //// for simple message */}
                        {
                         item.message !='' && item.type=="message" ?
                         <div
                         className="row"
                         style={{
                           marginTop: "10px",
                           marginBottom: "10px",
                           marginLeft: "10px",
                         }}
                       >
                         <div
                           className={
                             item.senderUID == currentUser.userId
                               ? "col-md-12 d-flex justify-content-end"
                               : "col-md-12 d-flex"
                           }
                         >
                           {item.senderUID == currentUser.userId ? (
                             <Avatar alt="Remy Sharp" src={currentUser?.image} />
                           ) : (
                             <Avatar
                               alt="Remy Sharp"
                               src={receiverDetails.image}
                             />
                           )}
                           <Card
                             className="messageorignall mx-3"
                             style={
                               item.senderUID == currentUser.userId
                                 ? {
                                   borderRadius: "20px",
                                   backgroundColor: "#3699FF",
                                   maxWidth: "70%",
                                 }
                                 : { borderRadius: "20px", maxWidth: "70%" }
                             }
                           >
                             <CardContent
                               style={{
                                 paddingTop: "5px",
                                 paddingBottom: "5px",
                               }}
                             >
                               <p
                                 className={
                                   item.senderUID == currentUser.userId
                                     ? "messagetext maramessage"
                                     : "messagetext"
                                 }
                               >
                                 {item.message}
                               </p>
                               <div ref={dummy} />
                             </CardContent>
                           </Card>
        <div style={{margin:"5px"}}>
        <a
        
        className="btn btn-icon btn-light btn-hover-danger btn-sm"
        onClick={() => handleDelete(item.id,index)}
      >
        <span className="svg-icon svg-icon-md svg-icon-danger">
          <SVG title="Delete" src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} />
        </span>
      </a>
        </div>
                         </div>
                         <div ref={chatRef}></div>
                       </div>:''
                        }
   
                        {
                         item.type=="image"?
                         <div style={{marginBottom:"10px",marginTop: "10px",}}>
                           <div
                         className="row"
                         style={{
                          marginLeft: "10px",
                         }}
                       >
                         <div
                           className={
                             item.senderUID == currentUser.userId
                               ? "col-md-12 d-flex justify-content-end"
                               : "col-md-12 d-flex"
                           }
                         >
                           {item.senderUID == currentUser.userId ? (
                             <Avatar alt="Remy Sharp" src={currentUser?.image} />
                           ) : (
                             <Avatar
                               alt="Remy Sharp"
                               src={receiverDetails.image}
                             />
                           )}
                          <div style={{height:"200px",width:"200px",borderRadius:"20px",marginLeft:"10px",marginRight:"10px",display:"inline-block"}}>
                               <img style={{height:"100%",width:"100%",borderRadius:"20px"}} src={item.url} alt='image'/>
                          </div>
                          <div style={{margin:"5px"}}>
        <a
        
        className="btn btn-icon btn-light btn-hover-danger btn-sm"
        onClick={() => handleDelete(item.id,index)}
      >
        <span className="svg-icon svg-icon-md svg-icon-danger">
          <SVG title="Delete" src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} />
        </span>
      </a>
        </div>
                         </div>
                       </div>
                       {!!item.message && <div
                        className="row"
                        style={{
                          marginLeft:item.senderUID == currentUser.userId?  "0px" : "60px",
                          marginRight:item.senderUID == currentUser.userId?  "37px" : "0px",
                          marginTop: "10px",
                        }}
                      >
                        <div
                          className={
                            item.senderUID == currentUser.userId
                              ? "col-md-12 d-flex justify-content-end"
                              : "col-md-12 d-flex"
                          }
                        >
                       
                          <Card
                            className="messageorignall mx-3"
                            style={
                              item.senderUID == currentUser.userId
                                ? {
                                    borderRadius: "20px",
                                    backgroundColor: "#3699FF",
                                    maxWidth: "70%",
                                  }
                                : { borderRadius: "20px", maxWidth: "70%" }
                            }
                          >
                            <CardContent
                              style={{
                                paddingTop: "5px",
                                paddingBottom: "5px",
                              }}
                            >
                              <p
                                className={
                                  item.senderUID == currentUser.userId
                                    ? "messagetext maramessage"
                                    : "messagetext"
                                }
                              >
                                {item.message}
                              </p>
                              <div ref={dummy} />
                            </CardContent>
                          </Card>
                        </div>
                      </div>}
                         <div ref={chatRef}></div>
                        </div>
                         :''
                        }
   
   {
                         item.type=="video"?
                         <div style={{marginBottom:"10px",marginTop: "10px",}}>
                           <div
                         className="row"
                         style={{
                           marginLeft: "10px",
                         }}
                       >
                         <div
                           className={
                             item.senderUID == currentUser.userId
                               ? "col-md-12 d-flex justify-content-end"
                               : "col-md-12 d-flex"
                           }
                         >
                           {item.senderUID == currentUser.userId ? (
                             <Avatar alt="Remy Sharp" src={currentUser?.image} />
                           ) : (
                             <Avatar
                               alt="Remy Sharp"
                               src={receiverDetails.image}
                             />
                           )}
                               <video controls src={item.url} style={{height:"200px",width:"200px", margin:"10px"}}/>
                               <div style={{margin:"5px"}}>
        <a
        
        className="btn btn-icon btn-light btn-hover-danger btn-sm"
        onClick={() => handleDelete(item.id,index)}
      >
        <span className="svg-icon svg-icon-md svg-icon-danger">
          <SVG title="Delete" src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} />
        </span>
      </a>
        </div>
                         </div>
                       </div>
                       {!!item.message && <div
                        className="row"
                        style={{
                          marginLeft:item.senderUID == currentUser.userId?  "0px" : "60px",
                          marginRight:item.senderUID == currentUser.userId?  "37px" : "0px",
                        }}
                      >
                        <div
                          className={
                            item.senderUID == currentUser.userId
                              ? "col-md-12 d-flex justify-content-end"
                              : "col-md-12 d-flex"
                          }
                        >
                       
                          <Card
                            className="messageorignall mx-3"
                            style={
                              item.senderUID == currentUser.userId
                                ? {
                                    borderRadius: "20px",
                                    backgroundColor: "#3699FF",
                                    maxWidth: "70%",
                                  }
                                : { borderRadius: "20px", maxWidth: "70%" }
                            }
                          >
                            <CardContent
                              style={{
                                paddingTop: "5px",
                                paddingBottom: "5px",
                              }}
                            >
                              <p
                                className={
                                  item.senderUID == currentUser.userId
                                    ? "messagetext maramessage"
                                    : "messagetext"
                                }
                              >
                                {item.message}
                              </p>
                              <div ref={dummy} />
                            </CardContent>
                          </Card>
                        </div>
                      </div>}

                         <div ref={chatRef}></div>
                        </div>
                         :''
                        }
   
   
   {
                         item.type=="file"?
                         <div style={{marginBottom:"10px",marginTop: "10px",}}>
                          <div
                         className="row"
                         style={{
                           marginLeft: "10px",
                         }}
                       >
                         <div
                           className={
                             item.senderUID == currentUser.userId
                               ? "col-md-12 d-flex justify-content-end"
                               : "col-md-12 d-flex"
                           }
                         >
                           {item.senderUID == currentUser.userId ? (
                             <Avatar alt="Remy Sharp" src={currentUser?.image} />
                           ) : (
                             <Avatar
                               alt="Remy Sharp"
                               src={receiverDetails.image}
                             />
                           )}
                            <div style={{height:"200px",width:"200px",marginLeft:"10px",marginRight:"10px",marginBottom:"10px",display:"inline-block"}}>
                             <a href={item.url} target='_blank'>View</a>
                              <iframe src={item.url} height={180} width={180}/>
                            </div>
                            <div style={{margin:"5px"}}>
        <a
        
        className="btn btn-icon btn-light btn-hover-danger btn-sm"
        onClick={() => handleDelete(item.id,index)}
      >
        <span className="svg-icon svg-icon-md svg-icon-danger">
          <SVG title="Delete" src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} />
        </span>
      </a>
        </div>
                         </div>
                       </div>

                       {!!item.message && <div
                        className="row"
                        style={{
                          marginLeft:item.senderUID == currentUser.userId?  "0px" : "60px",
                          marginRight:item.senderUID == currentUser.userId?  "37px" : "0px",
                        }}
                      >
                        <div
                          className={
                            item.senderUID == currentUser.userId
                              ? "col-md-12 d-flex justify-content-end"
                              : "col-md-12 d-flex"
                          }
                        >
                       
                          <Card
                            className="messageorignall mx-3"
                            style={
                              item.senderUID == currentUser.userId
                                ? {
                                    borderRadius: "20px",
                                    backgroundColor: "#3699FF",
                                    maxWidth: "70%",
                                  }
                                : { borderRadius: "20px", maxWidth: "70%" }
                            }
                          >
                            <CardContent
                              style={{
                                paddingTop: "5px",
                                paddingBottom: "5px",
                              }}
                            >
                              <p
                                className={
                                  item.senderUID == currentUser.userId
                                    ? "messagetext maramessage"
                                    : "messagetext"
                                }
                              >
                                {item.message}
                              </p>
                              <div ref={dummy} />
                            </CardContent>
                          </Card>
                        </div>
                      </div>}
                         <div ref={chatRef}></div>
                       </div>
                         :''
                        }
                        
                       </>
                    ))
                  ) : (
                    <div className="noboardFound">
                    <Empty/>
                    </div>
                  )}
                </Paper>
              </div>
            </div>
          </div>
        </div>}
        {/* <div className="">
          <div className="row">
            <div className="col-md-4"></div>
            <div className="col-md-8 sendBAr">
              <Divider style={{ margin: 0 }} />
  
              <form onSubmit={sendMessages}>
                <Card style={{ marginLeft: "18px" }}>
                  <CardContent
                    style={{ paddingTop: "5px", paddingBottom: "5px" }}
                  >
                    <div className="d-flex">
                      <Input
                        value={message}
                        size="large"
                        placeholder="input with clear icon"
                        allowClear
                        onChange={(e) => setMessage(e.target.value)}
                      />
                      <Button
                        htmlType="submit"
                        onClick={sendMessages}
                        type="primary"
                        icon={<SendOutlined />}
                        size="large"
                      />
                    </div>
                  </CardContent>
                </Card>
              </form>
              <Divider style={{ margin: 0 }} />
            </div>
          </div>
        </div> */}
      </>
    );
  };
  
  export default AdminChat;
  