import React, { Suspense, lazy } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import { BuilderPage } from "./pages/BuilderPage";
import { MyPage } from "./pages/MyPage";
import { DashboardPage } from "./pages/DashboardPage";
import { Books } from "./pages/Books/Books";
import Board from "./pages/Board/Board";
import Designer from "./pages/Designer/Designer";
import Projects from "./pages/Projects/Projects";
import Construction from "./pages/Construction/Construction";
import Users from "./pages/Users/Users";
import ViewProject from "./pages/ViewProject/ViewProject";
import DesignerProjects from "./pages/DesignerModule/DesignerProjects/DesignerProjects";
import DesignerProjectDetails from "./pages/DesignerModule/DesignerProjectDetails/DesignerProjectDetails";
import CreateBoard from "./pages/DesignerModule/CreateBoard/CreateBoard";
import ProjectBoard from "./pages/DesignerModule/DesignerProjectDetails/ProjectBoard/ProjectBoard";
import MainBoard from "./pages/DesignerModule/DesignerProjectDetails/ProjectBoard/MainBoard";
import ChatBox from "./pages/DesignerModule/Chatbox/ChatBox";
import ViewReports from "./pages/ViewProject/ViewReports";
import UserProject from "./pages/UserModule/UserProjects/UserProject";
import ViewUserProject from "./pages/UserModule/UserProjects/ViewUserProject";
import ConstructionProjects from "./pages/ConstructionProjects/ConstructionProjects";
import UserMuiBar from "./pages/UserModule/UserMuiBar/UserMuiBar";
import Chat from "./pages/DesignerModule/DesignerProjectDetails/ProjectBoard/Chat";
import InviteUsers from "./pages/UserModule/InviteUsers/InviteUsers";
import Invitations from "./pages/UserModule/Invitations/Invitations";
import UserDesigners from "./pages/UserModule/UserDesigners/UserDesigners";
import UserConstructors from "./pages/UserModule/UserConstructors/UserConstructors";
import ViewUserReports from "./pages/UserModule/ViewUserReports/ViewUserReports";
import ReportDetails from "./pages/UserModule/ViewUserReports/ReportDetails";
import ProjectFrom from "./pages/UserModule/ProjectForm/ProjectFrom";
import Forms from "./pages/UserModule/Forms/Forms";
import GeneralFrom from "./pages/UserModule/GeneralFrom/GeneralFrom";
import UserConstruProjects from "./pages/UserModule/UserConstruProjects/UserConstruProjects.js";
import ConstruReports from "./pages/UserModule/UserConstruProjects/ConstruReports";
import PersonaInformation from "./modules/UserProfile/PersonaInformation";
import PDFandComments from "./Components/PDFandComments/PDFandComments";
import AllNotifactions from "./pages/AllNotifactions/AllNotifactions";
import UsersNotifactions from "./pages/AdminSendNotifications/UsersNotifactions";
import DesignerNotifications from "./pages/AdminSendNotifications/DesignerNotifications";
import Announcements from "./pages/Announcements/Announcements";
import ViewConstructionProjects from "./pages/ConstructionProjects/ViewConstructionProjects";
import NotificationLog from "./pages/NotificationsLog/NotificationLog";
import PrivacyPolicy from "./modules/Auth/pages/PrivacyPolicy/PrivacyPolicy";
import OtherDesignerProjects from "./pages/OtherDesignerProjects/other-designer-projects.js";
import UserDesigner from "./pages/UserModule/UserDesigner/UserDesigner.js";
import UserConstructions from "./pages/UserConstructions/UserConstructions.js";
import AdminChat from "./pages/AdminChat/AdminChat.js";
const GoogleMaterialPage = lazy(() =>
  import("./modules/GoogleMaterialExamples/GoogleMaterialPage")
);
const ReactBootstrapPage = lazy(() =>
  import("./modules/ReactBootstrapExamples/ReactBootstrapPage")
);
const ECommercePage = lazy(() =>
  import("./modules/ECommerce/pages/eCommercePage")
);
const UserProfilepage = lazy(() =>
  import("./modules/UserProfile/UserProfilePage")
);

export default function BasePage() {
  // useEffect(() => {
  //   console.log('Base page');
  // }, []) // [] - is required if you need only one call
  // https://reactjs.org/docs/hooks-reference.html#useeffect

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          <Redirect exact from="/" to="/dashboard" />
        }
        <ContentRoute path="/dashboard" component={DashboardPage} />
        <ContentRoute path="/builder" component={BuilderPage} />
        <ContentRoute path="/my-page" component={MyPage} />
        <Route path="/google-material" component={GoogleMaterialPage} />

        {/* Admin routes */}
        <ContentRoute role={["Admin"]} path="/books" component={Books} />
        <ContentRoute role={["Admin"]} path="/designers" component={Designer} />
        <ContentRoute role={["Admin"]} path="/projects" component={Projects} />
        <ContentRoute role={["Admin"]} path="/construction" component={Construction} />
        <ContentRoute role={["Admin"]} path="/all-users" component={Users} />
        <ContentRoute role={["Admin"]} path="/view-project/:id" component={ViewProject} />
        <ContentRoute role={["Admin"]} path="/admin-chat/:id" component={AdminChat} />
        <ContentRoute role={["Admin"]} path="/view-reports/:id" component={ViewReports} />
        <ContentRoute role={["Admin"]} path="/cont-project" component={ConstructionProjects} />
        <ContentRoute role={["Admin"]} path="/users-noti" component={UsersNotifactions} />
        <ContentRoute role={["Admin"]} path="/designer-noti" component={DesignerNotifications} />
        <ContentRoute role={["Admin"]} path="/view-contproject/:id" component={ViewConstructionProjects} />
        <ContentRoute role={["Admin"]} path="/notifica-log" component={NotificationLog} />
        <ContentRoute role={["Admin"]} path="/other-designer-projects/:id" component={OtherDesignerProjects} />
        <ContentRoute role={["Admin", "designer", "client"]} path="/all-announcement" component={Announcements} />
        <ContentRoute role={["Admin"]} path="/desginer-details/:id" component={UserDesigner} />



        <ContentRoute
          role={["designer"]}
          path="/designer-projects"
          component={DesignerProjects}
        />
        <ContentRoute
          role={["designer"]}
          path="/create-board"
          component={CreateBoard}
        />
        <ContentRoute
          role={["designer"]}
          path="/board/:id"
          component={MainBoard}
        />

        <ContentRoute
          role={["designer", "client"]}
          path="/all-chats"
          component={ChatBox}
        />
        <ContentRoute
          role={["designer", "client", "Admin"]}
          path="/personal"
          component={PersonaInformation}
        />
        <ContentRoute
          role={["designer", "client", "Admin"]}
          path="/pdfandcomments/:id/:projectId"
          component={PDFandComments}
        />

        <ContentRoute
          role={["client"]}
          path="/chat/:id"
          component={Chat}
        />
        <ContentRoute
          role={["client"]}
          path="/forms/:id"
          component={Forms}
        />
        <ContentRoute
          role={["client"]}
          path="/project-form/:id"
          component={ProjectFrom}
        />
        <ContentRoute
          role={["client"]}
          path="/general-form/:id"
          component={GeneralFrom}
        />
        {/* <ContentRoute
          role={["client"]}
          path="/inviteUsers"
          component={InviteUsers}
        /> */}
        <ContentRoute
          role={["Admin", "designer"]}
          path="/designer-projects-details/:id"
          component={DesignerProjectDetails}
        />
        <ContentRoute
          role={["client"]}
          path="/user-projects"
          component={UserProject}
        />
        <ContentRoute
          role={["client"]}
          path="/user-designers"
          component={UserDesigners}
        />
        {/* <ContentRoute
          role={["client"]}
          path="/user-constructors"
          component={UserConstructors}
        /> */}
        <ContentRoute
          role={["client"]}
          path="/user-construction"
          component={UserConstructions}
        />
        <ContentRoute
          role={["client", "designer"]}
          path="/all-notifications"
          component={AllNotifactions}
        />
        <ContentRoute
          role={["client"]}
          path="/invitations"
          component={Invitations}
        />
        <ContentRoute
          role={["client"]}
          path="/userConstruProjects"
          component={UserConstruProjects}
        />
        <ContentRoute
          role={["client"]}
          path="/construction-project-reports/:id"
          component={ConstruReports}
        />

        <ContentRoute
          role={["client"]}
          path="/view-user-projects/:id"
          component={UserMuiBar}
        />
        <ContentRoute
          role={["client"]}
          path="/user-reports"
          component={ViewUserReports}
        />
        <ContentRoute
          role={["client"]}
          path="/reportsdetails/:id"
          component={ReportDetails}
        />
        {/* <ContentRoute
          role={["client"]}
          path="/projectfrom/:projectId"
          component={ProjectFrom}
        /> */}

        <Route path="/react-bootstrap" component={ReactBootstrapPage} />
        <Route path="/e-commerce" component={ECommercePage} />
        <Route path="/user-profile" component={UserProfilepage} />
        {/* //hamra routes */}
        <Route path="/board" component={Board} />
     

        <Redirect to="error/error-v3" />
      </Switch>
    </Suspense>
  );
}
