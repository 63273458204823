/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { useState } from "react";
import { AddUserProject } from "../../../../app/pages/UserModule/UserProjects/AddUserProject";
import { Modal } from "react-bootstrap";

export function TilesWidget10({ className, widgetHeight = "175px" }) {


  const [addModal, setaddModal] = useState(false);


  return (
    <>
      <div
        className={`card card-custom ${className}`}
        style={{ height: widgetHeight }}
      >
        {/* begin::Body */}
        <div className="card-body d-flex align-items-center justify-content-between flex-wrap">
          <div className="mr-2">
            <h3 className="font-weight-bolder">Create New Project</h3>
            <div className="text-dark-50 font-size-lg mt-2">
              Start Your Project Now
            </div>
          </div>
          <div
            onClick={() => setaddModal(true)}
            className="btn btn-primary font-weight-bold py-3 px-6">
            Create Now
          </div>
        </div>
        {/* end::Body */}
        <Modal size='lg' show={addModal}>
          <Modal.Header>
            <h4>{"Add New Project"}</h4>
          </Modal.Header>
          <AddUserProject
            editItem={""}
            // reload={fetchUserBoards}
            isEdit={false}
            onHide={() => setaddModal(false)}
          />
        </Modal>
      </div>

    </>
  );
}
