// please be familiar with react-bootstrap-table-next column formaters
// https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html?selectedKind=Work%20on%20Columns&selectedStory=Column%20Formatter&full=0&addons=1&stories=1&panelRight=0&addonPanel=storybook%2Factions%2Factions-panel
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../../../../_metronic/_helpers";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

export function ActionsColumnFormatterConsProject(
  cellContent,
  row,
  rowIndex,
  { openEditCustomerDialog, onUploadButtonClick, onViewButtonClick,openDeleteCustomerDialog,attachUsers }
) {
  return (
    <>
      {/* <>
        <OverlayTrigger
          overlay={<Tooltip id="products-delete-tooltip">Update Status</Tooltip>}
        >
          <a
            className="btn btn-icon btn-light btn-hover-primary btn-sm mx-1"
            onClick={() => openEditCustomerDialog(row)}
          >
            <span className="svg-icon svg-icon-md svg-icon-primary">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/General/Update.svg")}
              />
            </span>
          </a>
        </OverlayTrigger>
      </> */}
          <button
          title="Attach Permissions"
          className="btn btn-primary"
          // disabled={row.isLoading}
          onClick={() => attachUsers(row, rowIndex)}
        >
          Attach Users
        </button>

      <>
 
          <a
            className="btn btn-icon btn-light btn-hover-primary btn-sm mx-1"
            onClick={() => onViewButtonClick(row)}
          >
            <span className="svg-icon svg-icon-md svg-icon-primary">
              <SVG
               title="Add On"
                src={toAbsoluteUrl("/media/svg/icons/Code/Plus.svg")}
              />
            </span>
          </a>
      </>
            {/* <OverlayTrigger
        overlay={<Tooltip id="products-delete-tooltip">Edit</Tooltip>}
      > */}
        <a
          className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
          onClick={() => openEditCustomerDialog(row)}
        >
          <span className="svg-icon svg-icon-md svg-icon-primary">
            <SVG
               title="Edit"
              src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")}
            />
          </span>
        </a>
      {/* </OverlayTrigger> */}
      {/* <OverlayTrigger
        overlay={<Tooltip id="products-delete-tooltip">Delete</Tooltip>}
      > */}
        <a
          className="btn btn-icon btn-light btn-hover-danger btn-sm"
          onClick={() => openDeleteCustomerDialog(row.id)}
        >
          <span className="svg-icon svg-icon-md svg-icon-danger">
            <SVG 
             title="Delete"
            src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} />
          </span>
        </a>
      {/* </OverlayTrigger> */}

      <>
        {/* <OverlayTrigger
          overlay={<Tooltip id="products-delete-tooltip">Upload Report</Tooltip>}
        > */}
          <a
            className="btn btn-icon btn-light btn-hover-primary btn-sm mx-1"
            onClick={() => onUploadButtonClick(row)}
          >
            <span className="svg-icon svg-icon-md svg-icon-primary">
              <SVG
                title="Upload Report"
                src={toAbsoluteUrl("/media/svg/icons/Files/Uploaded-file.svg")}
              />
            </span>
          </a>
        {/* </OverlayTrigger> */}
      </>
    </>
  );
}
