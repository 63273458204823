import { Backdrop, CircularProgress, Paper } from "@material-ui/core";
import { Button, Divider, message, Select, Space, Spin, Upload } from "antd";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Modal } from "antd";
import { useHistory, useParams } from "react-router-dom";
import firebase from "firebase";
import { DownloadOutlined } from "@ant-design/icons";
import {
  UploadOutlined,
  FilePdfOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import "react-quill/dist/quill.snow.css";
import ReactQuill from "react-quill";
import axios from "axios";
import { Card as AntCard } from "antd";
import $ from "jquery";
import { toast } from "react-hot-toast";
import Uploader from "../../../app/Images/upload.png";
import { CloseOutlined, ArrowLeftOutlined } from "@ant-design/icons";

import "./projectReport.css";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#333",
  },
}));



const ViewReports = () => {
  const [board, setBoard] = useState([]);
  const params = useParams();
  const [allFiles, setAllFiles] = useState([]);
  const [allImages, setAllImages] = useState([]);
  const [allVideos, setAllVideos] = useState({});
  const [loading, setLoading] = useState(false);
  const [video, setVideo] = useState(null);
  const [thumbnail, setThumbnail] = useState(null);
  const [uploadLoading, setuploadLoading] = useState(false);
  const [image, setImage] = React.useState(null);
  const [deletePicmodal, setDeletePicModal] = useState(false);
  const [deleteFileModal, setDeleteFileModal] = useState(false);
  const [selectedId, setSelectedId] = useState("");
  const [noBoardFound, setNoBoardFound] = useState(false);
  const [details, setDetails] = useState("");
  const [viewingDetails, setViewingDetails] = useState();
  const [imageModal, setImageModal] = useState(false);
  const [images, setImages] = useState([]);
  const [imagesUploadLoading, setImagesUploadLoading] = useState(false)
  const [selectedImageName,setSelectedImageName]=useState("")
  /* Demo purposes only */

  $("figure").mouseleave(function () {
    $(this).removeClass("hover");
  });
  useEffect(() => {
    getProject();
  }, []);
  const history = useHistory()
  const getProject = () => {
    setLoading(true);

    firebase
      .database()
      .ref(`/projects/${params.id}`)
      .on("value", (snapshot) => {
        if (snapshot.val().reports) {
          setLoading(false);
          if (snapshot.val().reports.images) {
            firebase
              .database()
              .ref(`/projects/${params.id}/reports/images`)
              .on("value", (snapshot) => {
                let arr = [];
                if (snapshot.exists()) {
                  snapshot.forEach((item) => {
                    let value = item.val();
                    value.id = item.key;
                    arr.push(value);
                  });
                }
                setAllImages(arr);
              });
          }
          if (snapshot.val().reports.details) {
            setViewingDetails(snapshot.val().reports.details);
            console.log(snapshot.val().reports.details);
          }
          if (snapshot.val().reports.files) {
            firebase
              .database()
              .ref(`/projects/${params.id}/reports/files`)
              .on("value", (snapshot) => {
                let arr = [];
                if (snapshot.exists()) {
                  snapshot.forEach((item) => {
                    let value = item.val();
                    value.id = item.key;
                    arr.push(value);
                  });
                }
                setAllFiles(arr);
              });
          }
          if (snapshot.val().reports.video) {
            console.log(snapshot.val().reports.video);

            setAllVideos(snapshot.val().reports.video);
          }
        } else {
          setLoading(false);
        }
      });
  };
  const handleImageUpload = (img) => {
    updateStatus()
    setuploadLoading(true);
    const storageRef = firebase.storage().ref();
    storageRef
      .child(`reportsImages/${img.name}`)
      .put(img)
      .then(async (snapshot) => {
        snapshot.ref.getDownloadURL().then((val) => {
          if (val) {
            setuploadLoading(false);
          }
          firebase
            .database()
            .ref(`/projects/${params.id}/reports/images`)
            .push({
              val,
              imageName:img.name
            });
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const updateStatus = () => {
    firebase
      .database()
      .ref(`/projects/${params.id}`).update({
        reportStatus: "yes",
      })
  }

  const handleFileUpload = (file) => {
    setuploadLoading(true);
    updateStatus()
    const storageRef = firebase.storage().ref();
    const pushkey = firebase.database().ref(`/projects/${params.id}/reports/files`).push().key
    storageRef
      .child(`reportsImages/${file.name}`)
      .put(file)
      .then(async (snapshot) => {
        snapshot.ref.getDownloadURL().then((val) => {
          if (val) {
            setuploadLoading(false);
          }
          firebase
            .database()
            .ref(`/projects/${params.id}/reports/files`)
            .push({
              id: pushkey,
              uri: val,
              name: file.name,
            });
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleVedioUpload = async (vedio) => {
    if(allVideos.uri){
      const storageRef = firebase.storage().ref();
      const fileRef = storageRef.child(`reportsImages/${allVideos.videoName}`);
      fileRef.delete()
      .then(() => {
        console.log("Video deleted successfully");
      })
      .catch((error) => {
        console.error("Error deleting image:", error);
      });
    }
    setVideo(vedio);
    setuploadLoading(true);
    updateStatus()
    try {
      // get the frame at 1.5 seconds of the video file
      const cover = await getVideoCover(vedio, 1.5);
      // print out the result image blob
      const myFile = new File([cover], "thumbnail", { type: cover.type });
      console.log(myFile, "this is f");

      const storageRef = firebase.storage().ref();
      storageRef
        .child(`reportsImages/${"thumbnail"}`)
        .put(myFile)
        .then(async (snapshot) => {
          snapshot.ref.getDownloadURL().then((thu) => {
            console.log(thu, "this is thumbnail");

            if (thu) {
              const storageRef = firebase.storage().ref();
              storageRef
                .child(`reportsImages/${vedio.name}`)
                .put(vedio)
                .then(async (snapshot) => {
                  snapshot.ref.getDownloadURL().then((val) => {
                    if (val) {
                      setuploadLoading(false);
                    }
                    console.log(cover, "this is cover");
                    firebase
                      .database()
                      .ref(`/projects/${params.id}/reports/video`)
                      .set({
                        uri: val,
                        thumbnail: thu,
                        videoName:vedio.name
                      });
                  });
                })
                .catch((err) => {
                  console.log(err);
                });
            }
          });
        });

      setImage(cover);
    } catch (ex) {
      console.log("ERROR: ", ex);
    }
  };

  const downloadFile = async (fileURL) => {
    console.log(fileURL);
    try {
      const response = await axios.get(fileURL.reportUrl, {
        responseType: "blob", // automatically detect the response type
      });

      const blob = new Blob([response.data], { type: response.data.type });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = "download-file.pdf";
      link.click();
    } catch (error) {
      console.log(error);
    }
  };
  const classes = useStyles();

  function getVideoCover(file, seekTo = 0.0) {
    console.log("getting video cover for file: ", file);
    return new Promise((resolve, reject) => {
      // load the file to a video player
      const videoPlayer = document.createElement("video");
      videoPlayer.setAttribute("src", URL.createObjectURL(file));
      videoPlayer.load();
      videoPlayer.addEventListener("error", (ex) => {
        reject("error when loading video file", ex);
      });
      // load metadata of the video to get video duration and dimensions
      videoPlayer.addEventListener("loadedmetadata", () => {
        // seek to user defined timestamp (in seconds) if possible
        if (videoPlayer.duration < seekTo) {
          reject("video is too short.");
          return;
        }
        // delay seeking or else 'seeked' event won't fire on Safari
        setTimeout(() => {
          videoPlayer.currentTime = seekTo;
        }, 200);
        // extract video thumbnail once seeking is complete
        videoPlayer.addEventListener("seeked", () => {
          console.log("video is now paused at %ss.", seekTo);
          // define a canvas to have the same dimension as the video
          const canvas = document.createElement("canvas");
          canvas.width = videoPlayer.videoWidth;
          canvas.height = videoPlayer.videoHeight;
          // draw the video frame to canvas
          const ctx = canvas.getContext("2d");
          ctx.drawImage(videoPlayer, 0, 0, canvas.width, canvas.height);
          // return the canvas image as a blob
          ctx.canvas.toBlob(
            (blob) => {
              resolve(blob);
            },
            "image/jpeg",
            0.75 /* quality */
          );
        });
      });
    });
  }

  const deleteFile = () => {
    console.log("deleted");
    firebase
      .database()
      .ref(`/projects/${params.id}/reports/files`)
      .child(selectedId)
      .remove()
      .then((res) => {

        const storageRef = firebase.storage().ref();
        const fileRef = storageRef.child(`reportsImages/${selectedImageName}`);
        fileRef.delete()
        .then(() => {
          console.log("Image deleted successfully");
        })
        .catch((error) => {
          console.error("Error deleting image:", error);
        });
        toast.success("Deleted Successfully");

        setDeleteFileModal(false);
      });
  };

  const deleteImg = () => {
    console.log("deleted");
    firebase
      .database()
      .ref(`/projects/${params.id}/reports/images`)
      .child(selectedId)
      .remove()
      .then((res) => {
        const storageRef = firebase.storage().ref();
        const fileRef = storageRef.child(`reportsImages/${selectedImageName}`);
        fileRef.delete()
        .then(() => {
          console.log("Image deleted successfully");
        })
        .catch((error) => {
          console.error("Error deleting image:", error);
        });
        toast.success("Deleted Successfully");
        setDeletePicModal(false);
      });
  };
  const updateDetails = () => {
    updateStatus()
    firebase
      .database()
      .ref(`/projects/${params.id}/reports/details`)
      .update({
        html: details,
      })
      .then(() => {
        setDetails("")
        //   toast.success("Created Successfully");
        //   setModel(false);
        //   history.push("/designer-projects");
      });
  };

  const getDownloadURL = async (file) => {
    const url = await firebase
      .storage()
      .ref(`reportsImages/${file.name}`)
      .getDownloadURL();
    return url;
  };

  const handleUpload = async () => {
    if(images.length==0){
     return toast.error("Please select at least one image")
    }
    setImagesUploadLoading(true);
    const promises = [];

    for (let i = 0; i < images.length; i++) {
      const file = images[i];
      const uploadTask = firebase
        .storage()
        .ref(`reportsImages/${file.name}`)
        .put(file);
      promises.push(uploadTask);
    }

    Promise.all(promises).then(async () => {
      console.log("Uploaded all files");
      const urls = [];
      for (let i = 0; i < images.length; i++) {
        const url = await getDownloadURL(images[i]);
        await firebase
          .database()
          .ref(`/projects/${params.id}/reports/images`)
          .push({
            val: url,
            imageName:images[i].name
          })
          .then(() => {
            if (i + 1 == images.length) {
              setImages([])
              setImagesUploadLoading(false);
              setImageModal(false)
              document.getElementById("imagesupload").value = "";
              toast.success('Uploaded Successfully')
            }
          })
          .catch((err) => {
            if (i + 1 == images.length) {
              setImagesUploadLoading(false);
            }
          });

      }



    });
  };
  const handleInputChange = (event) => {
    const files = event.target.files;

    for (const file of files) {
      setImages((prevImages) => [...prevImages, file]);
    }
    console.log(images);
  };
  const handleImageRemove = (index) => {
    let data = document.getElementById("imagesupload").value
    console.log(data)
    console.log(index);
    images.splice(index, 1);
    setImages([...images]);
  };
  return (
    <>
      {loading ? (
        <Backdrop className={classes.backdrop} open={loading}>
          {/* <Space size="middle"> */}
          <Spin tip="Loading...." size="large" />
          {/* </Space> */}
        </Backdrop>
      ) : (
        <>
          {uploadLoading ? (
            <Backdrop
              style={{ backgroundColor: "rgb(0, 0, 255)" }}
              className={classes.backdrop}
              open={uploadLoading}
            >
              {/* <Space size="middle"> */}
              <Spin tip="uploading" size="large" />
              {/* </Space> */}
            </Backdrop>
          ) : (
            ""
          )}
          <div>
            <input
              onChange={(e) => handleFileUpload(e.target.files[0])}
              style={{ display: "none" }}
              className="btn btn-base"
              type="file"
              id="fileupload"
              accept=".pdf"
            />
            <input
              onChange={(e) => handleVedioUpload(e.target.files[0])}
              style={{ display: "none" }}
              className="btn btn-base"
              type="file"
              id="vedioUpload"
              accept=".mp4"
            />
            <input
              onChange={(e) => handleImageUpload(e.target.files[0])}
              style={{ display: "none" }}
              className="btn btn-base"
              type="file"
              id="imageUpload"
              accept=".png, .jpg, .jpeg"
              multiple
            />
          </div>
          <div className="container">
            <div>
              <div className="form-group fv-plugins-icon-container">
                <AntCard size="small" title="Details" extra={<Button onClick={() => history.push(`/view-project/${params.id}`)} type="primary" shape="round" icon={<ArrowLeftOutlined style={{ position: "relative", top: "-14%" }} />} size="middle">
                  Go Back
                </Button>}>
                  <ReactQuill
                    theme="snow"
                    value={details}
                    onChange={setDetails}
                    // modules={modules}
                    placeholder={"Write something..."}
                  />
                  <div className="text-right mt-5">
                    <Button type="primary" onClick={updateDetails}>
                      Submit
                    </Button>
                  </div>
                </AntCard>
              </div>
              {viewingDetails?.html ? (
                <div className="form-group fv-plugins-icon-container">
                  <AntCard size="small" title="Details">
                    <div dangerouslySetInnerHTML={{ __html: viewingDetails?.html }}></div>


                  </AntCard>
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="row">
              <div className="col-md-4">
                <AntCard
                  size="small"
                  title="Video"
                  extra={
                    <label
                      className="uploadbuttonboard text-primary"
                      htmlFor="vedioUpload"
                    >
                      Upload
                    </label>
                  }
                  style={{
                    width: 300,
                    minHeight: "100vh",
                  }}
                >
                  {allVideos.uri ? (
                    <video
                      controls
                      height={200}
                      width={270}
                      autoPlay={false}
                      src={allVideos.uri}
                    />
                  ) : (
                    <div className="nofoundText">
                      <h3 className="text-primary">No Video Found</h3>
                    </div>
                  )}
                </AntCard>
              </div>
              <div className="col-md-4">
                <AntCard
                  size="small"
                  title="Images"
                  extra={
                    <label
                      className="uploadbuttonboard text-primary"
                      onClick={() => setImageModal(true)}
                    // htmlFor="imageUpload"
                    >
                      Upload
                    </label>
                  }
                  style={{
                    width: 300,
                    minHeight: "100vh",
                  }}
                >
                  {allImages.length > 0 ? (
                    allImages.map((item) => (
                      <section className=" mt-2">
                        <figure class="snip0023">
                          <img src={item.val} alt="sample26" />
                          <div>
                            <i
                              onClick={() => {
                                setSelectedId(item.id);
                                setSelectedImageName(item?.imageName)
                                setDeletePicModal(true);
                              }}
                              className="ion-ios-trash text-danger"
                            ></i>
                            <div class="curl"></div>
                            <a href="#"></a>
                          </div>
                        </figure>
                      </section>
                    ))
                  ) : (
                    <div className="nofoundText">
                      <h3 className="text-primary">No Image Found</h3>
                    </div>
                  )}
                </AntCard>
              </div>
              <div className="col-md-4">
                <AntCard
                  size="small"
                  title="Files"
                  extra={
                    <label
                      className="uploadbuttonboard text-primary"
                      htmlFor="fileupload"
                    >
                      Upload
                    </label>
                  }
                  style={{
                    width: 300,
                    minHeight: "100vh",
                  }}
                >
                  <div className="mt-2">
                    {allFiles.length > 0 ? (
                      allFiles.map((item, i) => (
                        <AntCard
                          size="small"
                          title={
                            item?.name.length > 10
                              ? item?.name.substr(0, 10) + "..."
                              : item?.name
                          }
                          extra={
                            <section className="d-flex">
                              <DeleteOutlined
                                onClick={() => {
                                  setSelectedImageName(item.name)
                                  setSelectedId(item.id);
                                  setDeleteFileModal(true);
                                }}
                                style={{ color: "red" }}
                              />
                              <DownloadOutlined
                                onClick={() => downloadFile(item.uri)}
                                className="text-primary mx-2"
                              />
                            </section>
                          }
                          style={{
                            width: 270,
                            height: 185,
                            marginTop: 13,
                          }}
                        >
                          <div className="filesvg text-center">
                            <FilePdfOutlined
                              style={{ fontSize: "7rem", color: "red" }}
                            />
                          </div>
                        </AntCard>
                      ))
                    ) : (
                      <div className="nofoundText">
                        <h3 className="text-primary">No File Found</h3>
                      </div>
                    )}
                  </div>
                </AntCard>
              </div>
            </div>
          </div>
          <Modal
            title="Delete File"
            centered
            open={deleteFileModal}
            onOk={deleteFile}
            onCancel={() => setDeleteFileModal(false)}
            okText="Delete"
            okButtonProps={{ style: { backgroundColor: "red" } }}
          >
            <div>
              <p>Are you sure you want to delete this</p>
            </div>
          </Modal>
          <Modal
            title="Delete Image"
            centered
            open={deletePicmodal}
            onOk={deleteImg}
            onCancel={() => setDeletePicModal(false)}
            okText="Delete"
            okButtonProps={{ style: { backgroundColor: "red" } }}
          >
            <div>
              <p>Are you sure you want to delete this</p>
            </div>
          </Modal>

          <Modal
            title="Upload Images"
            centered
            open={imageModal}
            onOk={handleUpload}
            confirmLoading={imagesUploadLoading}
            okText="Upload"
            // okButtonProps={{ disabled: uploadLoading }}
            onCancel={() => setImageModal(false)}
          >
            <div className="form-group row">
              <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="avatar-upload-input text-center">
                  <img src={Uploader} alt="img" />
                  <h2>Upload Image</h2>
                  <div className="avatar-edit-input">
                    <input
                      className="form-control"
                      name="file"
                      id="imagesupload"
                      type={"file"}
                      multiple
                      accept=".png, .jpg, .jpeg"
                      onChange={(e) => {
                        handleInputChange(e);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              {images.map((item, index) => (
                <div className="col-md-3 mt-2" style={{ position: "relative" }}>
                  <CloseOutlined
                    onClick={() => handleImageRemove(index)}
                    style={{
                      position: "absolute",
                      top: "-10px",
                      color: "red",
                      right: "3px",
                      cursor: "pointer",
                    }}
                  />
                  <img
                    style={{ height: "100px", width: "100px" }}
                    src={URL.createObjectURL(item)}
                  />
                </div>
              ))}
            </div>
          </Modal>
        </>


      )}
    </>
  );
};

export default ViewReports;
