import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { Input, TextArea } from "../../../_metronic/_partials/controls";
import firebase, { database } from "firebase";
import { toast } from "react-hot-toast";
import Upload from "../../../app/Images/upload.png";



const logo = `https://cdn-icons-png.flaticon.com/512/3772/3772243.png`


export function AddConstructionProject({
    saveCustomer,
    customer,
    actionsLoading,
    onHide,
    editItem,
    reload
}) {
    const [loading, setLoading] = useState(false);
    const [imgShow, setimgShow] = useState("");
    const [image, setimage] = useState("");
    const [imgError, setimgError] = useState(false);
    useEffect(() => {
        if (editItem) {
          setimage(editItem.image)
        }
      }, [])
    const CustomerEditSchema = Yup.object().shape({
        name: Yup.string().required("Name is required"),
        address: Yup.string().required("Address is required"),
        state: Yup.string().required("State is required"),
        city: Yup.string().required("City is required"),
        zip_code: Yup.string().required("Zip Code is required"),
        number: Yup.string().required("Contact Number is required"),
        manager: Yup.string().required("Project Manager is required"),
    });
    const editinitialValues = {
        name: editItem.name,
        address: editItem.address,
        state: editItem.state,
        city: editItem.city,
        zip_code: editItem.zip_code,
        number:editItem.number,
        manager:editItem.manager,
    }

    const addinitialValues = {
        name: '',
        address: '',
        state: '',
        city: '',
        zip_code: '',
        number:'',
        manager:''
    }
    const handleSubmit = async (values, resetForm) => {
        if (image == "") {
            setimgError(true);
            return;
          }
        setLoading(true)
    if(editItem){
        if (imgShow == '') {
            values['image'] = imgShow == '' ? editItem.image : ''
        database()
        .ref("/constructionprojects")
        .child(editItem.constructionprojectId)
        .update(values)
        .then(() => {
            setLoading(false)
            toast.success('Updated Successfully')
            resetForm()
            onHide()
        })
        .catch((err) => {
            setLoading(false)
            console.log(err)
        })
    }else{
        
        const storageRef = firebase.storage().ref();
        storageRef
          .child(`projectimages/${image.name}`)
          .put(image)
          .then(async (snapshot) => {
            snapshot.ref.getDownloadURL().then((val) => {
        values['image'] = val
        database()
            .ref("/constructionprojects")
            .child(editItem.constructionprojectId)
            .update(values)
            .then(() => {
                setLoading(false)
                toast.success('Updated Successfully')
                resetForm()
                onHide()
            })
            .catch((err) => {
                setLoading(false)
                console.log(err)
            })
        })
    })
        .catch((err) => {
            setLoading(false)

          console.log(err);
        });
    }
    }else{


        const storageRef = firebase.storage().ref();
        storageRef
          .child(`projectimages/${image.name}`)
          .put(image)
          .then(async (snapshot) => {
            snapshot.ref.getDownloadURL().then((val) => {
        let pushkey = database().ref("/constructionprojects").push().key
        values['constructionprojectId'] = pushkey
        values['createrId'] = firebase.auth().currentUser.uid
        values['image'] = val

        database()
            .ref("/constructionprojects")
            .child(pushkey)
            .set(values)
            .then(() => {
                setLoading(false)
                toast.success('Created Successfully')
                resetForm()
                onHide()
            })
            .catch((err) => {
                setLoading(false)
                console.log(err)
            })
        })
    })
        .catch((err) => {
            setLoading(false)

          console.log(err);
        });

    }

    }

    return (
        <>
            <Formik
                enableReinitialize={true}
                initialValues={editItem !='' ?editinitialValues:addinitialValues}
                validationSchema={CustomerEditSchema}
                onSubmit={(values, { resetForm }) => {
                  handleSubmit(values, resetForm);
                }}
            >
                {({ values }) => (
                    <>
                        <Form className="form form-label-right">
                            <Modal.Body className="overlay overlay-block cursor-default pt-0 pb-0">
                                {actionsLoading && (
                                    <div className="overlay-layer bg-transparent">
                                        <div className="spinner spinner-lg spinner-success" />
                                    </div>
                                )}
                                <div className="form-group row">

                                    <div className="col-lg-6 col-md-6 col-sm-6 mt-3">
                                        <label>Project Name</label>
                                        <Field
                                            // style={{ height: "8rem" }}
                                            value={values.name}
                                            name="name"
                                            component={Input}
                                            placeholder="Enter Name"
                                        />
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-sm-6 mt-3">
                                        <label>Address</label>
                                        <Field
                                            // style={{ height: "8rem" }}
                                            value={values.address}
                                            name="address"
                                            component={Input}
                                            placeholder="Enter Address"
                                        />
                                    </div>


                                </div>
                                <div className="form-group row">

                                    <div className="col-lg-6 col-md-6 col-sm-6 mt-3">
                                        <label>State</label>
                                        <Field
                                            // style={{ height: "8rem" }}
                                            value={values.state}
                                            name="state"
                                            component={Input}
                                            placeholder="Enter State"
                                        />
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-sm-6 mt-3">
                                        <label>City</label>
                                        <Field
                                            value={values.city}
                                            name="city"
                                            component={Input}
                                            placeholder="Enter City Name"
                                        />
                                    </div>
                                 
                                </div>
                                <div className="form-group row">
                                <div className="col-lg-6 col-md-6 col-sm-6 mt-3">
                                        <label>Zip Code</label>
                                        <Field
                                            value={values.zip_code}
                                            name="zip_code"
                                            component={Input}
                                            placeholder="Enter Zip Code"
                                        />
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-sm-6 mt-3">
                                        <label>Contact Number</label>
                                        <Field
                                            value={values.number}
                                            name="number"
                                            component={Input}
                                            placeholder="Enter Contact Number"
                                        />
                                    </div>
</div>

                                <div className="form-group row">

                              
                                    <div className="col-lg-6 col-md-6 col-sm-6 mt-3">
                                        <label>Project manager </label>
                                        <Field
                                            value={values.manager}
                                            name="manager"
                                            component={Input}
                                            placeholder="Enter project manager"
                                        />
                                    </div>
                                </div>
                                {
                  imgShow ?
                    <div className="row">
                      <div className="col-md-4">
                        <img src={imgShow} style={{ height: "200px", width: "300px" }} />
                      </div>
                    </div>

                    : ''
                }

                {



                  editItem && imgShow == '' ?
                    <div className="row">
                      <div className="col-md-4">
                        <img src={editItem.image} style={{ height: "200px", width: "300px" }} />
                      </div>
                    </div> : ''
                }
                <div className="form-group row">
                  <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className="avatar-upload-input text-center">
                      <img src={Upload} alt="img" />
                      <h2>Upload Image</h2>
                      <div className="avatar-edit-input">
                        <input
                          className="form-control"
                          name="file"
                          type={"file"}
                          onChange={(e) => {
                            setimgError(false);
                            setimage(e.target.files[0]);
                            setimgShow(URL.createObjectURL(e.target.files[0]));
                          }}
                        />
                        {imgError ? (
                          <label className="text-danger">
                            Image is required
                          </label>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <button
                                    type="button"
                                    onClick={onHide}
                                    className="btn btn-light btn-elevate"
                                >
                                    Cancel
                                </button>
                                <> </>
                                <button
                                    disabled={loading}
                                    type="submit"
                                    className="btn btn-primary btn-elevate"
                                >
                                    Save
                                </button>
                            </Modal.Footer>
                        </Form>

                    </>
                )}
            </Formik>
        </>
    );
}
