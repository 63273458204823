import React from 'react';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
// import Tabs from '@material-ui/core/Tabs';
import { Tabs } from 'antd';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import ViewUserProject from '../UserProjects/ViewUserProject';
import Comments from '../../DesignerModule/DesignerProjectDetails/ProjectBoard/Comments';
import UserDesigner from '../UserDesigner/UserDesigner';
import ProjectFrom from '../ProjectForm/ProjectFrom';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import firebase from 'firebase';
import ReportDetails from '../ViewUserReports/ReportDetails';
import ViewAdminReports from '../../../Components/ViewAdminReports/ViewAdminReports';







function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
}));
const UserMuiBar = () => {
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const [projectUser,setProjectUser]=React.useState({})

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const params=useParams()

  const handleChangeIndex = (index) => {
    setValue(index);
  };
  useEffect(() => {
    getProjectUser()
  }, [])
  
  const getProjectUser=()=>{
    firebase
    .database()
    .ref(`/projects/${params.id}/designer`)
    .on("value", (snapshot) => {
      if (snapshot.exists()) {
            console.log(snapshot.val(),"this is user")
            setProjectUser(snapshot.val())
          }
        });

  }


  const onChange = (key) => {
    console.log(key);
  };
  const items = [
    {
      key: '1',
      label: 'Project Details',
      children: <ViewUserProject/>,
      // icon:<CloudUploadOutlined />
    },
    {
      key: '2',
      label: 'Comments',
      children: <Comments projectUser={projectUser}/>,
      // icon:<CommentOutlined />
    },
    {
      key: '3',
      label: 'Designer',
      children:  <UserDesigner/>,
      // icon:<WechatOutlined />
    },
    {
      key: '4',
      label: 'Admin Reports',
      children:  <ViewAdminReports id={params.id}/>,
    },
  ];
  return (
    <>
     <div className='container'>
   <Tabs  size='large' defaultActiveKey="1" items={items} onChange={onChange} />
   </div>
      {/* <div className={classes.root}>
        <AppBar position="static" color="#333">
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
            aria-label="full width tabs example"
          >
            <Tab label="Project Details" {...a11yProps(0)} />
            <Tab label="Comments" {...a11yProps(1)} />
            <Tab label="Designer" {...a11yProps(2)} />
          </Tabs>
        </AppBar>
        <SwipeableViews
          axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
          index={value}
          onChangeIndex={handleChangeIndex}
        >
          <TabPanel value={value} index={0} dir={theme.direction}>
          <ViewUserProject/>
          </TabPanel>
          <TabPanel value={value} index={1} dir={theme.direction}>
          <Comments projectUser={projectUser}/>
          </TabPanel>
          <TabPanel value={value} index={2} dir={theme.direction}>
          <UserDesigner/>
          </TabPanel>
        </SwipeableViews>
      </div> */}
    </>
  )
}

export default UserMuiBar