import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
  Pagination,
} from "../../../_metronic/_partials/controls";
import DeleteModel from '../../Components/DeleteModel/DeleteModel';
import { Modal, Spinner } from "react-bootstrap";
import firebase from "firebase";
import { makeStyles } from "@material-ui/core/styles";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import SVG from "react-inlinesvg";
import * as columnFormatters from "../../modules/ECommerce/pages/customers/customers-table/column-formatters";
import * as uiHelpers from "../../modules/ECommerce/pages/customers/CustomersUIHelpers";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";

const NotificationLog = () => {
  const [addModal, setaddModal] = useState(false);
  const [editItem, setEditItem] = useState({});
  const [editModal, seteditModal] = useState(false);
  const [boards, setBoards] = useState([]);
  const [loading, setloading] = useState(false);
  const [selectedId, setSelectedId] = useState("");
  const [deleteModal, setdeleteModal] = useState(false);
  const [notifactionUserList, setNotifactionUserList] = useState([]);
  const [userDetails, setuserDetail] = useState({});
  const [searchText, setsearchText] = useState("");
  const userLocal = JSON.parse(localStorage.getItem('firebaseUser'))

  ////for pagination
  const initialFilter = {
    pageNumber: 1,
    pageSize: 10,
  };

  const [queryParams, setQueryParamsBase] = useState(initialFilter);

  const columns = [
    // {
    //   dataField: "image",
    //   text: "User Image",
    //   formatter: (row, cell) => (
    //     <>
    //       <img style={{ height: 50, width: 50 }} src={cell.image ? cell.image : toAbsoluteUrl("/media/users/blank.png")} />
    //     </>
    //   )

    // },
    {
      dataField: "receiverName",
      text: "User Name",
    },
    {
      dataField: "receiverEmail",
      text: "Email",
    },
    {
      dataField: "title",
      text: "Title",
    },
    {
      dataField: "message",
      text: "description",
    },
    {
      dataField: "id",
      text: "Actions",
      formatter: (id) =>(
          <>
          {userLocal.type === "Admin"?
          <a
          className="btn btn-icon btn-light btn-hover-danger btn-sm"
          onClick={() => onDeleteClick(id)}
        >
          <span className="svg-icon svg-icon-md svg-icon-danger">
            <SVG
              title="Delete"
              src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")}
            />
          </span>
        </a>:null}
            
          </>
        )

        //    openNotificationModal: (user) => {
        // const resultUser=     boards.filter((item)=>{
        //      return  user.id==item.id
        //      })
        //      setNotifactionUserList(resultUser)
        //      setaddModal(true);
        //    },
      ,
      classes: "text-right pr-0",
      headerClasses: "text-right pr-3",
      style: {
        minWidth: "100px",
      },
    },
  ];

  const useStyles = makeStyles({
    root: {
      minWidth: 275,
      margin: 5,
    },
    bullet: {
      display: "inline-block",
      margin: "0 2px",
      transform: "scale(0.8)",
    },
    title: {
      color: "black",
      fontSize: 14,
    },
    pos: {
      marginBottom: 12,
    },
  });

  const classes = useStyles();

  useEffect(() => {
    fetchNotifications();
    getUser();
  }, []);

  const fetchNotifications = () => {
    setloading(true);
    firebase
      .database()
      .ref("AdminNotification")
      .on("value", (snapshot) => {
        setloading(false);
        let arr = [];
        if (snapshot.exists()) {
          snapshot.forEach((item) => {
            let value = item.val();
            value.id = item.key;
            arr.push(value);
          });
        }
        setBoards(arr.reverse());
        console.log(arr)
      });
  };
  const getUser = () => {
    console.log("on Auth chala");

    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        firebase
          .database()
          .ref("users")
          .child(user.uid)
          .on("value", (snapshot) => {
            if (snapshot.exists()) {
              setuserDetail(snapshot.val());
            }
          });
      }
    });
  };
  console.log(selectedId);
// console.log(getUser())
  const onEditClick = (item) => {
    setEditItem(item);
    seteditModal(true);
    setaddModal(true);
  };

  const onDeleteClick = (id) => {
    setSelectedId(id);
    setdeleteModal(true);
  };

  const updatingQueryParams = (page) => {
    console.log(page);
    setQueryParamsBase({
      pageNumber: page,
    });
  };

  const onPaginationSelectChange = () => {
    setQueryParamsBase({
      pageNumber: 1,
    });
  };

  const dataSearch = boards.filter((user) => {
    let text = searchText.toLowerCase();
    const resultent = text
      ? user?.receiverName?.toLowerCase().includes(text)
      : true;
    return resultent;
  });
  const options = {
    custom: true,
    totalSize: dataSearch.length,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: queryParams.pageSize,
    page: queryParams.pageNumber,
  };

  return (
    <>
      <Card>
        <CardHeader title="Notifactions Log">
          <CardHeaderToolbar>
            {/* <button
              type="button"
              className="btn btn-primary"
              onClick={() => {
                setNotifactionUserList(boards)
                setaddModal(true)}}
            >
              Send to all
            </button> */}
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          <div className="col-lg-4">
            <small className="form-text text-muted">
              <b>Search</b> By User Name
            </small>
            <input
              type="text"
              className="form-control"
              name="searchText"
              placeholder="Search"
              value={searchText}
              onChange={(e) => setsearchText(e.target.value)}
              // onBlur={handleBlur}
              // value={values.searchText}
              // onChange={(e) => {
              //   setFieldValue("searchText", e.target.value);
              //   handleSubmit();
              // }}
            />
          </div>

          <PaginationProvider pagination={paginationFactory(options)}>
            {({ paginationProps, paginationTableProps }) => {
              return (
                <>
                  <Pagination
                    updatingQueryParams={updatingQueryParams}
                    onPaginationSelectChange={onPaginationSelectChange}
                    isLoading={loading}
                    paginationProps={paginationProps}
                  >
                    <BootstrapTable
                      wrapperClasses="table-responsive"
                      bordered={false}
                      classes="table table-head-custom table-vertical-center overflow-hidden constritaTable"
                      bootstrap4
                      keyField="id"
                      data={dataSearch}
                      columns={columns}
                      defaultSorted={uiHelpers.defaultSorted}
                      {...paginationTableProps}
                    ></BootstrapTable>
                  </Pagination>
                  {/* )} */}
                </>
              );
            }}
          </PaginationProvider>
        </CardBody>
      </Card>
      {/* <Modal size="md" show={addModal}>
        <Modal.Header>
          <h4>{notifactionUserList.length==1?`Send Notification To ${notifactionUserList[0].userName}`:
          `Send Notification To All Users`
          }</h4>
        </Modal.Header>
        <SendNotifactionModal
        currentUserData={userDetails}
        users={notifactionUserList}
          onHide={() => setaddModal(false)}
        />
      </Modal> */}
         <DeleteModel
        onHide={() => setdeleteModal(false)}
        show={deleteModal}
        title="Delete Notification"
        refer={"AdminNotification"}
        id={selectedId}
      />
    </>
  );
};

export default NotificationLog;
