import { Paper } from "@material-ui/core";
import { Button, Divider, message, Select, Space, Spin, Tooltip } from "antd";
import Upload from "../../../../app/Images/upload.png";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Modal } from "antd";
import { useHistory } from "react-router-dom";
import firebase from "firebase";
import "./createboard.css";
import vedio1 from "./BoardVeios/Vedio1.mp4";
import vedio2 from "./BoardVeios/Vedio2.mp4";
import vedio3 from "./BoardVeios/Vedio3.mp4";
import { DownloadOutlined } from "@ant-design/icons";
import { UploadOutlined } from "@ant-design/icons";
import { forEach } from "lodash";
import { toast } from "react-hot-toast";
const CreateBoard = () => {
  const history = useHistory();
  const [projects, setProjects] = useState([]);
  const [selectedId, setSelectedId] = useState("");
  const [name, setName] = useState("");
  const [documents, setdocuments] = useState([]);
  const [orignalDocument, setOrignalDocument] = useState([]);
  const [image, setImage] = useState("");
  const [buttonDisable, setButtonDisable] = useState(false);
  const [goBack, setGoBack] = useState(false);
  const [projectError, setProjectError] = useState(false);
  const [nameError, setnameError] = useState(false);
  const user = JSON.parse(localStorage.getItem("firebaseUser"));

  useEffect(() => {
    getAllProjects();
  }, []);
  const getAllProjects = () => {
    firebase
      .database()
      .ref("projects")
      .on("value", (snapshot) => {
        let arr = [];
        if (snapshot.exists()) {
          snapshot.forEach((item) => {
            let value = item.val();
            value.id = item.key;
            arr.push(value);
          });
        }
        let filterd = arr.filter(
          (item) => item?.designer?.userId == user.userId
        );
        console.log(filterd);
        const noBoardFilters = filterd.filter((subitem) => {
          if (subitem.designer?.board) {
            return console.log("board hy");
          } else {
            return subitem;
          }
        });

        let selectarray = [];
        noBoardFilters.forEach((element) => {
          selectarray.push({
            value: element.id,
            label: element.projectTitle,
          });
        });
        setProjects(selectarray);
        console.log(selectarray);
      });
  };
  const [model, setModel] = useState(true);

  const handelOkClick = () => {
    if (selectedId == "") {
      setProjectError(true);
      return;
    }

    firebase
      .database()
      .ref(`/projects/${selectedId}/designer`)
      .update({
        board: {
          name: "Board",
        },
      })
      .then(() => {
        toast.success("Created Successfully");
        setModel(false);
        history.push(`/board/${selectedId}`);
      });
    // firebase
    //   .database()
    //   .ref("projects")
    //   .child(selectedId)
    //   .once("value", (snapshot) => {
    //     if (snapshot.exists()) {
    //       let { designer } = snapshot.val();

    //       const board = {
    //         images:[]
    //       };
    //       let tempObj = designer;
    //       tempObj["board"] = board;
    //       firebase
    //         .database()
    //         .ref("projects")
    //         .child(selectedId)
    //         .update({
    //           designer: tempObj,
    //         })
    //         .then(() => {
    //           setModel(false);
    //           history.push('/designer-projects')
    //         });
    //       console.log(snapshot.val());
    //     }
    //   });

    // firebase
    // .database()
    // .ref("projects")
    // .child(selectedId)
    // .once("value", (snapshot) => {
    //   if (snapshot.exists()) {
    //     let { designer } = snapshot.val();
    //     console.log(snapshot.val());
    //     let temp = designer;
    //     let temp2 = designer.board;
    //     const storageRef = firebase.storage().ref();
    //     storageRef
    //       .child(`boardimages/${image.name}`)
    //       .put(image)
    //       .then(async (snapshot) => {
    //         snapshot.ref.getDownloadURL().then((val) => {
    //           temp2["fileUrl"] = val;
    //           temp["board"] = temp2;

    //           console.log(temp);
    //           firebase
    //             .database()
    //             .ref("projects")
    //             .child(selectedId)
    //             .update({
    //               designer: temp,
    //             });
    //         });
    //       })
    //       .catch((err) => {
    //         console.log(err);
    //       });
    //   }
    // });
  };

  const handleImageUpload = (image) => {
    firebase
      .database()
      .ref("projects")
      .child(selectedId)
      .once("value", (snapshot) => {
        if (snapshot.exists()) {
          let { designer } = snapshot.val();
          console.log(snapshot.val());
          let temp = designer;
          let temp2 = designer.board;
          const storageRef = firebase.storage().ref();
          storageRef
            .child(`boardimages/${image.name}`)
            .put(image)
            .then(async (snapshot) => {
              snapshot.ref.getDownloadURL().then((val) => {
                temp2["fileUrl"] = val;
                temp["board"] = temp2;

                console.log(temp);
                firebase
                  .database()
                  .ref("projects")
                  .child(selectedId)
                  .update({
                    designer: temp,
                  });
              });
            })
            .catch((err) => {
              console.log(err);
            });
        }
      });
  };
  const handleFileUpload = (img) => {
    firebase
      .database()
      .ref("projects")
      .child(selectedId)
      .once("value", (snapshot) => {
        if (snapshot.exists()) {
          let { designer } = snapshot.val();
          console.log(snapshot.val());
          let temp = designer;
          let temp2 = designer.board;
          const storageRef = firebase.storage().ref();
          storageRef
            .child(`boardimages/${img.name}`)
            .put(img)
            .then(async (snapshot) => {
              snapshot.ref.getDownloadURL().then((val) => {
                let data = {
                  url: val,
                  type: "file",
                };
                temp2["fileUrl"] = data;
                temp["board"] = temp2;

                console.log(temp);
                firebase
                  .database()
                  .ref("projects")
                  .child(selectedId)
                  .update({
                    designer: temp,
                  });
              });
            })
            .catch((err) => {
              console.log(err);
            });
        }
      });
  };
  const handleVedioUpload = (img) => {
    firebase
      .database()
      .ref("projects")
      .child(selectedId)
      .once("value", (snapshot) => {
        if (snapshot.exists()) {
          let { designer } = snapshot.val();
          console.log(snapshot.val());
          let temp = designer;
          let temp2 = designer.board;
          const storageRef = firebase.storage().ref();
          storageRef
            .child(`boardimages/${img.name}`)
            .create(img)
            .then(async (snapshot) => {
              snapshot.ref.getDownloadURL().then((val) => {
                let data = {
                  url: val,
                  type: "vedio",
                };
                temp2["fileUrl"] = data;
                temp["board"] = temp2;

                console.log(temp);
                firebase
                  .database()
                  .ref("projects")
                  .child(selectedId)
                  .update({
                    designer: temp,
                  });
              });
            })
            .catch((err) => {
              console.log(err);
            });
        }
      });
  };
  const handleChangeDocuments = (e) => {
    setButtonDisable(true);

    setOrignalDocument([]);
    const documentsArray = [...documents];
    let isValid = "";

    for (let i = 0; i < e.target.files.length; i++) {
      documentsArray.push(e.target.files[i]);
    }
    setdocuments(documentsArray);

    console.log("documentsarry", documentsArray);

    const yrr = [];

    documentsArray.forEach((item, i) => {
      const storageRef = firebase.storage().ref();
      storageRef
        .child(`boardimages/${item.name}`)
        .put(item)
        .then(async (snapshot) => {
          snapshot.ref.getDownloadURL().then((val) => {
            if (val && i + 1 == documentsArray.length) {
              setButtonDisable(false);
              setGoBack(false);
            }
            yrr.push({
              type: item.type,
              url: val,
            });
          });
        })
        .catch((err) => {
          console.log(err);
        });
      console.log(yrr);
      setOrignalDocument(yrr);
    });
    // if(buttonDisable){
    //   setTimeout(() => {
    //     setGoBack(true)
    //  }, 20000);
    // }
  };

  return (
    <>
      <Paper
        style={{
          marginTop: "10px",
          marginBottom: "10px",
          padding: "15px",
          paddingLeft: 0,
        }}
      >
        {/* <div className="row">
          <div className="col-md-4 mx-2">
            <h2>Board Name</h2>
          </div>
        </div> */}
        {/* <Divider />
        <div className="row" style={{ paddingLeft: 0 }}>
          <div className="col-md-2 text-center">
            <h3 className="sidebanner">Vedios</h3>
          </div>
          <div className="col-md-2">
            <div>
              <input
                onChange={(e) => handleVedioUpload(e.target.files[0])}
                style={{ display: "none" }}
                className="btn btn-base"
                type="file"
                id="vedioUpload"
                accept=".mp4"
                multiple
              />
              <label className="btn uploadbutton" htmlFor="vedioUpload">
                Click to upload
              </label>
            </div>
          </div>
        </div> */}
        <div className="container">
          <div className="row"></div>
        </div>
        {/* <Divider />
        <div className="row">
          <div className="col-md-2 text-center">
            <h3 className="sidebanner">Images</h3>
          </div>
          <div className="col-md-2">
            <div>
              <input
                onChange={(e) => handleImageUpload(e.target.files[0])}
                style={{ display: "none" }}
                className="btn btn-base"
                type="file"
                id="imageUpload"
            
                multiple
              />
              <label className="btn uploadbutton" htmlFor="imageUpload">
                Click to upload
              </label>
            </div>
          </div>
        </div> */}
        <div className="container mt-1">
          <div className="row"></div>
        </div>

        {/* <Divider />
        <div className="row" style={{ paddingLeft: 0 }}>
          <div className="col-md-2 text-center">
            <h3 className="sidebanner">Files</h3>
          </div>
          <div className="col-md-2">
            <div>
              <input
                onChange={(e) => handleFileUpload(e.target.files[0])}
                style={{ display: "none" }}
                className="btn btn-base"
                type="file"
                id="fileupload"
                multiple
              />
              <label className="btn uploadbutton" htmlFor="fileupload">
                Click to upload
              </label>
            </div>
          </div>
        </div> */}
      </Paper>

      <Modal
        title="Assign Board to Project"
        centered
        open={model}
        onOk={handelOkClick}
        okText="Save"
        okButtonProps={{ disabled: buttonDisable }}
        onCancel={() => history.push("/designer-projects")}
      >
        {/* {buttonDisable?
          <Space
          direction="vertical"
          style={{
            width: '100%',
            height:"100%",
            position:"absolute",
            left:0,
            top:0,
            backgroundColor:`#fff`,
            zIndex:111,
            alignItems:"center"
            
            
           
            
          }}
        >
            <Spin style={{marginTop:"100px"}} tip="uploading.....">
            </Spin>
          <div style={{textAlign:"center"}}>
            <p>Opps I wanted to select the other file</p>
            <button onClick={()=>{
              setButtonDisable(false)
             
            }} className="text-primary" style={{border:"none",backgroundColor:"transparent",fontStyle:"italic",fontWeight:"500"}}>Select other File</button>
            </div>
            </Space>:''}
        */}
        <div className="container">
          <div className="row">
            <div className="col-md-12 pt-2 pb-2 d-flex justify-content-center">
              <Select
                onChange={(value) => {
                  setProjectError(false);
                  setSelectedId(value);
                }}
                showSearch
                style={{ width: 400 }}
                placeholder="Search to Select"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label ?? "").includes(input)
                }
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? "")
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? "").toLowerCase())
                }
                options={projects}
              />
            </div>
            {projectError ? (
              <label style={{ paddingLeft: "25px" }} className="text-danger">
                Please Select the project
              </label>
            ) : (
              ""
            )}
          </div>
          {/* <div className="col-md-12 mt-5">
            <div className="avatar-upload-input text-center">
           <img src={Upload} alt="img" />:''
           <h2>Upload</h2>
      
        
              <div className="avatar-edit-input">
           <Tooltip placement="topLeft" title={selectedId?'':"Please select project first"}>

                <input
                disabled={selectedId?false: true}
                  className="form-control"
                  name="file"
                  type={"file"}
                  onChange={(e) => handleChangeDocuments(e)}
                  style={{ padding: "5px" }}
                  multiple
                />
      </Tooltip>

              </div>
            </div>
          </div> */}
        </div>
      </Modal>
    </>
  );
};

export default CreateBoard;
