/* eslint-disable no-unused-vars */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useState, useMemo } from "react";
import { Nav, Tab, Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import SVG from "react-inlinesvg";
import objectPath from "object-path";
import { useHtmlClassService } from "../../../_core/MetronicLayout";
import { toAbsoluteUrl } from "../../../../_helpers";
import { DropdownTopbarItemToggler } from "../../../../_partials/dropdowns";
import { useEffect } from "react";
import firebase from "firebase";
import { toast } from "react-hot-toast";

const perfectScrollbarOptions = {
  wheelSpeed: 2,
  wheelPropagation: false,
};

export function UserNotificationsDropdown() {

  const userLocal = JSON.parse(localStorage.getItem('firebaseUser'))
  const [key, setKey] = useState("Alerts");
  const bgImage = toAbsoluteUrl("/media/misc/bg-2.png");

  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      offcanvas:
        objectPath.get(uiService.config, "extras.notifications.layout") ===
        "offcanvas",
    };
  }, [uiService]);


  const [notificationList, setnotificationList] = useState([])


  useEffect(() => {
    getNotifications()
  }, [])

  const getNotifications = () => {

    firebase.database()
      .ref('notifications')
      .child(firebase.auth().currentUser.uid)
      .on('value', snapshot => {
        let arr = [];
        if (snapshot.exists()) {
          snapshot.forEach((item) => {
            let value = item.val();
            value.id = item.key;
            arr.push(value);
          });
          // let filterd = arr.filter(not => not.action == 'invitation')
          setnotificationList(arr.reverse())
        }
      })

  }


  console.log('list of notifications', notificationList)



  const getTime = (timestamp) => {
    const now = new Date();
    const elapsedMilliseconds = now.getTime() - timestamp;

    const elapsedSeconds = Math.floor(elapsedMilliseconds / 1000);
    const elapsedMinutes = Math.floor(elapsedSeconds / 60);
    const elapsedHours = Math.floor(elapsedMinutes / 60);
    const elapsedDays = Math.floor(elapsedHours / 24);

    let message = "";

    if (elapsedDays > 0) {
      message = elapsedDays + "d" + " " + "ago";
    } else if (elapsedHours > 0) {
      message = elapsedHours + "h" + " " + "ago";
    } else if (elapsedMinutes > 0) {
      message = elapsedMinutes + "m" + " " + "ago";
    } else {
      message = "just now";
    }

    return message
  }

  const onAction = (projectId, status) => {
    const user = firebase.auth().currentUser;
    if (user != null) {
      const uid = user.uid;
      firebase.database().ref(`projects/${projectId}/invitedUsers/${uid}`)
        .update({ status })
        .then(() => {
          firebase
            .database()
            .ref('notifications')
            .child(userLocal.userId)
            .child(projectId)
            .remove()
            toast.success('Status updated successfully to ' + status);
            getNotifications()
        })
        .catch((error) => {
          console.error(error);
        });
      // Use the uid to perform operations that require the user's UID
    } else {
      // User is not logged in
    }

  }

  return (
    <>
      {layoutProps.offcanvas && (
        <div className="topbar-item">
          <div
            className="btn btn-icon btn-clean btn-lg mr-1 pulse pulse-primary"
            id="kt_quick_notifications_toggle"
          >
            <span className="svg-icon svg-icon-xl svg-icon-primary">
              <SVG src={toAbsoluteUrl("/media/svg/icons/General/Notifications1.svg")} />
            </span>
            <span className="pulse-ring"></span>
          </div>
        </div>
      )}
      {!layoutProps.offcanvas && (
        <Dropdown drop="down" alignRight>
          <Dropdown.Toggle
            as={DropdownTopbarItemToggler}
            id="kt_quick_notifications_toggle"
          >
            <OverlayTrigger
              placement="bottom"
              overlay={
                <Tooltip id="user-notification-tooltip">
                  User Notifications
                </Tooltip>
              }
            >
              <div
                className="btn btn-icon btn-clean btn-lg mr-1 pulse pulse-primary"
                id="kt_quick_notifications_toggle"
              >
                <span className="svg-icon svg-icon-xl svg-icon-primary">
                  <SVG
                    src={toAbsoluteUrl("/media/svg/icons/General/Notifications1.svg")}
                  />
                </span>
                <span className="pulse-ring"></span>
                <span className="pulse-ring" />
              </div>
            </OverlayTrigger>
          </Dropdown.Toggle>

          <Dropdown.Menu className="dropdown-menu p-0 m-0 dropdown-menu-right dropdown-menu-anim-up dropdown-menu-lg">

            {/** Head */}
            <div
              className="d-flex flex-column pt-12 bgi-size-cover bgi-no-repeat rounded-top"
              style={{ backgroundImage: `url(${bgImage})` }}
            >
              <h4 className="d-flex flex-center rounded-top">
                <span className="text-white">User Notifications</span>
                <span className="btn btn-text btn-success btn-sm font-weight-bold btn-font-md ml-2">
                  {notificationList.length}
                </span>
              </h4>

              <Tab.Container defaultActiveKey={key}>
                <Nav
                  as="ul"
                  className="nav nav-bold nav-tabs nav-tabs-line nav-tabs-line-3x nav-tabs-line-transparent-white nav-tabs-line-active-border-success mt-3 px-8"
                  onSelect={(_key) => setKey(_key)}
                >
                  <Nav.Item className="nav-item" as="li">
                    <Nav.Link
                      eventKey="Alerts"
                      className={`nav-link show ${key === "Alerts" ? "active" : ""
                        }`}
                    >
                      Notifications
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item as="li">
                    <Nav.Link
                      eventKey="Events"
                      className={`nav-link show ${key === "Events" ? "active" : ""
                        }`}
                    >
                      Invitations
                    </Nav.Link>
                  </Nav.Item>
                  {/* <Nav.Item as="li">
                      <Nav.Link
                        eventKey="Logs"
                        className={`nav-link show ${
                          key === "Logs" ? "active" : ""
                        }`}
                      >
                        Logs
                      </Nav.Link>
                    </Nav.Item> */}
                </Nav>

                <Tab.Content className="tab-content">
                  <Tab.Pane eventKey="Alerts" className="p-8">
                    <PerfectScrollbar
                      options={perfectScrollbarOptions}
                      className="scroll pr-7 mr-n7"
                      style={{ maxHeight: "300px", position: "relative" }}
                    >
                      {
                        notificationList.map((notif) => (
                          <div className="d-flex align-items-center mb-6">
                            <div className="symbol symbol-40 symbol-light-primary mr-5">
                              <span className="symbol-label">
                              <div
                        style={{ width: "50px", height: "40px", display: "block" }}
                      >
                        <img style={{ height: "100%", width: "100%" }} src={notif.profileImage || "https://drehomes.com/wp-content/uploads/dummy-image.jpeg"} alt="img" />
                      </div>
                                {/* <SVG
                                  src={toAbsoluteUrl(
                                    "/media/svg/icons/Home/Library.svg"
                                  )}
                                  className="svg-icon-lg svg-icon-primary"
                                ></SVG> */}
                              </span>
                              <b>
                              {notif.userName}
                                </b>
                            </div>
                            <div className="d-flex flex-column font-weight-bold">
                              <a
                                href="#"
                                className="text-dark text-hover-primary mb-1 font-size-lg"
                              >
                                {notif.title}
                              </a>
                            
                              <span className="text-muted">
                                {notif.message}
                              </span>
                            </div>
                          </div>
                        ))
                      }

                    </PerfectScrollbar>
                  </Tab.Pane>
                  <Tab.Pane
                    eventKey="Events"
                    id="topbar_notifications_events"
                  >
                    <PerfectScrollbar
                      options={perfectScrollbarOptions}
                      className="navi navi-hover scroll my-4"
                      style={{ maxHeight: "300px", position: "relative" }}
                    >

                      {
                        notificationList.map((notif) => (
                          
                            notif.action =='invitation'?
                            <div className="navi-item">
                            <div className="navi-link">
                              <div className="navi-icon mr-2">
                                <i className="flaticon2-paper-plane text-danger"></i>
                              </div>
                              <div className="navi-text">
                                <div className="font-weight-bold">
                                  {notif.userName} {notif.message}
                                </div>
                                <div className="text-muted">{getTime(notif.timeStamp)}</div>
                              </div>

                              <button onClick={() => onAction(notif.projectId, 'accept')} className="btn btn-success m-1">
                                Accept
                              </button>
                              <button onClick={() => onAction(notif.projectId, 'reject')} className="btn btn-danger m-1">
                                Reject
                              </button>
                            </div>
                          </div>:
                          ''
                          

                        ))
                      }



                    </PerfectScrollbar>
                  </Tab.Pane>


                  <Tab.Pane eventKey="Logs" id="topbar_notifications_logs">
                    <div className="d-flex flex-center text-center text-muted min-h-200px">
                      All caught up!
                      <br />
                      No new notifications.
                    </div>
                  </Tab.Pane>
                </Tab.Content>
              </Tab.Container>
            </div>
          </Dropdown.Menu>
        </Dropdown>
      )}
    </>
  );
}
