import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { database } from "firebase";
const EditBooks = ({ open, handleClose,data }) => {
   console.log(data)
    const [buttonLoading,setButtonLoading]=useState(false)
    const [name,setName]=useState(data?.name)
    const [price,setPrice]=useState(data?.price)
  const useStyles = makeStyles((theme) => ({
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      //   border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
  }));

  const classes = useStyles();

  const onSubmit = () => {
    setButtonLoading(true)
    database()
      .ref("/books").child(data.id)
      .update({
        name: name,
        price: price,
      })
      .then(() =>{
        setButtonLoading(false)
        handleClose()
        console.log("Data set.")  
      }
      ).catch((err)=>console.log(err))
  };
  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <div>
              <h5 className="text-center">Add Book</h5>
              <div className="row">
                <div className="col-md-12">
                  <label>Name</label>
                  <input value={name} onChange={(e)=>setName(e.target.value)} type="text" className="form-control" />
                </div>
                <div className="col-md-12">
                  <label>Price</label>
                  <input value={price} onChange={(e)=>setPrice(e.target.value)} type="number" className="form-control" />
                </div>
              </div>
              <div>
                <button
                disabled={buttonLoading}
                  style={{ float: "right" }}
                  className="btn btn-primary mt-5"
                  onClick={onSubmit}
                >
                  Add
                </button>
              </div>
            </div>
          </div>
        </Fade>
      </Modal>
    </>
  );
};

export default EditBooks;
