import React, { useState, useEffect } from 'react'
import { Modal } from 'react-bootstrap'
import firebase from 'firebase'
import { toast } from 'react-hot-toast'
import { SendNotifaction } from '../../../utils/SendNotifaction'
const AssignProjectModal = ({
    visible,
    object,
    hideModal
}) => {


    const currentUser=JSON.parse(localStorage.getItem("firebaseUser"))

    const [usersList, setUsersList] = useState([])

    const [selectedUserId, setSelectedUserId] = useState("")



    useEffect(() => {
        fetchBoards();
    }, []);

    const fetchBoards = () => {

        firebase
            .database()
            .ref("users")
            .on("value", (snapshot) => {
                let arr = [];
                if (snapshot.exists()) {
                    snapshot.forEach((item) => {
                        let value = item.val();
                        value.id = item.key;
                        arr.push(value);
                    });
                }
                const orignalarray = arr.filter((item) => item.type == "designer")
                setUsersList(orignalarray);
            });
    };



    const assignDesigner = () => {
        firebase.database().ref("users")
            .child(selectedUserId)
            .once('value', snapshot => {
                if (snapshot.exists()) {
                    let user = snapshot.val()
                    user['designerId'] = user.userId
                    firebase.database().ref('projects')
                        .child(object.projectId)
                        .update({
                            designerStatus:"yes",
                            designer: user
                        }).then(() => {
                            SendNotifaction(`${currentUser?.userName}`,`Assigned you the ${object.projectTitle} Project `,user.token,"")
                            toast.success(`Project assign to ${user.userName} successfully`)
                            hideModal()
                        })
                }
            })
    }


    return (
        <Modal
            centered
            show={visible}>
            <Modal.Header>
                <Modal.Title>Assign Project to Designer</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <select
                    className='form-control'
                    onChange={(e) => setSelectedUserId(e.target.value)}
                >
                    <option value={''}>Select Designer</option>
                    {
                        usersList.map((user) => (
                            <option value={user.userId}>{user.userName}</option>
                        ))
                    }
                </select>

            </Modal.Body>
            <Modal.Footer>
                <button onClick={hideModal} className='btn btn-danger'>Cancel</button>
                <button onClick={assignDesigner} disabled={selectedUserId == '' ? true : false} className='btn btn-primary'>Assign</button>
            </Modal.Footer>
        </Modal>
    )
}

export default AssignProjectModal