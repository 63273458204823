import React, { useEffect, useState } from 'react'
import { Card, CardBody, CardHeader, CardHeaderToolbar, Pagination } from '../../../_metronic/_partials/controls'
import { Modal, Spinner } from 'react-bootstrap';
import { Card as MiCard, CardActions, CardContent, LinearProgress, Paper, Typography, CardMedia } from '@material-ui/core'
import firebase from 'firebase';
import { makeStyles } from '@material-ui/core/styles';
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import * as columnFormatters from '../../modules/ECommerce/pages/customers/customers-table/column-formatters';
import * as uiHelpers from '../../modules/ECommerce/pages/customers/CustomersUIHelpers';
import { toast } from 'react-hot-toast';
import { StatusColumnFormatter } from '../../helpers/UIhelpers';
import { DatePicker, Progress } from 'antd';
import { useHistory } from 'react-router-dom'
import { AddConstructionProject } from '../../Components/AddConstructionProject/AddConstructionProject';
import { Modal as AntModal,Checkbox, Divider } from 'antd';
import Upload from "../../../app/Images/upload.png";
import './myConstructionProject.css'
const statusList = [{
  id: 1,
  title: 'Approve',
  value: 'approved'
},
{
  id: 2,
  title: 'Reject',
  value: 'rejected'

},

]

const ConstructionProjects = () => {


  const history = useHistory()

  const [addModal, setaddModal] = useState(false);
  const [editItem, setEditItem] = useState({})
  const [editModal, seteditModal] = useState(false);
  const [boards, setBoards] = useState([])
  const [loading, setloading] = useState(false)
  const [selectedId, setSelectedId] = useState("")
  const [deleteModal, setdeleteModal] = useState(false);
  const [uploadModal, setUploadModal] = useState(false);
  const [fileLoading, setFileLoading] = useState(false)
  const [updateModal, setUpdateModal] = useState(false)
  const [date,setDate]=useState('')
  const [currentToken, setcurrentToken] = useState('')
  const [imgError, setimgError] = useState(false);
  const [attachModal, setattachModal] = useState(false);
  const [allUsers, setAllUsers] = useState([]);
  const [progress, setProgress] = useState(0)
  const [attachedLoading,setAttachedLoading]=useState(false)

  const [status, setStatus] = useState('')


  const [file, setFile] = useState('')

  ////for pagination
  const initialFilter = {
    pageNumber: 1,
    pageSize: 10,
  };


  const [queryParams, setQueryParamsBase] = useState(initialFilter);


  const columns = [
    {
      dataField: "image",
      text: "Project Image",
      formatter: (row, cell) => (
        <>
            {cell.image ?  <img style={{ height: 50, width: 50 }} src={cell.image} /> : "N/A"}
        </>
      ),
    },
    {
      dataField: "name",
      text: "Name",
    },
    {
      dataField: "address",
      text: "Address",
    },
    {
      dataField: "state",
      text: "State",
    },
    {
      dataField: "city",
      text: "City",
    },
    {
      dataField: "zip_code",
      text: "Zip Code",
    },
    // {
    //   dataField: "status",
    //   text: "Status",
    //   formatter: StatusColumnFormatter,
    // },
    {
      dataField: "action",
      text: "Actions",
      formatter: columnFormatters.ActionsColumnFormatterConsProject,
      formatExtraData: {
        attachUsers:(item)=>{handlemodalopen(item)},
        openDeleteCustomerDialog: (id) =>{ setdeleteModal(true) 
          setSelectedId(id)},
          openEditCustomerDialog: (item) => openEdit(item),
        onUploadButtonClick: (id) => onUploadButtonClick(id),
        onViewButtonClick: (item) => history.push(`/view-contproject/${item.constructionprojectId}`)

      },
      classes: "text-right pr-0",
      headerClasses: "text-right pr-3",
      style: {
        minWidth: "290px",
      },
    },
  ];


  const useStyles = makeStyles({
    root: {
      minWidth: 275,
      margin: 5,
    },
    bullet: {
      display: 'inline-block',
      margin: '0 2px',
      transform: 'scale(0.8)',
    },
    title: {
      color: 'black',
      fontSize: 14,
    },
    pos: {
      marginBottom: 12,
    },
  });


  const classes = useStyles();


  const onUploadButtonClick = (row) => {
    setSelectedId(row.constructionprojectId)
    setUploadModal(true)
    console.log(row)

  }


  useEffect(() => {
    fetchConProjects();
    fetchUsers()
  }, []);

  const fetchConProjects = () => {
    setloading(true);
    firebase
      .database()
      .ref("constructionprojects")
      .on("value", (snapshot) => {
        setloading(false);
        let arr = [];
        if (snapshot.exists()) {
          snapshot.forEach((item) => {
            let value = item.val();
            value.id = item.key;
            arr.push(value);
          });
        }
        setBoards(arr.reverse());
      });
  };
  const fetchUsers = () => {
    firebase
      .database()
      .ref("users")
      .on("value", (snapshot) => {
        let arr = [];
        if (snapshot.exists()) {
          snapshot.forEach((item) => {
            let value = item.val();
            value.id = item.key;
            arr.push(value);
          });
        }
      const clientUsers=  arr.filter((user)=>user.type=='client')
        setAllUsers(clientUsers);
      });
  };


  const openEdit = (item) => {
    setEditItem(item);
    seteditModal(true);
    setaddModal(true);
  };



  const onDeleteClick = (id) => {
    setSelectedId(id)
    setdeleteModal(true)
  }

  const options = {
    custom: true,
    totalSize: boards.length,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: queryParams.pageSize,
    page: queryParams.pageNumber,
  };




  const updatingQueryParams = (page) => {
    console.log(page);
    setQueryParamsBase({
      pageNumber: page,
    });
  };

  const onPaginationSelectChange = () => {
    setQueryParamsBase({
      pageNumber: 1,
    });
  };


  const updateStatus = () => {
    if (status == '') {
      toast.error('Please select a status')
    } else {
      firebase.database().ref('projects')
        .child(selectedId)
        .update({
          status: status
        }).then(() => {
          toast.success('Status updated successfully')
          setUpdateModal(false)
          // sendNotification()
        }).catch(err => {
          toast.error(err.message)
        })
    }

  }




  const uploadFile = () => {
    if(file==""){
      setimgError(true)
      return
    }
    if (file) {
      setFileLoading(true)
      const storageRef = firebase.storage().ref();
      storageRef.child(`customerReports/${file.name}`)
        .put(file)
        .then((async (snapshot) => {
          let percentComplete = 100 * (snapshot.bytesTransferred / snapshot.totalBytes)
          setProgress(percentComplete)
          snapshot.ref.getDownloadURL().then((val) => {
            firebase
            .database()
            .ref(`/constructionprojects/${selectedId}/constructionProjectsReport`)
              .push({
                date:Date.now(date),
                uri: val,
                fileType: file.type,
                name: file.name
              }).then(() => {
                toast.success('File uploaded successfully')
                setFileLoading(false)
                setUploadModal(false)
              }).catch(err => {
                setFileLoading(false)
                toast.error(err.message)
              })

          })
        }))
    } else {
      toast.error('Please select a file to upload')
    }
  }

  const handleDeleteProject=()=>{
    firebase
    .database()
    .ref(`constructionprojects`)
    .child(selectedId)
    .remove()
    .then(() => {
        setdeleteModal(false)
      toast.success('Project Deleted Successfully')
    });
}
const onDateChange = (date, dateString) => {
 setDate(dateString)
};
const handleCheckAllChange = (event) => {
  setAllUsers((prevData) => {
    const newData = [...prevData];
    newData.forEach((item) => (item.checked = event.target.checked));
    return newData;
  });
}
const handleCheckboxChange = (itemId) => {
  setAllUsers((prevData) => {
    const newData = [...prevData];
    const itemIndex = newData.findIndex((item) => item.id === itemId);
    newData[itemIndex].checked = !newData[itemIndex].checked;
    return newData;
  });
};;

const handlemodalopen=(data)=>{
  setSelectedId(data.constructionprojectId)
  let checkboxes = allUsers;
  const dummy=[]
  allUsers.map((item, index) => {
    if (data.attachedUsers?.includes(item.userId)) {
      item["checked"] = true;
      item["selected"] = true;
      checkboxes[index] = item;
      dummy[index]=item
    } else {
      item["checked"] = false;
      item["selected"] = false;
      checkboxes[index] = item;
    }
  });
  console.log("List", checkboxes);
  setAllUsers(checkboxes);
  setattachModal(true)
}

const attachedUserClick=()=>{
  setAttachedLoading(true)
  const checkedUsers = allUsers
      .filter((item) => item.checked)
      .map((item) => item.id);

      firebase.database().ref('constructionprojects')
      .child(selectedId)
        .update({
          attachedUsers:checkedUsers,
        }).then((res) => {
          toast.success('Attached successfully')
          setAttachedLoading(false)
          setattachModal(false)
  
        }).catch(err => {
  setAttachedLoading(false)
          toast.error(err.message)
        })
}
  return (
    <>
      <Card>
        <CardHeader title="Construction Projects List">
          <CardHeaderToolbar>
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => {
                seteditModal(false)
                setaddModal(true)}}
            >
              New Construction Project
            </button>
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>

          <PaginationProvider pagination={paginationFactory(options)}>
            {({ paginationProps, paginationTableProps }) => {
              return (
                <>
                  <Pagination
                    updatingQueryParams={updatingQueryParams}
                    onPaginationSelectChange={onPaginationSelectChange}
                    isLoading={loading}
                    paginationProps={paginationProps}
                  >
                    <BootstrapTable
                      wrapperClasses="table-responsive"
                      bordered={false}
                      classes="table table-head-custom table-vertical-center overflow-hidden constritaTable"
                      bootstrap4
                      keyField="id"
                      data={boards}
                      columns={columns}
                      defaultSorted={uiHelpers.defaultSorted}
                      {...paginationTableProps}
                    ></BootstrapTable>
                  </Pagination>
                  {/* )} */}
                </>
              );
            }}
          </PaginationProvider>


        </CardBody>
      </Card>



      <Modal
        show={uploadModal}
        onHide={() => setUploadModal(true)}
      >
        <Modal.Header>
          <Modal.Title>{`Upload Report`}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='row'>
             <div className='col-md-12'>
             <DatePicker style={{width:"100%"}} onChange={onDateChange} />

             </div>
          </div>
          <div className="form-group row mt-4">
                  <div className="col-lg-12 col-md-12 col-sm-12 mt-2">
                    <div className="avatar-upload-input text-center">
                      <img src={Upload} alt="img" />
                      <h2>Upload Report</h2>
                      <div className="avatar-edit-input">
                      <input
            onChange={(e) => {
              setimgError(false)
              setFile(e.target.files[0])}}
            type={'file'}
          // accept='.docs .png .jpg .pdf .doc'
          />
                        <div>
                        {imgError ? (
                          <label className="text-danger">
                            Report is required
                          </label>
                        ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
    
          {
            fileLoading ?
              <Progress type="line" percent={progress} /> : null
          }

        </Modal.Body>
        <Modal.Footer>
          {
            fileLoading ?
              <Spinner />
              :
              <>
                <button onClick={() => setUploadModal(false)} className='btn btn-danger'>Cancel</button>
                <button onClick={uploadFile} className='btn btn-primary'>Upload Report</button>

              </>
          }
        </Modal.Footer>
      </Modal>


      <Modal 
      size='lg'
        animation
        show={addModal}>
        <Modal.Header>
          <Modal.Title>{'Add Project'}</Modal.Title>
        </Modal.Header>
          <AddConstructionProject
            editItem={editModal ? editItem : ""}
            reload={fetchConProjects}
            isEdit={editModal ? true : false}
            onHide={() => setaddModal(false)}
        

          />
      </Modal>
      <AntModal
        title="Delete Construction Projects"
        centered
        open={deleteModal}
        onOk={handleDeleteProject}
        okText="Delete"
        okButtonProps={{style:{backgroundColor:"red"}}}
        onCancel={() => setdeleteModal(false)}
      >
       <p>Are you sure you want to delete it</p>
      </AntModal>
      <Modal size="xl" show={attachModal}>
        <Modal.Header>{"Attach Users"}</Modal.Header>
        <Modal.Body>
          {/* <ReactSelect
                        defaultValue={selecetedPerm}
                        options={list}
                        isMulti
                        // loading={true}
                        name="roles"
                        onChange={e => onChangePerm(e)}
                        className="basic-multi-select"
                        classNamePrefix="select"
                    /> */}
          {/* <Select
                        mode="multiple"
                        allowClear
                        style={{
                            width: '100%',
                        }}
                        placeholder="Please select"
                        defaultValue={selecetedPerm}
                        onChange={onChangePerm}
                        options={list}
                    /> */}
          <Checkbox checked={allUsers.every((a)=>a.checked)} onChange={handleCheckAllChange}>Check All</Checkbox>

          <Divider style={{marginBottom:"0px"}}/>
          <div
        //   style={{
        //       display: 'flex',
        //       flexWrap: 'wrap',

        //   }}
          >
    
            {allUsers.map((user, i) => (
              <>
        <span
                  style={{ width: "25%"}}
                >
                  <Checkbox
                  style={{ paddingRight: "30px",paddingTop:"5px"}}
                  className="givingspace"
                    checked={user.checked}
                    onChange={(event) => handleCheckboxChange(user.userId)}
                  >
                    {user.userName}
                  </Checkbox>
                </span>
              </>
            ))}
          </div>
        </Modal.Body>

        <Modal.Footer>
          <button
            onClick={() => setattachModal(false)}
            className="btn btn-danger"
          >
            Cancel
          </button>
            <button disabled={attachedLoading} onClick={attachedUserClick}  className="btn btn-primary">
              Attach Users
            </button>
        </Modal.Footer>
      </Modal>

    </>
  )
}

export default ConstructionProjects

