import React, { useEffect, useState } from 'react'
import { CardActions, CardContent, LinearProgress, Paper, Typography } from '@material-ui/core'
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import VisibilityIcon from '@material-ui/icons/Visibility';
import EditIcon from '@material-ui/icons/Edit';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { Modal } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import DeleteModel from '../../Components/DeleteModel/DeleteModel';
import firebase from 'firebase';
import { AddAnnouncement } from './AddAnnouncement';
import { Card, CardBody, CardHeader, CardHeaderToolbar } from '../../../_metronic/_partials/controls';
import './annoucment.css'
import SVG from "react-inlinesvg";
import { Button as Butter } from 'antd';
import { toAbsoluteUrl } from '../../../_metronic/_helpers';

const useStyles = makeStyles({
  root: {
    minWidth: 275,
    margin: 5,


  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    color: 'black',
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});


const Announcements = () => {
  const classes = useStyles();
  const [deleteModal, setdeleteModal] = useState(false);
  const [addModal, setaddModal] = useState(false);
  const [editModal, seteditModal] = useState(false);
  const [announcement, setAnnouncement] = useState([])
  const [loading, setloading] = useState(false)
  const [selectedId, setSelectedId] = useState("")
  const [editItem, setEditItem] = useState({})
  const [users, setAllUsers] = useState([])
  const history = useHistory()
  const [userDetails, setuserDetail] = useState({})

  useEffect(() => {
    fetchAnnouncement();
    fetchUsers();
    getUser()

  }, []);

  const fetchAnnouncement = () => {
    setloading(true);
    firebase
      .database()
      .ref("Announcement")
      .on("value", (snapshot) => {
        setloading(false);
        let arr = [];
        if (snapshot.exists()) {
          snapshot.forEach((item) => {
            let value = item.val();
            value.id = item.key;
            arr.push(value);
          });
        }
        setAnnouncement(arr.reverse());
      });
  };


  const fetchUsers = () => {
    setloading(true);
    firebase
      .database()
      .ref("users")
      .on("value", (snapshot) => {
        setloading(false);
        let arr = [];
        if (snapshot.exists()) {
          snapshot.forEach((item) => {
            let value = item.val();
            value.id = item.key;
            arr.push(value);
          });
        }
        const clientUsers = arr.filter((user) => user.type !== 'Admin')
        setAllUsers(clientUsers);
      });
  };
  const getUser = () => {
    console.log("on Auth chala")

    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        firebase.database().ref('users')
          .child(user.uid)
          .on('value', snapshot => {
            if (snapshot.exists()) {
              setuserDetail(snapshot.val())
            }
          })
      }
    })
  }
console.log(selectedId)
  const onDeleteClick = (id) => {
    setSelectedId(id)
    setdeleteModal(true)
  }

  const onEditClick = (item) => {
    setEditItem(item)
    seteditModal(true)
    setaddModal(true)
  }

  return (
    <>
      {loading ? <LinearProgress /> : null}
      <Card>
        <CardHeader title="Announcements">
          <CardHeaderToolbar>
            {
              userDetails.type == "Admin" ?
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => {
                    seteditModal(false)
                    setaddModal(true)
                  }}
                >
                  Add Announcement
                </button> :
                ''
            }
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          {
            announcement.map((item) => (
              <div className='annousmentCardCont'>
                <div className='annousmentCard'>
                  <div className='announcementImage'>
                   {item.image? <img src={item.image} />:''}
                  </div>
                  <div className='anouncementTitle'>
                    <div>
                      <h3>{item.title}</h3>
                    </div>
                    <div>
                      <p>{item.description}</p>
                    </div>
                  </div>
                  {
                    userDetails.type == "Admin" ?
                      <div className='annoutbutton'>
                        <a
                          title="Edit"
                          className="btn btn-icon btn-light btn-hover-primary btn-sm mx-2"
                          onClick={() => onEditClick(item)}
                        >
                          <span className="svg-icon svg-icon-md svg-icon-primary">
                            <SVG
                              title="Edit"
                              src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")}
                            />
                          </span>
                        </a>
                        <a
                          className="btn btn-icon btn-light btn-hover-danger btn-sm"
                          onClick={() => onDeleteClick(item.id)}
                        >
                          <span className="svg-icon svg-icon-md svg-icon-danger">
                            <SVG title="Delete" src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} />
                          </span>
                        </a>
                      </div>
                      :
                      null
                  }
                </div>
              </div>
            ))
          }

        </CardBody>
      </Card>

      <Modal
        centered
        show={addModal}
      >
        <Modal.Header><h4>
          {editModal ? 'Edit Announcement' : 'Add Announcement'}
        </h4></Modal.Header>
        <AddAnnouncement
          users={users}
          currentUserData={userDetails}
          editItem={editModal ? editItem : ''}
          isEdit={editModal ? true : false}
          saveCustomer={values => console.log(values)}
          onHide={() => setaddModal(false)}
        />
      </Modal>
      <DeleteModel
        onHide={() => setdeleteModal(false)}
        show={deleteModal}
        title="Delete Announcement"
        refer={"Announcement"}
        id={selectedId}
      />
    </>
  )
}

export default Announcements