import React from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { useState } from "react";
import firebase from "firebase";
import { Card as AntCard, Divider, Empty, Tooltip } from "antd";
import { DownloadOutlined, FilePdfOutlined } from "@ant-design/icons";
import workers from "../../../Images/workers.png";
import metrial from "../../../Images/metrial.png";
import payment from "../../../Images/payment.png";
import paper from "../../../Images/paper.png";
import live from "../../../Images/live.png";
import { FaCity } from "react-icons/fa";
import { FaGlobe } from "react-icons/fa";
import { FaMapSigns } from "react-icons/fa";
import { FaHome } from "react-icons/fa";
const ConstruReports = () => {
  const [loading, setLoading] = useState(false);
  const [projectDetails, setProjectDetails] = useState(false);
  const [materialList, setMaterialList] = useState(false);
  const [projectReports, setProjectReports] = useState(false);
  const { id } = useParams();
  console.log(id, "this is");
  useEffect(() => {
    getProjectDetails();
  }, []);
  const getProjectDetails = () => {
    setLoading(true);
    firebase
      .database()
      .ref("constructionprojects")
      .child(id)

      .on("value", (snapshot) => {
        if (snapshot.exists()) {
          setProjectDetails(snapshot.val());
          getReports();
          setLoading(false);
        }
      });
  };
  const getReports = () => {
    firebase
      .database()
      .ref("constructionprojects")
      .child(id)
      .child("constructionProjectsReport")
      .on("value", (snapshot) => {
        let arr = [];
        if (snapshot.exists()) {
          snapshot.forEach((item) => {
            let value = item.val();
            value.id = item.key;
            arr.push(value);
          });
        }
        // setMaterialList(arr);
        setProjectReports(arr);
      });
  };

 
  const dateChnage = (timestamp) => {

      var date = new Date(timestamp);

      // Format the date in Pacific Time (PT) format
      var formattedDate = new Intl.DateTimeFormat('en-US', {
        timeZone: 'America/Los_Angeles',
        month: '2-digit',
        day: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: true
      }).format(date);
      
console.log(formattedDate)

return formattedDate;
  };

  // console.log(materialList[0])

  return (
    <>
      <Card>
        <CardHeader title={`${projectDetails.name}`}>
          <CardHeaderToolbar>
            {/* <button
                            type="button"
                            className="btn btn-primary"
                            onClick={() => {
                                seteditModal(false);
                                setaddModal(true);
                            }}
                        >
                            Create Project
                        </button> */}
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          <div className="row">
            <div className="col-md-12">
              <img
                src={projectDetails.image}
                className="constructorprojecttImage"
              />
            </div>
          </div>
          <div className="row mb-2" style={{marginTop:"20px"}}>
           <div className="col-md-3">
               <div className="addressIconContainer">
                 <FaGlobe style={{color:"#3699FF"}}/>
                 <p>State</p>
               </div>
               <p className="address-main">{projectDetails.state}</p>
           </div>
           <div className="col-md-3">
               <div className="addressIconContainer">
                 <FaCity/>
                 <p>City</p>
               </div>
               <p className="address-main">{projectDetails.city}</p>
           </div>
           <div className="col-md-3">
               <div className="addressIconContainer">
                 <FaMapSigns style={{color:"red"}}/>
                 <p>Zip Code</p>
               </div>
               <p className="address-main">{projectDetails.zip_code}</p>
           </div>
           <div className="col-md-3">
               <div className="addressIconContainer">
                 <FaHome style={{color:"orange"}}/>
                 <p>Address</p>
               </div>
               <p className="address-main">{projectDetails.address}</p>
           </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              {/* <div className="pt-2">
                <h2>{projectDetails.name}</h2>
                <p className="muted">
                  {projectDetails.address} {projectDetails.city}{" "}
                  {projectDetails.state} {projectDetails.zip_code}
                </p>
              </div> */}
              <div className="row mt-5">
                <div className="col-md-6">
                  <div className="statsCard1">
                    {/* <div className="stateCardImgcont">
                      <img src={workers} />
                      <h3>{projectDetails.name || 0}</h3>
                      <h6>Number Of Workers </h6>
                    </div> */}
                    <div className="icon-container">
                    <img src={workers} />
                    </div>
                    <div className="mt-5">
                    <h3>{projectDetails.numberOfWorkers || 0}</h3>
                      <h6>Number Of Workers</h6>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="statsCard2">
                    {/* <div className="stateCardImgcont">
                      <img src={live} />
                      <h3>{projectDetails.liveView || 0}</h3>
                      <h6>Live View</h6>
                    </div> */}
                    <div className="icon-container2">
                    <img src={live} />
                    </div>
                    <div className="mt-5">
                    <h3>{projectDetails.liveView || 0}</h3>
                      <h6>Live View</h6>
                    </div>
                  </div>
                </div>
              
              </div>
              <div className="col-lg-12 mt-7">
              <div className="row">
                <div
                  className="contionreportsheading"
                >
                  <h2 className="">Material List</h2>
                  <div className="liners"></div>
                </div>
                {projectDetails?.materialList?.length > 0 ? (
                  projectDetails?.materialList?.map((item, i) => (              
                     <div className="col-md-4 mt-4">
                         <div className="metriallistcard">
                          <div style={{fontSize:"20px"}}><strong>{item.key}</strong></div>   
                        <div style={{fontSize:"20px"}}><strong>{item.input}</strong></div>
                        </div>
                     </div>
                  ))
                ) : (
                  <div className="noReportdiv">
                  <Empty/>
              </div>
                )}
              </div>
            </div>
            <div className="col-lg-12 mt-7 mb-5">
              <div className="row">
              <div
                  className="contionreportsheading"
                >
                  <h2 className="">Paper Work</h2>
                  <div className="liners"></div>
                </div>
                {projectDetails?.paperWork ? (
                      <>
                  <div
                      style={{
                        paddingLeft: "20px",
                        paddingRight: "20px",
                        paddingBottom: "20px",
                        width:"100%"
                      }}
                    >
                      <AntCard
                        size="small"
                        title={
                            <p
                            style={{ color: "black", margin: 0 }}>
                              {" "}
                              {projectDetails?.paperWork?.name}
                            </p>
                          // </a>
                        }
               
                        style={{
                          width: "auto",
                          height: 500,
                          marginTop: 13,
                          position:"relative"

                        }}
                      >
                        <div className="filesvg text-center">
                          <iframe
                            style={{ height: "500px", width: "100%" }}
                            src={projectDetails?.paperWork?.uri}
                          ></iframe>
                        </div>
                        {/* <div style={{position:"absolute",top:"18%",left:0,width:"100%",height:"600px",opacity:0}} 
                      onClick={()=>history.push(`/pdfandcomments/${projectDetails?.paperWork?.id}/${params.id}`)}
                      ></div> */}
                      </AntCard>
                    </div>
                      </>
               
                ) : (
                  <div className="noReportdiv">
                  <Empty/>
              </div>
                )}
              </div>
            </div>
            <div className="col-lg-12 mt-7 mb-3">
              <div className="row">
              <div
                  className="contionreportsheading"
                >
                  <h2 className="">Payment</h2>
                  <div className="liners"></div>
                </div>
                {projectDetails?.payment ? (
                      <>
                       <div
                      style={{
                        paddingLeft: "20px",
                        paddingRight: "20px",
                        paddingBottom: "20px",
                        width:"100%"
                      }}
                    >
                      <AntCard
                        size="small"
                        title={
                            <p
                            style={{ color: "black", margin: 0 }}>
                              {" "}
                              {projectDetails?.payment?.name}
                            </p>
                          // </a>
                        }
               
                        style={{
                          width: "auto",
                          height: 500,
                          marginTop: 13,
                          position:"relative"

                        }}
                      >
                        <div className="filesvg text-center">
                          <iframe
                            style={{ height: "500px", width: "100%" }}
                            src={projectDetails?.payment?.uri}
                          ></iframe>
                        </div>
                        {/* <div style={{position:"absolute",top:"18%",left:0,width:"100%",height:"600px",opacity:0}} 
                      onClick={()=>history.push(`/pdfandcomments/${projectDetails?.payment?.id}/${params.id}`)}
                      ></div> */}
                      </AntCard>
                    </div>
                      </>
               
                ) : (
                  <div className="noReportdiv">
                  <Empty/>
              </div>
                )}
              </div>
            </div>
            </div>
            <div className="col-lg-12 mt-7">
              <div className="row">
              <div
                  className="contionreportsheading"
                >
                  <h2 className="">Project Reports</h2>
                  <div className="liners"></div>
                </div>
                {projectReports.length > 0 ? (
                  projectReports.map((filee, i) => (
                    <a href={filee.uri} target="_blank">
                      <div className="col-md-4">
                        <AntCard
                          size="small"
                          title={
                            filee?.name.length > 25
                              ? filee?.name.substr(0, 25) + "..."
                              : filee?.name
                          }
                          extra={<section className="d-flex"></section>}
                          style={{
                            width: 280,
                            height: 250,
                            marginTop: 13,
                          }}
                        >
                          <div className="filesvg text-center">
                            {/* <FilePdfOutlined
                          style={{ fontSiz
                            e: "7rem", color: "red" }}
                        /> */}
                            <iframe
                              style={{ height: "160px", width: "240px" }}
                              src={filee.uri}
                            ></iframe>
                            <p style={{ paddingTop: "10px" }}>
                              {dateChnage(filee.date)}
                            </p>
                          </div>
                        </AntCard>
                      </div>
                    </a>
                  ))
                ) : (
                  <div className="noReportdiv">
                    <p>No Project Reports Yet</p>
                  </div>
                )}
              </div>
            </div>
           
         
          </div>
        </CardBody>
      </Card>
    </>
  );
};

export default ConstruReports;
