import { Paper } from '@material-ui/core'
import firebase from 'firebase'
import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Empty } from 'antd'
import { useParams,useHistory } from 'react-router-dom'
import { Card, CardBody, CardHeader, CardHeaderToolbar } from '../../../_metronic/_partials/controls'

const OtherDesignerProjects = () => {
const history=useHistory()

    const params = useParams()
    const { id } = params

    const [loading, setloading] = useState(false)

    const [projectList, setProjectList] = useState([])


    const user = JSON.parse(localStorage.getItem('firebaseUser'))



    useEffect(() => {
        getProjects()
    }, [])

    const getProjects = () => {
        setloading(true);
        firebase
            .database()
            .ref("projects")
            .on("value", (snapshot) => {
                setloading(false);
                let arr = [];
                if (snapshot.exists()) {
                    snapshot.forEach((item) => {
                        let value = item.val();
                        value.id = item.key;
                        arr.push(value);
                    });
                }
                let filterd = arr.filter(item => item?.designer?.userId == id)
                console.log(filterd);
                setProjectList(filterd);
            });

    }

    return (
        <Card>
        <CardHeader title="Designer Projects">
          <CardHeaderToolbar>
            <button
              type="button"
              className="btn btn-secondary"
              onClick={() => {
               history.push('/designers')
              }}
            >
              Go Back
            </button>
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>

            <div className='row'>
                {
                    projectList?.length > 0 ?
                        projectList.map((project) => (
                            <Link to={`/view-project/${project.projectId}`}>
                                <div className='col-md-4'>
                                    <div class="card2" style={{ height: "300px" }}>
                                        <div class="slider">
                                            <img src={project.image} alt="Image 1" />
                                        </div>
                                        <div class="card-content2">
                                            <h2>{project?.projectTitle?.length > 19
                                                ? project?.projectTitle.substr(0, 19) + "..."
                                                : project?.projectTitle}</h2>
                                            <p>{project?.description?.length > 50
                                                ? project?.description.substr(0, 50) + "..."
                                                : project?.description}</p>
                                        </div>
                                    </div>
                                </div>
                            </Link>

                        ))
                        :
                        <div className='emptyerr'>
                            <Empty />
                        </div>
                }

            </div>

            </CardBody>
            </Card>
    )
}

export default OtherDesignerProjects