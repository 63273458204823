import React, { useEffect, useState } from 'react'
import { Card, CardBody, CardHeader, CardHeaderToolbar, Pagination } from '../../../_metronic/_partials/controls'
import { Modal, Spinner } from 'react-bootstrap';
import { Card as MiCard, CardActions, CardContent, LinearProgress, Paper, Typography, CardMedia } from '@material-ui/core'
import firebase from 'firebase';
import { makeStyles } from '@material-ui/core/styles';
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
    PaginationProvider,
} from "react-bootstrap-table2-paginator";
import * as columnFormatters from '../../modules/ECommerce/pages/customers/customers-table/column-formatters';
import * as uiHelpers from '../../modules/ECommerce/pages/customers/CustomersUIHelpers';
import { toast } from 'react-hot-toast';
import { StatusColumnFormatter } from '../../helpers/UIhelpers';
import { Progress } from 'antd';
import { useHistory } from 'react-router-dom'
import { Modal as AntModal } from "antd";
import { SendNotifaction } from '../../../utils/SendNotifaction';

const statusList = [{
    id: 1,
    title: 'Approve',
    value: 'approved'
},
{
    id: 2,
    title: 'Reject',
    value: 'rejected'

},

]

const Projects = () => {


    const history = useHistory()

    const [addModal, setaddModal] = useState(false);
    const [editItem, setEditItem] = useState({})
    const [editModal, seteditModal] = useState(false);
    const [boards, setBoards] = useState([])
    const [loading, setloading] = useState(false)
    const [selectedId, setSelectedId] = useState("")
    const [deleteModal, setdeleteModal] = useState(false);
    const [uploadModal, setUploadModal] = useState(false);
    const [fileLoading, setFileLoading] = useState(false)
    const [updateModal, setUpdateModal] = useState(false)
    const [currentToken, setcurrentToken] = useState('')

    const [progress, setProgress] = useState(0)


    const [status, setStatus] = useState('')


 

    const [file, setFile] = useState('')

    ////for pagination
    const initialFilter = {
        pageNumber: 1,
        pageSize: 10,
    };


    const [queryParams, setQueryParamsBase] = useState(initialFilter);


    const columns = [
        {
            dataField: "image",
            text: "User Image",
            formatter: (row, cell) => (
                <>
                   {cell.image ?  <img style={{ height: 50, width: 50 }} src={cell.image} /> : "N/A"}
                </>
            )

        },
        {
            dataField: "projectTitle",
            text: "Project Title",
        },
        {
            dataField: "description",
            text: "Description",
        },
        {
            dataField: "faxNumber",
            text: "Fax Number",
        },
        {
            dataField: "email",
            text: "Email",
        },
        {
            dataField: "phoneNumber",
            text: "Phone Number",
        },
        {
            dataField: "status",
            text: "Status",
            formatter: StatusColumnFormatter,
        },
        {
            dataField: "action",
            text: "Actions",
            formatter: columnFormatters.ActionsColumnFormatterProject,
            formatExtraData: {
                openEditCustomerDialog: (item) => onEditClick(item),
                onUploadButtonClick: (item) => onUploadButtonClick(item),
                openDeleteCustomerDialog: (id) => {
                    setdeleteModal(true);
                    setSelectedId(id);
                  },
                onViewButtonClick: (item) => history.push(`/view-project/${item.projectId}`)

            },
            classes: "text-right pr-0",
            headerClasses: "text-right pr-3",
            style: {
                minWidth: "160px",
            },
        },
    ];


    const useStyles = makeStyles({
        root: {
            minWidth: 275,
            margin: 5,
        },
        bullet: {
            display: 'inline-block',
            margin: '0 2px',
            transform: 'scale(0.8)',
        },
        title: {
            color: 'black',
            fontSize: 14,
        },
        pos: {
            marginBottom: 12,
        },
    });


    const classes = useStyles();


    const onUploadButtonClick = (row) => {
        setSelectedId(row.projectId)
        setUploadModal(true)
        console.log(row)

    }


    useEffect(() => {
        fetchBoards();
    }, []);

    const fetchBoards = () => {
        setloading(true);
        firebase
            .database()
            .ref("projects")
            .on("value", (snapshot) => {
                setloading(false);
                let arr = [];
                if (snapshot.exists()) {
                    snapshot.forEach((item) => {
                        let value = item.val();
                        value.id = item.key;
                        arr.push(value);
                    });
                }
                console.log(arr.reverse(),"here we go")
                setBoards(arr);
            });
    };



    const onEditClick = (item) => {
        console.log(item.status,"Sdfsadsa")
        setStatus(item.status)
        firebase.database().ref('users')
            .child(item.projectCreatorId)
            .on('value', snapshot => {
                if (snapshot.exists()) {
                    setUpdateModal(true)
                    setSelectedId(item.id)
                    setcurrentToken(snapshot.val()?.token)
                }
            })

    }





    const onDeleteClick = (id) => {
        setSelectedId(id)
        setdeleteModal(true)
    }

    const options = {
        custom: true,
        totalSize: boards.length,
        sizePerPageList: uiHelpers.sizePerPageList,
        sizePerPage: queryParams.pageSize,
        page: queryParams.pageNumber,
    };




    const updatingQueryParams = (page) => {
        console.log(page);
        setQueryParamsBase({
            pageNumber: page,
        });
    };

    const onPaginationSelectChange = () => {
        setQueryParamsBase({
            pageNumber: 1,
        });
    };


    const updateStatus = () => {
        if (status == '' || status=="pending") {
            toast.error('Please select a status')
        } else {
            firebase.database().ref('projects')
                .child(selectedId)
                .update({
                    status: status
                }).then(() => {
                    if(status=='approved'){
                        SendNotifaction(`Approve Project`, `Congratulate admin approve your project`,currentToken)
                    }
                    toast.success('Status updated successfully')
                    setUpdateModal(false)
                    // sendNotification()
                }).catch(err => {
                    toast.error(err.message)
                })
        }

    }





    const uploadFile = () => {
        if (file) {
            setFileLoading(true)
            const storageRef = firebase.storage().ref();
            storageRef.child(`customerReports/${file.name}`)
                .put(file)
                .then((async (snapshot) => {
                    let percentComplete = 100 * (snapshot.bytesTransferred / snapshot.totalBytes)
                    setProgress(percentComplete)
                    snapshot.ref.getDownloadURL().then((val) => {
                        firebase.database().ref('customerReports')
                            .push({
                                projectId: selectedId,
                                reportUrl: val,
                                fileType: file.type,
                                name: file.name
                            }).then(() => {
                                toast.success('File uploaded successfully')
                                setFileLoading(false)
                                setUploadModal(false)
                            }).catch(err => {
                                setFileLoading(false)
                                toast.error(err.message)
                            })

                    })
                }))
        } else {
            toast.error('Please select a file to upload')
        }
    }
    const handleDeleteProject = () => {
        firebase
          .database()
          .ref(`projects`)
          .child(selectedId)
          .remove()
          .then(() => {
            setdeleteModal(false);
            toast.success("Project Deleted Successfully");
          });
      };

    return (
        <>
            <Card>
                <CardHeader title="Projects list">
                    <CardHeaderToolbar>
                        {/* <button
              type="button"
              className="btn btn-primary"
              onClick={() => setaddModal(true)}
            >
              New Designer
            </button> */}
                    </CardHeaderToolbar>
                </CardHeader>
                <CardBody>

                    <PaginationProvider pagination={paginationFactory(options)}>
                        {({ paginationProps, paginationTableProps }) => {
                            return (
                                <>
                                    <Pagination
                                        updatingQueryParams={updatingQueryParams}
                                        onPaginationSelectChange={onPaginationSelectChange}
                                        isLoading={loading}
                                        paginationProps={paginationProps}
                                    >
                                        <BootstrapTable
                                            wrapperClasses="table-responsive"
                                            bordered={false}
                                            classes="table table-head-custom table-vertical-center overflow-hidden constritaTable"
                                            bootstrap4
                                            keyField="id"
                                            data={boards}
                                            columns={columns}
                                            defaultSorted={uiHelpers.defaultSorted}
                                            {...paginationTableProps}
                                        ></BootstrapTable>
                                    </Pagination>
                                    {/* )} */}
                                </>
                            );
                        }}
                    </PaginationProvider>



                    {/* 
          <div className='container'>
            {
              loading ?
                <div style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}>
                  <Spinner animation='border' />
                </div>
                :
                <div className='row'>

                  {boards?.map((item) => (
                    <div
                      className='col-md-4'
                    >
                      <MiCard className={classes.root}>
                        <CardMedia
                          sx={{ height: 270 }}
                          image={item.ImageUrl}
                          title="aa"
                        />
                        <CardContent className="d-flex justify-content-between">
                          <Typography className={classes.title} color="textSecondary" gutterBottom>
                            <p className='title-price'>User Name</p>
                            <p>{item.username}</p>
                          </Typography>
                          <Typography className={classes.title} color="textSecondary" gutterBottom>
                            <p className='title-price'>First Name</p>
                            <p>{item.fname}</p>
                          </Typography>
                          <Typography className={classes.title} color="textSecondary" gutterBottom>
                            <p className='title-price'>Last Name</p>
                            <p>{item.lname}</p>
                          </Typography>
                        </CardContent>
                        <CardActions
                          style={{
                            justifyContent: 'space-around'
                          }}
                        >
                          <Button
                            onClick={() => onEditClick(item)}
                            title={'Edit'} size="small">
                            <EditIcon style={{ color: "blue" }} />
                          </Button>
                          <Button
                            onClick={() => onDeleteClick(item.desinerIdnp)}
                            title='Delete' size="small">
                            <DeleteOutlineIcon style={{ color: "red" }} />
                          </Button>
                        </CardActions>
                      </MiCard>

                    </div>
                  ))}

                </div>
            }
          </div> */}



                </CardBody>
            </Card>

            <Modal
                show={updateModal}
                onHide={() => setUpdateModal(true)}
            >
                <Modal.Header>
                    <Modal.Title>{`Update Project Status`}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <select
                    value={status}
                        onChange={(e) => setStatus(e.target.value)}
                        className='form-control'>
                        <option value={''}>Select Status</option>

                        {
                            statusList.map((st) => (
                                <option key={st.id} value={st.value}>{st.title}</option>
                            ))
                        }
                    </select>
                </Modal.Body>
                <Modal.Footer>
                    <button onClick={() => setUpdateModal(false)} className='btn btn-danger'>Cancel</button>
                    <button onClick={updateStatus} className='btn btn-primary'>Update Status</button>
                </Modal.Footer>
            </Modal>

            <Modal
                show={uploadModal}
                onHide={() => setUploadModal(true)}
            >
                <Modal.Header>
                    <Modal.Title>{`Upload Report`}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <input
                        onChange={(e) => setFile(e.target.files[0])}
                        type={'file'}
                    // accept='.docs .png .jpg .pdf .doc'
                    />
                    {
                        fileLoading ?
                            <Progress type="line" percent={progress} /> : null
                    }

                </Modal.Body>
                <Modal.Footer>
                    {
                        fileLoading ?
                            <Spinner />
                            :
                            <>
                                <button onClick={() => {
                                    setFile('')
                                    setUploadModal(false)}} className='btn btn-danger'>Cancel</button>
                                <button onClick={uploadFile} className='btn btn-primary'>Upload Report</button>

                            </>
                    }
                </Modal.Footer>
            </Modal>
            <AntModal
        title="Delete Project"
        centered
        open={deleteModal}
        onOk={handleDeleteProject}
        okText="Delete"
        okButtonProps={{ style: { backgroundColor: "red" } }}
        onCancel={() => setdeleteModal(false)}
      >
        <p>Are you sure you want to delete it</p>
      </AntModal>

        </>
    )
}

export default Projects

