import React, { useEffect } from 'react'
import './notifations.css'
import { Avatar } from '@material-ui/core'
import { CheckCircleFilled, CloseCircleFilled } from '@ant-design/icons'
import { useState } from 'react'
import { toast } from 'react-hot-toast'
import firebase from 'firebase'
import { Empty } from 'antd'

const AllNotifactions = () => {

  const [notificationList, setnotificationList] = useState([])
  const [isAdmin, setIsAdmin] = useState(true);
  const [currentUser, setCurrentUser] = useState({})
  useEffect(() => {
    getUser()
  }, [])
  const userLocal = JSON.parse(localStorage.getItem('firebaseUser'))
  console.log(userLocal.type)
  const getUser = () => {
    console.log("on Auth chala")

    firebase.auth().onAuthStateChanged(user => {
      console.log(user)
      if (user) {
        firebase.database().ref('users')
          .child(user.uid)
          .on('value', snapshot => {
            if (snapshot.exists()) {
              setCurrentUser(snapshot.val())
              firebase.database()
                .ref('notifications')
                .child(snapshot.val().userId)
                .on('value', snapshot => {
                  let arr = [];
                  if (snapshot.exists()) {
                    console.log("fas")

                    snapshot.forEach((item) => {
                      let value = item.val();
                      value.id = item.key;
                      arr.push(value);
                    });
                    // let filterd = arr.filter(not => not.action == 'invitation')
                    setnotificationList(arr.reverse())
                  } else {
                    setnotificationList([])
                  }
                })
            }
          })
      }
    })
  }

  const getTime = (timestamp) => {
    const now = new Date();
    const elapsedMilliseconds = now.getTime() - timestamp;

    const elapsedSeconds = Math.floor(elapsedMilliseconds / 1000);
    const elapsedMinutes = Math.floor(elapsedSeconds / 60);
    const elapsedHours = Math.floor(elapsedMinutes / 60);
    const elapsedDays = Math.floor(elapsedHours / 24);

    let message = "";

    if (elapsedDays > 0) {
      message = elapsedDays + "d" + " " + "ago";
    } else if (elapsedHours > 0) {
      message = elapsedHours + "h" + " " + "ago";
    } else if (elapsedMinutes > 0) {
      message = elapsedMinutes + "m" + " " + "ago";
    } else {
      message = "just now";
    }

    return message
  }

  const onAction = (projectId, status) => {
    const user = firebase.auth().currentUser;
    if (user != null) {
      const uid = user.uid;
      firebase.database().ref(`projects/${projectId}/invitedUsers/${uid}`)
        .update({ status })
        .then(() => {
          firebase
            .database()
            .ref('notifications')
            .child(currentUser.userId)
            .child(projectId)
            .remove()
          toast.success('Status updated successfully to ' + status);
        })
        .catch((error) => {
          console.error(error);
        });
      // Use the uid to perform operations that require the user's UID
    } else {
      // User is not logged in
    }

  }
  // const handleDelete = (projectId, status, action, id, userName) => {
  //   if (userName === 'Admin') {
  //     deleteThisNotifaction(id);
  //   } else {
  //     return ;

  //   }
  //     if (action === 'invitation') {
  //        onAction(projectId, status);
  //      } else {
  //        deleteThisNotifaction(id);
  //      }
  // };


  const handleDelete = (projectId, status, action, id) => {
    if (action == 'invitation') {
      onAction(projectId, status)
    } else {
      deleteThisNotifaction(id)
    }
  }




  // const handleDelete=(projectId,status,action,id)=>{
  //  if(action=='invitation'){
  //   onAction(projectId,status)
  //  }else{
  //    deleteThisNotifaction(id)
  //  }
  // }
  const deleteThisNotifaction = (id) => {
    console.log(id, "this is id")
    firebase
      .database()
      .ref('notifications')
      .child(currentUser.userId)
      .child(id)
      .remove()
      .then(() => {
        getUser()
        toast.success('Deleted Successfully')
      });
  }
  return (
    <>
      <div className='notificationContainer'>
        <h2>Notifications</h2>
        <div className='notiline'></div>
        {
          notificationList.length > 0 ?
            notificationList.map((notif) => (
              <div className='notifactionCard'>
                <div>
                  <Avatar src={notif.image} className='mt-5' />
                  <b className='pt-1'>{notif.userName}</b>
                </div>
                <div className='pt-4'>
                  <p><b>{notif.title}</b></p>
                  <p>{notif.message}</p>
                  <p>{getTime(notif.timeStamp)}</p>
                </div>
                <div className='cardsvgs'>
                  {notif.action == "invitation" ? <CheckCircleFilled className='notiSuccess' onClick={() => onAction(notif.projectId, 'accept')} /> : null}
                  {/* {notif.type ==="admin"? <CloseCircleFilled className='notifail'  onClick={() => handleDelete(notif.projectId, 'reject',notif.action,notif.id,notif.userName)}/>:<CheckCircleFilled className='notiSuccess' onClick={() => onAction(notif.projectId, 'accept')}/>  } */}


                  {userLocal.type === "Admin" ? <CloseCircleFilled className='notifail' onClick={() => handleDelete(notif.projectId, 'reject', notif.action, notif.id, notif.userName)} /> : null}
                </div>
              </div>
            ))
            :
            <div className='nodata'>
              <Empty />
            </div>

        }
      </div>
    </>
  )
}

export default AllNotifactions