import React from "react";

const CustomerStatusCssClasses = ["danger", "success", "info", ""];

export function StatusColumnFormatter(cellContent, row) {
    const getLabelCssClasses = () => {
        return `label label-lg label-light-${row.status == 'approved' ? 'success' : row.status == 'rejected' ? 'danger' : 'info' } label-inline`;
    };
    return (
        <span className={getLabelCssClasses()}>
            {row.status?.toUpperCase()}
        </span>
    );
}
