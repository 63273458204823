import React, { useEffect, useState } from 'react'
import { Paper } from '@material-ui/core'
import firebase from 'firebase'
import { PlusCircleOutlined, RiseOutlined, SearchOutlined } from '@ant-design/icons'
import { Empty, Input, Modal, Tooltip } from 'antd'
import './inviteUser.css'
import { toast } from 'react-hot-toast'
import { serverKey } from '../../../../utils/constants'
const InviteUsers = () => {
  useEffect(() => {
    getProjects()
    getUsers()
  }, [])
  const [loading, setloading] = useState(false)
  const [searchText, setsearchText] = useState('')
  const [projectList, setProjectList] = useState([])
  const [userList, setUserList] = useState([])
  const [inviteModal, setInviteModal] = useState(false)

  const [selectedProject, setSelectedProject] = useState({})

  const user = JSON.parse(localStorage.getItem('firebaseUser'))
  const getProjects = () => {
    setloading(true);
    firebase
      .database()
      .ref("projects")
      .on("value", (snapshot) => {
        setloading(false);
        let arr = [];
        if (snapshot.exists()) {
          snapshot.forEach((item) => {
            let value = item.val();
            value.id = item.key;
            arr.push(value);
          });
        }
        const filteredData = arr.filter((item => {
          return item.projectCreatorId == user.userId && item.status == "approved"
        }))
        console.log(filteredData);
        setProjectList(filteredData);
      });

  }
  const getUsers = () => {
    firebase
      .database()
      .ref("users")
      .orderByChild('type')
      .equalTo('client')
      .on("value", (snapshot) => {
        let arr = [];
        if (snapshot.exists()) {
          snapshot.forEach((item) => {
            let value = item.val();
            value.id = item.key;
            arr.push(value);
          });
        }
        const filteredData = arr.filter((item => {
          return item.userId !== firebase.auth().currentUser.uid
        }))
        setUserList(filteredData);
      });
  }


  function checkUserIdExists(list, userId) {
    if (list) {
      let invitedUsersList = Object.values(list)

      return invitedUsersList.some(user => user.invitedUser === userId);

    }
  }


  const inviteToUser = (user) => {

    console.log(selectedProject.invitedUsers)
    console.log('==========');
    console.log(user)

    firebase.database().ref('projects')
      .child(selectedProject.projectId)
      .child('invitedUsers')
      .child(user.userId)
      .set({
        allow: false,
        email: user.email,
        invitedUser: user.userId,
        status: "pending",
        userName: user.userName,
        profileImage: user.image ? user.image : '',
      }).then(() => {
        toast.success(`Invite succesfully to ${user.userName}`)
        notificationsUserDataGetter(user)
      }).catch(err => {
        toast.error(err.message)
      })
  }


  const notificationsUserDataGetter = async item => {
    firebase.database().ref('users')
      .child(firebase.auth().currentUser?.uid).once('value', snapshot => {
        if (snapshot) {
          sendNotificaion(snapshot.val(), item);
        }
      });
  };



  const sendNotificaion = async (currentUserData, item) => {
    firebase.database().ref('notifications')
      .child(item.userId)
      .child(selectedProject.projectId)
      .set({
        action: 'invitation',
        userId: currentUserData.userId,
        message: 'send you board invitation',
        projectId: selectedProject.projectId,
        timeStamp: Date.now(),
        userName: currentUserData.userName,
        profileImage: currentUserData.image ? currentUserData.image : '',
        invitorUserId: item.userId,
      })
      .then(async () => {
        // showMessage({
        //   message: 'Invitation Send',
        //   duration: 1000,
        //   type: 'success',
        // });

        const notification = {
          body: 'send you board invitation',
          title: currentUserData.userName,
        };

        await fetch('https://fcm.googleapis.com/fcm/send', {
          headers: {
            'Content-Type': 'application/json',
            Authorization: serverKey
          },
          method: 'POST',
          body: JSON.stringify({
            notification: notification,
            to: item.token,
          }),
        })
          .then(response => response.json())
          .then(data => {
            console.log('response', data);
          });
      })
      .catch(error => {
        console.log(error)
      });
  };



  const dataSearch = userList.filter(user => {
    let text1 = searchText.toLowerCase()
    return user.userName.toLowerCase() === text1 || user.email.toLowerCase() === text1;
  })

  return (
    <>
      <Paper style={{ minHeight: "90vh", padding: "10px" }}>
        <div className='row'>
          {
           projectList.length>0? projectList.map((project) => (
              <div key={project.projectId} className='col-md-4 mt-2'>
                <div class="card2" style={{ minHeight: "300px", }}>
                  <div class="slider">
                    {
                      project.ImagesArray && project.ImagesArray.map(img => (
                        <img src={img} alt="Image 1" />
                      ))
                    }
                  </div>
                  <div class="card-content2">
                    <h2>{project.projectTitle}</h2>
                    <p>{project?.description?.length > 50
                      ? project?.description.substr(0, 50) + "..."
                      : project?.description}</p>
                    <div className='d-flex justify-content-between'>
                      <p style={{ margin: 0, fontWeight: 'bold' }}>Invite Users to Project</p>
                      <Tooltip placement="top" title="Invite Users">
                        <PlusCircleOutlined onClick={() => {
                          setSelectedProject(project)
                          setInviteModal(true)
                        }} style={{ fontSize: "1.5rem", fontWeight: 'bold' }} />
                      </Tooltip>
                    </div>
                  </div>
                </div>
              </div>

            )):
            <div style={{height:"100vh",width:"100%",display:"flex",justifyContent:"center",alignItems:"center",flexDirection:"column"}}>
            <Empty/>
      </div>
          }

        </div>
      </Paper>
      <Modal
        centered
        open={inviteModal}
        onOk={() => setInviteModal(false)}
        okText="Done"
        onCancel={() => setInviteModal(false)}
      >
        <div className='text-center'>
          <RiseOutlined style={{ fontSize: "3rem" }} />
          <h2>Invite People to this Project</h2>
        </div>
        <div className='mt-5'>
          <Input value={searchText} onChange={(e) => setsearchText(e.target.value)} size="large" placeholder="Search Users" prefix={<SearchOutlined />} />
        </div>
        <div className='row'>
          {
            searchText?
            dataSearch && dataSearch.map((user) => (
              <div className='col-md-12 mt-3'>
                <div className='userCard'>
                  <div className='useImage'>
                    <img src={user.image} />
                  </div>
                  <div className='mx-2 mt-2'>
                    <h6 style={{ margin: 0 }}>{user.userName}</h6>
                    <p>
                      {user.email}
                    </p>
                  </div>
                  <div>

                    <button

                      disabled={checkUserIdExists(selectedProject.invitedUsers, user.userId)}
                      onClick={() => inviteToUser(user)} className='btn inviteButton'>Invite</button>
                  </div>
                </div>
              </div>
            ))
         :'' }

        </div>
      </Modal>
    </>
  )
}

export default InviteUsers