import React, { useState, useEffect } from "react";
import { Field, Form, Formik } from "formik";
import { Link, useParams } from "react-router-dom";
import { Input } from "../../../../_metronic/_partials/controls";
import * as Yup from 'yup';
import { toast } from "react-hot-toast";
import firebase from "firebase";
import {useHistory} from 'react-router-dom'

function GeneralFrom() {
    const params=useParams()
    const [details,setdetails]=useState({})
    const [loading,setLoading]=useState(false)
  const history=useHistory()
    useEffect(() => {
        getProjectData()
    }, [])
    
    
  const getProjectData = () => {
    firebase
      .database()
      .ref('projects')
      .child(params.id).child('GeneralForm')
      .on('value', snapshot => {
        if (snapshot.exists()) {
          const projectData = snapshot.val();
          console.log(projectData)
          setdetails(projectData)
        }

      })
  }
    const initValues = {
        SiteArea:details.SiteArea ||  "",
        Budget: details.Budget || "",
        FirstFloor: details.FirstFloor || "",
        SecondFloor: details.SecondFloor || "",
        ThirdFloor: details.ThirdFloor || "",
        Entrance: details.Entrance || "",
        Floors:details.Floors ||  "",
        Walls:details.Walls ||  "",
      };
    
    
    
    
      const validationSchema = Yup.object().shape({
        SiteArea: Yup.string(),
        Budget: Yup.string(),
        FirstFloor: Yup.string(),
        SecondFloor: Yup.string(),
        ThirdFloor: Yup.string(),
        Entrance: Yup.string(),
        Floors: Yup.string(),
        Walls: Yup.string(),
      });
    
const handleSubmit=(values)=>{
    setLoading(true);
    firebase.database().ref('projects').child(params.id).child("GeneralForm")
      .update({
        SiteArea: values.SiteArea,
        Budget: values.Budget,
        FirstFloor: values.FirstFloor,
        SecondFloor: values.SecondFloor,
        ThirdFloor: values.ThirdFloor,
        Entrance: values.Entrance,
        Floors: values.Floors,
        Walls: values.Walls,
        formStatus:true
      })
      .then(() => {
        
        setLoading(false);
        toast.success('You have successfully Add General Form')
        history.push('/user-projects')
      });
}
  return (
    <>
      <Formik
       enableReinitialize={true}
       initialValues={initValues}
       validationSchema={validationSchema}
        onSubmit={(values) => {
          handleSubmit(values);
        }}
      >
        {({ values }) => (
          <>
            {/* begin::Header */}
            <Form className="form form-label-right">
              <div className="card-header py-3">
                <div className="d-flex justify-content-between mt-2">
                  <div className="card-title align-items-start flex-column">
                    <h3 className="card-label font-weight-bolder text-dark">
                      General Form Information
                    </h3>
                    <span className="text-muted font-weight-bold font-size-sm mt-1">
                      {/* Update your personal informaiton */}
                    </span>
                  </div>
                  <div className="card-toolbar">
                    <button disabled={loading} type="submit" className="btn btn-primary mr-2">
                      Save
                    </button>
                    <Link
                      to={`/forms/${params.id}`}
                      className="btn btn-secondary"
                    >
                      Go back
                    </Link>
                  </div>
                </div>
                <div className="form">
                  {/* begin::Body */}
                  <div className="card-body">
                    <div className="form-group row">
                      <label className="col-xl-3 col-lg-3 col-form-label">
                      Site Area/SQFT
                      </label>
                      <div className="col-lg-9 col-xl-6">
                        <Field
                          component={Input}
                          placeholder="e.g 10,000"
                          value={values.SiteArea}
                          name="SiteArea"
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-xl-3 col-lg-3 col-form-label">
                        Budget (Not Precise)
                      </label>
                      <div className="col-lg-9 col-xl-6">
                        <Field
                          component={Input}
                          placeholder="e.g $10Million"
                          value={values.Budget}
                          name="Budget"
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-xl-3 col-lg-3 col-form-label">
                        1st Floor Preferences
                      </label>
                      <div className="col-lg-9 col-xl-6">
                        <Field
                          component={Input}
                          placeholder=" e.g Whole 1st  Floor will be dedicated to amenities."
                          value={values.FirstFloor}
                          name="FirstFloor"
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-xl-3 col-lg-3 col-form-label">
                      2st Floor Preferences
                      </label>
                      <div className="col-lg-9 col-xl-6">
                        <Field
                          component={Input}
                          placeholder="e.g 2nd Floor will be family area."
                          value={values.SecondFloor}
                          name="SecondFloor"
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-xl-3 col-lg-3 col-form-label">
                      3st Floor Preferences
                      </label>
                      <div className="col-lg-9 col-xl-6">
                        <Field
                          component={Input}
                          placeholder="e.g This floor will be for master bed"
                          value={values.ThirdFloor}
                          name="ThirdFloor"
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-xl-3 col-lg-3 col-form-label">
                      Entrance
                      </label>
                      <div className="col-lg-9 col-xl-6">
                        <Field
                          component={Input}
                          placeholder="e.g Circular Driveway"
                          value={values.Entrance}
                          name="Entrance"
                        />
                      </div>
                    </div>
                  
                    <div className="form-group row">
                      <label className="col-xl-3 col-lg-3 col-form-label">
                      Floors
                      </label>
                      <div className="col-lg-9 col-xl-6">
                        <Field
                          component={Input}
                          placeholder="e.g White Italian marble"
                          value={values.Floors}
                          name="Floors"
                        />
                      </div>
                    </div>
                  
                    <div className="form-group row">
                      <label className="col-xl-3 col-lg-3 col-form-label">
                      Walls
                      </label>
                      <div className="col-lg-9 col-xl-6">
                        <Field
                          component={Input}
                          placeholder="e.g clean art touch"
                          value={values.Walls}
                          name="Walls"
                        />
                      </div>
                    </div>
                  
               
                   
                   

               
                  </div>
                  {/* end::Body */}
                </div>
              </div>
              {/* end::Header */}
              {/* begin::Form */}

              {/* end::Form */}
            </Form>
          </>
        )}
      </Formik>
    </>
  );
}

export default GeneralFrom;
