import { Paper } from '@material-ui/core'
import firebase from 'firebase'
import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import './styles.css'
import { Empty } from 'antd'
const DesignerProjects = () => {


  const [loading, setloading] = useState(false)

  const [projectList, setProjectList] = useState([])


  const user = JSON.parse(localStorage.getItem('firebaseUser'))



  useEffect(() => {
    getProjects()
  }, [])

  const getProjects = () => {
    setloading(true);
    firebase
      .database()
      .ref("projects")
      .on("value", (snapshot) => {
        setloading(false);
        let arr = [];
        if (snapshot.exists()) {
          snapshot.forEach((item) => {
            let value = item.val();
            value.id = item.key;
            arr.push(value);
          });
        }
        let filterd = arr.filter(item => item?.designer?.userId == user.userId)
        console.log(filterd);
        setProjectList(filterd);
      });

  }

  return (
    <Paper className='p-5'>

      <div className='row'>
        {
          projectList?.length > 0 ?
            projectList.map((project) => (
              <Link to={`/designer-projects-details/${project.projectId}`}>
                <div className='col-md-4'>
                  <div class="card2" style={{ height: "300px" }}>
                    <div class="slider">
                      <img src={project.image} alt="Image 1" />
                    </div>
                    <div class="card-content2">
                      <h2>{project?.projectTitle?.length > 18
                        ? project?.projectTitle.substr(0, 18) + "..."
                        : project?.projectTitle}</h2>
                      <p>{project?.description?.length > 50
                        ? project?.description.substr(0, 50) + "..."
                        : project?.description}</p>
                    </div>
                  </div>
                </div>
              </Link>

            ))
            :
            <div className='emptyerr'>
              <Empty />
            </div>
        }

      </div>

    </Paper>
  )
}

export default DesignerProjects