import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
  Pagination,
} from "../../../../_metronic/_partials/controls";
import firebase from "firebase";
import { makeStyles } from "@material-ui/core/styles";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import * as uiHelpers from "../../../modules/ECommerce/pages/customers/CustomersUIHelpers";
import { StatusColumnFormatter } from "../../../helpers/UIhelpers";
import { Modal as AntModal } from "antd";
import { useHistory } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { AddUserProject } from "./AddUserProject";
import * as columnFormatters from "../../../modules/ECommerce/pages/customers/customers-table/column-formatters";
import { toast } from "react-hot-toast";
import axios from 'axios'

const UserProject = () => {
  const history = useHistory();
  const [boards, setBoards] = useState([]);
  const [loading, setloading] = useState(false);
  const [selectedId, setSelectedId] = useState("");
  const [addModal, setaddModal] = useState(false);
  const [editModal, seteditModal] = useState(false);
  const [editItem, setEditItem] = useState({});
  const [deleteModal, setdeleteModal] = useState(false);

  const [uploadModal, setUploadModal] = useState(false);

  useEffect(() => {

    let url = `https://firebasestorage.googleapis.com/v0/b/designapp-1cad4.appspot.com/o/customerReports%2FALS%20LIQUOR-T-1.0%20(1).pdf?alt=media&token=cc2b901b-bd9d-4d9a-88b1-a831bbe85bbb`
    getBlob(url);
  }, []);

  const getBlob = (url) => {


    axios({
        url:url,
        method:'GET',
        responseType:'arraybuffer',
    }).then(res=>{
        console.log(JSON.stringify(res.data))
    }).catch(err=>{
        console.log(err.response)
    })

    // fetch(url, {
    //   method: "GET",
    // })
    //   .then(async(res) => {
    //     await res.blob();
    //   })
    //   .then((data) => {
    //     console.log(data);
    //   })
    //   .catch((err) => {
    //     console.log("erroor while fetching", err);
    //   });
  };

  ////for pagination
  const initialFilter = {
    pageNumber: 1,
    pageSize: 10,
  };

  const [queryParams, setQueryParamsBase] = useState(initialFilter);

  const columns = [
    {
      dataField: "image",
      text: "Project Image",
      formatter: (row, cell) => (
        <>
        {cell.image ?  <img style={{ height: 50, width: 50 }} src={cell.image} /> : "N/A"}
        </>
      ),
    },
    {
      dataField: "projectTitle",
      text: "Project Title",
    },
    {
      dataField: "faxNumber",
      text: "Fax Number",
    },
    {
      dataField: "email",
      text: "Email",
    },
    {
      dataField: "phoneNumber",
      text: "Phone Number",
    },
    {
      dataField: "status",
      text: "Status",
      formatter: StatusColumnFormatter,
    },
    {
      dataField: "action",
      text: "Actions",
      formatter: columnFormatters.TriAction,
      formatExtraData: {
        openEditCustomerDialog: (item) => openEdit(item),
        addForms: (item) => addForm(item),
        openDeleteCustomerDialog: (id) => {
          setdeleteModal(true);
          setSelectedId(id);
        },
        openViewCustomerDialog: (id) =>
          history.push(`/view-user-projects/${id}`),
      },
      classes: "text-right pr-0",
      headerClasses: "text-right pr-3",
      style: {
        minWidth: "200px",
      },
    },
  ];

  const addForm = (item) => {
    history.push(`/forms/${item.projectId}`);
  };

  const useStyles = makeStyles({
    root: {
      minWidth: 275,
      margin: 5,
    },
    bullet: {
      display: "inline-block",
      margin: "0 2px",
      transform: "scale(0.8)",
    },
    title: {
      color: "black",
      fontSize: 14,
    },
    pos: {
      marginBottom: 12,
    },
  });

  const openEdit = (item) => {
    setEditItem(item);
    seteditModal(true);
    setaddModal(true);
  };
  const classes = useStyles();

  let user = JSON.parse(localStorage.getItem("firebaseUser"));

  useEffect(() => {
    fetchUserBoards();
  }, []);

  const fetchUserBoards = () => {
    setloading(true);
    firebase
      .database()
      .ref("projects")
      .on("value", (snapshot) => {
        setloading(false);
        let arr = [];
        if (snapshot.exists()) {
          snapshot.forEach((item) => {
            let value = item.val();
            value.id = item.key;
            arr.push(value);
          });
        }
        const filteredData = arr.filter((item) => {
          return item.projectCreatorId == user.userId;
        });
        setBoards(filteredData.reverse());
      });
  };

  const options = {
    custom: true,
    totalSize: boards.length,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: queryParams.pageSize,
    page: queryParams.pageNumber,
  };

  const updatingQueryParams = (page) => {
    console.log(page);
    setQueryParamsBase({
      pageNumber: page,
    });
  };

  const onPaginationSelectChange = () => {
    setQueryParamsBase({
      pageNumber: 1,
    });
  };

  const handleDeleteProject = () => {
    firebase
      .database()
      .ref(`projects`)
      .child(selectedId)
      .remove()
      .then(() => {
        setdeleteModal(false);
        toast.success("Project Deleted Successfully");
      });
  };



  return (
    <>
      <Card>
        <CardHeader title="Projects list">
          <CardHeaderToolbar>
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => {
                seteditModal(false);
                setaddModal(true);
              }}
            >
              Create Project
            </button>
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          <PaginationProvider pagination={paginationFactory(options)}>
            {({ paginationProps, paginationTableProps }) => {
              return (
                <>
                  <Pagination
                    updatingQueryParams={updatingQueryParams}
                    onPaginationSelectChange={onPaginationSelectChange}
                    isLoading={loading}
                    paginationProps={paginationProps}
                  >
                    <BootstrapTable
                      wrapperClasses="table-responsive"
                      bordered={false}
                      classes="table table-head-custom table-vertical-center overflow-hidden constritaTable"
                      bootstrap4
                      keyField="id"
                      data={boards}
                      columns={columns}
                      defaultSorted={uiHelpers.defaultSorted}
                      {...paginationTableProps}
                    ></BootstrapTable>
                  </Pagination>
                  {/* )} */}
                </>
              );
            }}
          </PaginationProvider>
        </CardBody>
      </Card>
      <Modal size="lg" show={addModal}>
        <Modal.Header>
          <h4>{editModal ? "Edit Project" : "Add New Project"}</h4>
        </Modal.Header>
        <AddUserProject
          editItem={editModal ? editItem : ""}
          reload={fetchUserBoards}
          isEdit={editModal ? true : false}
          onHide={() => setaddModal(false)}
        />
      </Modal>
      <AntModal
        title="Delete Project"
        centered
        open={deleteModal}
        onOk={handleDeleteProject}
        okText="Delete"
        okButtonProps={{ style: { backgroundColor: "red" } }}
        onCancel={() => setdeleteModal(false)}
      >
        <p>Are you sure you want to delete it</p>
      </AntModal>
    </>
  );
};

export default UserProject;
