import React from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import { Carousel as carousel, Divider, Empty, Image, Spin, Tooltip } from "antd";
import firebase from "firebase";
import { useState, useEffect } from "react";
import { Paper } from "@material-ui/core";
import { Player, PosterImage } from "video-react";
import "./styles.css";
import { Card, Spinner } from "react-bootstrap";
import { Card as AntCard } from 'antd'
import axios from "axios";
import { Button } from "antd";
import AssignProjectModal from "../../Components/AssignProjectModal/AssignProjectModal";
import Carousel from "react-bootstrap/Carousel";
import Slider from "react-slick";
import { PhoneOutlined,FileAddOutlined,VideoCameraAddOutlined, MailOutlined, FileZipOutlined, BorderRightOutlined, VerticalAlignMiddleOutlined,DownloadOutlined,FilePdfOutlined } from '@ant-design/icons'

const ViewProject = () => {
  const { id } = useParams();

  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [projectDetails, setProjectDetails] = useState({});
  const [projectImages, setProjectImages] = useState([]);
  const [projectFiles, setProjectFiles] = useState([]);
  const [projectDescription, setProjectDescription] = useState([]);
  const [assignModal, setAssignModal] = useState(false);
const history=useHistory()
  const contentStyle = {
    height: "160px",
    color: "#fff",
    lineHeight: "160px",
    textAlign: "center",
    background: "#364d79",
  };

  useEffect(() => {
    getProjectDetails();
    getProjectDescription();
  }, []);

  const getProjectDetails = () => {
    setLoading(true);
    firebase
      .database()
      .ref("projects")
      .child(id)
      .on("value", (snapshot) => {
        if (snapshot.exists()) {
          console.log('exits 1')
          setProjectDetails(snapshot.val());
          setProjectImages(snapshot.val()?.ImagesArray);
          getProjectReports();
          setLoading(false)
        }
      });
  };
  const getProjectDescription = () => {
    setLoading(true);
    firebase
      .database()
      .ref("projects")
      .orderByKey()
      .limitToFirst(1)
      .on("value", (snapshot) => {
        if (snapshot.exists()) {
          console.log('exits 2')

          const Project1Key = Object.keys(snapshot.val())[0];
          const ProjectData = snapshot.val()[Project1Key];
          setProjectDescription(ProjectData);
          setLoading(false)

        }
      });
  };
  const getProjectReports = () => {
    firebase
      .database()
      .ref("customerReports")
      .on("value", (snapshot) => {
        let arr = [];
        if (snapshot.exists()) {
          console.log('exits 3')

          snapshot.forEach((item) => {
            let value = item.val();
            value.id = item.key;
            arr.push(value);
          });
        }
        let fil = arr.filter((ele) => ele.projectId == id);
        setProjectFiles(fil);
        setLoading(false);
      });
  };

  const getdateTime = (date) => {
    let dd = new Date(date);

    const fd = new Intl.DateTimeFormat("en-us", {
      dateStyle: "medium",
      timeStyle: "medium",
    });

    return fd.format(dd);
  };

  const handleDownloadVideo = async () => {
    const videoUrl = projectDetails.videoUrl;
    const link = document.createElement("a");
    link.href = videoUrl;
    link.target = "_blank";
    link.setAttribute("download", "projectVideo.mp4");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const downloadFile = async (fileURL) => {
    const response = await axios.get(fileURL.reportUrl, {
      responseType: "blob", // automatically detect the response type
    });

    const blob = new Blob([response.data], { type: response.data.type });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "your-file-name";
    link.click();
  };
  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 2.2,
    slidesToScroll: 2.2,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };



  console.log('loading',loading)


  return (
    <Paper className="p-3">
      {loading ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Spinner animation="border" />
        </div>
      ) : (
        <>
          <div
            style={{ padding: "30px" }}
            className="d-flex align-items-center justify-content-between"
          >
            <div>
              <h3>Project Detail</h3>
            </div>
            <div>
            <button 
                  onClick={()=>history.push(`/view-reports/${id}`)}
                 className="btn btn-primary mx-2"
                  >View Reports</button>
            {projectDetails.designer ? (
              <>
                  <button 
                  onClick={()=>history.push(`/admin-chat/${id}`)}
                 className="btn btn-primary mx-2"
                  >View Chat</button>
               <button
                 title="View desginer details mx-2"
                 onClick={() => history.push(`/desginer-details/${id}`)}
                 className="btn btn-primary"
               >
                 View desginer details
               </button>
              </>
            ) : (
              <>
              <button 
                 disabled
                 className="btn btn-primary mx-2"
                  >
                 <Tooltip title="Assign desginer to view chat">
                    View Chat
              </Tooltip>
                    
                    </button>
              
                    <button
                 
                  disabled={projectDetails.status == "approved" ? false : true}
                  onClick={() => setAssignModal(true)}
                  className="btn btn-primary mx-2"
                >
                  <Tooltip title={
                    projectDetails.status == "approved"
                      ? "Assign Project"
                      : "Approved Project can assign"
                  }>
                  Assign Project
                  </Tooltip>
                </button>
              </>
              
            )}
            </div>

          </div>
          <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="projectDetailCard">
                    <div className="row">
                      <div className="col-md-4 imageContainerCol">
                        <div className="imageContainerProject">
                          {projectDetails.image ? <img src={projectDetails.image} /> : 
                          <div style={{display:"flex",justifyContent:"center",width:"100%",flexDirection:"column",alignItems:"center",height:"100%"}}>
                          <Empty/>
                         </div>
                          }
                        </div>
                      </div>
                      <div className="col-md-8">
                        <div className="row mt-2">
                          <div className="col-md-12">
                            <h3 className="headingProject">Project Title</h3>

                          </div>
                          <div className="col-md-12">
                            <p className="subheadingProject">
                              {projectDetails.projectTitle}
                            </p>
                          </div>
                        </div>
                        <div className="row mt-2">
                          <div className="col-md-12">
                            <h3 className="headingProject">Project Description</h3>

                          </div>
                          <div className="col-md-12">
                            <p className="subheadingProject">
                              {projectDetails.description}

                            </p>
                          </div>
                        </div>
                        <div className="row mt-2">
                          <div className="col-md-12 mt-2 d-flex">
                            <MailOutlined style={{ fontSize: "1.5rem" }} />
                            <p className="subheadingProject pl-2">{projectDetails.email}</p>

                          </div>
                          <div className="col-md-12 mt-2 d-flex">
                            <PhoneOutlined style={{ fontSize: "1.5rem" }} />
                            <p className="subheadingProject pl-2">
                              {projectDetails.phoneNumber}
                            </p>
                          </div>
                          <div className="col-md-12 mt-2 d-flex">
                            <FileZipOutlined style={{ fontSize: "1.5rem" }} />
                            <p className="subheadingProject pl-2">
                              {projectDetails.zipCode}
                            </p>
                          </div>
                          <div className="col-md-12 mt-2 d-flex">
                            <BorderRightOutlined style={{ fontSize: "1.5rem" }} />
                            <p className="subheadingProject pl-2">
                              {projectDetails.faxNumber}
                            </p>
                          </div>
                          <div className="col-md-12 mt-2 d-flex">
                            <VerticalAlignMiddleOutlined style={{ fontSize: "1.5rem" }} />
                            <p className="subheadingProject pl-2">
                              {projectDetails.address}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          <hr />
          <div className="container">
          <div className="row">
            <div className="col-md-12">
            <h3 className="headingProject">Project Images</h3>
            </div>
         </div>
          </div>
          {
            projectImages && projectImages.length ? (
              <>

                <div className='carsolemasin'>
                  <Slider {...settings}>
                    {projectImages.map((img) => (
                      <div>
                        <Card
                          style={{ width: '25rem', borderRadius: "10px", height: "300px" }}
                          className="mb-2 slidercard"
                        >
                          <Card.Body style={{ padding: 0 }}>
                            <div>
                              <img style={{ height: "300px" }} className='sliderCarimg' src={img} alt="" />
                            </div>
                          </Card.Body>
                        </Card>
                      </div>
                    ))}
                  </Slider>
                </div>
              </>) :   <div style={{display:"flex",justifyContent:"center",width:"100%"}}>
          <Empty/>
         </div>

          }

          {projectImages && projectImages?.length ? (
            <div style={{ display: "none" }}>
              <Image.PreviewGroup
                preview={{ visible, onVisibleChange: (vis) => setVisible(vis) }}
              >
                {projectImages.map((image, index) => (
                  <Image src={image} />
                ))}
              </Image.PreviewGroup>
            </div>
          ) : null}

          <hr />
          <div className="container">
          <div className="row">
            <div className="col-md-12">
            <h3 className="headingProject">Add On</h3>
            <p className="mt-2">{projectDetails?.addOnText}</p>
            </div>
            {!projectDetails?.addOnText &&   <div style={{display:"flex",justifyContent:"center",width:"100%"}}>
          <Empty/>
         </div>}
         </div>
          </div>
          <hr />

          {/* <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h3 className="headingProject">Image Description</h3>
                <div className="row p-5">
                  {projectDescription.addOnDescription}
                </div>

              </div>
            </div>

          </div> */}
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h3 className="headingProject">Project Reports</h3>
                <div className="row">
                  {projectFiles.length > 0 ? projectFiles.map((filee, i) => (
                    <div className="col-md-6">
                      <a target="_blank" href={filee.reportUrl}>
                        <AntCard
                          size="small"
                          title={
                            filee?.name.length > 10
                              ? filee?.name.substr(0, 10) + "..."
                              : filee?.name
                          }
                          extra={
                            <section className="d-flex">
                              <DownloadOutlined
                                onClick={() => downloadFile(filee)}
                                className="text-primary mx-2"
                              />
                            </section>
                          }
                          style={{
                            width: 400,
                            height: 300,
                            marginTop: 13,
                          }}
                        >
                          <div className="filesvg text-center">
                            {/* <FilePdfOutlined
                              style={{ fontSize: "7rem", color: "red" }}
                            /> */}
                            <iframe style={{ height: "230px", width: "350px" }} src={filee.reportUrl}></iframe>
                          </div>
                        </AntCard>
                      </a>
                    </div>


                  )
                  )
                    :
                    <div className="noReportdiv">
                      <p>No Project Reports Yet</p>
                    </div>}
                </div>

              </div>
              {/* <div className="col-md-4">
                  <div className="row">
                    <div className="col-md-12">
                      <AntCard size="small" title="Project Video" extra={
                        <Tooltip placement="topLeft" title="Download Video">
                          <DownloadOutlined onClick={handleDownloadVideo} />
                        </Tooltip>

                      } style={{ width: 300 }}>
                     

                      </AntCard>
                    </div>
                    <div className="col-md-12">

                    </div>
                  </div>
                </div> */}

            </div>

          </div>
          {projectDetails?.GeneralForm?.formStatus ?
            <div className="mt-3">
              <div className="col-md-12">
                <h3 className="headingProject">General Forms Information</h3>
                <h6>Below you can see that the project general Forms Details</h6>
              </div>
              <div className="container">
                <div className="row gx-0 mt-4">
                  <div className="col-md-4 mainLabelform">
                    <div className="lableformfield">
                      <p>Site Area/SQFT</p>
                    </div>
                    <div className="lableformfield">
                      <p>Budget (Not Precise)</p>
                    </div>
                    <div className="lableformfield">
                      <p>1st Floor Preferences</p>
                    </div>
                    <div className="lableformfield">
                      <p>  2st Floor Preferences</p>
                    </div>
                    <div className="lableformfield">
                      <p>3st Floor Preferences</p>
                    </div>
                    <div className="lableformfield">
                      <p> Entrance</p>
                    </div>
                    <div className="lableformfield">
                      <p>Floors</p>
                    </div>
                    <div className="lableformfield">
                      <p>Walls</p>
                    </div>
                  </div>
                  <div className="col-md-8 keysofform">
                    <div className="lableformfield2">
                      <p>{projectDetails?.GeneralForm?.SiteArea || "-"}</p>
                    </div>
                    <div className="lableformfield2">
                      <p>{projectDetails?.GeneralForm?.Budget || "-"}</p>
                    </div>
                    <div className="lableformfield2">
                      <p>{projectDetails?.GeneralForm?.FirstFloor || "-"}</p>
                    </div>
                    <div className="lableformfield2">
                      <p>{projectDetails?.GeneralForm?.SecondFloor || "-"}</p>
                    </div>
                    <div className="lableformfield2">
                      <p>{projectDetails?.GeneralForm?.ThirdFloor || "-"}</p>
                    </div>
                    <div className="lableformfield2">
                      <p>{projectDetails?.GeneralForm?.Entrance || "-"}</p>
                    </div>
                    <div className="lableformfield2">
                      <p>{projectDetails?.GeneralForm?.Floors || "-"}</p>
                    </div>
                    <div className="lableformfield2">
                      <p>{projectDetails?.GeneralForm?.Walls || "-"}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            : ''
          }
          {
            projectDetails?.formStatus ?
              <>
                <Divider />
                <div className="mt-3">
                  <div className="col-md-12">
                    <h3 className="headingProject">Project Forms Information</h3>
                    <h6>Below you can see that the project  Forms Details</h6>
                  </div>
                  {
                    projectDetails?.floor == "" && projectDetails?.Walls == "" && projectDetails?.doors == "" && projectDetails?.Roof == "" && projectDetails?.window == "" && projectDetails?.Kitchen == "" && projectDetails?.Bedrooms == "" && projectDetails?.Ceilings == "" && projectDetails?.Washrooms == "" && projectDetails?.Garage == "" ?
                      ""
                      :
                      <>
                        <div className="col-md-12">
                          <h5 className="mt-5" style={{ fontWeight: "bold" }}>Basic Information</h5>

                        </div>
                        <div className="container">
                          <div className="row gx-0 mt-4">
                            <div className="col-md-4 mainLabelform">
                              <div className="lableformfield">
                                <p>Floor</p>
                              </div>
                              <div className="lableformfield">
                                <p>Walls</p>
                              </div>
                              <div className="lableformfield">
                                <p>Doors</p>
                              </div>
                              <div className="lableformfield">
                                <p>Windows</p>
                              </div>
                              <div className="lableformfield">
                                <p>Kitchen</p>
                              </div>
                              <div className="lableformfield">
                                <p> Bedrooms</p>
                              </div>
                              <div className="lableformfield">
                                <p>Roof</p>
                              </div>
                              <div className="lableformfield">
                                <p>Ceilings</p>
                              </div>
                              <div className="lableformfield">
                                <p>Washrooms</p>
                              </div>
                              <div className="lableformfield">
                                <p>Garage</p>
                              </div>
                            </div>
                            <div className="col-md-8 keysofform">
                              <div className="lableformfield2">
                                <p>{projectDetails?.floor || "-"}</p>
                              </div>
                              <div className="lableformfield2">
                                <p>{projectDetails?.Walls || "-"}</p>
                              </div>
                              <div className="lableformfield2">
                                <p>{projectDetails?.doors || "-"}</p>
                              </div>
                              <div className="lableformfield2">
                                <p>{projectDetails?.window || "-"}</p>
                              </div>
                              <div className="lableformfield2">
                                <p>{projectDetails?.Kitchen || "-"}</p>
                              </div>
                              <div className="lableformfield2">
                                <p>{projectDetails?.Bedrooms || "-"}</p>
                              </div>
                              <div className="lableformfield2">
                                <p>{projectDetails?.Roof || "-"}</p>
                              </div>
                              <div className="lableformfield2">
                                <p>{projectDetails?.Ceilings || "-"}</p>
                              </div>
                              <div className="lableformfield2">
                                <p>{projectDetails?.Washrooms || "-"}</p>
                              </div>
                              <div className="lableformfield2">
                                <p>{projectDetails?.Garage || "-"}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                  }

                </div>
                <Divider />
                {
                  projectDetails?.lift == "" && projectDetails?.BallRoom == "" && projectDetails?.Home_Theater == "" && projectDetails?.Home_Bar == "" && projectDetails?.Tennis_Court == "" && projectDetails?.gym == "" && projectDetails?.office == "" && projectDetails?.Library == "" && projectDetails?.Swimming_pool == "" && projectDetails?.Panic_room_Bunker == "" && projectDetails?.Walk_in_closets == "" && projectDetails?.Stairs == "" ?
                    ""
                    :
                    <>
                      <div className="mt-3">
                        <div className="col-md-12">
                          <h5 className="mt-5" style={{ fontWeight: "bold" }}>Amenties Information</h5>
                        </div>
                        <div className="container">
                          <div className="row gx-0 mt-4">
                            <div className="col-md-4 mainLabelform">
                              <div className="lableformfield">
                                <p>Stairs</p>
                              </div>
                              <div className="lableformfield">
                                <p>Walk in closets</p>
                              </div>
                              <div className="lableformfield">
                                <p>Panic room / Bunker</p>
                              </div>
                              <div className="lableformfield">
                                <p>Swimming pool</p>
                              </div>
                              <div className="lableformfield">
                                <p>Library</p>
                              </div>
                              <div className="lableformfield">
                                <p> Office</p>
                              </div>
                              <div className="lableformfield">
                                <p>Gym</p>
                              </div>
                              <div className="lableformfield">
                                <p>Tennis Court</p>
                              </div>
                              <div className="lableformfield">
                                <p>Home Bar</p>
                              </div>
                              <div className="lableformfield">
                                <p>Home Theater</p>
                              </div>
                              <div className="lableformfield">
                                <p>BallRoom</p>
                              </div>
                              <div className="lableformfield">
                                <p>lift</p>
                              </div>
                            </div>
                            <div className="col-md-8 keysofform">
                              <div className="lableformfield2">
                                <p>{projectDetails?.Stairs || "-"}</p>
                              </div>
                              <div className="lableformfield2">
                                <p>{projectDetails?.Walk_in_closets || "-"}</p>
                              </div>
                              <div className="lableformfield2">
                                <p>{projectDetails?.Panic_room_Bunker || "-"}</p>
                              </div>
                              <div className="lableformfield2">
                                <p>{projectDetails?.Swimming_pool || "-"}</p>
                              </div>
                              <div className="lableformfield2">
                                <p>{projectDetails?.Library || "-"}</p>
                              </div>
                              <div className="lableformfield2">
                                <p>{projectDetails?.office || "-"}</p>
                              </div>
                              <div className="lableformfield2">
                                <p>{projectDetails?.gym || "-"}</p>
                              </div>
                              <div className="lableformfield2">
                                <p>{projectDetails?.Tennis_Court || "-"}</p>
                              </div>
                              <div className="lableformfield2">
                                <p>{projectDetails?.Home_Bar || "-"}</p>
                              </div>
                              <div className="lableformfield2">
                                <p>{projectDetails?.Home_Theater || "-"}</p>
                              </div>
                              <div className="lableformfield2">
                                <p>{projectDetails?.BallRoom || "-"}</p>
                              </div>
                              <div className="lableformfield2">
                                <p>{projectDetails?.lift || "-"}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <Divider />
                    </>
                }

                {
                  projectDetails?.PartyRoom == "" && projectDetails?.OutdoorKitchen == "" && projectDetails?.SettingArea == "" && projectDetails?.Aquariums == "" && projectDetails?.Waterfalls == "" && projectDetails?.Jacuzzis == "" && projectDetails?.IndoorPlantation == "" && projectDetails?.PrayerArea == "" && projectDetails?.MusicSystem == "" && projectDetails?.Lighting == "" && projectDetails?.Chandeliers == "" ?
                    ""
                    :
                    <div className="mt-3">
                      <div className="col-md-12">
                        <h5 className="mt-5" style={{ fontWeight: "bold" }}>Entertainment</h5>
                      </div>
                      <div className="container">
                        <div className="row gx-0 mt-4">
                          <div className="col-md-4 mainLabelform">
                            <div className="lableformfield">
                              <p>PartyRoom</p>
                            </div>
                            <div className="lableformfield">
                              <p>Out door Kitchen</p>
                            </div>
                            <div className="lableformfield">
                              <p>Setting Area</p>
                            </div>
                            <div className="lableformfield">
                              <p>Aquariums</p>
                            </div>
                            <div className="lableformfield">
                              <p>Waterfalls</p>
                            </div>
                            <div className="lableformfield">
                              <p> Jacuzzis</p>
                            </div>
                            <div className="lableformfield">
                              <p>In Door Plantation</p>
                            </div>
                            <div className="lableformfield">
                              <p>Prayer Area</p>
                            </div>
                            <div className="lableformfield">
                              <p>Music System</p>
                            </div>
                            <div className="lableformfield">
                              <p>Lighting</p>
                            </div>
                            <div className="lableformfield">
                              <p>Chandeliers</p>
                            </div>
                          </div>
                          <div className="col-md-8 keysofform">
                            <div className="lableformfield2">
                              <p>{projectDetails?.PartyRoom || "-"}</p>
                            </div>
                            <div className="lableformfield2">
                              <p>{projectDetails?.OutdoorKitchen || "-"}</p>
                            </div>
                            <div className="lableformfield2">
                              <p>{projectDetails?.SettingArea || "-"}</p>
                            </div>
                            <div className="lableformfield2">
                              <p>{projectDetails?.Aquariums || "-"}</p>
                            </div>
                            <div className="lableformfield2">
                              <p>{projectDetails?.Waterfalls || "-"}</p>
                            </div>
                            <div className="lableformfield2">
                              <p>{projectDetails?.Jacuzzis || "-"}</p>
                            </div>
                            <div className="lableformfield2">
                              <p>{projectDetails?.IndoorPlantation || "-"}</p>
                            </div>
                            <div className="lableformfield2">
                              <p>{projectDetails?.PrayerArea || "-"}</p>
                            </div>
                            <div className="lableformfield2">
                              <p>{projectDetails?.MusicSystem || "-"}</p>
                            </div>
                            <div className="lableformfield2">
                              <p>{projectDetails?.Lighting || "-"}</p>
                            </div>
                            <div className="lableformfield2">
                              <p>{projectDetails?.Chandeliers || "-"}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                }

              </>
              : ''}

          <Divider />
          <div className="container">
          <div className="row">
          <div className="col-md-12">
                <h3 className="headingProject">Project Video</h3>
              </div>
          </div>
          </div>
          {projectDetails.videoUrl ?

            <div className="mt-3">
           
              <div className="container">
                <Player

                  fluid={false}
                  height={400}
                  width="100%"
                  poster={projectDetails.thumbnailUrl}
                  src={projectDetails.videoUrl}
                />
              </div>
            </div> :  <div style={{display:"flex",justifyContent:"center",width:"100%"}}>
          <Empty/>
         </div>}


          {/* <div style={{ placeContent: "center", margin: 10 }} className="row">
            <div className="col-md-2">
              <label>
                <b>Email:</b>
              </label>
            </div>
            <div className="col-md-3">{projectDetails.email}</div>
            <div className="col-md-2">
              <label>
                <b>Phone Number:</b>
              </label>
            </div>
            <div className="col-md-3">{projectDetails.phoneNumber}</div>
          </div>
          <div style={{ placeContent: "center", margin: 10 }} className="row">
            <div className="col-md-2">
              <label>
                <b>Zip Code:</b>
              </label>
            </div>
            <div className="col-md-3">{projectDetails.zipCode}</div>
            <div className="col-md-2">
              <label>
                <b>Fax Number:</b>
              </label>
            </div>
            <div className="col-md-3">{projectDetails.faxNumber}</div>
          </div>
          <div style={{ placeContent: "center", margin: 10 }} className="row">
            <div className="col-md-2">
              <label>
                <b>Status:</b>
              </label>
            </div>
            <div className="col-md-3">{projectDetails.status}</div>
            <div className="col-md-2">
              <label>
                <b>Project date and Time:</b>
              </label>
            </div>
            <div className="col-md-3">{projectDetails.dateTime}</div>
          </div>
          <div style={{ placeContent: "center", margin: 10 }} className="row">
            <div className="col-md-2">
              <label>
                <b>Address:</b>
              </label>
            </div>
            <div className="col-md-3">{projectDetails.address}</div>
            <div className="col-md-2">
              <label>
                <b>Download Project Video:</b>
              </label>
            </div>
            <div className="col-md-3">
              <Button
                onClick={handleDownloadVideo}
                type="primary"
                icon={<DownloadOutlined />}
                size={"large"}
              >
                Download
              </Button>
            </div>
          </div> */}

          {/* <div className="row video-box">
            <div className="col-md-4">
              <Player
                fluid={false}
                height={200}
                width={250}
                poster={projectDetails.thumbnailUrl}
                src={projectDetails.videoUrl}
              />
            </div>
          </div> */}




          {/* 
          <div style={{ placeContent: "center", margin: 10 }} className="row">
            <div className="col-md-10">
              <h5>Project Reports</h5>
              {projectFiles.map((filee, i) => (
                <div
                  style={{ paddingRight: 20, margin: 4 }}
                  className="d-flex justify-content-between align-items-center w-25"
                >
                  <div>File #{i + 1}</div>
                  <Button
                    onClick={() => downloadFile(filee)}
                    type="primary"
                    shape="circle"
                    icon={<DownloadOutlined />}
                    size={"middle"}
                  />
                </div>
              ))}
            </div>
          </div> */}
        </>
      )}

      <AssignProjectModal
        visible={assignModal}
        hideModal={() => setAssignModal(false)}
        object={projectDetails}
      />
    </Paper>
  );
};

export default ViewProject;
