import React, { useEffect, useState } from 'react'
import { Paper } from '@material-ui/core'
import firebase from 'firebase'
import { PlusCircleOutlined, RiseOutlined, SearchOutlined } from '@ant-design/icons'
import { toast } from 'react-hot-toast'
import { useHistory } from 'react-router-dom'
import { Empty } from 'antd'


const Invitations = () => {

    const history = useHistory()

    const [projectList, setProjectList] = useState([])




    useEffect(() => {
        getProjects()


        // firebase.database().ref('projects/invitedUsers')
        //     .orderByChild('userId')
        //     .equalTo(firebase.auth().currentUser?.uid)
        //     .on('value', snapshot => {
        //         let arr = []
        //         if (snapshot.exists()) {
        //             snapshot.forEach((item) => {
        //                 let value = item.val();
        //                 value.id = item.key;
        //                 arr.push(value);
        //             });
        //         }
        //         const filteredData = arr.filter((item => {
        //             return item.status == 'pending'
        //         }))
        //         setProjectList(filteredData)
        //     })


    }, [])


    const getProjects = () => {
        firebase.database()
            .ref('projects')
            .on('value', snapshot => {
                if (snapshot) {

                    let array = [];
                    let invitedUserArray = [];

                    snapshot.forEach((item, index) => {
                        item.child('invitedUsers').forEach((user, index) => {
                            if (
                                user.key == firebase.auth().currentUser?.uid &&
                                user.child('status').val() == 'accept'
                            ) {
                                let data = item.val();
                                data.key = item.key;
                                array.push(data);
                                if (user.child('favourite').val() == true) {
                                    invitedUserArray.push(item.key);
                                }
                            }
                        });
                    });

                    setProjectList(array)
                    console.log(invitedUserArray)
                    if (array.length === 0) {
                        console.log('nothing')
                    }
                }
            });

    }



    const onAction = (projectId, status) => {
        const user = firebase.auth().currentUser;
        if (user != null) {
            const uid = user.uid;
            firebase.database().ref(`projects/${projectId}/invitedUsers/${uid}`)
                .update({ status })
                .then(() => {
                    toast.success('Status updated successfully to' + status);
                })
                .catch((error) => {
                    console.error(error);
                });


            // Use the uid to perform operations that require the user's UID
        } else {
            // User is not logged in
        }

    }



    const viewProject = (id) => {
        history.push(`/view-user-projects/${id}`)
    }



    return (
        <div>
            <Paper style={{ minHeight: "90vh", padding: "10px" }}>
              <div className='container'>
              <div className='row'>
                    {
                    projectList.length>0?    projectList.map((project) => (
                            <div
                                onClick={() => viewProject(project.projectId)}
                                key={project.projectId} className='col-md-4 mt-2'>
                                <div class="card2" style={{ minHeight: "300px", }}>
                                    <div class="slider">                    
                                                <img src={project.image} alt="Image 1" />
                                    </div>
                                    <div class="card-content2">
                                        <h2>{project.projectTitle}</h2>
                                        <p>{project?.description.length > 70
                                            ? project?.description.substr(0, 70) + "..."
                                            : project?.description}</p>
                                        <div className='d-flex justify-content-between'>

                                            {/* <button className='btn btn-primary'
                                                onClick={() => onAction(project.projectId, 'accept')}>Accept</button>
                                            <button className='btn btn-danger' onClick={() => onAction(project.projectId, 'reject')}>Reject</button> */}

                                        </div>
                                    </div>
                                </div>
                            </div>

                        )):
                        <div style={{height:"100vh",width:"100%",display:"flex",justifyContent:"center",alignItems:"center",flexDirection:"column"}}>
                              <Empty/>
                        </div>
                    }

                </div>
              </div>
            </Paper>

        </div>
    )
}

export default Invitations