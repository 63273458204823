import { Paper } from '@material-ui/core'
import firebase from 'firebase'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Card as AntCard, Tooltip } from 'antd'
import { PhoneOutlined, MailOutlined, FileZipOutlined, BorderRightOutlined, VerticalAlignMiddleOutlined } from '@ant-design/icons'
import { DownloadOutlined, FilePdfOutlined } from "@ant-design/icons";
import { Player, PosterImage } from "video-react";
import Slider from "react-slick";
import { Card as ReactbootCard } from "react-bootstrap";

const Slidersettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
};

const ReportDetails = () => {


    const params = useParams()


    const { id } = params


    const [boardIamgeArray, setBaordIamgeArray] = useState([]);
    const [boardPDFArray, setBaordpdfArray] = useState([]);
    const [videoArray, setArrayVideoUrl] = useState({});
    const [data, setProjectData] = useState({});



    useEffect(() => {
        getProjectDetails()
    }, [])



    const getProjectDetails = () => {
        firebase
            .database()
            .ref('projects')
            .child(id)
            .on('value', snapshot => {
                if (snapshot.exists()) {
                    console.log(snapshot.val())
                    const vals = snapshot.val()
                    const reports = vals.reports
                    const images = reports.images
                    const files = reports.files
                    const video = reports.video
                    setProjectData(vals);

                    if (images) {
                        setBaordIamgeArray(Object.values(images))
                    }
                    if (files) {
                        setBaordpdfArray(Object.values(files))
                    }
                    if (video) {
                        setArrayVideoUrl(Object.values(video))

                    }

                }
            })



    }


    return (
        <>

            <Paper className='p-3'>
                <h1 className='p-2'>Details</h1>

                {
                    <div dangerouslySetInnerHTML={{ __html: data?.details?.html }}>

                    </div>
                }

                <hr />




                <h1 className='p-2'>Images</h1>
                <div className='row'>
                    {
                        boardIamgeArray.length == 0 ?
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }}
                            >
                                <h5 className='ml-5'>No Images Found</h5>
                            </div>
                            :
                            // <Slider {...Slidersettings}>

                            //     {boardIamgeArray.map(img => (
                            //         <div className='col-md-4'>
                            //             <img src={img.val} />
                            //         </div>
                            //     ))}
                            // </Slider>
                                  <div className='carsolemasin'>
                                  <Slider {...Slidersettings}>
                                    {boardIamgeArray.map((img) => (
                                      <div>
                                        <ReactbootCard
                                          style={{ width: '25rem', borderRadius: "10px", height: "300px" }}
                                          className="mb-2 slidercard"
                                        >
                                          <ReactbootCard.Body style={{ padding: 0 }}>
                                            <div>
                                              <img style={{ height: "300px" }} className='sliderCarimg' src={img.val} alt="" />
                                            </div>
                                          </ReactbootCard.Body>
                                        </ReactbootCard>
                                      </div>
                                    ))}
                                  </Slider>
                                </div>
                    }
                </div>

                <hr />
                <div>

                </div>


                <div className="container">
                    <div className="row">
                        <div className="col-md-8">
                            <h3 className="headingProject">Project Reports</h3>
                            <div className="row">
                                {boardPDFArray.map((filee, i) => (
                                    <div className="col-md-6">
                                        <AntCard
                                            size="small"
                                            title={
                                                filee?.name.length > 10
                                                    ? filee?.name.substr(0, 10) + "..."
                                                    : filee?.name
                                            }
                                            extra={
                                                <section className="d-flex">
                                                    <DownloadOutlined
                                                        // onClick={() => downloadFile(filee)}
                                                        className="text-primary mx-2"
                                                    />
                                                </section>
                                            }
                                            style={{
                                                width: 270,
                                                height: 185,
                                                marginTop: 13,
                                            }}
                                        >
                                            <div className="filesvg text-center">
                                                <FilePdfOutlined
                                                    style={{ fontSize: "7rem", color: "red" }}
                                                />
                                            </div>
                                        </AntCard>
                                    </div>


                                ))}
                            </div>

                        </div>
                        <div className="col-md-4">
                            <div className="row">
                                <div className="col-md-12">
                                    <AntCard size="small" title="Project Video" extra={
                                        <Tooltip placement="topLeft" title="Download Video">
                                            <DownloadOutlined />
                                        </Tooltip>

                                    } style={{ width: 300 }}>
                                        <Player
                                            fluid={false}
                                            height={200}
                                            width={250}
                                            poster={data?.video?.thumbnailUrl}
                                            src={data?.video?.videoUrl}
                                        />

                                    </AntCard>
                                </div>
                                <div className="col-md-12">

                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </Paper>

        </>
    )
}

export default ReportDetails