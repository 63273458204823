import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { Formik, Form, Field, } from "formik";
import * as Yup from "yup";
import { Input } from "../../../../_metronic/_partials/controls";
import toast from "react-hot-toast";
import { TextArea } from "../../../../_metronic/_partials/controls";
import "./userproject.css";
// Validation schema
import Upload from "../../../../app/Images/upload.png";
import firebase from "firebase";
import { useEffect } from "react";
import ProjectFrom from "../ProjectForm/ProjectFrom";
import { useHistory } from "react-router-dom";
import { SendNotifaction } from "../../../../utils/SendNotifaction";

export function AddUserProject({
  saveCustomer,
  customer,
  actionsLoading,
  onHide,
  reload,
  editItem,
}) {

  console.log(editItem)
  const [loading, setLoading] = useState(false);
  const [imgShow, setimgShow] = useState("");
  const [image, setimage] = useState("");
  const [imgError, setimgError] = useState(false);
  const [formModal, setformModal] = useState(false)
  const [selectedProjectKey, setselectedProjectKey] = useState('')
  const [adminToken,setAdminToken]=useState("")
  const [currentUser, setcurrentUser] = useState({})


  const history = useHistory()

  let user = JSON.parse(localStorage.getItem("firebaseUser"));



  useEffect(() => {
    getAdminToken()
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        firebase.database().ref('users')
          .child(user.uid)
          .on('value', snapshot => {
            if (snapshot.exists()) {
              setcurrentUser(snapshot.val())

            }
          })
      }
    })


  }, [])
  const getAdminToken = () => {
    firebase
      .database()
      .ref("users")
      .on("value", (snapshot) => {
        let arr = [];
        if (snapshot.exists()) {
          snapshot.forEach((item) => {
            let value = item.val();
            value.id = item.key;
            arr.push(value);
          });
        }
    const result=arr.find((item)=>item.type =="Admin")
    setAdminToken(result.token)
      });
  };
  console.log(adminToken,"here is admin token")


  const CustomerEditSchema = Yup.object().shape({
    title: Yup.string().required("Title is required"),
    fax: Yup.string().notRequired("Fax is required"),
    zip: Yup.string().required("Zip is required"),
    email: Yup.string().required("Email is required"),
    phone: Yup.string().notRequired("Phone Number is required"),
    address: Yup.string().required("Address is required"),
    description: Yup.string().notRequired("Description is required"),
  });

  const editinitialValues = {
    title: editItem.projectTitle,
    fax: editItem.faxNumber,
    zip: editItem.zipCode,
    email: editItem.email,
    phone: editItem.phoneNumber,
    address: editItem.address,
    description: editItem.description,
  };
  const addinitialValues = {
    title: "",
    fax: "",
    zip: "",
    email: currentUser?.email,
    phone: "",
    address: "",
    description: "",
  };
  useEffect(() => {
    if (editItem) {
      setimage(editItem.image)
    }
  }, [])

  const handleSubmit = (values) => {
    if (image == "") {
      setimgError(true);
      return;
    }

    setLoading(true);
    console.log("this is values", values);
    if (editItem) {
      if (imgShow == '') {
        firebase.database().ref('projects')
          .child(editItem.projectId)
          .update({
            address: values.address,
            description: values.description,
            email: values.email,
            faxNumber: values.fax,
            image: imgShow == '' ? editItem.image : '',
            phoneNumber: values.phone,
            projectTitle: values.title,
            zipCode: values.zip,
          }).then(() => {
            toast.success('Updated successfully')
            setLoading(false)
            onHide();

          }).catch(err => {
            toast.error(err.message)
          })
      } else {
        const storageRef = firebase.storage().ref();
        storageRef
          .child(`projectimages/${image.name}`)
          .put(image)
          .then(async (snapshot) => {
            snapshot.ref.getDownloadURL().then((val) => {

              firebase.database().ref('projects')
                .child(editItem.projectId)
                .update({
                  address: values.address,
                  description: values.description,
                  email: values.email,
                  faxNumber: values.fax,
                  image: val,
                  phoneNumber: values.phone,
                  projectTitle: values.title,
                  zipCode: values.zip,
                }).then(() => {
                  toast.success('Updated successfully')
                  setLoading(false)
                  onHide();

                }).catch(err => {
                  toast.error(err.message)
                })


            });
          })
          .catch((err) => {
            console.log(err);
          });

      }
    } else {
      const storageRef = firebase.storage().ref();
      storageRef
        .child(`projectimages/${image.name}`)
        .put(image)
        .then(async (snapshot) => {
          snapshot.ref.getDownloadURL().then((val) => {
            const pushkey = firebase
              .database()
              .ref("projects")
              .push().key;
            firebase
              .database()
              .ref(`/projects`)
              .child(pushkey)
              .set({
                address: values.address,
                description: values.description,
                designerStatus: "no",
                email: values.email,
                faxNumber: values.fax,
                image: val,
                projectId: pushkey,
                phoneNumber: values.phone,
                projectCreatorId: user.userId,
                projectTitle: values.title,
                status: "pending",
                zipCode: values.zip,
              })
              .then(() => {
                toast.success("Created Successfully");
                SendNotifaction(`${values.title}`, `${currentUser?.userName} added new project waiting for approval`,adminToken)
                setselectedProjectKey(pushkey)
                history.push(`/forms/${pushkey}`)
                // setformModal(true)
                onHide();
                setLoading(false)

              });


          });
        })
        .catch((err) => {
          console.log(err);
        });
    }




  };

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={editItem != "" ? editinitialValues : addinitialValues}
        validationSchema={CustomerEditSchema}
        onSubmit={(values) => {
          handleSubmit(values);
        }}
      >
        {({ values }) => (
          <>
            <Form className="form form-label-right">
              <Modal.Body className="overlay overlay-block cursor-default">
                {actionsLoading && (
                  <div className="overlay-layer bg-transparent">
                    <div className="spinner spinner-lg spinner-success" />
                  </div>
                )}
                <div className="form-group row">
                  {/* First Name */}
                  <div className="col-lg-6 col-md-6 col-sm-6">
                    <label>Enter Project Title</label>
                    <Field
                      component={Input}
                      placeholder="Enter project title"
                      value={values.title}
                      name="title"
                    />
                  </div>

                  <div className="col-lg-6 col-md-6 col-sm-6">
                    <label>Enter Fax No</label>
                    <Field
                      // style={{ height: "8rem" }}
                      value={values.fax}
                      name="fax"
                      component={Input}
                      placeholder="Enter fax no"
                    />
                  </div>
                </div>
                <div className="form-group row">
                  {/* First Name */}
                  <div className="col-lg-6 col-md-6 col-sm-6">
                    <label>Enter Zip</label>
                    <Field
                      component={Input}
                      placeholder="Enter Zip"
                      value={values.zip}
                      name="zip"
                    />
                  </div>

                  <div className="col-lg-6 col-md-6 col-sm-6">
                    <label>Enter Email</label>
                    <Field
                      // style={{ height: "8rem" }}
                      value={values.email}
                      disabled
                      name="email"
                      component={Input}
                      placeholder="Enter email"
                    />
                  </div>
                </div>
                <div className="form-group row">
                  {/* First Name */}
                  <div className="col-lg-6 col-md-6 col-sm-6">
                    <label>Enter Phone Number</label>
                    <Field
                      component={Input}
                      placeholder="Enter phone number"
                      value={values.phone}
                      name="phone"
                    />
                  </div>

                  <div className="col-lg-6 col-md-6 col-sm-6">
                    <label>Enter Address</label>
                    <Field
                      // style={{ height: "8rem" }}
                      value={values.address}
                      name="address"
                      component={Input}
                      placeholder="Enter address"
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-lg-12 col-md-12 col-sm-12">
                    <label>Enter Description</label>
                    <Field
                      // style={{ height: "8rem" }}
                      value={values.description}
                      name="description"
                      component={TextArea}
                      placeholder="Enter description"
                    />
                  </div>
                </div>
                {
                  imgShow ?
                    <div className="row">
                      <div className="col-md-4">
                        <img src={imgShow} style={{ height: "200px", width: "300px" }} />
                      </div>
                    </div>

                    : ''
                }

                {



                  editItem && imgShow == '' ?
                    <div className="row">
                      <div className="col-md-4">
                        <img src={editItem.image} style={{ height: "200px", width: "300px" }} />
                      </div>
                    </div> : ''
                }
                <div className="form-group row">
                  <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className="avatar-upload-input text-center">
                      <img src={Upload} alt="img" />
                      <h2>Upload Image</h2>
                      <div className="avatar-edit-input">
                        <input
                          className="form-control"
                          name="file"
                          type={"file"}
                          onChange={(e) => {
                            setimgError(false);
                            setimage(e.target.files[0]);
                            setimgShow(URL.createObjectURL(e.target.files[0]));
                          }}
                        />
                        {imgError ? (
                          <label className="text-danger">
                            Image is required
                          </label>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <button
                  type="button"
                  onClick={onHide}
                  className="btn btn-light btn-elevate"
                >
                  Cancel
                </button>
                <> </>
                <button
                  disabled={loading}
                  type="submit"
                  // onClick={() =>  handleSubmit(values)}
                  className="btn btn-primary btn-elevate"
                >
                  Save
                </button>
              </Modal.Footer>
            </Form>
          </>
        )}
      </Formik>

      {/* 
      <ProjectFrom
        hideModal={() => setformModal()}
        visible={formModal}
        projectId={selectedProjectKey}

      /> */}
    </>
  );
}
