import React, { useEffect, useState } from 'react'
import { Card, CardBody, CardHeader, Pagination } from '../../../../_metronic/_partials/controls'

import firebase from 'firebase';
import { makeStyles } from '@material-ui/core/styles';
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
    PaginationProvider,
} from "react-bootstrap-table2-paginator";
import * as columnFormatters from '../../../modules/ECommerce/pages/customers/customers-table/column-formatters';
import * as uiHelpers from '../../../modules/ECommerce/pages/customers/CustomersUIHelpers';


const Designer = () => {

    const [searchText, setSearchText] = useState('')
    const [boards, setBoards] = useState([])
    const [loading, setloading] = useState(false)



    ////for pagination
    const initialFilter = {
        pageNumber: 1,
        pageSize: 10,
    };


    const [queryParams, setQueryParamsBase] = useState(initialFilter);


    const columns = [
        {
            dataField: "imageUrl",
            text: "User Image",
            formatter: (row, cell) => (
                <>
                      {cell.image ?  <img style={{ height: 50, width: 50 }} src={cell.image} /> : "N/A"}
                </>
            )

        },
        {
            dataField: "userName",
            text: "User Name",
        },
        {
            dataField: "email",
            text: "Email",
        },
        {
            dataField: "phoneNumber",
            text: "Phone Number",
        },
        // {
        //   dataField: "action",
        //   text: "Actions",
        //   formatter: columnFormatters.ActionsColumnFormatter,
        //   formatExtraData: {
        //     openEditCustomerDialog: (item) => onEditClick(item),
        //     openDeleteCustomerDialog: (id) => {
        //       setSelectedId(id);
        //       setdeleteModal(true);
        //     },
        //   },
        //   classes: "text-right pr-0",
        //   headerClasses: "text-right pr-3",
        //   style: {
        //     minWidth: "100px",
        //   },
        // },
    ];


    const useStyles = makeStyles({
        root: {
            minWidth: 275,
            margin: 5,


        },
        bullet: {
            display: 'inline-block',
            margin: '0 2px',
            transform: 'scale(0.8)',
        },
        title: {
            color: 'black',
            fontSize: 14,
        },
        pos: {
            marginBottom: 12,
        },
    });


    const classes = useStyles();



    useEffect(() => {
        fetchBoards();
    }, []);

    const fetchBoards = () => {
        setloading(true);
        firebase
            .database()
            .ref("users")
            .on("value", (snapshot) => {
                setloading(false);
                let arr = [];
                if (snapshot.exists()) {
                    snapshot.forEach((item) => {
                        let value = item.val();
                        value.id = item.key;
                        arr.push(value);
                    });
                }
                const orignalarray = arr.filter((item) => item.type == "designer")
                setBoards(orignalarray.reverse());
            });
    };



   




    const updatingQueryParams = (page) => {
        console.log(page);
        console.log("this is chus2");
        setQueryParamsBase({
            pageNumber: page,
        });
    };

    const onPaginationSelectChange = () => {
        setQueryParamsBase({
            pageNumber: 1,
        });
    };



    const dataSearch = boards.filter(user => {
        let text1 = searchText.toLowerCase().trim()
        const Searched = text1 ? user?.userName?.toLowerCase()===text1 : '';
        return Searched
    })

    // const dataSearch = userList.filter(user => {
    //     let text1 = searchText.toLowerCase()
    //     return user.userName.toLowerCase() === text1 || user.email.toLowerCase() === text1;
    //   })
    const options = {
        custom: true,
        totalSize: dataSearch.length,
        sizePerPageList: uiHelpers.sizePerPageList,
        sizePerPage: queryParams.pageSize,
        page: queryParams.pageNumber,
    };
    return (
        <>
            <Card>
                <CardHeader title="Designers list">
                </CardHeader>
                <CardBody>
                    <div className="col-lg-4">
                        <input
                            type="text"
                            className="form-control"
                            name="searchText"
                            placeholder="Search"
                            value={searchText}
                            onChange={(e) => {
                                setSearchText(e.target.value);
                            }}
                        />
                        <small className="form-text text-muted">
                            <b>Search</b> by Username
                        </small>
                    </div>

                    <PaginationProvider pagination={paginationFactory(options)}>
                        {({ paginationProps, paginationTableProps }) => {
                            return (
                                <>

                                    <Pagination
                                        updatingQueryParams={updatingQueryParams}
                                        onPaginationSelectChange={onPaginationSelectChange}
                                        isLoading={loading}
                                        paginationProps={paginationProps}
                                    >
                                        <BootstrapTable
                                            wrapperClasses="table-responsive"
                                            bordered={false}
                                            classes="table table-head-custom table-vertical-center overflow-hidden constritaTable"
                                            bootstrap4
                                            keyField="id"
                                            data={dataSearch}
                                            columns={columns}
                                            defaultSorted={uiHelpers.defaultSorted}
                                            {...paginationTableProps}
                                        ></BootstrapTable>
                                    </Pagination>
                                    {/* )} */}
                                </>
                            );
                        }}
                    </PaginationProvider>
                </CardBody>
            </Card>

        </>
    )
}

export default Designer