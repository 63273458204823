import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { Input, TextArea } from "../../../_metronic/_partials/controls";
import firebase, { database } from "firebase";
import { toast } from "react-hot-toast";




const logo = `https://cdn-icons-png.flaticon.com/512/3772/3772243.png`


export function AddConstruction({
    saveCustomer,
    customer,
    actionsLoading,
    onHide,
    editItem,
    reload
}) {
    const [loading, setLoading] = useState(false);

    const [image, setImage] = useState('')
    const [imageShow, setImageShow] = useState('')


    const CustomerEditSchema = Yup.object().shape({
        userName: Yup.string().required("User Name is required"),
        // fname: Yup.string().required("First Name is required"),
        // lname: Yup.string().required("Last Name is required"),
        // description: Yup.string().required("Description is required"),
        email: Yup.string().email('Invalid email').required('Email is required'),
        phoneNumber: Yup.string().required("PhoneNumber is required"),
    });
    const editinitialValues = {
        userName: editItem.userName,
        // fname: editItem.fname,
        // lname: editItem.lname,
        // description: editItem.description,
        email: editItem.email,
        phoneNumber: editItem.phoneNumber
    }

    const addinitialValues = {
        userName: '',
        // fname: '',
        // lname: '',
        // description: '',
        email: '',
        phoneNumber: '',
        type:'constructor',
        token:''
    }
    const handleSubmit = async (values,resetForm) => {

        setLoading(true)
        if(image){
            const storageRef = firebase.storage().ref();
            storageRef
                .child(`usersimages/${image.name}`)
                .put(image).then(async (snapshot) => {
                    snapshot.ref.getDownloadURL().then((val) => {
    
                        let pushkey = database().ref("/users").push().key
                        values['image'] = val
                        values['userId'] = pushkey
    
                        database()
                            .ref("/users")
                            .child(pushkey)
                            .set(values)
                            .then(() => {
                                setLoading(false)
                                resetForm()
                                onHide()
                            }
                            ).catch((err) => {
                                if(err.message){
                                    toast.error(err.message)
                                    setLoading(false)
                                   }
                                console.log(err)})
                    })
                }).catch(err => {
                    console.log(err)
                })
        }else{
            let pushkey = database().ref("/users").push().key
            values['image'] = ""
            values['userId'] = pushkey

            database()
                .ref("/users")
                .child(pushkey)
                .set(values)
                .then(() => {
                    setLoading(false)
                    resetForm()
                    onHide()
                }
                ).catch((err) => {
                    if(err.message){
                        toast.error(err.message)
                        setLoading(false)
                       }
                    console.log(err)})
        }

     


    }
    const editHandler = (values,resetForm) => {

        setLoading(true)
        if (image) {
            const storageRef = firebase.storage().ref();
            storageRef
                .child(`constructionImages/${image.name}`)
                .put(image).then(async (snapshot) => {
                    snapshot.ref.getDownloadURL().then((val) => {
                        values['image'] = val
                        database()
                            .ref("/users")
                            .child(editItem.userId)
                            .update(values)
                            .then(() => {
                                setLoading(false)
                                onHide()
                                resetForm()
                            }
                            ).catch((err) => console.log(err))
                    })
                }).catch(err => {
                    console.log(err)
                })

        } else {
            database()
                .ref("/users")
                .child(editItem.userId)
                .update(values)
                .then(() => {
                    setLoading(false)
                    onHide()
                    resetForm()

                }
                ).catch((err) => console.log(err))
        }

    }

    return (
        <>
            <Formik
                enableReinitialize={true}
                initialValues={editItem != '' ? editinitialValues : addinitialValues}
                validationSchema={CustomerEditSchema}
                onSubmit={(values,{resetForm}) => {
                    editItem ? editHandler(values,resetForm) : handleSubmit(values,resetForm);
                }}
            >
                {({ values }) => (
                    <>
                        <Form className="form form-label-right">
                            <Modal.Body className="overlay overlay-block cursor-default pt-0 pb-0">
                                {actionsLoading && (
                                    <div className="overlay-layer bg-transparent">
                                        <div className="spinner spinner-lg spinner-success" />
                                    </div>
                                )}
                                <div className="form-group row">

                                    <div className="col-lg-6 col-md-6 col-sm-6 mt-3">
                                        <label>User Name</label>
                                        <Field
                                            // style={{ height: "8rem" }}
                                            value={values.userName}
                                            name="userName"
                                            component={Input}
                                            placeholder="Enter username"
                                        />
                                    </div>
                                    {/* <div className="col-lg-6 col-md-6 col-sm-6 mt-3">
                                        <label>Enter First Name</label>
                                        <Field
                                            // style={{ height: "8rem" }}
                                            value={values.fname}
                                            name="fname"
                                            component={Input}
                                            placeholder="Enter first name"
                                        />
                                    </div> */}


                                {/* </div>
                                <div className="form-group row"> */}

                                    {/* <div className="col-lg-6 col-md-6 col-sm-6 mt-3">
                                        <label>Last Name</label>
                                        <Field
                                            // style={{ height: "8rem" }}
                                            value={values.lname}
                                            name="lname"
                                            component={Input}
                                            placeholder="Enter last name"
                                        />
                                    </div> */}
                                    <div className="col-lg-6 col-md-6 col-sm-6 mt-3">
                                        <label>Email</label>
                                        <Field
                                            // style={{ height: "8rem" }}
                                            value={values.email}
                                            name="email"
                                            component={Input}
                                            placeholder="Enter Email"
                                        />
                                    </div>


                                </div>
                                <div className="form-group row">

                                    <div className="col-lg-6 col-md-6 col-sm-6 mt-3">
                                        <label>Phone Number</label>
                                        <Field
                                            // style={{ height: "8rem" }}
                                            value={values.phoneNumber}
                                            name="phoneNumber"
                                            component={Input}
                                            placeholder="Enter phone Number"
                                        />
                                    </div>
                                    {/* <div className="col-lg-6 col-md-6 col-sm-6 mt-3">
                                        <label>Enter description</label>
                                        <Field
                                            style={{ height: "5rem" }}
                                            value={values.description}
                                            name="description"
                                            component={TextArea}

                                            placeholder="Enter description"
                                        />
                                    </div> */}
                                </div>

                                <div className="form-group row">

                                    {
                                        editItem.image ?

                                            <img style={{ height: 150, width: 150 }} src={editItem.image} />
                                            :
                                            imageShow ?
                                                <img style={{ height: 150, width: 150 }} src={imageShow} />
                                                : null

                                    }

                                    <label
                                        htmlFor='fileImage'
                                        style={{
                                            width: 100,
                                            margin: 20,
                                            padding: 10,
                                            borderRadius: 5,
                                            backgroundColor: '#E6E9EC',
                                            cursor: 'pointer'
                                        }}>
                                      
                                        <img loading={'lazy'} style={{ width: 78 }} src={logo} />
                                    </label>

                                    <input
                                        style={{ display: "none" }}
                                        type="file"
                                        id={`fileImage`}
                                        // ref={fileRef}
                                        accept="image/png, image/jpeg"
                                        onChange={(e) => {
                                            setImageShow(URL.createObjectURL(e.target.files[0]))
                                            setImage(e.target.files[0])
                                        }}
                                    />

                                    {/* <input type={'file'} onChange={(e) => setImage(e.target.files[0])} /> */}

                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <button
                                    type="button"
                                    onClick={onHide}
                                    className="btn btn-light btn-elevate"
                                >
                                    Cancel
                                </button>
                                <> </>
                                <button
                                    disabled={loading}
                                    type="submit"
                                    className="btn btn-primary btn-elevate"
                                >
                                    Save
                                </button>
                            </Modal.Footer>
                        </Form>

                    </>
                )}
            </Formik>
        </>
    );
}
