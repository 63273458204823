import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { Input, TextArea } from "../../../_metronic/_partials/controls";
import firebase, { database } from "firebase";
import { toast } from "react-hot-toast";
import { serverKey } from "../../../utils/constants";

export function AddAnnouncement({
  saveCustomer,
  customer,
  actionsLoading,
  onHide,
  editItem,
  reload,
  users,
  currentUserData,
}) {
  console.log(users,"here is the users??")
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState("");
  const [imageShow, setImageShow] = useState("");
  // const [imageEror, setimageError] = useState(false);
  const logo = `https://cdn-icons-png.flaticon.com/512/3772/3772243.png`;
  const CustomerEditSchema = Yup.object().shape({
    title: Yup.string().required("Title is required"),
    description: Yup.string().required("Description is required"),
  });
  const editinitialValues = {
    title: editItem.title,
    description: editItem.description,
  };
  const addinitialValues = {
    title: "",
    description: "",
  };
  const handleSubmit = (values) => {
    setLoading(true);

    const storageRef = firebase.storage().ref();
  
    // Check if an image is provided
    if (image) {
      storageRef
        .child(`announcementImages/${image.name}`)
        .put(image)
        .then(async (snapshot) => {
          snapshot.ref.getDownloadURL().then((val) => {
            createAnnouncement(values, val); // Call a function to create announcement
          });
        })
        .catch((error) => {
          console.log("Error uploading image:", error);
          setLoading(false);
        });
    } else {
      createAnnouncement(values, null); // Call the function without image URL
    }
  };
  
  function removeSpecialCharacters(str) {
    // Define a regular expression to match the special characters
    const regex = /[.#$[\]]/g;
    // Replace the special characters with an empty string
    return str.replace(regex, '');
  }
  const createAnnouncement = (values, imageUrl) => {
    const pushkey = firebase.database().ref("Announcement").push().key;
    const announcementData = {
      title: values.title,
      description: values.description,
      image: imageUrl || null,
      id: pushkey,
    };
  
    firebase
      .database()
      .ref("Announcement")
      .child(pushkey)
      .set(announcementData) .catch((err) => {
        console.log("Error creating announcement:", err);
        setLoading(false);
      });
      users.map((item) => {
        const pushkey = firebase.database().ref("notifications").push().key;
        firebase
          .database()
          .ref("notifications")
          .child(removeSpecialCharacters(item.userId))
          .child(pushkey)
          .set({
            notificationId: pushkey,
            action: "notification",
            userId: currentUserData.userId,
            message: values.description,
            title: values.title,
            timeStamp: Date.now(),
            userName: currentUserData.userName,
            profileImage: currentUserData.image ? currentUserData.image : "",
          })
          .then(async () => {
            const notification = {
              title: values.title,
              body: values.description,
            };
      
            await fetch("https://fcm.googleapis.com/fcm/send", {
              headers: {
                "Content-Type": "application/json",
                Authorization: serverKey,
              },
              method: "POST",
              body: JSON.stringify({
                notification: notification,
                to: item.token,
              }),
            })
            .then((response) => response.json())
            .then((data) => {
              console.log("response", data);
              onHide();
            })
            .catch((error) => {
              console.log(error);
            });
          })
          .catch((error) => {
            console.log(error);
          });
      });
      
      setLoading(true);
      toast.success("Created Successfully");
      onHide();
  };

//  const handleSubmit = async (values) => {
//     setLoading(true) ;
  
  //   try {
  //     if (image) {
  //       const storageRef = firebase.storage().ref();
  //       const snapshot = await storageRef.child(`announcementImages/${image.name}`).put(image);
  //       const val = await snapshot.ref.getDownloadURL();
  
  //       const pushkey = firebase.database().ref("Announcement").push().key;
  //       await firebase.database().ref("Announcement").child(pushkey).set({
  //         title: values.title,
  //         description: values.description,
  //         image: val,
  //         id: pushkey,
  //       });
  //     }

  //     await Promise.all(users.map(async (item) => {
  //       const sanitizePath = (path) => {
  //         return path.replace(/[.#$[\]]/g, "_");
  //       };
  //       const sanitizedUserId = sanitizePath(item.userId);
  //       const pushkeyNotification = firebase.database().ref("notifications").push().key;
  //       await firebase.database().ref("notifications").child(sanitizedUserId).child(pushkeyNotification).set({
  //         notificationId: pushkeyNotification,
  //         action: "notification",
  //         userId: currentUserData.userId,
  //         message: values.description,
  //         title: values.title,
  //         timeStamp: Date.now(),
  //         userName: currentUserData.userName,
  //         profileImage: currentUserData.image ? currentUserData.image : "",
  //       });
  
  //       const notification = {
  //         title: values.title,
  //         body: values.description,
  //       };
  
  //       await fetch("https://fcm.googleapis.com/fcm/send", {
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: serverKey,
  //         },
  //         method: "POST",
  //         body: JSON.stringify({
  //           notification: notification,
  //           to: item.token,
  //         }),
  //       });
  //     }));
  
  //     setLoading(false);
  //     toast.success("Created Successfully");
  //     if (typeof onHide === "function") {
  //       onHide();
  //     } else {
  //       console.error("onHide is not a working.");
  //     }
  //   } catch (error) {
  //     console.log(error);
  //     setLoading(false);
  //     return;
  //   }
  // };
  
  // const handleSubmit = (values) => {
  //   // if (image == "") {
  //   //   setimageError(true);
  //   //   return;
  //   // }
  //   setLoading(true);
  //   const storageRef = firebase.storage().ref();
  //   storageRef
  //     .child(`announcementImages/${image.name}`)
  //     .put(image)
  //     .then(async (snapshot) => {
  //       snapshot.ref.getDownloadURL().then((val) => {
  //         const pushkey = firebase
  //           .database()
  //           .ref("Announcement")
  //           .push().key;
  //         firebase
  //           .database()
  //           .ref("Announcement")
  //           .child(pushkey)
  //           .set({
  //             title: values.title,
  //             description: values.description,
  //             image: val,
  //             id: pushkey,
  //           })
  //           .then(() => {
  //             users.map((item, i) => {
  //               const pushkey = firebase
  //                 .database()
  //                 .ref("notifications")
  //                 .push().key;
  //               firebase
  //                 .database()
  //                 .ref("notifications")
  //                 .child(item.userId)
  //                 .child(pushkey)
  //                 .set({
  //                   notificationId: pushkey,
  //                   action: "notification",
  //                   userId: currentUserData.userId,
  //                   message: values.description,
  //                   title: values.title,
  //                   timeStamp: Date.now(),
  //                   userName: currentUserData.userName,
  //                   profileImage: currentUserData.image
  //                     ? currentUserData.image
  //                     : "",
  //                 })
  //                 .then(async () => {
  //                   // showMessage({
  //                   //   message: 'Invitation Send',
  //                   //   duration: 1000,
  //                   //   type: 'success',
  //                   // });

  //                   const notification = {
  //                     title: values.title,
  //                     body: values.description,
  //                   };

  //                   await fetch("https://fcm.googleapis.com/fcm/send", {
  //                     headers: {
  //                       "Content-Type": "application/json",
  //                       Authorization: serverKey,
  //                     },
  //                     method: "POST",
  //                     body: JSON.stringify({
  //                       notification: notification,
  //                       to: item.token,
  //                     }),
  //                   })
  //                     .then((response) => response.json())
  //                     .then((data) => {
  //                       console.log("response", data);
  //                       onHide()
  //                     });
  //                 })
  //                 .catch((error) => {
  //                   console.log(error);
  //                 });
  //             });
  //             setLoading(false);
  //             toast.success("Created Successfuly");
  //              onHide();
  //           })
  //           .catch((err) => console.log(err));
  //       });
  //     })
  //     .catch((err) => {});
  // };
  const editHandler = (values) => {
    setLoading(true);
    if (image) {
      const storageRef = firebase.storage().ref();
      storageRef
        .child(`announcementImages/${image.name}`)
        .put(image)
        .then(async (snapshot) => {
          snapshot.ref.getDownloadURL().then((val) => {
            firebase
              .database()
              .ref("Announcement")
              .child(editItem.id)
              .update({
                title: values.title,
                description: values.description,
                image: val,
              })
              .then(() => {
                setLoading(false);
                toast.success("Updated Successfuly");
                onHide();
              })
              .catch((err) => console.log(err));
          });
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      firebase
        .database()
        .ref("Announcement")
        .child(editItem.id)
        .update({
          title: values.title,
          description: values.description,
          image: editItem.image || null,
        })
        .then(() => {
          setLoading(false);
          toast.success("Updated Successfuly");
          onHide();
        });
    }
  };

  return (
    <>
    {loading}
      <Formik
        enableReinitialize={true}
        initialValues={editItem != "" ? editinitialValues : addinitialValues}
        validationSchema={CustomerEditSchema}
        onSubmit={(values) => {
          editItem ? editHandler(values) : handleSubmit(values);
        }}
      >
        {({ values }) => (
          <>
            <Form className="form form-label-right">
              <Modal.Body className="overlay overlay-block cursor-default pt-0 pb-0">
                {actionsLoading && (
                  <div className="overlay-layer bg-transparent">
                    <div className="spinner spinner-lg spinner-success" />
                  </div>
                )}
                <div className="form-group row">
                  <div className="col-lg-12 col-md-12 col-sm-12">
                    <label>Enter Title</label>
                    <Field
                      component={Input}
                      placeholder="Enter  title"
                      value={values.title}
                      name="title"
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <div className="col-lg-12 col-md-12 col-sm-12">
                    <label>Enter Description</label>
                    <Field
                      style={{ height: "8rem" }}
                      value={values.description}
                      name="description"
                      component={TextArea}
                      placeholder="Enter description"
                    />
                  </div>
                </div>

                <div className="form-group row" style={{ marginBottom: "0px" }}>
                  {imageShow ? (
                    <img style={{ height: 150, width: 150 }} src={imageShow} />
                  ) : editItem.image ? (
                    <img
                      style={{ height: 150, width: 150 }}
                      src={editItem.image}
                    />
                  ) : null}
                  <label
                    htmlFor="fileImage"
                    style={{
                      width: 100,
                      margin: 20,
                      padding: 10,
                      borderRadius: 5,
                      backgroundColor: "#E6E9EC",
                      cursor: "pointer",
                    }}
                  >
                    <img loading={"lazy"} style={{ width: 78 }} src={logo} />
                  </label>

                  <input
                    style={{ display: "none" }}
                    type="file"
                    id={`fileImage`}
                    // ref={fileRef}
                    accept="image/png, image/jpeg"
                    onChange={(e) => {
                      // setimageError(false);
                      setImageShow(URL.createObjectURL(e.target.files[0]));
                      setImage(e.target.files[0]);
                    }}
                  />

                  {/* <input type={'file'} onChange={(e) => setImage(e.target.files[0])} /> */}
                </div>
              </Modal.Body>
              <Modal.Footer>
                <button
                  type="button"
                  onClick={onHide}
                  className="btn btn-light btn-elevate"
                >
                  Cancel
                </button>
                <> </>
                <button
                  disabled={loading}
                
                  type="submit"
                  className="btn btn-primary btn-elevate"
                >
                 {loading ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : 'Save'}
                </button>
              </Modal.Footer>
            </Form>
          </>
        )}
      </Formik>
    </>
  );
}
