import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import { connect, useDispatch } from "react-redux";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import { FormattedMessage, injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import { register } from "../_redux/authCrud";
import firebase from "firebase";
import { firebaseAuth, googleAuthProvider, push_key } from "../../../../firebase/config";
import { toast } from "react-hot-toast";

const initialValues = {
  fullname: "",
  email: "",
  userName: "",
  password: "",
  confirm_password: "",
  phoneNumber: "",
  type: 'client',

};

// userName: userName,
// email: email,
// password: password,
// phoneNumber: phoneNumber,
// type: 'client',
// userId: auth().currentUser.uid,
// token: userToken,

function Registration(props) {
  const { intl } = props;
  const [loading, setLoading] = useState(false);
  const [token, settoken] = useState(null)
  const [googleLoginLoading, setgoogleLoginLoading] = useState(false)

  const dispatch = useDispatch();

  const RegistrationSchema = Yup.object().shape({
    phoneNumber: Yup.number()
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    email: Yup.string()
      .email("Wrong email format")
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    userName: Yup.string()
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    password: Yup.string()
      .min(8, "Minimum 8 symbols")
      .max(50, "Maximum 50 symbols") .matches(
        /^(?=.*[A-Z])(?=.*[^a-zA-Z0-9]).*$/,
        "Password  Must have one capital and one special character"
      )
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    confirm_password: Yup.string()
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      )
      .when("password", {
        is: (val) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref("password")],
          "Password and Confirm Password didn't match"
        ),
      }),

  });

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  useEffect(() => {
    firebase.messaging().getToken({ vapidKey: push_key })
      .then((currentToken) => {
        if (currentToken) {
          settoken(currentToken)
        }
      })
  }, [])


  const findandSave = (uid) => {
    firebase.database().ref('users')
      .child(uid)
      .once('value', snapshot => {
        if (snapshot.exists()) {
          console.log('found', snapshot.val());
          dispatch({
            type: 'ADD_USER',
            payload: {
              user: snapshot.val(),
            },
          })
          localStorage.setItem('firebaseUser', JSON.stringify(snapshot.val()))
        }
      })
  }

  const formik = useFormik({
    initialValues,
    validationSchema: RegistrationSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      values['token'] = token

      setSubmitting(true);
      enableLoading();
      firebase
      .database()
      .ref("users")
      .orderByChild("userName")
      .equalTo(values.userName)
      .once("value", (snapshot) => {
        if (snapshot.exists()) {
          toast.error("Username already taken");
      setSubmitting(false);
          setLoading(false);
        } else {
      firebase.auth().createUserWithEmailAndPassword(values.email, values.password)
        .then(({ user }) => {
          values['userId'] = firebase.auth().currentUser.uid
          firebase.database().ref('users')
            .child(firebase.auth().currentUser.uid)
            .set(values)
            .then(() => {
              findandSave(user.uid)
              disableLoading();
              setSubmitting(false);
              localStorage.setItem("userToken", user.refreshToken);
              dispatch({
                type: "ADD_USER",
                payload: {
                  isAuthenticated: true,
                  authtoken: user.refreshToken,
                  user: user,
                },
              });
            })

        }).catch(err => {
          setStatus(err.message);
          disableLoading();
          setSubmitting(false);


        })
      }
    });
    },
    
  });
  const onGoogleSignin = () => {
    setgoogleLoginLoading(true)
    firebaseAuth.signInWithPopup(googleAuthProvider)
      .then((result) => {
        // Handle successful sign-in
        console.log(result);
        const user = result.user;
        if (result.additionalUserInfo.isNewUser) {
          firebase.database().ref('users')
            .child(user.uid)
            .set({
              token: token,
              email: user.email,
              // password: values.password,
              phoneNumber: user.phoneNumber,
              // token: token,
              type: "client",
              userId: user.uid,
              userName: user.displayName

            }).then(() => {
              localStorage.setItem("userToken", user.refreshToken);
              findandSave(user.uid)
              setgoogleLoginLoading(false)
            })

        } else {
          firebase.database().ref('users')
            .child(user.uid)
            .update({
              token: token,
              // email: values.email,
              // password: values.password,
              // phoneNumber: "03000000000",
              // token: token,
              // type: "Admin",
              // userId: firebase.auth().currentUser.uid,
              // userName: "Admin"

            }).then(() => {
              findandSave(user.uid)
              localStorage.setItem("userToken", user.refreshToken);
              setgoogleLoginLoading(false)


            })
        }
      })
      .catch((error) => {
        // Handle error
        setgoogleLoginLoading(false)

        console.log(error);
      });
  }

  return (
    <div className="login-form login-signin" style={{ display: "block" }}>
      <div className="text-center mb-10 mb-lg-20">
        <h3 className="font-size-h1">
          <FormattedMessage id="AUTH.REGISTER.TITLE" />
        </h3>
        <p className="text-muted font-weight-bold">
          Enter your details to create your account
        </p>
      </div>

      <form
        id="kt_login_signin_form"
        className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
        onSubmit={formik.handleSubmit}
      >
        {/* begin: Alert */}
        {formik.status && (
          <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
            <div className="alert-text font-weight-bold">{formik.status}</div>
          </div>
        )}
        {/* end: Alert */}

        {/* begin: Fullname */}
        {/* <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="User ame"
            type="text"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "fullname"
            )}`}
            name="fullname"
            {...formik.getFieldProps("fullname")}
          />
          {formik.touched.fullname && formik.errors.fullname ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.fullname}</div>
            </div>
          ) : null}
        </div> */}
        {/* end: Fullname */}


        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="User Name"
            type="text"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "userName"
            )}`}
            name="userName"
            {...formik.getFieldProps("userName")}
          />
          {formik.touched.userName && formik.errors.userName ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.userName}</div>
            </div>
          ) : null}
        </div>

        {/* begin: Email */}
        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="Email"
            type="email"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "email"
            )}`}
            name="email"
            {...formik.getFieldProps("email")}
          />
          {formik.touched.email && formik.errors.email ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.email}</div>
            </div>
          ) : null}
        </div>
        {/* end: Email */}

        {/* begin: Username */}

        {/* end: Username */}

        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="Phone Number"
            type="text"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "phoneNumber"
            )}`}
            name="phoneNumber"
            {...formik.getFieldProps("phoneNumber")}
          />
          {formik.touched.phoneNumber && formik.errors.phoneNumber ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.phoneNumber}</div>
            </div>
          ) : null}
        </div>

        {/* begin: Password */}
        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="Password"
            type="password"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "password"
            )}`}
            name="password"
            {...formik.getFieldProps("password")}
          />
          {formik.touched.password && formik.errors.password ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.password}</div>
            </div>
          ) : null}
        </div>
        {/* end: Password */}

        {/* begin: Confirm Password */}
        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="Confirm Password"
            type="password"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "confirm_password"
            )}`}
            name="confirm_password"
            {...formik.getFieldProps("confirm_password")}
          />
          {formik.touched.confirm_password && formik.errors.confirm_password ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">
                {formik.errors.confirm_password}
              </div>
            </div>
          ) : null}
        </div>
        {/* end: Confirm Password */}

        {/* begin: Terms and Conditions */}
        {/* <div className="form-group">
          <label className="checkbox">
            <input
              type="checkbox"
              name="acceptTerms"
              className="m-1"
              {...formik.getFieldProps("acceptTerms")}
            />
            <Link
              to="/terms"
              target="_blank"
              className="mr-1"
              rel="noopener noreferrer"
            >
              I agree the Terms & Conditions
            </Link>
            <span />
          </label>
          {formik.touched.acceptTerms && formik.errors.acceptTerms ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.acceptTerms}</div>
            </div>
          ) : null}
        </div> */}
        {/* end: Terms and Conditions */}
        <button disabled={googleLoginLoading} onClick={onGoogleSignin} type="button" className="login-with-google-btn col-12" >
          Sign in with Google
        </button>
        <div className="form-group d-flex flex-wrap flex-center">
          <button
            type="submit"
            disabled={
              formik.isSubmitting ||
              !formik.isValid || googleLoginLoading

            }
            className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
          >
            <span>Submit</span>
            {loading && <span className="ml-3 spinner spinner-white"></span>}
          </button>

          <Link to="/auth/login">
            <button
              type="button"
              className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4"
            >
              Cancel
            </button>
          </Link>
        </div>
      </form>
    </div>
  );
}

export default injectIntl(connect(null, auth.actions)(Registration));
