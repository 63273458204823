import { serverKey } from "./constants";


export const SendNotifaction=async(title,des,token,image)=>{
    const notification = {
        body: des,
        title: title,
        image:image
      };

      await fetch('https://fcm.googleapis.com/fcm/send', {
        headers: {
          'Content-Type': 'application/json',
          Authorization: serverKey
        },
        method: 'POST',
        body: JSON.stringify({
          notification: notification,
          to:token,
        }),
      })
        .then(response => response.json())
        .then(data => {
          console.log('response', data);
        });
}
export const SendNotifactionChat=async(userId,title,des,postId,receiverId,clickAction,icon,token,image)=>{
  const notification = {
      user: userId,
      body: des,
      title: title,
      sent: receiverId,
      post: postId,
      clickAction: clickAction,
      icon: icon,
      image:image
    };

    await fetch('https://fcm.googleapis.com/fcm/send', {
      headers: {
        'Content-Type': 'application/json',
        Authorization: serverKey
      },
      method: 'POST',
      body: JSON.stringify({
        notification: notification,
        to:token,
      }),
    })
      .then(response => response.json())
      .then(data => {
        console.log('response', data);
      });
}