import React, { useEffect } from 'react'
import { Button, Divider, message, Steps, theme } from 'antd';
import { useState } from 'react';
import * as Yup from 'yup';
import './projectForm.css'
import { Field, Form, Formik } from 'formik';
import { Card, CardBody, CardHeader, CardHeaderToolbar, Input } from '../../../../_metronic/_partials/controls';
import firebase from 'firebase';
import { toast } from 'react-hot-toast';
import { useHistory, useParams,Link } from 'react-router-dom';
const steps = [
  {
    title: 'Basic Information',
  },
  {
    title: 'Amenities Information',
  },
  {
    title: 'Entertainment',
  },
];
const ProjectFrom = () => {


  const history = useHistory()
  const [Floor, setFloor] = useState('');
  const [Walls, setWalls] = useState('');
  const [Doors, setDoors] = useState('');
  const [Windows, setWindows] = useState('');
  const [Kitchen, setKitchen] = useState('');
  const [Bedrooms, setBedrooms] = useState('');
  const [Ceilings, setCeilings] = useState('');
  const [Washrooms, setWashrooms] = useState('');
  const [Garage, setGarage] = useState('');
  const [Stairs, setStairs] = useState('');
  const [Roof, setRoof] = useState('');
  const [Walk_in_closets, setWalkinClosets] = useState('');
  const [Panic_room_Bunker, setPanic_room_Bunker] = useState('');
  const [Swimming_pool, setSwimming_pool] = useState('');
  const [Library, setLibrary] = useState('');
  const [Office, setOffice] = useState('');
  const [gym, setGym] = useState('');
  const [Tennis_Court, setTennis_Court] = useState('');
  const [Home_Bar, setHome_Bar] = useState('');
  const [Home_Theater, setHome_Theater] = useState('');
  const [BallRoom, setBallRoom] = useState('');
  const [PartyRoom, setPartyRoom] = useState('');
  const [OutdoorKitchen, setOutdoorKitchen] = useState('');
  const [SettingArea, setSettingArea] = useState('');
  const [Aquariums, setAquariums] = useState('');
  const [Waterfalls, setWaterfalls] = useState('');
  const [Jacuzzis, setJacuzzis] = useState('');
  const [IndoorPlantation, setIndoorPlantation] = useState('');
  const [PrayerArea, setPrayerArea] = useState('');
  const [MusicSystem, setMusicSystem] = useState('');
  const [Lighting, setLighting] = useState('');
  const [lift, setlift] = useState('');
  const [Chandeliers, setChandeliers] = useState('');

  const [loading, setLoading] = useState(false)
  const params = useParams();


  console.log(params.id,"this is")

  useEffect(() => {
    getProjectData()

  }, [])


  const getProjectData = () => {
    firebase
      .database()
      .ref('projects')
      .child(params.id)
      .on('value', snapshot => {
        if (snapshot.exists()) {
          const projectData = snapshot.val();
          console.log(projectData)
          setFloor(projectData.floor || '');
          setWalls(projectData.Walls || '');
          setDoors(projectData.doors || '');
          setWindows(projectData.window || '');
          setKitchen(projectData.Kitchen || '');
          setBedrooms(projectData.Bedrooms || '');
          setCeilings(projectData.Ceilings || '');
          setWashrooms(projectData.Washrooms || '');
          setGarage(projectData.Garage || '');
          setStairs(projectData.Stairs || '');
          setRoof(projectData.Roof || '');
          setWalkinClosets(projectData.Walk_in_closets || '');
          setPanic_room_Bunker(projectData.Panic_room_Bunker || '');
          setSwimming_pool(projectData.Swimming_pool || '');
          setLibrary(projectData.Library || '');
          setOffice(projectData.office || '');
          setGym(projectData.gym || '');
          setTennis_Court(projectData.Tennis_Court || '');
          setHome_Bar(projectData.Home_Bar || '');
          setHome_Theater(projectData.Home_Theater || '');
          setBallRoom(projectData.BallRoom || '');
          setPartyRoom(projectData.PartyRoom || '');
          setOutdoorKitchen(projectData.OutdoorKitchen || '');
          setSettingArea(projectData.SettingArea || '');
          setAquariums(projectData.Aquariums || '');
          setWaterfalls(projectData.Waterfalls || '');
          setJacuzzis(projectData.Jacuzzis || '');
          setIndoorPlantation(projectData.IndoorPlantation || '');
          setPrayerArea(projectData.PrayerArea || '');
          setMusicSystem(projectData.MusicSystem || '');
          setLighting(projectData.Lighting || '');
          setlift(projectData.lift || '');
          setChandeliers(projectData.Chandeliers || '');
        }

      })
  }





console.log(Bedrooms,"bathroom")

  const initValues = {
    Floor: Floor,
    Walls: Walls,
    Doors: Doors,
    Windows: Windows,
    Kitchen: Kitchen,
    Bedrooms: Bedrooms,
    Ceilings: Ceilings,
    Washrooms: Washrooms,
    Garage: Garage,
    Stairs: Stairs,
    Roof: Roof,
    Walk_in_closets: Walk_in_closets,
    Panic_room_Bunker: Panic_room_Bunker,
    Swimming_pool: Swimming_pool,
    Library: Library,
    gym: gym,
    Tennis_Court: Tennis_Court,
    Home_Bar: Home_Bar,
    Home_Theater: Home_Theater,
    BallRoom: BallRoom,
    PartyRoom: PartyRoom,
    OutdoorKitchen: OutdoorKitchen,
    SettingArea: SettingArea,
    Aquariums: Aquariums,
    Waterfalls: Waterfalls,
    Jacuzzis: Jacuzzis,
    IndoorPlantation: IndoorPlantation,
    PrayerArea: PrayerArea,
    MusicSystem: MusicSystem,
    Lighting: Lighting,
    lift: lift,
    Chandeliers: Chandeliers,
    Office:Office
  };




  const validationSchema = Yup.object().shape({
    Floor: Yup.string(),
    Walls: Yup.string(),
    Doors: Yup.string(),
    Windows: Yup.string(),
    Kitchen: Yup.string(),
    bedrooms: Yup.string(),
    Ceilings: Yup.string(),
    Washrooms: Yup.string(),
    Garage: Yup.string(),
    Stairs: Yup.string(),
    Roof: Yup.string(),
    Walk_in_closets: Yup.string(),
    Panic_room_Bunker: Yup.string(),
    Swimming_pool: Yup.string(),
    Library: Yup.string(),
    Office: Yup.string(),
    gym: Yup.string(),
    Tennis_Court: Yup.string(),
    Home_Bar: Yup.string(),
    Home_Theater: Yup.string(),
    BallRoom: Yup.string(),
    PartyRoom: Yup.string(),
    OutdoorKitchen: Yup.string(),
    SettingArea: Yup.string(),
    Aquariums: Yup.string(),
    Waterfalls: Yup.string(),
    Jacuzzis: Yup.string(),
    IndoorPlantation: Yup.string(),
    PrayerArea: Yup.string(),
    MusicSystem: Yup.string(),
    Lighting: Yup.string(),
    lift: Yup.string(),
    Chandeliers: Yup.string(),
  });




  const handleSubmit = (values) => {
    console.log(params.id)
    setLoading(true)
    firebase.database().ref('projects').child(params.id)
      .update({
        floor: values.Floor,
        Walls: values.Walls,
        doors: values.Doors,
        window: values.Windows,
        Kitchen: values.Kitchen,
        Bedrooms: values.Bedrooms,
        Roof: values.Roof,
        Ceilings: values.Ceilings,
        Washrooms: values.Washrooms,
        Garage: values.Garage,
        Stairs: values.Stairs,
        lift: values.lift,
        Walk_in_closets: values.Walk_in_closets,
        Panic_room_Bunker: values.Panic_room_Bunker,
        Swimming_pool: values.Swimming_pool,
        Library: values.Library,
        office: values.Office,
        gym: values.gym,
        Tennis_Court: values.Tennis_Court,
        Home_Bar: values.Home_Bar,
        Home_Theater: values.Home_Theater,
        BallRoom: values.BallRoom,
        PartyRoom: values.PartyRoom,
        OutdoorKitchen: values.OutdoorKitchen,
        SettingArea: values.SettingArea,
        Aquariums: values.Aquariums,
        Waterfalls: values.Waterfalls,
        Jacuzzis: values.Jacuzzis,
        Chandeliers: values.Chandeliers,
        IndoorPlantation: values.IndoorPlantation,
        PrayerArea: values.PrayerArea,
        MusicSystem: values.MusicSystem,
        Lighting: values.Lighting,
        formStatus: true
      })
      .then(() => {
        setLoading(false)
        toast.success("You have successfully Added the Information")
        history.push('/user-projects')

      }).catch((err) => console.log(err));
  }



  const { token } = theme.useToken();
  const [current, setCurrent] = useState(0);
  const next = () => {
    setCurrent(current + 1);
  };
  const prev = () => {
    setCurrent(current - 1);
  };
  const items = steps.map((item) => ({
    key: item.title,
    title: item.title,
  }));
  const contentStyle = {
    lineHeight: '260px',
    textAlign: 'center',
    color: token.colorTextTertiary,
    backgroundColor: token.colorFillAlter,
    borderRadius: token.borderRadiusLG,
    border: `1px dashed ${token.colorBorder}`,
    marginTop: 16,
  };
  return (
    <>
      <Card>

        <CardHeader title="Basic Information">
        <CardHeaderToolbar>
        <Link
                      to={`/forms/${params.id}`}
                      className="btn btn-secondary"
                    >
                      Go Back
                    </Link>
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          <div className='mainStepperContainer'>
            <div className='stepscontainer'>
              <Steps current={current} items={items} />
            </div>
          </div>
          <Divider />
          <Formik
            enableReinitialize={true}
            initialValues={initValues}
            validationSchema={validationSchema}
            onSubmit={(values) => {
              handleSubmit(values);
            }}
          >
            {({ values, }) => (
              <>
                <Form className="form form-label-right">
                  <div className='multiform'>
                    {current == 0 ?
                      <>
                        <div className='formcontainer'>
                          <div className="form-group row">
                            {/* First Name */}
                            <div className="col-lg-4 col-md-4 col-sm-12 mt-5">
                              <label>Enter Floor</label>
                              <Field
                                component={Input}
                                defaultValue={Floor}
                                placeholder="e.g White Italian marble"
                                value={values.Floor}
                                name="Floor"
                              />
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-12 mt-5">
                              <label>Enter Walls</label>
                              <Field
                                // style={{ height: "8rem" }}
                                value={values.Walls}
                                name="Walls"
                                component={Input}
                                placeholder="e.g Clean art touch"
                              />
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-12 mt-5">
                              <label>Enter Doors</label>
                              <Field
                                // style={{ height: "8rem" }}
                                value={values.Doors}
                                name="Doors"
                                component={Input}
                                placeholder="e.g Less and unique design"
                              />
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-12 mt-5">
                              <label>Enter Windows</label>
                              <Field
                                // style={{ height: "8rem" }}
                                value={values.Windows}
                                name="Windows"
                                component={Input}
                                placeholder="e.g Big Windows"
                              />
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-12 mt-5">
                              <label>Enter Kitchen</label>
                              <Field
                                // style={{ height: "8rem" }}
                                value={values.Kitchen}
                                name="Kitchen"
                                component={Input}
                                placeholder="e.g Attach fancy kitchen with spice kitchen"
                              />
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-12 mt-5">
                              <label>Enter bedrooms</label>
                              <Field
                                // style={{ height: "8rem" }}
                                value={values.Bedrooms}
                                name="Bedrooms"
                                component={Input}
                                placeholder="e.g 7 Bedrooms"
                              />
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-12 mt-5">
                              <label>Enter roof</label>
                              <Field
                                // style={{ height: "8rem" }}
                                value={values.Roof}
                                name="Roof"
                                component={Input}
                                placeholder="e.g Flat with sitting on roof"
                              />
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-12 mt-5">
                              <label>Enter Ceilings</label>
                              <Field
                                // style={{ height: "8rem" }}
                                value={values.Ceilings}
                                name="Ceilings"
                                component={Input}
                                placeholder="e.g Dome on 3rd floor"
                              />
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-12 mt-5">
                              <label>Enter Washrooms</label>
                              <Field
                                // style={{ height: "8rem" }}
                                value={values.Washrooms}
                                name="Washrooms"
                                component={Input}
                                placeholder="e.g 8 Washrooms"
                              />
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-12 mt-5">
                              <label>Enter Garage</label>
                              <Field
                                // style={{ height: "8rem" }}
                                value={values.Garage}
                                name="Garage"
                                component={Input}
                                placeholder="e.g On first floor"
                              />
                            </div>


                          </div>
                        </div>:
                      </>
                      : ''
                    }
                    {current == 1 ?
                      <>
                        <div className='formcontainer'>
                          <div className="form-group row">
                            {/* First Name */}
                            <div className="col-lg-4 col-md-4 col-sm-12 mt-5">
                              <label>Enter Stairs</label>
                              <Field
                                component={Input}
                                placeholder="e.g Modern Material"
                                value={values.Stairs}
                                name="Stairs"
                              />
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-12 mt-5">
                              <label>Enter lift</label>
                              <Field
                                // style={{ height: "8rem" }}
                                value={values.lift}
                                name="lift"
                                component={Input}
                                placeholder="e.g Cargo lift connecting all floors"
                              />
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-12 mt-5">
                              <label>Enter Walk in closets</label>
                              <Field
                                // style={{ height: "8rem" }}
                                value={values.Walk_in_closets}
                                name="Walk_in_closets"
                                component={Input}
                                placeholder="e.g Included"
                              />
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-12 mt-5">
                              <label>Enter Panic room / Bunker</label>
                              <Field
                                // style={{ height: "8rem" }}
                                value={values.Panic_room_Bunker}
                                name="Panic_room_Bunker"
                                component={Input}
                                placeholder="e.g Nuclear bunker with storage for emergency supplies"
                              />
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-12 mt-5">
                              <label>Enter Swimming pool</label>
                              <Field
                                // style={{ height: "8rem" }}
                                value={values.Swimming_pool}
                                name="Swimming_pool"
                                component={Input}
                                placeholder="e.g Connect indoor and outdoor pool"
                              />
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-12 mt-5">
                              <label>Enter Library</label>
                              <Field
                                // style={{ height: "8rem" }}
                                value={values.Library}
                                name="Library"
                                component={Input}
                                placeholder="e.g Library attached with office"
                              />
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-12 mt-5">
                              <label>Enter office</label>
                              <Field
                                // style={{ height: "8rem" }}
                                value={values.Office}
                                name="Office"
                                component={Input}
                                placeholder="e.g On first floor"
                              />
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-12 mt-5">
                              <label>Enter gym</label>
                              <Field
                                // style={{ height: "8rem" }}
                                value={values.gym}
                                name="gym"
                                component={Input}
                                placeholder="e.g Included south west view"
                              />
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-12 mt-5">
                              <label>Enter Tennis Court</label>
                              <Field
                                // style={{ height: "8rem" }}
                                value={values.Tennis_Court}
                                name="Tennis_Court"
                                component={Input}
                                placeholder="e.g Included"
                              />
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-12 mt-5">
                              <label>Enter Home Bar</label>
                              <Field
                                // style={{ height: "8rem" }}
                                value={values.Home_Bar}
                                name="Home_Bar"
                                component={Input}
                                placeholder="e.g Included"
                              />
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-12 mt-5">
                              <label>Enter Home Theater</label>
                              <Field
                                // style={{ height: "8rem" }}
                                value={values.Home_Theater}
                                name="Home_Theater"
                                component={Input}
                                placeholder="e.g Included"
                              />
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-12 mt-5">
                              <label>Enter BallRoom</label>
                              <Field
                                // style={{ height: "8rem" }}
                                value={values.BallRoom}
                                name="BallRoom"
                                component={Input}
                                placeholder="e.g Included"
                              />
                            </div>



                          </div>
                        </div>:
                      </>
                      : ''
                    }
                    {current == 2 ?
                      <>
                        <div className='formcontainer'>
                          <div className="form-group row">
                            {/* First Name */}
                            <div className="col-lg-4 col-md-4 col-sm-12 mt-5">
                              <label>Enter Party Room</label>
                              <Field
                                component={Input}
                                placeholder="e.g Under the shed near BBQ area and bar"
                                value={values.PartyRoom}
                                name="PartyRoom"
                              />
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-12 mt-5">
                              <label>Enter Outdoor Kitchen</label>
                              <Field
                                // style={{ height: "8rem" }}
                                value={values.OutdoorKitchen}
                                name="OutdoorKitchen"
                                component={Input}
                                placeholder="e.g With BBQ area and sitting area under the shed"
                              />
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-12 mt-5">
                              <label>Enter Setting Area</label>
                              <Field
                                // style={{ height: "8rem" }}
                                value={values.SettingArea}
                                name="SettingArea"
                                component={Input}
                                placeholder="e.g Below dome"
                              />
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-12 mt-5">
                              <label>Enter Aquariums</label>
                              <Field
                                // style={{ height: "8rem" }}
                                value={values.Aquariums}
                                name="Aquariums"
                                component={Input}
                                placeholder="e.g Design aquariums on both 1st and 2nd floor"
                              />
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-12 mt-5">
                              <label>Enter Waterfalls</label>
                              <Field
                                // style={{ height: "8rem" }}
                                value={values.Waterfalls}
                                name="Waterfalls"
                                component={Input}
                                placeholder="e.g Inside: Quite waterfalls"
                              />
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-12 mt-5">
                              <label>Enter Jacuzzis</label>
                              <Field
                                // style={{ height: "8rem" }}
                                value={values.Jacuzzis}
                                name="Jacuzzis"
                                component={Input}
                                placeholder="e.g Two near pool area"
                              />
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-12 mt-5">
                              <label>Enter Indoor Plantation</label>
                              <Field
                                // style={{ height: "8rem" }}
                                value={values.IndoorPlantation}
                                name="IndoorPlantation"
                                component={Input}
                                placeholder="e.g Red roses"
                              />
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-12 mt-5">
                              <label>Enter Prayer Area</label>
                              <Field
                                // style={{ height: "8rem" }}
                                value={values.PrayerArea}
                                name="PrayerArea"
                                component={Input}
                                placeholder="e.g Attach prayer room with office"
                              />
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-12 mt-5">
                              <label>Enter Music System</label>
                              <Field
                                // style={{ height: "8rem" }}
                                value={values.MusicSystem}
                                name="MusicSystem"
                                component={Input}
                                placeholder="e.g Music system in whole mention"
                              />
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-12 mt-5">
                              <label>Enter Lighting</label>
                              <Field
                                // style={{ height: "8rem" }}
                                value={values.Lighting}
                                name="Lighting"
                                component={Input}
                                placeholder="e.g Visual concept given"
                              />
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-12 mt-5">
                              <label>Enter Chandeliers</label>
                              <Field
                                // style={{ height: "8rem" }}
                                value={values.Chandeliers}
                                name="Chandeliers"
                                component={Input}
                                placeholder="e.g From dome connecting two floors all the way towards sitting below"
                              />
                            </div>



                          </div>
                        </div>:
                      </>
                      : ''
                    }
                  </div>
                  <Divider />
                  <div

                    className='buttonContainerMulti'
                  >
                    {current > 0 && (
                      <Button
                        htmlType='button'
                        style={{
                          margin: '0 8px',
                        }}
                        onClick={() => prev()}
                      >
                        Previous
                      </Button>
                    )}
                    {current < steps.length - 1 && (
                      <Button htmlType='button' type="primary" onClick={() => next()}>
                        Next
                      </Button>
                    )}
                    {current === steps.length - 1 && (
                      <Button disabled={loading} type="primary" htmlType='submit'>
                        Done
                      </Button>
                    )}

                  </div>
                </Form>
              </>
            )}
          </Formik>
        </CardBody>
      </Card>
    </>
  );
};
export default ProjectFrom;
