/**
 * Entry application component used to compose providers and render Routes.
 * */

import React, { useEffect, useState } from "react";
import { Provider } from "react-redux";
import { BrowserRouter,Redirect,Route} from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import { AuthInit, PrivacyPolicy } from "./modules/Auth";

import Routes from "../app/Routes";
import { I18nProvider } from "../_metronic/i18n";
import { LayoutSplashScreen, MaterialThemeProvider } from "../_metronic/layout";
import { firebaseConfig, getTokenfirebase, onMessageListener } from "../firebase/config";
import * as firebase from "firebase"
import toast, { Toaster } from 'react-hot-toast';
// import PrivacyPolicy from "./pages/PrivacyPolicy/PrivacyPolicy";


export default function App({ store, persistor, basename }) {

  const [show, setShow] = useState(false);
  const [notification, setNotification] = useState({ title: '', body: '' });
  const [isTokenFound, setTokenFound] = useState(false);

  const [payload, setpayload] = useState()
  getTokenfirebase(setTokenFound);


  const notify = () => toast(<ToastDisplay />);

  function ToastDisplay() {
    return (
      <div onClick={() => window.location.href = '/'}>
        <p><b>{notification.title}</b></p>
        <p>{notification.body}</p>
      </div>
    );
  };

  onMessageListener().then(payload => {
    setpayload(payload)
    setShow(true);
    setNotification({ title: payload.notification.title, body: payload.notification.body })
  }).catch(err => console.log('failed: ', err));



  useEffect(() => {
    if (notification?.title) {
      notify()
    }
  }, [notification])





  return (
    <>
   
    {/* /* Provide Redux store */}
    <Provider store={store}>
      {/* Asynchronously persist redux stores and show `SplashScreen` while it's loading. */}
      <PersistGate persistor={persistor} loading={<LayoutSplashScreen />}>
        {/* Add high level `Suspense` in case if was not handled inside the React tree. */}
        <React.Suspense fallback={<LayoutSplashScreen />}>
          {/* Override `basename` (e.g: `homepage` in `package.json`) */}
          <BrowserRouter basename={basename}>

            {/*This library only returns the location that has been active before the recent location change in the current window lifetime.*/}
            <MaterialThemeProvider>
              {/* Provide `react-intl` context synchronized with Redux state.  */}
              <I18nProvider>
              
                {/* Render routes with provided `Layout`. */}
                <AuthInit>
                  {/* <PrivacyPolicy/> */}
                  <Routes />
                  <Toaster />
                </AuthInit>
              </I18nProvider>
            </MaterialThemeProvider>
          </BrowserRouter>
        </React.Suspense>
      </PersistGate>
    </Provider>
    </>
  );
}
