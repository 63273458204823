import React, { useRef } from "react";
import { AppBar, Avatar, CardContent, Paper, Typography,Card as Carder, CardHeader } from "@material-ui/core";
import { Card, Space,Input, Button, Divider } from "antd";
import "./comments.css";
import { useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import firebase from "firebase";
import pop from './audio/sound.mp3'
import { toast } from "react-hot-toast";
import { SendNotifaction } from "../../../../../utils/SendNotifaction";
const { TextArea } = Input;





const useStyles = makeStyles((theme) => ({
    root: {
      maxWidth: 400,
    },
    media: {
      height: 0,
      paddingTop: '56.25%', // 16:9
    },
    expand: {
      transform: 'rotate(0deg)',
      marginLeft: 'auto',
      // transition: theme.transitions.create('transform', {
      //   duration: theme.transitions.duration.shortest,
      // }),
    },
    expandOpen: {
      transform: 'rotate(180deg)',
    },
    avatar: {
      backgroundColor: "red",
    },
  }));

const Comments = ({projectUser}) => {
    const classes = useStyles();
    const [value, setValue] = useState('');
    const [commentList,setCommentList]=useState([])
    const [buttonLoading,setButtonLoading]=useState(false)
    const [designer,setDesigner]=useState({})
    const [currentUser,setcurrentUser]=useState({})

   const dummy = useRef()

 const params=useParams()


    useEffect(() => {
        commentsGetter()
      projectDesigner()
      getUser()
    }, [])

    const getUser = () => {
      console.log("on Auth chala")
  
      firebase.auth().onAuthStateChanged(user => {
        if (user) {
          firebase.database().ref('users')
            .child(user.uid)
            .on('value', snapshot => {
              if (snapshot.exists()) {
                setcurrentUser(snapshot.val())
              }
            })
        }
      })
    }


 
    const commentsGetter = async () => {
        firebase
        .database()
        .ref(`/projects/${params.id}/AllComments`)
        .on("value", (snapshot) => {
          let arr = [];
          if (snapshot.exists()) {
            snapshot.forEach((item) => {
              let value = item.val();
              value.id = item.key;
              arr.push(value);
            });
          }
          setCommentList(arr.reverse());
        });
      };
  
    const projectDesigner = async () => {
        firebase
        .database()
        .ref(`/projects/${params.id}/designer`)
        .on("value", (snapshot) => {
          if (snapshot.exists()) {
            firebase.database().ref('users')
            .child(snapshot.val().userId)
            .on('value', snapshot => {
                if (snapshot.exists()) {
                  setDesigner(snapshot.val())
                    // setcurrentToken(snapshot.val()?.token)
                }
            })
               
          }
    
        });
      };
    


      const addComment = async () => {
     
        if (value) {
            setButtonLoading(true)
          const commentId =  firebase
          .database()
          .ref(`/projects`).push().key;
          firebase
          .database()
          .ref(`/projects`).child(params.id)
            .child('AllComments')
            .child(commentId)
            .set({
              comment:value,
              commentId,
              timeStamp: Date.now(),
              userName: currentUser?.userName,
              uid: currentUser.userId,
              profileImage: currentUser.image || "",
              type:currentUser.type
            })
            .then(() => {
            setButtonLoading(false)
                toast.success("Comment Send successfully")
                const sound = document.getElementById("sound");
                sound.play();
                if(currentUser.type=="designer"){
                SendNotifaction(`${currentUser?.userName} added the comment`,value,projectUser.token,"")
                }else{
                  SendNotifaction(`${currentUser?.userName} added the comment`,value,designer.token,"")
                }
                setValue("")
            //   if (boardData.userId == auth().currentUser?.uid) {
            //     boardUsersDataGetter(commentId);
            //   } else {
            //     // boardUsersDataGetter(commentId)
            //     sendNotificaionToBoardOwner(commentId);
            //   }
            // dummy.current.scrollIntoView();
            })
            .catch(err => {
              console.log(err)
            });
        } else {
            toast.error("Please Add comment")
        //   showMessage({
        //     message: 'Add Comment',
        //     duration: 1000,
        //     type: 'warning',
        //   });
        }
      };
      const timeChanger=(timestamp)=>{

        var date = new Date(timestamp);
        // Format the date in Pacific Time (PT) format
        var formattedDate = new Intl.DateTimeFormat('en-US', {
          timeZone: 'America/Los_Angeles',
          month: '2-digit',
          day: '2-digit',
          year: 'numeric',
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
          hour12: true
        }).format(date);
        
console.log(formattedDate)

return formattedDate;
      }
  return (
    <>
     <audio id="sound" src={pop}></audio>
      <div className="row" >
        <div className="col-md-4" style={{height:"70vh", borderRight:"#e7d9d9"}}>
        <Space direction="vertical" size={16}>

    <Card
      size="small"
      title="Add Comment"
      style={{
        width: 300,
        minHeight:"250px"
      }}
    >
       <TextArea
        value={value}
        onChange={(e) => setValue(e.target.value)}
        placeholder="Write the comment"
        autoSize={{ minRows: 7, maxRows: 7 }}
      />
      <div  style={{textAlign:"end",marginTop:"20px"}}>
      <Button onClick={addComment} type="primary" loading={buttonLoading}>
        Send
        </Button>
      </div>
    </Card>
  </Space>
          </div>
       

        <Paper className="col-md-8 pt-3 hereScroll" style={{overflowY:"scroll",height:"100vh",minHeight:"100vh"}}>
            <div>
            <h3 className="commmentHeading mt-2">
               All Comments
             </h3>
             <Divider/>
            </div>
           
{/*         
    <Card
      size="small"
      title="Admin"
      extra={<Avatar style={{height:"35px"}} alt="Remy Sharp" src="/static/images/avatar/1.jpg" />}
      style={{
        width: 300,
        minHeight:"100px"
      }}

    >
        <p className="mainComment"></p>
   
    </Card> */}
   
  {commentList.length>0?
<>


{

commentList.map((item,i)=>(
    <div id={commentList?.length==i+1?'yes':''} className={item.uid==currentUser?.userId?"row mt-4 mb-3 maraCard":"row mt-4 mb-3"}>
       <div className="col-md-12">
       <Carder className={classes.root}>
       <CardHeader
         avatar={
         //   <Avatar aria-label="recipe" className={classes.avatar}>
         //     <img  src={item.profileImage} alt="profileimg"/>
         //   </Avatar>
           <Avatar alt="Remy Sharp"  src={item.profileImage} />
         }
         // action={
         //   <IconButton aria-label="settings">
         //     <MoreVertIcon />
         //   </IconButton>
         // }
         title={item.userName}
         subheader={timeChanger(item?.timeStamp)}
       />
       <Divider style={{margin:0}}/>
 
       <CardContent>
         <Typography variant="body2" color="textSecondary" component="p">
           {item.comment}
         </Typography>
       </CardContent>
    
     
     </Carder>
       </div>
<div ref={dummy} />
    </div>
   )) 
}

</>
 
 
  :
  <div className="noboardFound">
    <h3>
        No comment found
    </h3>
  </div>

    
    }
        </Paper>
      </div>
    </>
  );
};

export default Comments;
