import React, { useCallback, useEffect, useMemo, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../_metronic/_partials/controls";
import firebase from "firebase";
import * as columnFormatters from "../../modules/ECommerce/pages/customers/customers-table/column-formatters";
import * as uiHelpers from "../../../app/modules/ECommerce/pages/customers/CustomersUIHelpers";
import { Pagination } from "../../../../src/_metronic/_partials/controls";
import LinearProgress from "@material-ui/core/LinearProgress";
import AddBooks from "./AddBooks";
import EditBooks from "./EditBooks";
export function Books() {
  const [info, setInfo] = useState([]);
  const [books, setbooks] = useState([]);
  const [loading, setloading] = useState(false);

  ////for pagination
  const initialFilter = {
    pageNumber: 1,
    pageSize: 10,
  };

  const [queryParams, setQueryParamsBase] = useState(initialFilter);

  const [open, setOpen] = React.useState(false);
  const [editOpen, setEditOpen] = React.useState(false);
  const [editData, setEditdata] = React.useState({});

  const handleClose = () => {
    setOpen(false);
  };
  const handleEditClose = () => {
    setEditOpen(false);
  };
  const columns = [
    {
      dataField: "name",
      text: "Name",
    },
    {
      dataField: "price",
      text: "Price",
    },
    {
      dataField: "action",
      text: "Actions",
      formatter: columnFormatters.ActionsColumnFormatter,
      formatExtraData: {
        openEditCustomerDialog: (item) => openEdit(item),
        openDeleteCustomerDialog: (id) => {
          console.log(id);
          firebase
            .database()
            .ref(`books`)
            .child(id)
            .remove()
            .then(() => {
              
            });
        },
      },
      classes: "text-right pr-0",
      headerClasses: "text-right pr-3",
      style: {
        minWidth: "100px",
      },
    },
  ];

  const openEdit = (item) => { 
    console.log(item)
    setEditdata(item)
    setEditOpen(true)
  };
  const options = {
    custom: true,
    totalSize: books.length,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: queryParams.pageSize,
    page: queryParams.pageNumber,
  };

  const onPaginationSelectChange = () => {
    setQueryParamsBase({
      pageNumber: 1,
    });
  };

  const updatingQueryParams = (page) => {
    console.log(page);
    console.log("this is chus2");
    setQueryParamsBase({
      pageNumber: page,
    });
  };
  useEffect(() => {
    Fetchdata();
  }, []);

  const Fetchdata = () => {
    setloading(true);
    firebase
      .database()
      .ref("books")
      .on("value", (snapshot) => {
        let arr = [];
        if (snapshot.exists()) {
          snapshot.forEach((item) => {
            let value = item.val();
            value.id = item.key;
            arr.push(value);
          });
        }
        setloading(false);
        setbooks(arr);
      });
  };

  // setInfo(arr => [...arr , data]);

  return (
    <>
      {loading ? <LinearProgress /> : null}
      <Card>
        <>
          <CardHeader title="Books">
            <CardHeaderToolbar>
              <button
                type="button"
                className="btn btn-primary mx-2"
                onClick={() => {
                  setOpen(true);
                }}
              >
                New Book
              </button>
            </CardHeaderToolbar>
          </CardHeader>
          <CardBody>
            {/* <CustomersFilter />
                {customersUIProps.ids.length > 0 && <CustomersGrouping />}
                <CustomersTable /> */}
            <PaginationProvider pagination={paginationFactory(options)}>
              {({ paginationProps, paginationTableProps }) => {
                return (
                  <>
                    <Pagination
                      updatingQueryParams={updatingQueryParams}
                      onPaginationSelectChange={onPaginationSelectChange}
                      isLoading={loading}
                      paginationProps={paginationProps}
                    >
                      <BootstrapTable
                        wrapperClasses="table-responsive"
                        bordered={false}
                        classes="table table-head-custom table-vertical-center overflow-hidden"
                        bootstrap4
                        keyField="id"
                        data={books}
                        columns={columns}
                        defaultSorted={uiHelpers.defaultSorted}
                        {...paginationTableProps}
                      ></BootstrapTable>
                    </Pagination>
                    {/* )} */}
                  </>
                );
              }}
            </PaginationProvider>
          </CardBody>
        </>
      </Card>

      <AddBooks  handleClose={handleClose} open={open} />
      <EditBooks data={editData}  handleClose={handleEditClose} open={editOpen} />
    </>
  );
}
