import React, { useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import { Content } from "./Content";

export function ContentRoute({ children, component, render, ...props }) {



  useEffect(() => {
    
  }, [])

  return (
    <Route {...props}>
      {routeProps => {


        if (Array.isArray(props.role)) {

           console.log(props.role)

          if (props.role?.includes(JSON.parse(localStorage.getItem("firebaseUser")).type) ==false ) {
            return <Redirect to={
              {
                pathname: '/error/error-v1',
                state: {
                  from: props.location
                }
              }
            } />
          }
        }else if(props.role){
          if (props.role != JSON.parse(localStorage.getItem("firebaseUser")).type) {
            return <Redirect to={
              {
                pathname: '/error/error-v1',
                state: {
                  from: props.location
                }
              }
            } />
          }
        }

        if (typeof children === "function") {
          return <Content>{children(routeProps)}</Content>;
        }

        if (!routeProps.match) {
          return null;
        }

        if (children) {
          return <Content>{children}</Content>;
        }

        if (component) {
          return (
            <Content>{React.createElement(component, routeProps)}</Content>
          );
        }

        if (render) {
          return <Content>{render(routeProps)}</Content>;
        }

        return null;
      }}
    </Route>
  );
}
