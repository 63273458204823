// please be familiar with react-bootstrap-table-next column formaters
// https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html?selectedKind=Work%20on%20Columns&selectedStory=Column%20Formatter&full=0&addons=1&stories=1&panelRight=0&addonPanel=storybook%2Factions%2Factions-panel
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../../../../_metronic/_helpers";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

export function ActionsColumnFormatterProject(
  cellContent,
  row,
  rowIndex,
  { openEditCustomerDialog, onUploadButtonClick, onViewButtonClick,openDeleteCustomerDialog }
) {
  return (
    <>
      <a
        className="btn btn-icon btn-light btn-hover-danger btn-sm"
        onClick={() => openDeleteCustomerDialog(row.id)}
      >
        <span className="svg-icon svg-icon-md svg-icon-danger">
          <SVG title="Delete" src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} />
        </span>
      </a>
      <>
        {/* <OverlayTrigger
          overlay={<Tooltip id="products-delete-tooltip">Update Status</Tooltip>}
        > */}
          <button
            className="btn btn-icon btn-light btn-hover-primary btn-sm mx-1"
            onClick={() => openEditCustomerDialog(row)}
          >
            <span className="svg-icon svg-icon-md svg-icon-primary">
              <SVG
                title="Update Status"
                src={toAbsoluteUrl("/media/svg/icons/General/Update.svg")}
              />
            </span>
          </button>
        {/* </OverlayTrigger> */}
      </>

      <>
        {/* <OverlayTrigger
          overlay={<Tooltip id="products-delete-tooltip">View Project</Tooltip>}
        > */}
          <a
            className="btn btn-icon btn-light btn-hover-primary btn-sm mx-1"
            onClick={() => onViewButtonClick(row)}
          >
            <span className="svg-icon svg-icon-md svg-icon-primary">
              <SVG
                title={'View Project'}
                src={toAbsoluteUrl("/media/svg/icons/Devices/TV1.svg")}
              />
            </span>
          </a>
        {/* </OverlayTrigger> */}
      </>

      <>
        {/* <OverlayTrigger
          overlay={<Tooltip id="products-delete-tooltip">Upload Report</Tooltip>}
        > */}
          <a
            className="btn btn-icon btn-light btn-hover-primary btn-sm mx-1"
            onClick={() => onUploadButtonClick(row)}
          >
            <span className="svg-icon svg-icon-md svg-icon-primary">
              <SVG
                title="Upload Report"
                src={toAbsoluteUrl("/media/svg/icons/Files/Uploaded-file.svg")}
              />
            </span>
          </a>
        {/* </OverlayTrigger> */}
      </>
    </>
  );
}
