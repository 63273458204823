import React from 'react'
import { Card, CardBody, CardHeader } from '../../../_metronic/_partials/controls'
import { Divider } from 'antd'
import './style.css'
import { toAbsoluteUrl } from '../../../_metronic/_helpers'

const UserConstructions = () => {
  return (
    <>
    <Card>
        <CardHeader title="Construction">
        </CardHeader>
        <CardBody>
          <div className='logo-container'>
            <div className='main-image-Container'>
            <img
          src={toAbsoluteUrl("/media/logos/icon1.png")}
          alt="logo"
        />
            </div>
        
          </div>
          <div>
            <h3>Introduction</h3>
            <p>As a top-rated home builder in California's Sacramento market, Boulevard Construction is your dedicated partner in turning dreams into reality. At Boulevard Construction, we prioritize excellence, and innovation, ensuring a distinct class of service for every residential, business, and exclusive construction project</p>
          </div>
          <Divider/>
          <div>
            <h3>Our Services</h3>
             <div className='main-cards-container-construction'>
               <a href='https://theboulevard.us/about/' target='_blank'>
               <div className='contructioncard'>
                  <p>About App</p>
               </div>
               </a>
               <a href='https://theboulevard.us/portfolio/' target='_blank'>
               <div className='contructioncard'>
                 <p>Portfolio</p>
               </div>
               </a>
               <a href='https://theboulevard.us/services/' target='_blank'>
               <div className='contructioncard'>
                 <p>Services</p>
               </div>
               </a>
               <a href='https://blvdnow.com/' target='_blank'>
               <div className='contructioncard'>
                 <p>Who We Are</p>
               </div>
               </a>

             </div>
          </div>
        </CardBody>
    </Card>

</>
  )
}

export default UserConstructions