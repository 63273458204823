import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { connect, useDispatch } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import { login } from "../_redux/authCrud";
import * as firebase from 'firebase'
import { useEffect } from "react";
import { googleAuthProvider, firebaseAuth, push_key, } from "../../../../firebase/config";
/*
  INTL (i18n) docs:
  https://github.com/formatjs/react-intl/blob/master/docs/Components.md#formattedmessage
*/

/*
  Formik+YUP:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
*/

const initialValues = {
  email: "",
  password: "",
};

function Login(props) {
  const { intl } = props;
  const [loading, setLoading] = useState(false);
  const [token, settoken] = useState(null)
  const [googleLoginLoading, setgoogleLoginLoading] = useState(false)



  useEffect(() => {
    firebase.messaging().getToken({ vapidKey: push_key })
      .then((currentToken) => {
        if (currentToken) {
          settoken(currentToken)
        }
      })
  }, [])




  const dispatch = useDispatch();

  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email("Wrong email format")
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    password: Yup.string()
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
  });

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues,
    validationSchema: LoginSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setStatus('')
      enableLoading();
      // if (values.email == 'admin@system.com') {
      setTimeout(() => {
        firebase.auth().signInWithEmailAndPassword(values.email, values.password)
          .then(({ user }) => {
            console.log(user, "user")
            console.log(token, "token")
            firebase.database().ref('users')
              .child(user.uid)
              .update({
                token: token,
                // email: values.email,
                // password: values.password,
                // phoneNumber: "03000000000",
                // token: token,
                // type: "Admin",
                // userId: firebase.auth().currentUser.uid,
                // userName: "Admin"

              })
              .then(() => {
                findandSave(user.uid)
                disableLoading();
                localStorage.setItem("userToken", user.refreshToken);
                dispatch({
                  type: "ADD_USER",
                  payload: {
                    isAuthenticated: true,
                    authtoken: user.refreshToken,
                    user: user,
                  },
                });

              })

          })
          .catch((err) => {
            setStatus(err.message);
          })
          .finally(() => {
            disableLoading();
            setSubmitting(false);
          });
      }, 1000);

      // } else {
      //   disableLoading();
      //   setSubmitting(false)
      //   setStatus(`Please log in with admin account`)
      // }

    },
  });


  const onGoogleSignin = () => {
    setgoogleLoginLoading(true)
    firebaseAuth.signInWithPopup(googleAuthProvider)
      .then((result) => {
        // Handle successful sign-in
        console.log(result);
        const user = result.user;
        // if (result.additionalUserInfo.isNewUser) {
        firebase.database().ref('users')
          .child(user.uid)
          .set({
            token: token,
            email: user.email,
            // password: values.password,
            phoneNumber: user.phoneNumber,
            // token: token,
            type: "client",
            userId: user.uid,
            userName: user.displayName

          }).then(() => {
            localStorage.setItem("userToken", user.refreshToken);
            findandSave(user.uid)
            setgoogleLoginLoading(false)
          })

        // } else {
        //   firebase.database().ref('users')
        //     .child(user.uid)
        //     .update({
        //       token: token,
        //       // email: values.email,
        //       // password: values.password,
        //       // phoneNumber: "03000000000",
        //       // token: token,
        //       // type: "Admin",
        //       // userId: firebase.auth().currentUser.uid,
        //       // userName: "Admin"

        //     }).then(() => {
        //       findandSave(user.uid)
        //       localStorage.setItem("userToken", user.refreshToken);
        //       setgoogleLoginLoading(false)


        //     })
        // }
      })
      .catch((error) => {
        // Handle error
        setgoogleLoginLoading(false)

        console.log(error);
      });
  }



  const findandSave = (uid) => {
    if (uid) {
      firebase.database().ref('users')
        .child(uid)
        .once('value', snapshot => {
          if (snapshot.exists()) {
            console.log('found', snapshot.val());
            dispatch({
              type: 'ADD_USER',
              payload: {
                user: snapshot.val(),
              },
            })
            localStorage.setItem('firebaseUser', JSON.stringify(snapshot.val()))
          }
        })

    } else firebase.auth().onAuthStateChanged(user => {
      if (user) {
        firebase.database().ref('users')
          .child(user.uid)
          .once('value', snapshot => {
            if (snapshot.exists()) {
              console.log('found', snapshot.val());
              dispatch({
                type: 'ADD_USER',
                payload: {
                  user: snapshot.val(),
                },
              })
              localStorage.setItem('firebaseUser', JSON.stringify(snapshot.val()))
            }
          })
      }
    })
  }




  return (
    <div className="login-form login-signin" id="kt_login_signin_form">
      {/* begin::Head */}
      <div className="text-center mb-10 mb-lg-20">
        <h3 className="font-size-h1">
          <FormattedMessage id="AUTH.LOGIN.TITLE" />
        </h3>
        <p className="text-muted font-weight-bold">
          Enter your username and password
        </p>
      </div>
      {/* end::Head */}

      {/*begin::Form*/}
      <form
        onSubmit={formik.handleSubmit}
        className="form fv-plugins-bootstrap fv-plugins-framework"
      >
        {formik.status ? (
          <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
            <div className="alert-text font-weight-bold">{formik.status}</div>
          </div>
        ) : (
          <>
          </>
          // <div className="mb-10 alert alert-custom alert-light-info alert-dismissible">
          //   <div className="alert-text ">
          //     Use account <strong>admin@demo.com</strong> and password{" "}
          //     <strong>demo</strong> to continue.
          //   </div>
          // </div>
        )}

        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="Email"
            type="email"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "email"
            )}`}
            name="email"
            {...formik.getFieldProps("email")}
          />
          {formik.touched.email && formik.errors.email ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.email}</div>
            </div>
          ) : null}
        </div>
        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="Password"
            type="password"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "password"
            )}`}
            name="password"
            {...formik.getFieldProps("password")}
          />
          {formik.touched.password && formik.errors.password ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.password}</div>
            </div>
          ) : null}
        </div>
        <button disabled={googleLoginLoading} onClick={onGoogleSignin} type="button" className="login-with-google-btn col-12" >
          Sign in with Google
        </button>

        <div className=" mt-5 mb-15 mb-lg-0 flex-column-auto justify-content-center py-3 ">
          <span className="font-weight-bold text-dark-50">
            Don't have an account yet?
          </span>
          <Link
            to="/auth/registration"
            className="font-weight-bold ml-2"
            id="kt_login_signup"
          >
            Sign Up!
          </Link>
        </div>
        <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
          <Link
            to="/auth/forgot-password"
            className="text-dark-50 text-hover-primary my-3 mr-2"
            id="kt_login_forgot"
          >
            <FormattedMessage id="AUTH.GENERAL.FORGOT_BUTTON" />
          </Link>
          <button
            id="kt_login_signin_submit"
            type="submit"
            disabled={formik.isSubmitting || googleLoginLoading}
            style={{ backgroundColor: '#004E89' }}
            className={`btn btn-primary font-weight-bold px-9 py-4 my-3`}
          >
            <span>Sign In</span>
            {loading && <span className="ml-3 spinner spinner-white"></span>}
          </button>
        </div>
      </form>
      {/*end::Form*/}
    </div>
  );
}

export default injectIntl(connect(null, auth.actions)(Login));
