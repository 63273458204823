
const INITIAL_STATE = {
    campuses: [],
    campusesfeechallans: [],
    campusesclasschallans: [],
    sectionfeechallans: [],
    campusunpaidchallans: [],
    campuspaidchallans: [],
    sectionpaidchallans: [],
    sectionunpaidchallans: [],
    setchallanrecevingdate: '',
    setselectedcampusid: "",
    selectedbankcategory_red: "",
    selectedbank_red: "",
};


const CampusReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'GET_ALL_CAMPUS':
            return {
                ...state,
                campuses: action.payload
            };
        case 'ADD_CAMPUS_FEE_CHALLAN':
            return {
                ...state,
                campusesfeechallans: action.payload
            };
        case 'ADD_CAMPUS_CLASS_CHALLAN':
            return {
                ...state,
                campusesclasschallans: action.payload
            };
        case 'ADD_SECTION_CHALLAN':
            return {
                ...state,
                sectionfeechallans: action.payload
            };
        case 'CAMPUS_UNPAID_CHALLANS':
            return {
                ...state,
                campusunpaidchallans: action.payload
            };
        case 'CAMPUS_PAID_CHALLANS':
            return {
                ...state,
                campuspaidchallans: action.payload
            };
        case 'SECTION_PAID_CHALLANS':
            return {
                ...state,
                sectionpaidchallans: action.payload
            };
        case 'SECTION_UNPAID_CHALLANS':
            return {
                ...state,
                sectionunpaidchallans: action.payload
            };
        case 'SELECTED_CAMPUS_ID':


            return {
                ...state,
                setselectedcampusid: action.payload.setselectedcampusid
            };
        case 'CHALLAN_RECEVING_DATE':

            return {
                ...state,
                setchallanrecevingdate: action.payload.setchallanrecevingdate
            };
        case 'CHALLAN_BANK_CATEGORY':
           
            return {
                ...state,
                selectedbankcategory_red: action.payload.selectedbankcategory_red
            };
            case 'CHALLAN_BANK':
           
                return {
                    ...state,
                    selectedbank_red: action.payload.selectedbank_red
                };

        default:
            return state;
    }
};

export default CampusReducer;
