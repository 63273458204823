import React, { useEffect, useState } from 'react'
import { Card, CardBody, CardHeader, CardHeaderToolbar, Pagination } from '../../../_metronic/_partials/controls'
import { Modal, Spinner } from 'react-bootstrap';
import firebase from 'firebase';
import { makeStyles } from '@material-ui/core/styles';
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import * as columnFormatters from '../../modules/ECommerce/pages/customers/customers-table/column-formatters';
import * as uiHelpers from '../../modules/ECommerce/pages/customers/CustomersUIHelpers';
import { toAbsoluteUrl } from '../../../_metronic/_helpers';


const Users = () => {
  const [addModal, setaddModal] = useState(false);
  const [editItem, setEditItem] = useState({})
  const [editModal, seteditModal] = useState(false);
  const [boards, setBoards] = useState([])
  const [loading, setloading] = useState(false)
  const [selectedId, setSelectedId] = useState("")
  const [deleteModal, setdeleteModal] = useState(false);
  const [searchText, setsearchText] = useState("");


  ////for pagination
  const initialFilter = {
    pageNumber: 1,
    pageSize: 10,
  };


  const [queryParams, setQueryParamsBase] = useState(initialFilter);


  const columns = [
    {
      dataField: "image",
      text: "User Image",
      formatter: (row, cell) => (
        <>
          <img style={{ height: 50, width: 50 }} src={cell.image ? cell.image : toAbsoluteUrl("/media/users/blank.png")} />
        </>
      )

    },
    {
      dataField: "userName",
      text: "User Name",
    },
    {
      dataField: "email",
      text: "Email",
    },
    {
      dataField: "phoneNumber",
      text: "Phone Number",
    },
    {
      dataField: "type",
      text: "Type",
    },
    // {
    //   dataField: "action",
    //   text: "Actions",
    //   formatter: columnFormatters.ActionsColumnFormatterUser,
    //   formatExtraData: {
    //     openEditCustomerDialog: (item) => onEditClick(item),
    //     openDeleteCustomerDialog: (id) => {
    //       setSelectedId(id);
    //       setdeleteModal(true);
    //     },
    //   },
    //   classes: "text-right pr-0",
    //   headerClasses: "text-right pr-3",
    //   style: {
    //     minWidth: "100px",
    //   },
    // },
  ];


  const useStyles = makeStyles({
    root: {
      minWidth: 275,
      margin: 5,


    },
    bullet: {
      display: 'inline-block',
      margin: '0 2px',
      transform: 'scale(0.8)',
    },
    title: {
      color: 'black',
      fontSize: 14,
    },
    pos: {
      marginBottom: 12,
    },
  });


  const classes = useStyles();



  useEffect(() => {
    fetchBoards();
  }, []);

  const fetchBoards = () => {
    setloading(true);
    firebase
      .database()
      .ref("users")
      .on("value", (snapshot) => {
        setloading(false);
        let arr = [];
        if (snapshot.exists()) {
          snapshot.forEach((item) => {
            let value = item.val();
            value.id = item.key;
            arr.push(value);
          });
        }
    const result=arr.filter((item)=>item.type =="client")
        setBoards(result.reverse());
      });
  };



  const onEditClick = (item) => {
    setEditItem(item)
    seteditModal(true)
    setaddModal(true)
  }



  const onDeleteClick = (id) => {
    setSelectedId(id)
    setdeleteModal(true)
  }





  const updatingQueryParams = (page) => {
    console.log(page);
    setQueryParamsBase({
      pageNumber: page,
    });
  };

  const onPaginationSelectChange = () => {
    setQueryParamsBase({
      pageNumber: 1,
    });
  };



  const dataSearch = boards.filter((user) => {
    let text = searchText.toLowerCase();
    const resultent = text
      ? user?.userName?.toLowerCase().includes(text)
      : true;
    return resultent;
  });


  const options = {
    custom: true,
    totalSize: dataSearch.length,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: queryParams.pageSize,
    page: queryParams.pageNumber,
  };
  return (
    <>
      <Card>
        <CardHeader title="Users list">
          <CardHeaderToolbar>
            {/* <button
              type="button"
              className="btn btn-primary"
              onClick={() => setaddModal(true)}
            >
              New Construction
            </button> */}
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
        <div className="col-lg-4">
            <small className="form-text text-muted">
              <b>Search</b> By User Name
            </small>
            <input
              type="text"
              className="form-control"
              name="searchText"
              placeholder="Search"
              value={searchText}
              onChange={(e) => setsearchText(e.target.value)}
              // onBlur={handleBlur}
              // value={values.searchText}
              // onChange={(e) => {
              //   setFieldValue("searchText", e.target.value);
              //   handleSubmit();
              // }}
            />
          </div>

          <PaginationProvider pagination={paginationFactory(options)}>
            {({ paginationProps, paginationTableProps }) => {
              return (
                <>

                  <Pagination
                    updatingQueryParams={updatingQueryParams}
                    onPaginationSelectChange={onPaginationSelectChange}
                    isLoading={loading}
                    paginationProps={paginationProps}
                  >
                    <BootstrapTable
                      wrapperClasses="table-responsive"
                      bordered={false}
                      classes="table table-head-custom table-vertical-center overflow-hidden constritaTable"
                      bootstrap4
                      keyField="id"
                      data={dataSearch}
                      columns={columns}
                      defaultSorted={uiHelpers.defaultSorted}
                      {...paginationTableProps}
                    ></BootstrapTable>
                  </Pagination>
                  {/* )} */}
                </>
              );
            }}
          </PaginationProvider>



          {/* 
          <div className='container'>
            {
              loading ?
                <div style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}>
                  <Spinner animation='border' />
                </div>
                :
                <div className='row'>

                  {boards?.map((item) => (
                    <div
                      className='col-md-4'
                    >
                      <MiCard className={classes.root}>
                        <CardMedia
                          sx={{ height: 270 }}
                          image={item.ImageUrl}
                          title="aa"
                        />
                        <CardContent className="d-flex justify-content-between">
                          <Typography className={classes.title} color="textSecondary" gutterBottom>
                            <p className='title-price'>User Name</p>
                            <p>{item.username}</p>
                          </Typography>
                          <Typography className={classes.title} color="textSecondary" gutterBottom>
                            <p className='title-price'>First Name</p>
                            <p>{item.fname}</p>
                          </Typography>
                          <Typography className={classes.title} color="textSecondary" gutterBottom>
                            <p className='title-price'>Last Name</p>
                            <p>{item.lname}</p>
                          </Typography>
                        </CardContent>
                        <CardActions
                          style={{
                            justifyContent: 'space-around'
                          }}
                        >
                          <Button
                            onClick={() => onEditClick(item)}
                            title={'Edit'} size="small">
                            <EditIcon style={{ color: "blue" }} />
                          </Button>
                          <Button
                            onClick={() => onDeleteClick(item.desinerIdnp)}
                            title='Delete' size="small">
                            <DeleteOutlineIcon style={{ color: "red" }} />
                          </Button>
                        </CardActions>
                      </MiCard>

                    </div>
                  ))}

                </div>
            }
          </div> */}



        </CardBody>
      </Card>


    </>
  )
}

export default Users 