import React from "react";
import {  Avatar, CardContent, Typography,Card as Carder, CardHeader,Backdrop,CircularProgress,Paper, LinearProgress } from "@material-ui/core";

import { Button, Divider, Empty, message, Select, Space, Spin, Upload } from "antd";
import { makeStyles } from "@material-ui/core/styles";
import { useEffect } from "react";
import { useState } from "react";
import { Modal } from "antd";
import { Link, useHistory, useParams } from "react-router-dom";
import firebase from "firebase";
import { DownloadOutlined } from "@ant-design/icons";
import {
  UploadOutlined,
  FilePdfOutlined,
  DeleteOutlined,
  CloseOutlined
} from "@ant-design/icons";
import axios from "axios";
import { Card as AntCard } from "antd";
import "./projectBoard.css";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import $ from "jquery";
import { toast } from "react-hot-toast";
import { Player } from "video-react";
import Slider from 'react-slick'
import { useRef } from "react";
import pop from './audio/sound.mp3'
import { Card } from "react-bootstrap";
import { SendNotifaction } from "../../../../../utils/SendNotifaction";
import Uploader from "../../../../../app/Images/upload.png";
import { id } from "date-fns/locale";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#333",
  },
  root: {
    maxWidth: 400,
    backgroundColor:"#333",
    opacity:0.5,
  },
}));
const ProjectBoard = ({projectUser}) => {
  const [board, setBoard] = useState([]);
  const params = useParams();
  const [allFiles, setAllFiles] = useState([]);
  const [allImages, setAllImages] = useState([]);
  const [allVideos, setAllVideos] = useState({});
  const [loading, setLoading] = useState(false);
  const [video, setVideo] = useState(null);
  const [thumbnail, setThumbnail] = useState(null);
  const [uploadLoading, setuploadLoading] = useState(false);
  const [image, setImage] = React.useState(null);
  const [deletePicmodal, setDeletePicModal] = useState(false);
  const [deleteFileModal, setDeleteFileModal] = useState(false);
  const [selectedId, setSelectedId] = useState("");
  const [noBoardFound, setNoBoardFound] = useState(false);
  const [commentList,setCommentList]=useState([])
  const [value, setValue] = useState('');
  const [buttonLoading,setButtonLoading]=useState(false)
  const [showMore, setShowMore] = useState(true)
  const [showLess, setShowLess] = useState(false)
  const [projectDetail, setProjectDetails] = useState({})
  const [imageModal, setImageModal] = useState(false);
  const [images, setImages] = useState([]);
  const [selectedImageName,setSelectedImageName]=useState("")
const [imagesUploadLoading,setImagesUploadLoading]=useState(false)
  const currentUser = JSON.parse(localStorage.getItem("firebaseUser"));
  /* Demo purposes only */
  const dummy = useRef()
  $("figure").mouseleave(function() {
    $(this).removeClass("hover");
  });
  const history=useHistory()
  useEffect(() => {
    getDesginer();
    commentsGetter()
    getProject()
    // .on('value', snapshot => {
    //    snapshot.val().;
    // });
  }, []);
  const getProject=()=>{
    firebase
    .database().ref(`/projects/${params.id}`).on("value",(snapshot)=>{
      if(snapshot.val()){
        setProjectDetails(snapshot.val())
      }
    })
  }
  const getDesginer = () => {
    setLoading(true);

    firebase
      .database()
      .ref(`/projects/${params.id}/designer`)
      .on("value", (snapshot) => {
       if(snapshot.exists){
        if (snapshot.val()?.board) {
          setLoading(false);
          if (snapshot.val().board.images) {
            firebase
              .database()
              .ref(`/projects/${params.id}/designer/board/images`)
              .on("value", (snapshot) => {
                let arr = [];
                if (snapshot.exists()) {
                  snapshot.forEach((item) => {
                    let value = item.val();
                    value.id = item.key;
                    arr.push(value);
                  });
                }
                setAllImages(arr.reverse());
              });

            // setAllImages(Object.values(snapshot.val().board.images));
          }
          if (snapshot.val().board.files) {
            firebase
              .database()
              .ref(`/projects/${params.id}/designer/board/files`)
              .on("value", (snapshot) => {
                let arr = [];
                if (snapshot.exists()) {
                  snapshot.forEach((item) => {
                    let value = item.val();
                    value.id = item.key;
                    arr.push(value);
                  });
                }
                setAllFiles(arr.reverse());
              });
            // setAllFiles(Object.values(snapshot.val().board.files));
          }
          if (snapshot.val().board.video) {
            console.log(snapshot.val().board.video);

            setAllVideos(snapshot.val().board.video);
          }
          // console.log(snapshot.val().board.images)

          // console.log(  Object.entries(snapshot.val().board.images))

          // setAllImages(snapshot.val().board.images)
        } else {
          setNoBoardFound(true);
        }
       }
      });
    // setLoading(true)
    // firebase
    //   .database()
    //   .ref("projects")
    //   .child(params.id)
    //   .once("value", (snapshot) => {
    //     if (snapshot.exists()) {
    //         console.log(snapshot.val().designer.board)
    //       setBoard(snapshot.val().designer.board)
    //          if(snapshot.val().designer.board){
    //     const allfiles=      snapshot.val().designer.board.filter((files)=>{
    //             if(files.type.split("/")[0] =="application"){
    //               return files
    //             }
    //           })
    //           const allImage=      snapshot.val().designer.board.filter((images)=>{
    //             if(images.type.split("/")[0] =="image"){
    //               return images
    //             }
    //           })
    //           const allVideo=      snapshot.val().designer.board.filter((video)=>{
    //             if(video.type.split("/")[0] =="video"){
    //               return video
    //             }
    //           })
    //           setAllImages(allImage)
    //           setAllVideos(allVideo)
    //           setAllFiles(allfiles)
    //         //  setLoading(false)

    //          }
    //          setLoading(false)

    //     }
    //   });
  };
  const handleImageUpload = (img) => {
    setuploadLoading(true);
    const storageRef = firebase.storage().ref();
    storageRef
      .child(`boardimages/${img.name}`)
      .put(img)
      .then(async (snapshot) => {
        snapshot.ref.getDownloadURL().then((val) => {
          if (val) {
            setuploadLoading(false);
            SendNotifaction(`${currentUser?.userName}`,`Added New Image in ${projectDetail?.projectTitle} project board`,projectUser.token,val)
          }
          firebase
            .database()
            .ref(`/projects/${params.id}/designer/board/images`)
            .push({
              val,
              imageName:img.name
            });
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleFileUpload = (file) => {
    setuploadLoading(true);


    console.log(params.id)
  
    const storageRef = firebase.storage().ref();
    storageRef
      .child(`boardfiles/${file.name}`)
      .put(file)
      .then(async (snapshot) => {
        await snapshot.ref.getDownloadURL().then((val) => {
          const pushkey = firebase
          .database()          
          .ref(`projects/${params.id}/designer/board/files`)
          .push().key;

          console.log("ushks",pushkey)

          if (val) {
            setuploadLoading(false);
            SendNotifaction(`${currentUser?.userName}`,`Added New File in ${projectDetail?.projectTitle} project board`,projectUser.token,val)
          }
          firebase
            .database()
            .ref(`/projects/${params.id}/designer/board/files`)
            .child(pushkey)
            .set({
              id:pushkey,
              uri: val,
              name: file.name,
            }).then(()=>{
              console.log('done?')
            }).catch(err=>{
              console.log("pushing error",err);
            })
        });
      })
      .catch((err) => {
        setuploadLoading(false);
        console.log("final error",err);
      });
  };
  const handleVedioUpload = async (vedio) => {
    if(allVideos.uri){
      const storageRef = firebase.storage().ref();
      const fileRef = storageRef.child(`boardimages/${allVideos.videoName}`);
      fileRef.delete()
      .then(() => {
        console.log("Video deleted successfully");
      })
      .catch((error) => {
        console.error("Error deleting image:", error);
      });
    }
    setVideo(vedio);
    setuploadLoading(true);
    try {
      // get the frame at 1.5 seconds of the video file
      const cover = await getVideoCover(vedio, 1.5);
      // print out the result image blob
      const myFile = new File([cover], "thumbnail", { type: cover.type });
      console.log(myFile, "this is f");

      const storageRef = firebase.storage().ref();
      storageRef
        .child(`boardimages/${"thumbnail"}`)
        .put(myFile)
        .then(async (snapshot) => {
          snapshot.ref.getDownloadURL().then((thu) => {
            console.log(thu, "this is thumbnail");

            if (thu) {
              const storageRef = firebase.storage().ref();
              storageRef
                .child(`boardimages/${vedio.name}`)
                .put(vedio)
                .then(async (snapshot) => {
                  snapshot.ref.getDownloadURL().then((val) => {
                    if (val) {
                      setuploadLoading(false);
                    SendNotifaction(`${currentUser?.userName}`,`Added New Video in ${projectDetail?.projectTitle} project board`,projectUser.token,thu)
                    }
                    console.log(cover, "this is cover");
                    firebase
                      .database()
                      .ref(`/projects/${params.id}/designer/board/video`)
                      .set({
                        videoName:vedio.name,
                        uri: val,
                        thumbnail: thu,
                      });
                  });
                })
                .catch((err) => {
                  console.log(err);
                });
            }
          });
        });

      setImage(cover);
    } catch (ex) {
      console.log("ERROR: ", ex);
    }
  };

  const downloadFile = async (fileURL) => {
    console.log(fileURL);
    try {
      const response = await axios.get(fileURL.reportUrl, {
        responseType: "blob", // automatically detect the response type
      });

      const blob = new Blob([response.data], { type: response.data.type });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = "download-file.pdf";
      link.click();
    } catch (error) {
      console.log(error);
    }
  };
  const classes = useStyles();

  function getVideoCover(file, seekTo = 0.0) {
    console.log("getting video cover for file: ", file);
    return new Promise((resolve, reject) => {
      // load the file to a video player
      const videoPlayer = document.createElement("video");
      videoPlayer.setAttribute("src", URL.createObjectURL(file));
      videoPlayer.load();
      videoPlayer.addEventListener("error", (ex) => {
        reject("error when loading video file", ex);
      });
      // load metadata of the video to get video duration and dimensions
      videoPlayer.addEventListener("loadedmetadata", () => {
        // seek to user defined timestamp (in seconds) if possible
        if (videoPlayer.duration < seekTo) {
          reject("video is too short.");
          return;
        }
        // delay seeking or else 'seeked' event won't fire on Safari
        setTimeout(() => {
          videoPlayer.currentTime = seekTo;
        }, 200);
        // extract video thumbnail once seeking is complete
        videoPlayer.addEventListener("seeked", () => {
          console.log("video is now paused at %ss.", seekTo);
          // define a canvas to have the same dimension as the video
          const canvas = document.createElement("canvas");
          canvas.width = videoPlayer.videoWidth;
          canvas.height = videoPlayer.videoHeight;
          // draw the video frame to canvas
          const ctx = canvas.getContext("2d");
          ctx.drawImage(videoPlayer, 0, 0, canvas.width, canvas.height);
          // return the canvas image as a blob
          ctx.canvas.toBlob(
            (blob) => {
              resolve(blob);
            },
            "image/jpeg",
            0.75 /* quality */
          );
        });
      });
    });
  }

  const deleteFile = () => {
    console.log("deleted");
    firebase
      .database()
      .ref(`/projects/${params.id}/designer/board/files`)
      .child(selectedId)
      .remove()
      .then((res) => {
        const storageRef = firebase.storage().ref();
        const fileRef = storageRef.child(`boardfiles/${selectedImageName}`);
        fileRef.delete()
        .then(() => {
          console.log("Image deleted successfully");
        })
        .catch((error) => {
          console.error("Error deleting image:", error);
        });
        toast.success("Deleted Successfully");

        setDeleteFileModal(false);
      });
  };

  const deleteImg = () => {
    console.log("deleted");
    firebase
      .database()
      .ref(`/projects/${params.id}/designer/board/images`)
      .child(selectedId)
      .remove()
      .then((res) => {
        const storageRef = firebase.storage().ref();
        const fileRef = storageRef.child(`boardimages/${selectedImageName}`);
        fileRef.delete()
        .then(() => {
          console.log("Image deleted successfully");
        })
        .catch((error) => {
          console.error("Error deleting image:", error);
        });
        toast.success("Deleted Successfully");
        setDeletePicModal(false);
      });
  };
  const commentsGetter = async () => {
    firebase
    .database()
    .ref(`/projects/${params.id}/comments`)
    .on("value", (snapshot) => {
      let arr = [];
      if (snapshot.exists()) {
        snapshot.forEach((item) => {
          let value = item.val();
          value.id = item.key;
          arr.push(value);
        });
      }
      setCommentList(arr.reverse());
    });
  };
  const addComment = async () => {
     
    if (value) {
        setButtonLoading(true)
      const commentId =  firebase
      .database()
      .ref(`/projects`).push().key;
      firebase
      .database()
      .ref(`/projects`).child(params.id)
        .child('comments')
        .child(commentId)
        .set({
          comment:value,
          commentId,
          timeStamp: Date.now(),
          userName: currentUser.userName,
          uid: currentUser.userId,
          profileImage: currentUser.image || "",
          type:currentUser.type
        })
        .then(() => {
        setButtonLoading(false)
            toast.success("Comment Send successfully")
            SendNotifaction(`${currentUser?.userName} added the comment`,value,projectUser.token,"")
            const sound = document.getElementById("sound");
            sound.play();
        //   if (boardData.userId == auth().currentUser?.uid) {
        //     boardUsersDataGetter(commentId);
        //   } else {
        //     // boardUsersDataGetter(commentId)
        //     sendNotificaionToBoardOwner(commentId);
        //   }
        // dummy.current.scrollIntoView();
         setValue("")
        })
        .catch(err => {
          console.log(err)
        });
    } else {
        toast.error("Please Add comment")
    //   showMessage({
    //     message: 'Add Comment',
    //     duration: 1000,
    //     type: 'warning',
    //   });
    }
  };
  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 2.2,
    slidesToScroll: 2.2,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };


  const getDownloadURL = async (file) => {
    const url = await firebase
      .storage()
      .ref(`boardimages/${file.name}`)
      .getDownloadURL();
    return url;
  };

  const handleUpload = async () => {
    setImagesUploadLoading(true);
    const promises = [];

    for (let i = 0; i < images.length; i++) {
      const file = images[i];
      const uploadTask = firebase
        .storage()
        .ref(`boardimages/${file.name}`)
        .put(file);
      promises.push(uploadTask);
    }

    Promise.all(promises).then(async () => {
      console.log("Uploaded all files");
      const urls = [];
      for (let i = 0; i < images.length; i++) {
        const url = await getDownloadURL(images[i]);
        await firebase
        .database()
        .ref(`/projects/${params.id}/designer/board/images`)
        .push({
          val:url,
          imageName:images[i].name
        })
        .then(() => {
          if(i+1==images.length){
            setImages([])
          setImagesUploadLoading(false);
          setImageModal(false)
          document.getElementById("imagesupload").value = "";
          toast.success('Uploaded Successfully')
          SendNotifaction(`${currentUser?.userName}`,`Added New Image in ${projectDetail?.projectTitle} project board`,projectUser.token,url)
          }
        })
        .catch((err) => {
          if(i+1==images.length){
            setImagesUploadLoading(false);
          }
        });
        
      }
   

   
    });
  };
  const handleInputChange = (event) => {
    const files = event.target.files;

    for (const file of files) {
      setImages((prevImages) => [...prevImages, file]);
    }
    console.log(images);
  };
  const handleImageRemove = (index) => {
    let data = document.getElementById("imagesupload").value
    console.log(data)
    console.log(index);
    images.splice(index, 1);
    setImages([...images]);
  };
  return (
    <>
      {noBoardFound ? (
        <Paper
          style={{
            marginTop: "10px",
            marginBottom: "10px",
            padding: "15px",
            paddingLeft: 0,
            height: "100vh",
          }}
        >
          <div className="noboardFound">
            <h3>No board Found</h3>
            {currentUser.type == "designer" ? (
              <Link to="/create-board">
                <button className="text-primary">Click here to create</button>
              </Link>
            ) : null}
          </div>
        </Paper>
      ) : (
        <>
          {loading ? (
            <Backdrop className={classes.backdrop} open={loading}>
              {/* <Space size="middle"> */}
              <Spin tip="Loading...." size="large" />
              {/* </Space> */}
            </Backdrop>
          ) : (
            <>
              {uploadLoading ? (
                <Backdrop
                  style={{ backgroundColor: "rgb(0, 0, 255)" }}
                  className={classes.backdrop}
                  open={uploadLoading}
                >
                  {/* <Space size="middle"> */}
                  <Spin tip="uploading" size="large" />
                  {/* </Space> */}
                </Backdrop>
              ) : (
                ""
              )}
              {uploadLoading?<LinearProgress/>:''}
              <Paper>
                <audio id="sound" src={pop}></audio>

                
                  <>
                    <div className="row">
                      <div className="col-md-12 barcontainer">
                        <h2>Designer Board</h2>
                        <div className="bar__"></div>
                      </div>
                    </div>
                    <Divider />
                    <div className="row">
                      <div className="col-md-6">
                        <div class="folded">
                          <h2>Designer Reports</h2>
                        </div>
                       
                      </div>
                      
                      <div className="col-md-6">
                      <label
                      style={{float:"right"}}
                            className="uploadbuttonboard text-primary"
                            htmlFor="fileupload"
                          >
                        <div class="bestSellerRgt">Click To Upload File</div>
                        </label>
                        </div>
                     {
                      allFiles.length>0?
                     <>
                      <div
                      style={{
                        paddingLeft: "20px",
                        paddingRight: "20px",
                        paddingTop: "30px",
                        paddingBottom: "20px",
                        width:"100%"
                      }}
                    >
                      <AntCard
                        size="small"
                        title={
                          // <a target="_blank" href={allFiles[0]?.uri}>
                            <p
                            onClick={()=>history.push(`/pdfandcomments/${allFiles[0]?.id}/${params.id}`)}
                            style={{ color: "black", margin: 0 }}>
                              {" "}
                              {allFiles[0]?.name}
                            </p>
                          // </a>
                        }
                        extra={
                     <>
                          <DeleteOutlined
                          onClick={() => {
                            setDeleteFileModal(true);
                            setSelectedId(allFiles[0]?.id);
                            setSelectedImageName(allFiles[0]?.name);
                          }}
                          style={{ color: "red", marginTop: "5px",position:"relative",right:"150px",fontSize:"1.5rem" }}
                        />
                          {/* <a target="_blank" href={allFiles[0]?.uri}> */}
                            <button
                             onClick={()=>history.push(`/pdfandcomments/${allFiles[0]?.id}/${params.id}`)}
                            className="clickherebutton">
                              Click here to open
                            </button>
                          {/* </a> */}
                     </>
                        }
                        style={{
                          width: "auto",
                          height: 700,
                          marginTop: 13,
                          position:"relative"

                        }}
                      >
                        <div className="filesvg text-center">
                          <iframe
                            style={{ height: "700px", width: "100%" }}
                            src={allFiles[0]?.uri}
                          ></iframe>
                        </div>
                        <div style={{position:"absolute",top:"18%",left:0,width:"100%",height:"600px",opacity:0}} 
                      onClick={()=>history.push(`/pdfandcomments/${allFiles[0]?.id}/${params.id}`)}
                      ></div>
                      </AntCard>
                    </div>
                      {/* <div ref={dummy} />
                      <div className="commentscont">
                        {commentList.length > 0 ? (
                          <>
                            {commentList.map((item, i) => (
                              <>
                                <div
                                  id={commentList?.length == i + 1 ? "yes" : ""}
                                  className={
                                    item.uid == currentUser?.userId
                                      ? "row mt-4 mb-3 maraCard"
                                      : "row mt-4 mb-3"
                                  }
                                >
                                  <div
                                    className={
                                      item.uid == currentUser?.userId
                                        ? "col-md-12 endIt"
                                        : "col-md-12"
                                    }
                                  >
                                    <Carder
                                      style={{
                                        width:
                                          item.uid == currentUser?.userId
                                            ? "400px"
                                            : "",
                                      }}
                                      className={classes.root}
                                    >
                                      <CardHeader
                                        avatar={
                                          <Avatar
                                            alt="Remy Sharp"
                                            src={item.profileImage}
                                          />
                                        }
                                        title={
                                          <Typography
                                            variant="p"
                                            style={{ color: "white" }}
                                          >
                                            {item.userName}
                                            <br />
                                          </Typography>
                                        }
                                        subheader={
                                          <Typography
                                            variant="p"
                                            style={{ color: "white" }}
                                          >
                                            {new Date(
                                              item?.timeStamp
                                            ).toLocaleString()}
                                          </Typography>
                                        }
                                      />
                                      <Divider
                                        style={{
                                          margin: 0,
                                          backgroundColor: "white",
                                        }}
                                      />

                                      <CardContent>
                                        <Typography
                                          variant="body2"
                                          style={{
                                            color: "white",
                                            fontSize: "18px",
                                            fontWeight: "bold",
                                          }}
                                          component="p"
                                        >
                                          {item.comment}
                                        </Typography>
                                      </CardContent>
                                    </Carder>
                                  </div>
                                  <div />
                                </div>
                              </>
                            ))}
                          </>
                        ) : (
                          ""
                        )}
                      </div> */}
                     </>
                    :
                    <div className="bordempter">
                      <Empty/>
                    </div>
                    
                     }
                    
                    </div>
 
                    {showMore && allFiles.length>1 ? (
                      <div className="row buttonContainerShow">
                        <div className="">
                          <button
                            className="showButton"
                            onClick={() => {
                              setShowMore(false);
                              setShowLess(true);
                            }}
                          >
                            Show More
                          </button>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}

                    { allFiles &&
                        allFiles.map((item, index) =>
                          index > 0 ? (
                            
                            showLess
                      ?
                            <div
                              style={{
                                paddingLeft: "20px",
                                paddingTop: "30px",
                              paddingRight: "20px",
                                paddingBottom: "20px",
                              }}
                            >
                              <AntCard
                                size="small"
                                title={
                                  // <a target="_blank" href={item?.uri}>
                                    <p
                                    onClick={()=>history.push(`/pdfandcomments/${item?.id}/${params.id}`)}
                                    style={{ color: "black", margin: 0 }}>
                                      {" "}
                                      {item?.name}
                                    </p>
                                  // </a>
                                }
                                extra={
                               <>
                                  <DeleteOutlined
                                  onClick={() => {
                                    setDeleteFileModal(true);
                                    setSelectedImageName(item.name)
                                    setSelectedId(item.id);
                                  }}
                                  style={{ color: "red", marginTop: "5px",position:"relative",right:"150px",fontSize:"1.5rem" }}

                                />
                                  {/* <a target="_blank" href={item?.uri}> */}
                                    <button 
                                    onClick={()=>history.push(`/pdfandcomments/${item?.id}/${params.id}`)}
                                    className="clickherebutton">
                                      Click here to open
                                    </button>
                                  {/* </a> */}
                               </>
                                }
                                style={{
                                  width: "auto",
                                  height: 700,
                                  marginTop: 13,
                                }}
                              >
                                <div className="filesvg text-center">
                                  <iframe
                                    id="desginerPdf"
                                    style={{ height: "700px", width: "100%" }}
                                    src={item?.uri}
                                  ></iframe>
                                </div>
                                <div style={{position:"absolute",top:"18%",left:0,width:"100%",height:"600px",opacity:0}} 
                                onClick={()=>history.push(`/pdfandcomments/${item?.id}/${params.id}`)}
                      ></div>
                              </AntCard>
                            </div>
                            : ""
                          ) : (
                            ""
                          )
                        )
                          }
                    {showLess ? (
                      <div className="row buttonContainerShow">
                        <div className="">
                          <button
                            className="showButton"
                            onClick={() => {
                              setShowLess(false);
                              setShowMore(true);
                            }}
                          >
                            Show Less
                          </button>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    <Divider />
                    <div className="row mt-5">
                    <div className="col-md-6">
                        <div class="folded">
                          <h2>Designer Images</h2>
                        </div>
                      </div>
                      <div className="col-md-6">
                      <label
                      style={{float:"right"}}
                       className="uploadbuttonboard text-primary"
                      //  htmlFor="imageUpload"
                      onClick={()=>setImageModal(true)}
                          >
                        <div class="bestSellerRgt">Click To Upload Image</div>
                        </label>
                        </div>
                    </div>
                    {allImages && allImages.length ? (
                      <div className="carsolemasin mt-3">
                        <Slider {...settings}>
                          {allImages.map((img) => (
                            <div>
                              <Card
                                style={{
                                  width: "25rem",
                                  borderRadius: "10px",
                                  height: "300px",
                                }}
                                className="mb-2 slidercard"
                              >
                                <Card.Body style={{ padding: 0 }}>
                                  <div>
                                  <CloseOutlined
                                   onClick={() => {
                                    setDeletePicModal(true);
                                    setSelectedId(img.id);
                                    setSelectedImageName(img.imageName)
                                  }}
                                  style={{ color: "red",position:"absolute",right:"-8px",top:"-6px",fontSize:"1.7rem" }}

                                />
                                    <img
                                      style={{ height: "300px" }}
                                      className="sliderCarimg"
                                      src={img.val}
                                      alt=""
                                    />
                                  </div>
                                </Card.Body>
                              </Card>
                            </div>
                          ))}
                        </Slider>
                      </div>
                    ) : 
                    <div className="bordempter">
                      <Empty/>
                    </div>
                    }
                    <Divider />
                    <div className="mt-3">
                   <div className="row">
                   <div className="col-md-6">
                        <div class="folded">
                          <h2>Designer Video</h2>
                        </div>
                      </div>
                      <div className="col-md-6">
                      <label
                      style={{float:"right"}}
                            className="uploadbuttonboard text-primary"
                            htmlFor="vedioUpload"
                          >
                        <div class="bestSellerRgt">Click To Upload Video</div>
                        </label>
                        </div>
                   </div>
                   {
                    allVideos.uri?
                    <div className="container" style={{ paddingTop: "50px",paddingBottom:"40px" }}>
                    <Player
                      fluid={false}
                      height={400}
                      width="100%"
                      poster={allVideos.thumbnailUrl}
                      src={allVideos.uri}
                    />
                  </div>
                  :
                  <div className="bordempter">
                  <Empty/>
                </div>
                   }
                    </div>
                  </>
               
              </Paper>
              <div>
                <input
                  onChange={(e) => handleFileUpload(e.target.files[0])}
                  style={{ display: "none" }}
                  className="btn btn-base"
                  type="file"
                  id="fileupload"
                  accept=".pdf"
                />
                <input
                  onChange={(e) => handleVedioUpload(e.target.files[0])}
                  style={{ display: "none" }}
                  className="btn btn-base"
                  type="file"
                  id="vedioUpload"
                  accept=".mp4"
                />
                <input
                  onChange={(e) => handleImageUpload(e.target.files[0])}
                  style={{ display: "none" }}
                  className="btn btn-base"
                  type="file"
                  id="imageUpload"
                  accept=".png, .jpg, .jpeg"
                  multiple
                />
              </div>
              {/* <div className="container">
                <div className="row">
                  <div className="col-md-4">
                    <AntCard
                      size="small"
                      title="Video"
                      extra={
                        currentUser.type == "designer" ? (
                          <label
                            className="uploadbuttonboard text-primary"
                            htmlFor="vedioUpload"
                          >
                            Upload
                          </label>
                        ) : (
                          ""
                        )
                      }
                      style={{
                        width: 300,
                        minHeight: "100vh",
                      }}
                    >
                      {allVideos.uri ? (
                        <video
                          controls
                          height={200}
                          width={270}
                          autoPlay={false}
                          src={allVideos.uri}
                        />
                      ) : (
                        <div className="nofoundText">
                          <h3 className="text-primary">No Video Found</h3>
                        </div>
                      )}
                    </AntCard>
                  </div>
                  <div className="col-md-4">
                    <AntCard
                      size="small"
                      title="Images"
                      extra={
                        currentUser.type == "designer" ? (
                          <label
                            className="uploadbuttonboard text-primary"
                            htmlFor="imageUpload"
                          >
                            Upload
                          </label>
                        ) : (
                          ""
                        )
                      }
                      style={{
                        width: 300,
                        minHeight: "100vh",
                      }}
                    >
                      {allImages.length > 0 ? (
                        allImages.map((item) => (
                          <section className=" mt-2">
                            <figure class="snip0023">
                              <img src={item.val} alt="sample26" />
                              {currentUser.type == "designer" ? (
                                <div>
                                  <i
                                    onClick={() => {
                                      setDeletePicModal(true);
                                      setSelectedId(item.id);
                                    }}
                                    className="ion-ios-trash text-danger"
                                  ></i>
                                  <div class="curl"></div>
                                  <a href="#"></a>
                                </div>
                              ) : null}
                            </figure>
                          </section>
                        ))
                      ) : (
                        <div className="nofoundText">
                          <h3 className="text-primary">No Image Found</h3>
                        </div>
                      )}
                    </AntCard>
                  </div>
                  <div className="col-md-4">
                    <AntCard
                      size="small"
                      title="Files"
                      extra={
                        currentUser.type == "designer" ? (
                          <label
                            className="uploadbuttonboard text-primary"
                            htmlFor="fileupload"
                          >
                            Upload
                          </label>
                        ) : (
                          ""
                        )
                      }
                      style={{
                        width: 300,
                        minHeight: "100vh",
                      }}
                    >
                      <div className="mt-2">
                        {allFiles.length > 0 ? (
                          allFiles.map((item, i) => (
                            <AntCard
                              size="small"
                              title={
                                <a target="_blank" href={item.uri}>
                                  <p style={{ color: "black", margin: 0 }}>
                                    {" "}
                                    {item?.name.length > 10
                                      ? item?.name.substr(0, 10) + "..."
                                      : item?.name}
                                  </p>
                                </a>
                              }
                              extra={
                                <section className="d-flex">
                                  {currentUser.type == "designer" ? (
                                    <DeleteOutlined
                                      onClick={() => {
                                        setDeleteFileModal(true);
                                        setSelectedId(item.id);
                                      }}
                                      style={{ color: "red", marginTop: "5px" }}
                                    />
                                  ) : (
                                    ""
                                  )}
                                  <a target="_blank" href={item.uri}>
                                    <DownloadOutlined
                                      onClick={() => downloadFile(item.uri)}
                                      className="text-primary mx-2"
                                    />
                                  </a>
                                </section>
                              }
                              style={{
                                width: 270,
                                height: 220,
                                marginTop: 13,
                              }}
                            >
                              <div className="filesvg text-center">
                                <iframe
                                  style={{ height: "160px", width: "240px" }}
                                  src={item.uri}
                                ></iframe>
                              </div>
                            </AntCard>
                          ))
                        ) : (
                          <div className="nofoundText">
                            <h3 className="text-primary">No File Found</h3>
                          </div>
                        )}
                      </div>
                    </AntCard>
                  </div>
                </div>
              </div> */}
            </>
          )}

          <Modal
            title="Delete File"
            centered
            open={deleteFileModal}
            onOk={deleteFile}
            onCancel={() => setDeleteFileModal(false)}
            okText="Delete"
            okButtonProps={{ style: { backgroundColor: "red" } }}
          >
            <div>
              <p>Are you sure you want to delete this</p>
            </div>
          </Modal>
          <Modal
            title="Delete Image"
            centered
            open={deletePicmodal}
            onOk={deleteImg}
            onCancel={() => setDeletePicModal(false)}
            okText="Delete"
            okButtonProps={{ style: { backgroundColor: "red" } }}
          >
            <div>
              <p>Are you sure you want to delete this</p>
            </div>
          </Modal>



          <Modal
          title="Upload Images"
          centered
          open={imageModal}
          onOk={handleUpload}
          confirmLoading={imagesUploadLoading}
          okText="Upload"
          // okButtonProps={{ disabled: uploadLoading }}
          onCancel={() => setImageModal(false)}
        >
          <div className="form-group row">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="avatar-upload-input text-center">
                <img src={Uploader} alt="img" />
                <h2>Upload Image</h2>
                <div className="avatar-edit-input">
                  <input
                    className="form-control"
                    name="file"
                    id="imagesupload"
                    type={"file"}
                    multiple
                    accept=".png, .jpg, .jpeg"
                    onChange={(e) => {
                      handleInputChange(e);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            {images.map((item, index) => (
              <div className="col-md-3 mt-2" style={{ position: "relative" }}>
                <CloseOutlined
                  onClick={() => handleImageRemove(index)}
                  style={{
                    position: "absolute",
                    top: "-10px",
                    color: "red",
                    right: "3px",
                    cursor: "pointer",
                  }}
                />
                <img
                
                  style={{ height: "100px", width: "100px" }}
                  src={URL.createObjectURL(item)}
                />
              </div>
            ))}
          </div>
        </Modal>
        </>
      )}
    </>
  );
};

export default ProjectBoard;
