import React, { useState } from "react";
import { Alert, Modal } from "react-bootstrap";
import { ModalProgressBar } from "../../../_metronic/_partials/controls";
import toast from "react-hot-toast";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import './Delete.css'
import firebase from "firebase";
const DeleteModel = ({
  show,
  onHide,
  isLoading,
  title,
  id,
  refer
}) => {
  const [loading, setloading] = useState(false);
const handleDelete=()=>{
  setloading(true)
  firebase
  .database()
  .ref(refer)
  .child(id)
  .remove()
  .then(() => {
    toast.success('Deleted Successfully')
    onHide()
  setloading(false)
  });
}
  return (
    <Modal
    className="deletemodal"
      show={show}
      onHide={onHide}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      {/*begin::Loading*/}
      {isLoading && <ModalProgressBar />}
      {/*end::Loading*/}
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-md">
        <div className="d-flex">
        <div>
            <span className="svg-icon svg-icon-xl svg-icon-danger">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Code/Warning-2.svg")} />
            </span>
          </div>
          <div className="mx-2 mt-1">
            <p> {title}</p>
          </div>
        </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ paddingTop: "0px", paddingBottom: "0px" }}>
        <Alert style={{ backgroundColor: "#ff818e", color: "#fff" }}>
          Are you sure to permanently delete this?
        </Alert>
      </Modal.Body>
      <Modal.Footer>
        <div>
          <button
            type="button"
            onClick={onHide}
            className="btn btn-light btn-elevate"
          >
            Cancel
          </button>
          <> </>
          <button
          onClick={handleDelete}
            type="button"
            disabled={loading}
            className="btn btn-danger btn-elevate"
          >
           {loading ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : 'Delete'}
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteModel;
