import React, { useEffect, useState } from 'react'
import { Card, CardBody, CardHeader, CardHeaderToolbar, Pagination } from '../../../_metronic/_partials/controls'
import { Modal, Spinner } from 'react-bootstrap';
import { Card as MiCard, CardActions, CardContent, LinearProgress, Paper, Typography, CardMedia } from '@material-ui/core'
import firebase from 'firebase';
import { makeStyles } from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/Edit';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import Button from '@material-ui/core/Button';
import DeleteModel from '../../Components/DeleteModel/DeleteModel';

import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import * as columnFormatters from '../../modules/ECommerce/pages/customers/customers-table/column-formatters';
import * as uiHelpers from '../../modules/ECommerce/pages/customers/CustomersUIHelpers';
import { AddConstruction } from './AddConstruction';


const Construction = () => {
  const [addModal, setaddModal] = useState(false);
  const [editItem, setEditItem] = useState({})
  const [editModal, seteditModal] = useState(false);
  const [boards, setBoards] = useState([])
  const [loading, setloading] = useState(false)
  const [selectedId, setSelectedId] = useState("")
  const [deleteModal, setdeleteModal] = useState(false);


  ////for pagination
  const initialFilter = {
    pageNumber: 1,
    pageSize: 10,
  };


  const [queryParams, setQueryParamsBase] = useState(initialFilter);


  const columns = [
    {
      dataField: "imageUrl",
      text: "User Image",
      formatter: (row,cell) => (
        <>
          <img style={{ height: 50, width: 50 }} src={cell.image || "https://drehomes.com/wp-content/uploads/dummy-image.jpeg"} />
        </>
      )

    },
    {
      dataField: "userName",
      text: "User Name",
    },
    // {
    //   dataField: "fname",
    //   text: "First Name",
    // },
    // {
    //   dataField: "lname",
    //   text: "Last Name",
    // },
    {
      dataField: "email",
      text: "Email",
    },
    {
      dataField: "phoneNumber",
      text: "Phone Number",
    },
    {
      dataField: "action",
      text: "Actions",
      formatter: columnFormatters.Edit,
      formatExtraData: {
        openEditCustomerDialog: (item) => onEditClick(item),
        openDeleteCustomerDialog: (id) => {
          setSelectedId(id);
          setdeleteModal(true);
        },
      },
      classes: "text-right pr-0",
      headerClasses: "text-right pr-3",
      style: {
        minWidth: "100px",
      },
    },
  ];


  const useStyles = makeStyles({
    root: {
      minWidth: 275,
      margin: 5,


    },
    bullet: {
      display: 'inline-block',
      margin: '0 2px',
      transform: 'scale(0.8)',
    },
    title: {
      color: 'black',
      fontSize: 14,
    },
    pos: {
      marginBottom: 12,
    },
  });


  const classes = useStyles();



  useEffect(() => {
    fetchBoards();
  }, []);

  const fetchBoards = () => {
    setloading(true);
    firebase
      .database()
      .ref("users")
      .on("value", (snapshot) => {
        setloading(false);
        let arr = [];
        if (snapshot.exists()) {
          snapshot.forEach((item) => {
            let value = item.val();
            value.id = item.key;
            arr.push(value);
          });
        }
        const orignalarray=  arr.filter((item)=> item.type=="constructor")
        setBoards(orignalarray.reverse());
      });
  };



  const onEditClick = (item) => {
    setEditItem(item)
    seteditModal(true)
    setaddModal(true)
  }



  const onDeleteClick = (id) => {
    setSelectedId(id)
    setdeleteModal(true)
  }

  const options = {
    custom: true,
    totalSize: boards.length,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: queryParams.pageSize,
    page: queryParams.pageNumber,
  };




  const updatingQueryParams = (page) => {
    console.log(page);
    setQueryParamsBase({
      pageNumber: page,
    });
  };

  const onPaginationSelectChange = () => {
    setQueryParamsBase({
      pageNumber: 1,
    });
  };


  return (
    <>
      <Card>
        <CardHeader title="Constructor's list">
          <CardHeaderToolbar>
            <button
              type="button"
              className="btn btn-primary"
              onClick={() =>{ 
                seteditModal(false)
                setaddModal(true)}}
            >
              New Constructor
            </button>
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>

          <PaginationProvider pagination={paginationFactory(options)}>
            {({ paginationProps, paginationTableProps }) => {
              return (
                <>

                  <Pagination
                    updatingQueryParams={updatingQueryParams}
                    onPaginationSelectChange={onPaginationSelectChange}
                    isLoading={loading}
                    paginationProps={paginationProps}
                  >
                    <BootstrapTable
                      wrapperClasses="table-responsive"
                      bordered={false}
                      classes="table table-head-custom table-vertical-center overflow-hidden constritaTable"
                      bootstrap4
                      keyField="id"
                      data={boards}
                      columns={columns}
                      defaultSorted={uiHelpers.defaultSorted}
                      {...paginationTableProps}
                    ></BootstrapTable>
                  </Pagination>
                  {/* )} */}
                </>
              );
            }}
          </PaginationProvider>



          {/* 
          <div className='container'>
            {
              loading ?
                <div style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}>
                  <Spinner animation='border' />
                </div>
                :
                <div className='row'>

                  {boards?.map((item) => (
                    <div
                      className='col-md-4'
                    >
                      <MiCard className={classes.root}>
                        <CardMedia
                          sx={{ height: 270 }}
                          image={item.ImageUrl}
                          title="aa"
                        />
                        <CardContent className="d-flex justify-content-between">
                          <Typography className={classes.title} color="textSecondary" gutterBottom>
                            <p className='title-price'>User Name</p>
                            <p>{item.username}</p>
                          </Typography>
                          <Typography className={classes.title} color="textSecondary" gutterBottom>
                            <p className='title-price'>First Name</p>
                            <p>{item.fname}</p>
                          </Typography>
                          <Typography className={classes.title} color="textSecondary" gutterBottom>
                            <p className='title-price'>Last Name</p>
                            <p>{item.lname}</p>
                          </Typography>
                        </CardContent>
                        <CardActions
                          style={{
                            justifyContent: 'space-around'
                          }}
                        >
                          <Button
                            onClick={() => onEditClick(item)}
                            title={'Edit'} size="small">
                            <EditIcon style={{ color: "blue" }} />
                          </Button>
                          <Button
                            onClick={() => onDeleteClick(item.desinerIdnp)}
                            title='Delete' size="small">
                            <DeleteOutlineIcon style={{ color: "red" }} />
                          </Button>
                        </CardActions>
                      </MiCard>

                    </div>
                  ))}

                </div>
            }
          </div> */}



        </CardBody>
      </Card>


      <Modal
        show={addModal}
        size='lg'
      >
        <Modal.Header><h4>
          {editModal ? 'Edit Constructor' : 'Add Constructor'}
        </h4></Modal.Header>
        <AddConstruction
          editItem={editModal ? editItem : ''}
          isEdit={editModal ? true : false}
          saveCustomer={values => console.log(values)}
          onHide={() => setaddModal(false)}
        />
      </Modal>

      <DeleteModel
        onHide={() => setdeleteModal(false)}
        show={deleteModal}
        title="Delete Construction"
        refer={"users"}
        id={selectedId}
      />


    </>
  )
}

export default Construction 