import React, { useEffect, useState } from 'react'
import { Card, CardActions, CardContent, LinearProgress, Paper, Typography } from '@material-ui/core'
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import VisibilityIcon from '@material-ui/icons/Visibility';
import EditIcon from '@material-ui/icons/Edit';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import "./board.css"
import { Modal } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import DeleteModel from '../../Components/DeleteModel/DeleteModel';
import { AddBoard } from './AddBoard';
import firebase from 'firebase';

const useStyles = makeStyles({
  root: {
    minWidth: 275,
    margin: 5,


  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    color: 'black',
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});


const Board = () => {
  const classes = useStyles();
  const [deleteModal, setdeleteModal] = useState(false);
  const [addModal, setaddModal] = useState(false);
  const [editModal, seteditModal] = useState(false);
  const [boards, setBoards] = useState([])
  const [loading, setloading] = useState(false)
  const [selectedId, setSelectedId] = useState("")
  const [editItem, setEditItem] = useState({})
  const history = useHistory()


  useEffect(() => {
    fetchBoards();
  }, []);

  const fetchBoards = () => {
    setloading(true);
    firebase
      .database()
      .ref("board")
      .on("value", (snapshot) => {
        setloading(false);
        let arr = [];
        if (snapshot.exists()) {
          snapshot.forEach((item) => {
            let value = item.val();
            value.id = item.key;
            arr.push(value);
          });
        }
        setBoards(arr);
      });
  };

  const onDeleteClick = (id) => {
    setSelectedId(id)
    setdeleteModal(true)
  }

  const onEditClick = (item) => {
    setEditItem(item)
    seteditModal(true)
    setaddModal(true)
  }

  return (
    <>
      {loading ? <LinearProgress /> : null}
      <Paper className='p-5'>
        <div className='row justify-content-between p-5'>
          <h1 className='pl-5'>Boards</h1>
          <div>
            <button
              onClick={() => {
                seteditModal(false)
                setaddModal(true)
              }}
              style={{ color: 'white' }}
              className='btn btn-primary pr-5 mr-5'>
              Add Board
            </button>


          </div>
        </div>
        <div className='container'>
          <div className='row'>

            {boards?.map((item) => (
              <div
                className='col-md-4'
              >
                <Card className={classes.root}>
                  <CardContent className="d-flex justify-content-between">
                    <Typography className={classes.title} color="textSecondary" gutterBottom>
                      <p className='title-price'>Name</p>
                      <p>{item.name}</p>
                    </Typography>
                  </CardContent>
                  <CardActions
                    style={{
                      justifyContent: 'space-around'
                    }}
                  >
                    <Button
                      onClick={() => onEditClick(item)}
                      title={'Edit'} size="small">
                      <EditIcon style={{ color: "blue" }} />
                    </Button>
                    <Button
                      onClick={() => onDeleteClick(item.id)}
                      title='Delete' size="small">
                      <DeleteOutlineIcon style={{ color: "red" }} />
                    </Button>
                  </CardActions>
                </Card>

              </div>
            ))}

          </div>
        </div>




      </Paper>

      <Modal
        show={addModal}
      >
        <Modal.Header><h4>
          {editModal ? 'Edit Board' : 'Add Board'}
        </h4></Modal.Header>
        <AddBoard
          editItem={editModal ? editItem : ''}
          isEdit={editModal ? true : false}
          saveCustomer={values => console.log(values)}
          onHide={() => setaddModal(false)}
        />
      </Modal>
      <DeleteModel
        onHide={() => setdeleteModal(false)}
        show={deleteModal}
        title="Delete Board"
        refer={"board"}
        id={selectedId}
      />
    </>
  )
}

export default Board