// please be familiar with react-bootstrap-table-next column formaters
// https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html?selectedKind=Work%20on%20Columns&selectedStory=Column%20Formatter&full=0&addons=1&stories=1&panelRight=0&addonPanel=storybook%2Factions%2Factions-panel
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../../../../_metronic/_helpers";

export function TriAction(
  cellContent,
  row,
  rowIndex,
  { openEditCustomerDialog, openDeleteCustomerDialog,openViewCustomerDialog,addForms}
) {
  return (
    <>
  { row.status !="approved"
  ?
  <a
        className="btn btn-icon btn-light btn-hover-danger btn-sm"
        onClick={() => openDeleteCustomerDialog(row.id)}
      >
        <span className="svg-icon svg-icon-md svg-icon-danger">
          <SVG title="Delete" src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} />
        </span>
      </a>:null}
      <a
        title="Edit"
        className="btn btn-icon btn-light btn-hover-primary btn-sm mx-2"
        onClick={() => openEditCustomerDialog(row)}
      >
        <span className="svg-icon svg-icon-md svg-icon-primary">
          <SVG
          title="Edit"
            src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")}
          />
        </span>
      </a>
      <> </>

      <a
        title="View"
        className="btn btn-icon btn-light btn-hover-primary btn-sm mx-2"
        onClick={() => openViewCustomerDialog(row.id)}
      >
        <span className="svg-icon svg-icon-md svg-icon-primary">
          <SVG title="View" src={toAbsoluteUrl("/media/svg/icons/Devices/Display2.svg")} />
        </span>
      </a>
      <a
        title="Add/Update Forms"
        className="btn btn-icon btn-light btn-hover-primary btn-sm mx-2"
        onClick={() => addForms(row)}
      >
        <span className="svg-icon svg-icon-md svg-icon-primary">
          <SVG title="Forms" src={toAbsoluteUrl("/media/svg/icons/Design/Stamp.svg")} />
        </span>
      </a>
    </>
  );
}

