import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector, shallowEqual, connect, useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ModalProgressBar } from "../../../_metronic/_partials/controls";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import * as auth from "../Auth";
import firebase from "firebase";
import { toast } from "react-hot-toast";
import {useHistory} from 'react-router-dom'

function PersonaInformation(props) {
  // Fields
  const [loading, setloading] = useState(false);
  const [pic, setPic] = useState("");
  const userdetal=JSON.parse(localStorage.getItem("firebaseUser"))
const [newLoading,setNewLoading]=useState(false)
const [oldImage,setOldImage]=useState("")
  const dispatch = useDispatch();
  const [user, setuser] = useState({})
const history=useHistory()



  // useEffect(() => {
  //   if (user.pic) {
  //     setPic(user.pic);
  //   }
  // }, [user]);



  useEffect(() => {
    getUser()
  }, [])



  const getUser = () => {
    setNewLoading(true)
        firebase.database().ref('users')
          .child(userdetal.userId)
          .on('value', snapshot => {
            if (snapshot.exists()) {
              console.log(snapshot.val())
              setuser(snapshot.val())
              formik.setFieldValue("userName",snapshot.val().userName || "")
              formik.setFieldValue("firstName",snapshot.val().firstName || "")
              formik.setFieldValue("lastName",snapshot.val().lastName || "")
              formik.setFieldValue("email",snapshot.val().email || "")
              formik.setFieldValue("phoneNumber",snapshot.val().phoneNumber || "")
              formik.setFieldValue("address",snapshot.val().address || '')
              // setPic(snapshot.val().address || "")
              setOldImage(snapshot.val().image || "")
              setNewLoading(false)

            }
          })
  }
  const initialValues = {
    image: '',
    firstName: user.firstName,
    userName: user.userName,
    lastName: user.lastName,
    phoneNumber:  user.phoneNumber,
    email:  user.email,
    address: user?.address, 
  };

  // Methods
  const saveUser = (values, setStatus, setSubmitting) => {
    setloading(true);
console.log(user.userId)
    console.log(values);
      if (pic == '') {
        firebase.database().ref('users')
        .child(user.userId)
          .update({
            address: values.address || "",
            userName: values.userName || "",
            email: values.email || "",
            firstName: values.firstName || "",
            lastName: values.lastName || "",
            image: oldImage,
            phoneNumber: values.phoneNumber || "",
          }).then((res) => {
            history.push('/dashboard')
            toast.success('Updated successfully')
            setloading(false)
            console.log(res)
            firebase.database().ref('users')
            .child(userdetal?.userId)
            .on('value', snapshot => {
              if (snapshot.exists()) {
                console.log(snapshot.val())
                setuser(snapshot.val())
                 localStorage.setItem('firebaseUser', JSON.stringify(snapshot.val()))  
              }
            })
          }).catch(err => {
            toast.error(err.message)
          })
      } else {
        const storageRef = firebase.storage().ref();
        storageRef
          .child(`userimages/${pic.name}`)
          .put(pic)
          .then(async (snapshot) => {
            snapshot.ref.getDownloadURL().then((val) => {

              firebase.database().ref('users')
                .child(user.userId)
                .update({
                  address: values?.address,
                  userName: values?.userName,
                  email: values?.email,
                  firstName: values?.firstName,
                  lastName: values?.lastName,
                  image: val,
                  phoneNumber: values?.phoneNumber,
                }).then((res) => {
                  history.push('/dashboard')
                  toast.success('Updated successfully')
                  setloading(false)

                }).catch(err => {
                  toast.error(err.message)
                })


            });
          })
          .catch((err) => {
            console.log(err);
          });

      }
    

  };
  // UI Helpers

  const Schema = Yup.object().shape({
    firstname: Yup.string().notRequired("First name is required").nullable(),
    lastname: Yup.string().notRequired("Last name is required").nullable(),
    userName: Yup.string().required("userName is required"),
    phoneNumber: Yup.string().required("Phone is required"),
    email: Yup.string()
      .email("Wrong email format")
      .notRequired("Email is required"),
    address: Yup.string().notRequired('Address is required').nullable()

  });
  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };
  const formik = useFormik({
    initialValues,
    validationSchema: Schema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      saveUser(values, setStatus, setSubmitting);
    },
    onReset: (values, { resetForm }) => {
      resetForm();
    },
  });
  const getUserPic = () => {
    if (!pic) {
      return "none";
    }

    return `url(${pic})`;
  };
  const removePic = () => {
    setPic("");
  };
  
  return (
    <form
      className="card card-custom card-stretch"
      onSubmit={formik.handleSubmit}
    >
      {loading && <ModalProgressBar />}

     {
      newLoading?
      "":
      <>
       <div className="card-header py-3">
        <div className="card-title align-items-start flex-column">
          <h3 className="card-label font-weight-bolder text-dark">
            Personal Information
          </h3>
          <span className="text-muted font-weight-bold font-size-sm mt-1">
            Update your personal informaiton
          </span>
        </div>
        <div className="card-toolbar">
          <button
            type="submit"
            className="btn btn-primary mr-2"
            // disabled={
            //   formik.isSubmitting || (formik.touched && !formik.isValid)
            // }
          >
            Save Changes
            {formik.isSubmitting}
          </button>
          <Link
            to="/dashboard"
            className="btn btn-secondary"
          >
            Cancel
          </Link>
        </div>
      </div>
      <div className="form">
        {/* begin::Body */}
        <div className="card-body">
          <div className="form-group row">
            <label className="col-xl-3 col-lg-3 col-form-label">Avatar</label>
            <div className="col-lg-9 col-xl-6">
              <div
                className="image-input image-input-outline"
                id="kt_profile_avatar"
                style={{
                  backgroundImage: `url(${pic?URL.createObjectURL(pic):oldImage?oldImage:

                  toAbsoluteUrl(
                    "/media/users/blank.png"
                  )
                }`,
                }}
              >
                <div
                  className="image-input-wrapper"
                  style={{ backgroundImage: `${getUserPic()}` }}
                />
                <label
                  className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                  data-action="change"
                  data-toggle="tooltip"
                  title=""
                  data-original-title="Change avatar"
                >
                  <i className="fa fa-pen icon-sm text-muted"></i>
                  <input
                    type="file"
                    onChange={(e)=>setPic(e.target.files[0])}
                    // name="pic"
                    accept=".png, .jpg, .jpeg"
                  // {...formik.getFieldProps("pic")}
                  />
                  <input type="hidden" name="profile_avatar_remove" />
                </label>
                <span
                  className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                  data-action="cancel"
                  data-toggle="tooltip"
                  title=""
                  data-original-title="Cancel avatar"
                >
                  <i className="ki ki-bold-close icon-xs text-muted"></i>
                </span>
                {/* <span
                  onClick={()=>setPic("")}
                  className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                  data-action="remove"
                  data-toggle="tooltip"
                  title=""
                  data-original-title="Remove avatar"
                >
                  <i className="ki ki-bold-close icon-xs text-muted"></i>
                </span> */}
              </div>
              <span className="form-text text-muted">
                Allowed file types: png, jpg, jpeg.
              </span>
            </div>
          </div>
          <div className="form-group row">
            <label className="col-xl-3 col-lg-3 col-form-label">
              User Name
            </label>
            <div className="col-lg-9 col-xl-6">
              <input
                type="text"
                placeholder="User name"
                className={`form-control form-control-lg form-control-solid ${getInputClasses(
                  "userName"
                )}`}
                value={formik.values.userName}
                defaultValue={user?.userName}
                name="userName"
                {...formik.getFieldProps("userName")}
              />
              {formik.touched.userName && formik.errors.userName ? (
                <div className="invalid-feedback">
                  {formik.errors.userName}
                </div>
              ) : null}
            </div>
          </div>
          <div className="form-group row">
            <label className="col-xl-3 col-lg-3 col-form-label">
              First Name
            </label>
            <div className="col-lg-9 col-xl-6">
              <input
                defaultValue={user?.firstName}
                value={formik.values.firstName}

                type="text"
                placeholder="First name"
                className={`form-control form-control-lg form-control-solid ${getInputClasses(
                  "firstName"
                )}`}
                name="firstName"
                {...formik.getFieldProps("firstName")}
              />
              {formik.touched.firstName && formik.errors.firstName ? (
                <div className="invalid-feedback">
                  {formik.errors.firstName}
                </div>
              ) : null}
            </div>
          </div>
          <div className="form-group row">
            <label className="col-xl-3 col-lg-3 col-form-label">
              Last Name
            </label>
            <div className="col-lg-9 col-xl-6">
              <input
                defaultValue={user?.lastName}
                value={formik.values.lastName}

                type="text"
                placeholder="Last name"
                className={`form-control form-control-lg form-control-solid ${getInputClasses(
                  "lastName"
                )}`}
                name="lastName"
                {...formik.getFieldProps("lastName")}
              />
              {formik.touched.lastName && formik.errors.lastName ? (
                <div className="invalid-feedback">{formik.errors.lastName}</div>
              ) : null}
            </div>
          </div>
          <div className="form-group row">
            <label className="col-xl-3 col-lg-3 col-form-label">
              Address
            </label>
            <div className="col-lg-9 col-xl-6">
              <input
                defaultValue={user?.address}
                value={formik.values.address}
                type="text"
                placeholder="Enter Address"
                className={`form-control form-control-lg form-control-solid`}
                name="address"
                {...formik.getFieldProps("address")}
              />
              <span className="form-text text-muted">
                If you want your invoices addressed to a company. Leave blank to
                use your full name.
              </span>
            </div>
          </div>
          <div className="row">
            <label className="col-xl-3"></label>
            <div className="col-lg-9 col-xl-6">
              <h5 className="font-weight-bold mt-10 mb-6">Contact Info</h5>
            </div>
          </div>
          <div className="form-group row">
            <label className="col-xl-3 col-lg-3 col-form-label">
              Contact Phone
            </label>
            <div className="col-lg-9 col-xl-6">
              <div className="input-group input-group-lg input-group-solid">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <i className="fa fa-phone"></i>
                  </span>
                </div>
                <input
                defaultValue={user?.phoneNumber}
                value={formik.values.phoneNumber}

                  type="text"
                  placeholder="+1(123)112-11-11"
                  className={`form-control form-control-lg form-control-solid ${getInputClasses(
                    "phoneNumber"
                  )}`}
                  name="phoneNumber"
                  {...formik.getFieldProps("phoneNumber")}
                />
              </div>
              {formik.touched.phoneNumber && formik.errors.phoneNumber ? (
                <div className="invalid-feedback display-block">
                  {formik.errors.phoneNumber}
                </div>
              ) : null}
              <span className="form-text text-muted">
                We'll never share your phone with anyone else.
              </span>
            </div>
          </div>
          <div className="form-group row">
            <label className="col-xl-3 col-lg-3 col-form-label">
              Email Address
            </label>
            <div className="col-lg-9 col-xl-6">
              <div className="input-group input-group-lg input-group-solid">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <i className="fa fa-at"></i>
                  </span>
                </div>
                <input
                defaultValue={user?.email}
                value={formik.values.email}
                  disabled
                  type="email"
                  placeholder="Email"
                  className={`form-control form-control-lg form-control-solid ${getInputClasses(
                    "email"
                  )}`}
                  name="email"
                  {...formik.getFieldProps("email")}
                />
              </div>
              {formik.touched.email && formik.errors.email ? (
                <div className="invalid-feedback display-block">
                  {formik.errors.email}
                </div>
              ) : null}
            </div>
          </div>
          {/* <div className="form-group row">
            <label className="col-xl-3 col-lg-3 col-form-label">
              Company Site
            </label>
            <div className="col-lg-9 col-xl-6">
              <div className="input-group input-group-lg input-group-solid">
                <input
                  type="text"
                  placeholder="https://keenthemes.com"
                  className={`form-control form-control-lg form-control-solid`}
                  name="website"
                  {...formik.getFieldProps("website")}
                />
              </div>
              {formik.touched.website && formik.errors.website ? (
                <div className="invalid-feedback display-block">
                  {formik.errors.website}
                </div>
              ) : null}
            </div>
          </div> */}
        </div>
        {/* end::Body */}
      </div>
      </>
     }
    </form>
  );
}

export default connect(null, auth.actions)(PersonaInformation);
