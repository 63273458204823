import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { Input } from "../../../_metronic/_partials/controls";
import { database } from "firebase";

export function AddBoard({
  saveCustomer,
  customer,
  actionsLoading,
  onHide,
  editItem,
  reload
}) {
  const [loading, setLoading] = useState(false);

  const CustomerEditSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
  });
  const editinitialValues = {
    name: editItem.name
  }
  const addinitialValues = {
    name: ''
  }
  const handleSubmit = (values) => {
    setLoading(true)
    database()
      .ref("/board")
      .push({
        name: values.name,
      })
      .then(() => {
        setLoading(false)
        onHide()
      }
      ).catch((err) => console.log(err))

  }
  const editHandler = (values) => {
    setLoading(true)
    database()
      .ref("/board").child(editItem.id)
      .update({
        name: values.name,
      })
      .then(() => {
        setLoading(false)
        onHide()
      }
      ).catch((err) => console.log(err))

  }

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={editItem != '' ? editinitialValues : addinitialValues}
        validationSchema={CustomerEditSchema}
        onSubmit={(values) => {
          editItem ? editHandler(values) : handleSubmit(values);
        }}
      >
        {({ values }) => (
          <>
            <Form className="form form-label-right">
              <Modal.Body className="overlay overlay-block cursor-default pt-0 pb-0">
                {actionsLoading && (
                  <div className="overlay-layer bg-transparent">
                    <div className="spinner spinner-lg spinner-success" />
                  </div>
                )}
                <div className="form-group row">

                  <div className="col-lg-12 col-md-12 col-sm-12 mt-3">
                    <label>Enter Name</label>
                    <Field
                      // style={{ height: "8rem" }}
                      value={values.name}
                      name="name"
                      component={Input}
                      placeholder="Enter name"
                    />
                  </div>


                </div>
              </Modal.Body>
              <Modal.Footer>
                <button
                  type="button"
                  onClick={onHide}
                  className="btn btn-light btn-elevate"
                >
                  Cancel
                </button>
                <> </>
                <button
                  disabled={loading}
                  type="submit"
                  className="btn btn-primary btn-elevate"
                >
                  Save
                </button>
              </Modal.Footer>
            </Form>

          </>
        )}
      </Formik>
    </>
  );
}
