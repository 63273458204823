// /**
//  * High level router.
//  *
//  * Note: It's recommended to compose related routes in internal router
//  * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
//  */

import React, { useEffect, useState } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
// import { shallowEqual, useSelector } from "react-redux";
import { Layout } from "../_metronic/layout";
import BasePage from "./BasePage";
import { Logout, AuthPage, PrivacyPolicy } from "./modules/Auth";
import ErrorsPage from "./modules/ErrorsExamples/ErrorsPage";
import { useDispatch, useSelector, connect, shallowEqual } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import * as auth from "../app/modules/Auth/_redux/authRedux";
import TermsCondition from "./pages/TermsCondition/TermsCondition";
// import PrivacyPolicy from "./modules/Auth/pages/PrivacyPolicy/PrivacyPolicy";


function Routes() {
  const dispatch = useDispatch();

  const [authorized, setauthorized] = useState()

  let campusidget = useSelector((state) => state);
  console.log(campusidget)


  const { isAuthorized } = useSelector(
    ({ auth }) => ({
      isAuthorized: auth.user != null,
    }),
    shallowEqual
  );
  console.log(isAuthorized, "isAuthorized=>")


  useEffect(() => {
    console.log(isAuthorized, "isAuthorized=>")
    setauthorized(isAuthorized)
    if (
      performance.navigation.type == performance.navigation.TYPE_RELOAD &&
      localStorage.getItem("userToken")
    ) {
      dispatch({
        type: "ADD_USER",
        payload: {
          isAuthenticated: true,

          authtoken: localStorage.getItem("userToken"),
          user: JSON.parse(localStorage.getItem("userDetails")),
        },
      });
    }
  }, []);

  return (
    <Switch>
      <Route path="/terms-and-conditions">
        <TermsCondition />
      </Route>

      <Route path="/privacy-policy">
        <PrivacyPolicy />
      </Route>
      {!localStorage.getItem("userToken") || authorized ? (
        <>
          <Route>
            <AuthPage />
          </Route>
        </>
      ) : localStorage.getItem("userToken") || authorized ? (
        <Redirect from="/auth" to="/dashboard" />
      ) : null}

      <Route path="/error" component={ErrorsPage} />

      {localStorage.getItem("userToken") || authorized ? (
        <Layout>
          <BasePage />
        </Layout>
      ) : (
        <Redirect to="/auth/login" />
      )}

    </Switch>

    //     <Switch>

    //       {!localStorage.getItem("userToken") || authorized ? (
    //         //Render auth page when user at `/auth` and not authorized.
    // <>
    //         <Route>
    //           <AuthPage />
    //         </Route>
    //         {/* <Route to="/privacy-policy" >
    //           <PrivacyPolicy/>
    //         </Route> */}

    //         </>
    //       ) : localStorage.getItem("userToken") || authorized ? (
    //         <Redirect from="/auth" to="/dashboard" />
    //       ) : null}
    //       {!localStorage.getItem("userToken") || authorized ? (
    //         //Render auth page when user at `/auth` and not authorized.
    // <>
    //         <Route>
    //           <PrivacyPolicy/>
    //         </Route>
    //         </>
    //       ) : localStorage.getItem("userToken") || authorized ? (
    //         <Redirect from="/auth" to="/dashboard" />
    //       ) : null}
    //       <Route path="/error" component={ErrorsPage} />

    //       {localStorage.getItem("userToken") || authorized ? (
    //         <Layout>
    //           <BasePage />
    //         </Layout>
    //       ) : (
    //         <Redirect to="/auth/login" />
    //       )}
    //     </Switch>
  );
}
export default injectIntl(connect(null, auth.actions)(Routes));
