import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { Formik, Form, Field, } from "formik";
import * as Yup from "yup";
import { Input } from "../../../_metronic/_partials/controls";
import toast from "react-hot-toast";
import { TextArea } from "../../../_metronic/_partials/controls";
// Validation schema
import Upload from "../../../app/Images/upload.png";
import firebase from "firebase";
import { useHistory } from "react-router-dom";
import { SendNotifaction } from "../../../utils/SendNotifaction";
import { serverKey } from "../../../utils/constants";
import { error } from "jquery";

export function SendNotifactionModal({
  currentUserData,
  users,
  actionsLoading,
  onHide,
}) {
console.log(users)
  const [loading, setLoading] = useState(false);
  const [imgShow, setimgShow] = useState("");
  const [image, setimage] = useState("");
  const [imgError, setimgError] = useState(false);
  const [formModal, setformModal] = useState(false)
  const [selectedProjectKey, setselectedProjectKey] = useState('')
  const [currentUser, setcurrentUser] = useState({})


  const history = useHistory()

  let user = JSON.parse(localStorage.getItem("firebaseUser"));





  const CustomerEditSchema = Yup.object().shape({
    title: Yup.string().required("Title is required"),
    description: Yup.string().notRequired("Description is required"),
  });


  const addinitialValues = {
    title: "",
    description: "",
  };

  const handleSubmit = async (values) => {
    setLoading(true);
  
    try {
      await Promise.all(users.map(async (item) => {
        const pushkey = firebase.database().ref("notifications").push().key;
  
        // Set notification in user's node
        await firebase.database().ref('notifications')
          .child(item.userId)
          .child(pushkey)
          .set({
            notificationId: pushkey,
            action: 'notification',
            userId: currentUserData.userId,
            message: values.description,
            title: values.title,
            timeStamp: Date.now(),
            userName: currentUserData.userName,
            profileImage: currentUserData.image ? currentUserData.image : '',
          });
  
        // Set notification in AdminNotification node
        const adminPushkey = firebase.database().ref("AdminNotification").push().key;
        try {
          await firebase.database().ref('AdminNotification')
            .child(adminPushkey)
            .set({
              notificationId: adminPushkey,
              action: 'notification',
              userId: currentUserData.userId,
              message: values.description,
              receiverName: item.userName,
              receiverEmail: item.email,
              title: values.title,
              timeStamp: Date.now(),
              userName: currentUserData.userName,
              profileImage: currentUserData.image ? currentUserData.image : '',
            });
        } catch (adminError) {
          console.error("Error adding to AdminNotification:", adminError);
        }
  
        const notification = {
          title: values.title,
          body: values.description,
        };
        if(item.token){
        console.log('item.token:', item.token); 
        await fetch('https://fcm.googleapis.com/fcm/send', {
          headers: {
            'Content-Type': 'application/json',
            Authorization: serverKey,
          },
          method: 'POST',
          body: JSON.stringify({
            notification: notification,
            to: item.token,
          }),
        })
        .then(response => response.json())
        .then(data => {
          console.log('response', data);
          toast.success("sent successfully!")
              });
            }else{
              console.log("Item does not have a valid token:", item);
              toast.error("Notification not sent. user does not allow notification permission");
            }
  
      }));
  
      setLoading(false);
      onHide();
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };
  
  
  // const handleSubmit = (values) => {
  //   setLoading(true)
  //  users.map((item,i)=>{
  //   const pushkey = firebase
  //   .database()
  //   .ref("notifications")
  //   .push().key;
  //   firebase.database().ref('notifications')
  //   .child(item.userId)
  //   .child(pushkey)
  //   .set({
  //     notificationId:pushkey,
  //     action: 'notification',
  //     userId: currentUserData.userId,
  //     message: values.description,
  //     title:values.title,
  //     timeStamp: Date.now(),
  //     userName: currentUserData.userName,
  //     profileImage: currentUserData.image ? currentUserData.image : '',
  //   })
  //   .then(async () => {
  //     const pushkey = firebase
  //     .database()
  //     .ref("AdminNotification")
  //     .push().key;
  //     firebase.database().ref('AdminNotification')
  //     .child(pushkey)
  //     .set({
  //       notificationId:pushkey,
  //       action: 'notification',
  //       userId: currentUserData.userId,
  //       message: values.description,
  //       receiverName:item.userName,
  //       receiverEmail:item.email,
  //       title:values.title,
  //       timeStamp: Date.now(),
  //       userName: currentUserData.userName,
  //       profileImage: currentUserData.image ? currentUserData.image : '',
  //     })


  //     const notification = {
  //       title: values.title,
  //       body: values.description,
  //     };

  //     await fetch('https://fcm.googleapis.com/fcm/send', {
  //       headers: {
  //         'Content-Type': 'application/json',
  //         Authorization: serverKey
  //       },
  //       method: 'POST',
  //       body: JSON.stringify({
  //         notification: notification,
  //         to: item.token,
  //       }),
  //     })
  //       .then(response => {response.json()})
  //       .then(data => {
  //         console.log('response', data);
  //         if (!data) {
  //           return toast.error("invalid Device id");
  //           onHide();
  //          }
  //           setLoading(false)
  //           onHide();

  //       });
  //   })
  //   .catch(error => {
  //     console.log(error)
  //   });
  //   // SendNotifaction(values.title,values.description,item.token)
  //  })
  // };

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={addinitialValues}
        validationSchema={CustomerEditSchema}
        onSubmit={(values) => {
          handleSubmit(values);
        }}
      >
        {({ values }) => (
          <>
            <Form className="form form-label-right">
              <Modal.Body className="overlay overlay-block cursor-default">
                {actionsLoading && (
                  <div className="overlay-layer bg-transparent">
                    <div className="spinner spinner-lg spinner-success" />
                  </div>
                )}
                <div className="form-group row">
                  <div className="col-lg-12 col-md-12 col-sm-12">
                    <label>Enter Title</label>
                    <Field
                      component={Input}
                      placeholder="Enter title"
                      value={values.title}
                      name="title"
                    />
                  </div>
                </div>
          
     
                <div className="form-group row">
                  <div className="col-lg-12 col-md-12 col-sm-12">
                    <label>Enter Description</label>
                    <Field
                      style={{ height: "8rem" }}
                      value={values.description}
                      name="description"
                      component={TextArea}
                      placeholder="Enter description"
                    />
                  </div>
                </div>
                {/* {
                  imgShow ?
                    <div className="row">
                      <div className="col-md-4">
                        <img src={imgShow} style={{ height: "200px", width: "300px" }} />
                      </div>
                    </div>

                    : ''
                } */}
                {/* <div className="form-group row">
                  <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className="avatar-upload-input text-center">
                      <img src={Upload} alt="img" />
                      <h2>Upload Image</h2>
                      <div className="avatar-edit-input">
                        <input
                          className="form-control"
                          name="file"
                          type={"file"}
                          onChange={(e) => {
                            setimgError(false);
                            setimage(e.target.files[0]);
                            setimgShow(URL.createObjectURL(e.target.files[0]));
                          }}
                        />
                        {imgError ? (
                          <label className="text-danger">
                            Image is required
                          </label>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div> */}
              </Modal.Body>
              <Modal.Footer>
                <button
                  type="button"
                  onClick={onHide}
                  className="btn btn-light btn-elevate"
                >
                  Cancel
                </button>
                <> </>
                <button
                  // disabled={loading}
                  type="submit"
                  // onClick={() =>  handleSubmit(values)}
                  className="btn btn-primary btn-elevate"
                >
                  {loading ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : 'Send'}
                </button>
              </Modal.Footer>
            </Form>
          </>
        )}
      </Formik>

      {/* 
      <ProjectFrom
        hideModal={() => setformModal()}
        visible={formModal}
        projectId={selectedProjectKey}

      /> */}
    </>
  );
}
