import React, { useRef } from "react";
import "./userDesginer.css";
import { Paper } from "@material-ui/core";
import firebase from "firebase";
import { useHistory, useParams } from "react-router-dom";
import { useEffect } from "react";
import { useState } from "react";
import { PhoneOutlined, MailOutlined } from "@ant-design/icons";
import { Divider, Empty } from "antd";
import ProjectBoard from "../../DesignerModule/DesignerProjectDetails/ProjectBoard/ProjectBoard";
import { Card, Card as ReactbootCard, Spinner } from "react-bootstrap";
import { Player } from "video-react";
import Slider from "react-slick";
import { Card as AntCard } from "antd";
import {
  AppBar,
  Avatar,
  CardContent,
  Typography,
  Card as Carder,
  CardHeader,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import pop from "./audio/sound.mp3";
import { toast } from "react-hot-toast";
import { SendNotifaction } from "../../../../utils/SendNotifaction";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 400,
    backgroundColor: "#333",
    opacity: 0.5,
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    // transition: theme.transitions.create('transform', {
    //   duration: theme.transitions.duration.shortest,
    // }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  avatar: {
    backgroundColor: "red",
  },
}));
const UserDesigner = () => {
  const { id } = useParams();
  const [designer, setDesigner] = useState();
  const [invited, setInvited] = useState(false);
  const [showMore, setShowMore] = useState(true);
  const [showLess, setShowLess] = useState(false);
  const [allFiles, setAllFiles] = useState([]);
  const [allImages, setAllImages] = useState([]);
  const [allVideos, setAllVideos] = useState({});
  const [commentList, setCommentList] = useState([]);
  const [myLoading,setMyloading]=useState(false)
  const classes = useStyles();
  const [value, setValue] = useState("");
  const [buttonLoading, setButtonLoading] = useState(false);
  const dummy = useRef();
  const currentUser = JSON.parse(localStorage.getItem("firebaseUser"));
  useEffect(() => {
    getDesginer();
    getProjects();
    commentsGetter();
  }, []);
  const getProjects = () => {
    firebase
      .database()
      .ref("projects")
      .on("value", (snapshot) => {
        if (snapshot) {
          let array = [];
          let invitedUserArray = [];

          snapshot.forEach((item, index) => {
            item.child("invitedUsers").forEach((user, index) => {
              if (
                user.key == firebase.auth().currentUser?.uid &&
                user.child("status").val() == "accept"
              ) {
                let data = item.val();
                data.key = item.key;
                array.push(data);
                if (user.child("favourite").val() == true) {
                  invitedUserArray.push(item.key);
                }
              }
            });
          });
          array.filter((item) => {
            if (item.projectId == id) {
              console.log(item);
              setInvited(true);
            }
          });
          console.log(invitedUserArray);
          if (array.length === 0) {
            console.log("nothing");
          }
        }
      });
  };
  const history = useHistory();
  const getDesginer = () => {
    setMyloading(true)
    firebase
      .database()
      .ref(`/projects/${id}/designer`)
      .on("value", (snapshot) => {
        setMyloading(false)
        if (snapshot.exists()) {
          console.log(snapshot.val(), "this is desginer");
          setDesigner(snapshot.val());
          console.log("ya chala hy",snapshot.val())

          if (snapshot.val().board) {
            if (snapshot.val().board.images) {
              firebase
                .database()
                .ref(`/projects/${id}/designer/board/images`)
                .on("value", (snapshot) => {
                  let arr = [];
                  if (snapshot.exists()) {
                    snapshot.forEach((item) => {
                      let value = item.val();
                      value.id = item.key;
                      arr.push(value);
                    });
                  }
                  setAllImages(arr);
                });

              // setAllImages(Object.values(snapshot.val().board.images));
            }
            if (snapshot.val().board.files) {
              firebase
                .database()
                .ref(`/projects/${id}/designer/board/files`)
                .on("value", (snapshot) => {
                  let arr = [];
                  if (snapshot.exists()) {

                    snapshot.forEach((item) => {
                      let value = item.val();
                      value.id = item.key;
                      arr.push(value);
                    });
                  }
                  setAllFiles(arr.reverse());
                });
              // setAllFiles(Object.values(snapshot.val().board.files));
            }
            if (snapshot.val().board.video) {
              console.log(snapshot.val().board.video);

              setAllVideos(snapshot.val().board.video);
            }
            // console.log(snapshot.val().board.images)

            // console.log(  Object.entries(snapshot.val().board.images))

            // setAllImages(snapshot.val().board.images)
          }
        }
      });
  };
  const commentsGetter = async () => {
    firebase
      .database()
      .ref(`/projects/${id}/comments`)
      .on("value", (snapshot) => {
        let arr = [];
        if (snapshot.exists()) {
          snapshot.forEach((item) => {
            let value = item.val();
            value.id = item.key;
            arr.push(value);
          });
        }
        setCommentList(arr.reverse());
      });
  };
  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 2.2,
    slidesToScroll: 2.2,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const addComment = async () => {
    if (value) {
      setButtonLoading(true);
      const commentId = firebase
        .database()
        .ref(`/projects`)
        .push().key;
      firebase
        .database()
        .ref(`/projects`)
        .child(id)
        .child("comments")
        .child(commentId)
        .set({
          comment: value,
          commentId,
          timeStamp: Date.now(),
          userName: currentUser.userName,
          uid: currentUser.userId,
          profileImage: currentUser.image || "",
          type: currentUser.type,
        })
        .then(() => {
          setButtonLoading(false);
          toast.success("Comment Send successfully");
          SendNotifaction(
            `${currentUser?.userName} added the comment`,
            value,
            designer.token,
            ""
          );
          const sound = document.getElementById("sound");
          sound.play();
          //   if (boardData.userId == auth().currentUser?.uid) {
          //     boardUsersDataGetter(commentId);
          //   } else {
          //     // boardUsersDataGetter(commentId)
          //     sendNotificaionToBoardOwner(commentId);
          //   }
          // dummy.current.scrollIntoView();
          setValue("");
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      toast.error("Please Add comment");
      //   showMessage({
      //     message: 'Add Comment',
      //     duration: 1000,
      //     type: 'warning',
      //   });
    }
  };
  return (
    <>
     {myLoading ? 
     <div className="notFoundDesginer">
     <Spinner animation='border' />
   </div>:
     <Paper style={{ minHeight: "90vh", height: "auto" }}>
        <audio id="sound" src={pop}></audio>

        {designer ? (
          <>
            <div className="row">
              <div className="col-md-12 barcontainer">
                <h2>Designer Details</h2>
                <div className="bar__"></div>
              </div>
              <div className="col-md-12 profile-card-container">
                <div className="desginer-profile-card">
                  <div className="desginer-profile-inner">
                    <div className="row">
                      <div className="col-md-8">
                        <div className="text-center mt-5 pb-4">
                          <h3>{designer.userName}</h3>
                        </div>
                        <div className="col-md-12 mt-2 d-flex">
                          <MailOutlined style={{ fontSize: "1.5rem" }} />
                          <p className="subheadingProject pl-2">
                            {designer.email}
                          </p>
                        </div>
                        <div className="col-md-12 mt-2 d-flex">
                          <PhoneOutlined style={{ fontSize: "1.5rem" }} />
                          <p className="subheadingProject pl-2">
                            {designer.phoneNumber}
                          </p>
                        </div>
                        {invited || currentUser.type !="client" ? (
                          ""
                        ) : (
                          <div className="messagebutton-container">
                            <button
                              className="btn"
                              onClick={() => history.push(`/chat/${id}`)}
                            >
                              Message
                            </button>
                          </div>
                        )}
                      </div>
                      <div className="col-md-4">
                        <img className="desginerImage" src={designer?.image} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Divider />
            <div className="row">
              <div className="col-md-12 barcontainer">
                <h2>Designer Board</h2>
                <div className="bar__"></div>
              </div>
              {/* <div className='col-md-12 mt-5'>
                  <ProjectBoard />
                </div> */}
            </div>
            <Divider />
            <div className="row">
              <div className="col-md-12">
                <div class="folded">
                  <h2>Designer Reports</h2>
                </div>
              </div>
            {allFiles.length>0 ?
              <div
                style={{
                  paddingLeft: "20px",
                  paddingTop: "30px",
                  paddingRight: "20px",
                  paddingBottom: "20px",
                  width: "100%",
                }}
              >
                <AntCard
                  size="small"
                  title={
                    // <a target="_blank" href={allFiles[0]?.uri}>
                      <p
                      onClick={()=>history.push(`/pdfandcomments/${allFiles[0]?.id}/${id}`)}
                      style={{ color: "black", margin: 0 }}>
                        {" "}
                        {allFiles[0]?.name}
                      </p>
                    // </a>
                  }
                  extra={
                    // <a target="_blank" href={allFiles[0]?.uri}>
                    //   <button className="clickherebutton">
                    //     Click here to open
                    //   </button>
                    // </a>
                    // <a target="_blank" href={allFiles[0]?.uri}>
                      <button onClick={()=>history.push(`/pdfandcomments/${allFiles[0]?.id}/${id}`)} className="clickherebutton">
                        Click here to open
                      </button>
                    // </a>
                  }
                  style={{
                    width: "auto",
                    height: 700,
                    marginTop: 13,
                    position:"relative"
                  }}
                >
                  <div className="filesvg text-center">
                    <iframe
                      style={{ height: "700px", width: "100%" }}
                      src={allFiles[0]?.uri}
                      onClick={()=>history.push(`/pdfandcomments/${allFiles[0]?.id}/${id}`)}
                    ></iframe>
                  
                  </div>
                  <div style={{position:"absolute",top:"18%",left:0,width:"100%",height:"600px",opacity:0}} 
                      onClick={()=>history.push(`/pdfandcomments/${allFiles[0]?.id}/${id}`)}
                      ></div>
                </AntCard>
              </div>
              :
              <div style={{paddingTop:"20px",width:"100%"}}>
              <Empty description="No Report Yet"/>
            </div>
              }
              {/* <div ref={dummy} />
              <div className="commentscont">
                {commentList.length > 0 ? (
                  <>
                    {commentList.map((item, i) => (
                      <>
                        <div
                          id={commentList?.length == i + 1 ? "yes" : ""}
                          className={
                            item.uid == currentUser?.userId
                              ? "row mt-4 mb-3 maraCard"
                              : "row mt-4 mb-3"
                          }
                        >
                          <div
                            className={
                              item.uid == currentUser?.userId
                                ? "col-md-12 endIt"
                                : "col-md-12"
                            }
                          >
                            <Carder
                              style={{
                                width:
                                  item.uid == currentUser?.userId
                                    ? "400px"
                                    : "",
                              }}
                              className={classes.root}
                            >
                              <CardHeader
                                avatar={
                                  <Avatar
                                    alt="Remy Sharp"
                                    src={item.profileImage}
                                  />
                                }
                                title={
                                  <Typography
                                    variant="p"
                                    style={{ color: "white" }}
                                  >
                                    {item.userName}
                                    <br />
                                  </Typography>
                                }
                                subheader={
                                  <Typography
                                    variant="p"
                                    style={{ color: "white" }}
                                  >
                                    {new Date(item?.timeStamp).toLocaleString()}
                                  </Typography>
                                }
                              />
                              <Divider
                                style={{ margin: 0, backgroundColor: "white" }}
                              />

                              <CardContent>
                                <Typography
                                  variant="body2"
                                  style={{
                                    color: "white",
                                    fontSize: "18px",
                                    fontWeight: "bold",
                                  }}
                                  component="p"
                                >
                                  {item.comment}
                                </Typography>
                              </CardContent>
                            </Carder>
                          </div>
                          <div />
                        </div>
                      </>
                    ))}
                  </>
                ) : (
                  ""
                )}
              </div> */}
            </div>
            {/* <div className="addcommentCont">
              <div className="d-flex mt-4">
                <input
                  value={value}
                  onChange={(e) => setValue(e.target.value)}
                  className="form-control commentInput"
                  placeholder="write comment"
                />
                <button
                  onClick={addComment}
                  className="addCommentButton"
                  style={{ marginRight: "10px" }}
                >
                  Add Comment
                </button>
              </div>
            </div> */}
            {showMore && allFiles.length>0 ? (
              <div className="row buttonContainerShow">
                <div className="">
                  <button
                    className="showButton"
                    onClick={() => {
                      setShowMore(false);
                      setShowLess(true);
                    }}
                  >
                    Show More
                  </button>
                </div>
              </div>
            ) : (
              ""
            )}

            {showLess
              ? allFiles &&
                allFiles.map((item, index) =>
                  index > 0 ? (
                    <div
                      style={{
                        paddingLeft: "20px",
                        paddingTop: "30px",
                        paddingRight: "20px",
                        paddingBottom: "20px",
                        width: "100%",
                      }}
                    >
                      <AntCard
                        size="small"
                        title={
                          // <a target="_blank" href={item?.uri}>
                            <p
                            onClick={()=>history.push(`/pdfandcomments/${item?.id}/${id}`)}
                            style={{ color: "black", margin: 0 }}>
                              {" "}
                              {item?.name}
                            </p>
                          // </a>
                        }
                        extra={
                          // <a target="_blank" href={item?.uri}>
                          //   <button className="clickherebutton">
                          //     Click here to open
                          //   </button>
                          // </a>
                            <button onClick={()=>history.push(`/pdfandcomments/${item?.id}/${id}`)} className="clickherebutton">
                            Click here to open
                          </button>
                        }
                        style={{
                          width: "auto",
                          height: 700,
                          marginTop: 13,
                    position:"relative"
                        }}
                      >
                        <div className="filesvg text-center">
                          <iframe
                            id="desginerPdf"
                            style={{ height: "700px", width: "100%" }}
                            src={item?.uri}
                          ></iframe>
                        </div>
                        <div style={{position:"absolute",top:"18%",left:0,width:"100%",height:"600px",opacity:0}} 
                      onClick={()=>history.push(`/pdfandcomments/${item?.id}/${id}`)}
                      ></div>
                      </AntCard>
                    </div>
                  ) : (
                    ""
                  )
                )
              : ""}
            {showLess ? (
              <div className="row buttonContainerShow">
                <div className="">
                  <button
                    className="showButton"
                    onClick={() => {
                      setShowLess(false);
                      setShowMore(true);
                    }}
                  >
                    Show Less
                  </button>
                </div>
              </div>
            ) : (
              ""
            )}
            <Divider />
            <div className="row mt-5">
              <div className="col-md-12 mx-2">
                <div class="folded">
                  <h2>Designer Images</h2>
                </div>
              </div>
            </div>
            {allImages && allImages.length ? (
              <div className="carsolemasin mt-3">
                <Slider {...settings}>
                  {allImages.map((img) => (
                    <div>
                      <Card
                        style={{
                          width: "25rem",
                          borderRadius: "10px",
                          height: "300px",
                        }}
                        className="mb-2 slidercard"
                      >
                        <Card.Body style={{ padding: 0 }}>
                          <div>
                            <img
                              style={{ height: "300px" }}
                              className="sliderCarimg"
                              src={img.val}
                              alt=""
                            />
                          </div>
                        </Card.Body>
                      </Card>
                    </div>
                  ))}
                </Slider>
              </div>
            ) : <div style={{paddingTop:"20px"}}>
            <Empty description="No Images Yet"/>
          </div>}
            <Divider />
            <div className="mt-3">
              <div className="col-md-12">
                <div class="folded">
                  <h2>Designer Video</h2>
                </div>
              </div>
          {
            allVideos.uri?
            <div className="container" style={{ paddingTop: "50px" }}>
            <Player
              fluid={false}
              height={400}
              width="100%"
              poster={allVideos.thumbnailUrl}
              src={allVideos.uri}
            />
          </div>:
          <div style={{paddingTop:"20px"}}>
            <Empty description="No Video Yet"/>
          </div>
          }
            </div>
          </>
        ) : (
          <div className="notFoundDesginer">
            <h3>No Desginer Yet</h3>
          </div>
        )}
      </Paper>}
    </>
  );
};

export default UserDesigner;
