import { Avatar, Card, CardContent, CardHeader, Paper } from '@material-ui/core'
import { Button, Divider, Empty, Input, } from 'antd';

import React, { useEffect, useRef, useState } from 'react'
// import './chat.css'
import { SearchOutlined, SendOutlined,CloudUploadOutlined } from '@ant-design/icons';
import firebase, { database } from 'firebase';
import pop from "./audio/sound.mp3";
import './chatBox.css'
import { SendNotifaction, SendNotifactionChat } from '../../../../utils/SendNotifaction';
import { Spinner } from 'react-bootstrap';
const ChatBox = () => {
  const [dataList, setDataList] = useState([])
  const [viewChat, setViewChat] = useState([])
  const [receiverDetails, setReceiverDetails] = useState({})
  const [selectedId, setSelectedID] = useState("")
  const [message, setMessage] = useState("")
  const [imageUploadLoading, setImageUploadLoading] = useState(false);
  const [fileUploadLoading, setFileUploadLoading] = useState(false);
  const [chatSendMessages,setchatSendMessages]=useState('')
  const [uploadCover, setUploadCover] = useState();
  const [vediouploadLoading, setvediouploadLoading] = useState(false);
 const [globalLoading,setGlobalLoading]=useState(false)
 const [selectedItem,setSelectedItem]=useState()
const [selectedType,setSelectedType]=useState("")
const [adminToken,setAdminToken]=useState("")
  const chatRef = useRef();
  const [searchText, setsearchText] = useState("")
  const dummy = useRef()
  useEffect(() => {
    getAllChat()
    getAdminToken()
  }, [])

  const getAdminToken = () => {
    firebase
      .database()
      .ref("users")
      .on("value", (snapshot) => {
        let arr = [];
        if (snapshot.exists()) {
          snapshot.forEach((item) => {
            let value = item.val();
            value.id = item.key;
            arr.push(value);
          });
        }
    const result=arr.find((item)=>item.type =="Admin")
    setAdminToken(result.token)
      });
  };
  const currentUser = JSON.parse(localStorage.getItem("firebaseUser"))
  const getAllChat = () => {
    firebase.database()
      .ref('ChatList')
      .child(currentUser.userId)
      .on('value', snapshot => {
        if (snapshot.exists()) {
          const chat = [];
          snapshot.forEach(doc => {
            let data = doc.val();
            data.key = doc.key;
            chat.push(data);
          });
          console.log(chat)
          setDataList(chat);
        } else {
          console.log('no chat');
        }
      });
  };
  // const getAllChat = () => {
  //   database()
  //     .ref('ChatList')
  //     .child(currentUser.userId)
  //     .on('value', snapshot => {
  //       if (snapshot.exists()) {
  //         const chat = [];
  //         snapshot.forEach(doc => {
  //           let data = doc.val();
  //           data.key = doc.key;
  //           chat.push(data);
  //         });
  //         console.log(chat)


  //         setDataList(chat);
  //       } else {
  //         console.log('no chat');
  //       }
  //     });
  // }
  const hanldeGetClickedUserChat = (id) => {
    getReceiverUserData(id)
    setSelectedID(id)
    firebase
      .database()
      .ref(
        `/ChatMessages/${currentUser.userId}/${id
        }`
      )
      .on("value", (snapshot) => {
        let arr = [];
        if (snapshot.exists()) {
          snapshot.forEach((item) => {
            let value = item.val();
            value.id = item.key;
            arr.push(value);
          });
        }
        console.log(arr);
        setViewChat(arr);
      });

  }


  const dataSearch = dataList.filter((item) => {



    let text2 = searchText.toLowerCase()
    const data = text2 ? item?.senderUserName
      ?.toLowerCase().includes(text2) || item?.receiverUserName
        ?.toLowerCase().includes(text2) : true;

    return data

  });

  /////send messgae 

  const getReceiverUserData = (id) => {

    firebase
      .database()
      .ref('users')
      .child(id)
      .once('value')
      .then(snapshot => {
        console.log("mubarak ho", snapshot.val())
        setReceiverDetails(snapshot.val())

      });
  }


  // const sendMessages = async (e) => {
  //   e.preventDefault();
  //   if (message == "") {
  //     return;
  //   }
  //   database()
  //     .ref("ChatMessages")
  //     .child(currentUser.userId)
  //     .child(receiverDetails.userId)
  //     .push({
  //       senderUID: currentUser.userId,
  //       receiverUID: receiverDetails.userId,
  //       message: message,
  //       type:"message"
  //     })
  //     .then(() => {
  //       const sound = document.getElementById("sound");
  //       sound.play();
  //       SendNotifactionChat(currentUser.userId,`${currentUser?.userName} sent you the chat`,message,receiverDetails.userId,receiverDetails.userId, "chat_action","hello",receiverDetails.token,"")
  //       setMessage("");
  //       dummy.current.scrollIntoView();
  //       database()
  //         .ref("ChatMessages")
  //         .child(receiverDetails.userId)
  //         .child(currentUser.userId)
  //         .push({
  //           senderUID: currentUser.userId,
  //           receiverUID: receiverDetails.userId,
  //           message: message,
  //           type:"message"
  //         });
  //       database()
  //         .ref("ChatList")
  //         .child(receiverDetails.userId)
  //         .child(currentUser.userId)
  //         .set({
  //           senderUserName: currentUser.userName,
  //           senderUserImage: currentUser.image ? currentUser.image:'',
  //           senderUserEmail: currentUser.email,
  //           timeStamp: Date.now(),
  //           senderUID: currentUser.userId,
  //           receiverUID: receiverDetails.userId,
  //           lastmessage: message,
  //           Type: "received",
  //       type:"message"

  //         });
  //       database()
  //         .ref("ChatList")
  //         .child(currentUser.userId)
  //         .child(receiverDetails.userId)
  //         .set({
  //           receiverUserName: receiverDetails.userName,
  //           receiverUserImage: receiverDetails.image? receiverDetails.image:'',
  //           receiverUserEmail: receiverDetails.email,
  //           timeStamp: Date.now(),
  //           senderUID: currentUser.userId,
  //           receiverUID: receiverDetails.userId,
  //           lastmessage: message,
  //           Type: "Send",
  //       type:"message"
  //         });

  //       // // push notification Work
  //       // const receiverToken = receiverDetails ? receiverDetails?.token : 'no token'
  //       // sendPushNotification(currentUser.userId, "Send a new message", senderUserName, receiverUID, receiverUID, "chat_action", "hello", receiverToken)
  //     })
  //     .catch((err) => {
  //       // ToastAndroid.show(err, ToastAndroid.SHORT)
  //     });
  // };
  function getFileType(fileName) {
    // Get the file extension
    const fileExtension = fileName.split('.').pop().toLowerCase();

    // Check the file extension and return the corresponding type
    if (['png', 'jpg', 'jpeg', 'gif', 'bmp'].includes(fileExtension)) {
        return 'image';
    } else if (['mp4', 'avi', 'mkv', 'mov', 'wmv'].includes(fileExtension)) {
        return 'video';
    } else {
        return 'file';
    }
}
const handleDataUpload=(file)=>{
  const type= getFileType(file.name);
  setSelectedType(type)
  setSelectedItem(file)
  // if(type=='file'){
  //  handleFileUpload(file)
  // }
  // if(type=='video'){
  //  handleVedioUpload(file)
  // }
  // if(type=='image'){
  //  handleImageUpload(file)
  // }
  console.log(type,"here we go")
 }


 const handleFileUpload = (file) => {
  setGlobalLoading(true);

  const storageRef = firebase.storage().ref();
  storageRef
    .child(`chatfiles/${file.name}`)
    .put(file)
    .then(async (snapshot) => {
      snapshot.ref.getDownloadURL().then((val) => {
        if (val) {
          setGlobalLoading(false);
        }
        sendMessages(val,"file")
        // notisender(groupName.name, "Admin Send A File");
      });
    })
    .catch((err) => {
      setGlobalLoading(false)
      console.log(err);
    });
};
const handleImageUpload = (image) => {
  setGlobalLoading(true);

  const storageRef = firebase.storage().ref();
  storageRef
    .child(`chatImages/${image.name}`)
    .put(image)
    .then(async (snapshot) => {
      snapshot.ref.getDownloadURL().then((val) => {
        if (val) {
          setGlobalLoading(false);
        }
        sendMessages(val,"image")
        // notisender(groupName.name, "Admin Send A image");
      });
    })
    .catch((err) => {
      setGlobalLoading(false);

      console.log(err);
    });
};



const handleVedioUpload = async (vedio) => {
  try {
    // get the frame at 1.5 seconds of the video file
    const cover = await getVideoCover(vedio, 1.5);
    // print out the result image blob
    const myFile = new File([cover], "thumbnail", { type: cover.type });
    console.log(myFile, "this is f");
    setUploadCover(myFile);
              setGlobalLoading(true);

    const storageRef = firebase.storage().ref();
    storageRef
      .child(`webThumbnail/${vedio.name}`)
      .put(myFile)
      .then(async (snapshot) => {
        snapshot.ref.getDownloadURL().then(async (thu) => {

    // const thumbnail=await uploadFiles(myFile)
          // console.log(thumbnail.payload.file.original_url, "this is thumbnail");
          // let thu=thumbnail.payload.file.original_url
          if (thu) {
            console.log(thu, 'thumbnail')
            // const res=await uploadFiles(vedio)
            // if(res){
            //   if(res.metadata.success){
            //         sendData(messages, "video", thu, "", "", res.payload.file.original_url,res.payload.id,thumbnail.payload.id);
            //         notisender(groupName.name, "Admin Send A Video", thu);
            //                   setGlobalLoading(false);
            //   }else{
            //     toast.error('SomeThing went wrronge while uploading video')
            //   }
            // }
            const storageRef = firebase.storage().ref();
            storageRef
              .child(`chatVideos/${vedio.name}`)
              .put(vedio)
              .then(async (snapshot) => {
                snapshot.ref.getDownloadURL().then((val) => {
                  if (val) {
                              setGlobalLoading(false);
                  }

                 sendMessages(val,"video",thu)
                  // sendData(messages, "video", thu, "", "", val,'');
            
                });
              })
              .catch((err) => {
                console.log(err);
              });
          }
        });
      });

  } catch (ex) {
    console.log("ERROR: ", ex);
  }
};

function getVideoCover(file, seekTo = 0.0) {
  console.log("getting video cover for file: ", file);
  return new Promise((resolve, reject) => {
    // load the file to a video player
    const videoPlayer = document.createElement("video");
    videoPlayer.setAttribute("src", URL.createObjectURL(file));
    videoPlayer.load();
    videoPlayer.addEventListener("error", (ex) => {
      reject("error when loading video file", ex);
    });
    // load metadata of the video to get video duration and dimensions
    videoPlayer.addEventListener("loadedmetadata", () => {
      // seek to user defined timestamp (in seconds) if possible
      if (videoPlayer.duration < seekTo) {
        reject("video is too short.");
        return;
      }
      // delay seeking or else 'seeked' event won't fire on Safari
      setTimeout(() => {
        videoPlayer.currentTime = seekTo;
      }, 200);
      // extract video thumbnail once seeking is complete
      videoPlayer.addEventListener("seeked", () => {
        console.log("video is now paused at %ss.", seekTo);
        // define a canvas to have the same dimension as the video
        const canvas = document.createElement("canvas");
        canvas.width = videoPlayer.videoWidth;
        canvas.height = videoPlayer.videoHeight;
        // draw the video frame to canvas
        const ctx = canvas.getContext("2d");
        ctx.drawImage(videoPlayer, 0, 0, canvas.width, canvas.height);
        // return the canvas image as a blob
        ctx.canvas.toBlob(
          (blob) => {
            resolve(blob);
          },
          "image/jpeg",
          0.75 /* quality */
        );
      });
    });
  });
}

const sendMedia=()=>{
if(selectedItem){
  if(selectedType=='file'){
    handleFileUpload(selectedItem)
   }
   if(selectedType=='video'){
    handleVedioUpload(selectedItem)
   }
   if(selectedType=='image'){
    handleImageUpload(selectedItem)
   }
   setSelectedType("")
   setSelectedItem("")
}else{
  sendMessages("","","")
}
}

const sendMessages = async (url, type, thumbnail) => {
 
  const timeStamp = Date.now();
  const date = new Date(timeStamp);

  const utcYear = date.getUTCFullYear();
  const utcMonth = date.getUTCMonth();
  const utcDay = date.getUTCDate();
  const utcHours = date.getUTCHours();
  const utcMinutes = date.getUTCMinutes();
  const utcSeconds = date.getUTCSeconds();

  // Create a new Date object in Pacific Standard Time (PST)
  const pstDate = new Date(Date.UTC(utcYear, utcMonth, utcDay, utcHours, utcMinutes, utcSeconds));

  // Adjust for PST time zone offset (UTC-8)
  pstDate.setHours(pstDate.getHours() - 8);


  const formattedPSTDate = pstDate.toISOString().replace(/T/, ' ').replace(/\..+/, '');
  console.log("pstDate ======",formattedPSTDate);

  database()
      .ref(`ChatMessages/${currentUser.userId}/${receiverDetails.userId}`)
      .push({
          senderUserName: currentUser.userName,
          senderUID: currentUser.userId,
          receiverUID: receiverDetails.userId,
          message: chatSendMessages,
          userType: currentUser.type,
          type: type ? type : "message",
          thumbnail: thumbnail ? thumbnail : "thumbnail",
          // thumbnailID: thumbnailId,
          // deleteAbleId: videoId,
          url: url ? url : "uri",
          timeStamp: Date.now(),
      });

  database()
      .ref(`ChatMessages/${receiverDetails.userId}/${currentUser.userId}`)
      .push({
          senderUserName: currentUser.userName,
          senderUID: currentUser.userId,
          receiverUID: receiverDetails.userId,
          message: chatSendMessages,
          userType: currentUser.type,
          type: type ? type : "message",
          thumbnail: thumbnail ? thumbnail : "thumbnail",
          // thumbnailID: thumbnailId,
          // deleteAbleId: videoId,
          url: url ? url : "uri",
          timeStamp: Date.now(),
      }).then(() => {
        setchatSendMessages("")
        chatRef.current.scrollIntoView();
          SendNotifaction(`Chat Notification`,`${currentUser?.userName} send a ${type ? type : "message"} in chat`,receiverDetails.token,"") 
          SendNotifaction(`Chat Notification`,`${currentUser?.userName} send a ${type ? type : "message"} in chat`,adminToken,"") 
  })
      .catch(err => {
        
          // ToastAndroid.show(err, ToastAndroid.SHORT);
      });


  database()
      .ref('ChatList')
      .child(receiverDetails.userId)
      .child(currentUser.userId)
      .set({
          senderUserName: currentUser.userName,
          senderUserImage: currentUser.image || '',
          senderUserEmail: currentUser.email,
          timeStamp: Date.now(),
          senderUID: currentUser.userId,
          receiverUID: receiverDetails.userId,
          lastmessage: chatSendMessages,
          Type: 'received',
      });

  database()
      .ref('ChatList')
      .child(currentUser.userId)
      .child(receiverDetails.userId)
      .set({
          receiverUserName: receiverDetails.userName,
          receiverUserImage: receiverDetails.image || '',
          receiverUserEmail: receiverDetails.email,
          timeStamp: Date.now(),
          senderUID: currentUser.userId,
          receiverUID: receiverDetails.userId,
          lastmessage: chatSendMessages,
          Type: 'Send',
      })

};
const scrollToLastMessage = () => {
  // messagesEndRef.current.scrollIntoView();
  chatRef.current.scrollIntoView({ behavior: "smooth" });
};


  return (
    <>
      <audio id="sound" src={pop}></audio>
      <div className=" pt-3 hereScroll" style={{ overflowY: "scroll", height: "100vh", minHeight: "100vh", zIndex: "-1" }}>
        <div className="container" style={{ paddingRight: "0px" }}>
          <div className='row'>
            <div className='col-md-3'>
              <Card className='alluserShowCard'>

                <div className='seruser'>
                  <Input onChange={(e) => setsearchText(e.target.value)} size='large' placeholder="Search User" prefix={<SearchOutlined />} />
                </div>

                {
                  dataSearch.length > 0 ?
                    dataSearch.map((item) => (
                      <>
                        {
                          item.Type == "Send" ?
                            <>
                              <Card onClick={() => hanldeGetClickedUserChat(item.receiverUID)} className={item.receiverUID == selectedId ? 'hamaraUsers selectedID' : 'hamaraUsers'} style={{ width: "100%", marginTop: "10px" }}>
                                <CardHeader
                                  style={item.receiverUID == selectedId ? { paddingTop: "5px", paddingBottom: "5px", color: "#fff" } : { paddingTop: "5px", paddingBottom: "5px" }}
                                  avatar={

                                    <Avatar alt="Remy Sharp" src={item.receiverUserImage} />
                                  }

                                  title={item.receiverUserName}
                                  subheader={item.lastmessage}
                                  subheaderTypographyProps={item.receiverUID == selectedId ? { style: { color: '#fff' } } : ''}
                                />
                                <Divider style={{ margin: 0 }} />
                              </Card>
                              <Divider style={{ margin: 0 }} />
                            </>
                            :
                            <>
                              <Card onClick={() => hanldeGetClickedUserChat(item.senderUID)} className={item.senderUID == selectedId ? 'hamaraUsers selectedID' : 'hamaraUsers'} style={{ width: "100%", marginTop: "10px" }}>
                                <CardHeader
                                  style={item.senderUID == selectedId ? { paddingTop: "5px", paddingBottom: "5px", color: "#fff" } : { paddingTop: "5px", paddingBottom: "5px" }}
                                  avatar={

                                    <Avatar alt="Remy Sharp" src={item.senderUserImage} />
                                  }

                                  title={item.senderUserName}
                                  subheader={item?.lastmessage?.length > 20
                                    ? item?.lastmessage?.substr(0, 20) + "..."
                                    : item?.lastmessage}
                                  subheaderTypographyProps={item.senderUID == selectedId ? { style: { color: '#fff' } } : ''}
                                />
                                <Divider style={{ margin: 0 }} />
                              </Card>
                              <Divider style={{ margin: 0 }} />
                            </>
                        }
                      </>
                    )) :
                    <div className='mt-9'>
                      <Empty/>
                    </div>
                }

              </Card>
            </div>
            <div className="col-md-9">
              <Card
                className="herethefixheader"
                style={{
                  backgroundColor: "#3699FF",
                  boxShadow:
                    "rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px",
                }}
              >
                <CardHeader
                  className="herethefixheader"
                  style={{
                    paddingTop: "5px",
                    paddingBottom: "5px",
                    color: "#fff",
                  }}
                  avatar={
                    <Avatar alt="Remy Sharp" src={receiverDetails.image} />
                  }
                  title={
                    <div style={{paddingTop:"10px",paddingBottom:"10px",display:"flex",justifyContent:"space-between"}}>
                        <h6 className='mt-2'>{receiverDetails.userName}</h6>

                        <div>
                          <button onClick={() => scrollToLastMessage()} style={{border:"none",borderRadius:"5px",padding:"8px"}}>Scroll Down</button>
                        </div>
                    </div>
                }
                >
                  </CardHeader>
                <Divider style={{ margin: 0 }} />
              </Card>

              <Paper
                className="orignalChatCont"
                style={{ height: "82vh", overflow: "scroll",paddingBottom:"20px"}}
              >

{
                
                globalLoading &&
                <div style={{ height: "82vh", width: "100%",display:"flex",justifyContent:"center",flexDirection:"column",alignItems:"center",position:"absolute",zIndex:"11", backdropFilter: 'blur(8px)'}}>
                  <Spinner title='Uploading....' animation='border'/>
                  <p style={{textAlign:"center"}}>Uploading...</p>
                </div>}
                {
                viewChat.length > 0 ? (
                  viewChat.map((item) => (
                    <>
                     {/* //// for simple message */}
                     {
                      item.message !='' && item.type=="message" ?
                      <div
                      className="row"
                      style={{
                        marginTop: "10px",
                        marginBottom: "10px",
                        marginLeft: "10px",
                      }}
                    >
                      <div
                        className={
                          item.senderUID == currentUser.userId
                            ? "col-md-12 d-flex justify-content-end"
                            : "col-md-12 d-flex"
                        }
                      >
                        {item.senderUID == currentUser.userId ? (
                          <Avatar alt="Remy Sharp" src={currentUser?.image} />
                        ) : (
                          <Avatar
                            alt="Remy Sharp"
                            src={receiverDetails.image}
                          />
                        )}
                        <Card
                          className="messageorignall mx-3"
                          style={
                            item.senderUID == currentUser.userId
                              ? {
                                borderRadius: "20px",
                                backgroundColor: "#3699FF",
                                maxWidth: "70%",
                              }
                              : { borderRadius: "20px", maxWidth: "70%" }
                          }
                        >
                          <CardContent
                            style={{
                              paddingTop: "5px",
                              paddingBottom: "5px",
                            }}
                          >
                            <p
                              className={
                                item.senderUID == currentUser.userId
                                  ? "messagetext maramessage"
                                  : "messagetext"
                              }
                            >
                              {item.message}
                            </p>
                            <div ref={dummy} />
                          </CardContent>
                        </Card>
                      </div>
                      <div ref={chatRef}></div>
                    </div>:''
                     }

                     {
                      item.type=="image"?
                      <div style={{marginBottom:"10px",marginTop: "10px",}}>
                       <div
                      className="row"
                      style={{
                        marginLeft: "10px",
                      }}
                    >
                      <div
                        className={
                          item.senderUID == currentUser.userId
                            ? "col-md-12 d-flex justify-content-end"
                            : "col-md-12 d-flex"
                        }
                      >
                        {item.senderUID == currentUser.userId ? (
                          <Avatar alt="Remy Sharp" src={currentUser?.image} />
                        ) : (
                          <Avatar
                            alt="Remy Sharp"
                            src={receiverDetails.image}
                          />
                        )}
                       <div style={{height:"200px",width:"200px",border:"1px solid #3699FF",borderRadius:"20px",marginLeft:"10px",marginRight:"10px",display:"inline-block"}}>
                            <img style={{height:"100%",width:"100%",borderRadius:"20px"}} src={item.url} alt='image'/>
                       </div>
                      </div>
                    </div>
                    {!!item.message && <div
                        className="row"
                        style={{
                          marginLeft: "60px",
                          marginTop: "10px",
                        }}
                      >
                        <div
                          className={
                            item.senderUID == currentUser.userId
                              ? "col-md-12 d-flex justify-content-end"
                              : "col-md-12 d-flex"
                          }
                        >
                       
                          <Card
                            className="messageorignall mx-3"
                            style={
                              item.senderUID == currentUser.userId
                                ? {
                                    borderRadius: "20px",
                                    backgroundColor: "#3699FF",
                                    maxWidth: "70%",
                                  }
                                : { borderRadius: "20px", maxWidth: "70%" }
                            }
                          >
                            <CardContent
                              style={{
                                paddingTop: "5px",
                                paddingBottom: "5px",
                              }}
                            >
                              <p
                                className={
                                  item.senderUID == currentUser.userId
                                    ? "messagetext maramessage"
                                    : "messagetext"
                                }
                              >
                                {item.message}
                              </p>
                              <div ref={dummy} />
                            </CardContent>
                          </Card>
                        </div>
                      </div>}
                      <div ref={chatRef}></div>
                     </div>
                      :''
                     }

{
                      item.type=="video"?
                      <div style={{marginBottom:"10px",marginTop: "10px"}}>
                        <div
                      className="row"
                      style={{
                        marginLeft: "10px",
                      }}
                    >
                      <div
                        className={
                          item.senderUID == currentUser.userId
                            ? "col-md-12 d-flex justify-content-end"
                            : "col-md-12 d-flex"
                        }
                      >
                        {item.senderUID == currentUser.userId ? (
                          <Avatar alt="Remy Sharp" src={currentUser?.image} />
                        ) : (
                          <Avatar
                            alt="Remy Sharp"
                            src={receiverDetails.image}
                          />
                        )}
                            <video controls src={item.url} style={{height:"200px",width:"200px", margin:"10px"}}/>
                      </div>
                    </div>

                    {!!item.message && <div
                        className="row"
                        style={{
                          marginLeft: "60px",
                        }}
                      >
                        <div
                          className={
                            item.senderUID == currentUser.userId
                              ? "col-md-12 d-flex justify-content-end"
                              : "col-md-12 d-flex"
                          }
                        >
                       
                          <Card
                            className="messageorignall mx-3"
                            style={
                              item.senderUID == currentUser.userId
                                ? {
                                    borderRadius: "20px",
                                    backgroundColor: "#3699FF",
                                    maxWidth: "70%",
                                  }
                                : { borderRadius: "20px", maxWidth: "70%" }
                            }
                          >
                            <CardContent
                              style={{
                                paddingTop: "5px",
                                paddingBottom: "5px",
                              }}
                            >
                              <p
                                className={
                                  item.senderUID == currentUser.userId
                                    ? "messagetext maramessage"
                                    : "messagetext"
                                }
                              >
                                {item.message}
                              </p>
                              <div ref={dummy} />
                            </CardContent>
                          </Card>
                        </div>
                      </div>}
                      <div ref={chatRef}></div>
                  </div>
                      :''
                     }


{
                      item.type=="file"?
                      <div style={{marginBottom:"10px",marginTop: "10px",}}>
                       <div
                      className="row"
                      style={{
                        marginLeft: "10px",
                      }}
                    >
                      <div
                        className={
                          item.senderUID == currentUser.userId
                            ? "col-md-12 d-flex justify-content-end"
                            : "col-md-12 d-flex"
                        }
                      >
                        {item.senderUID == currentUser.userId ? (
                          <Avatar alt="Remy Sharp" src={currentUser?.image} />
                        ) : (
                          <Avatar
                            alt="Remy Sharp"
                            src={receiverDetails.image}
                          />
                        )}
                         <div style={{height:"200px",width:"200px",marginLeft:"10px",marginRight:"10px",marginBottom:"10px",display:"inline-block"}}>
                          <a href={item.url} target='_blank'>View</a>
                           <iframe src={item.url} height={180} width={180}/>
                         </div>
                      </div>
                    </div>

                    {!!item.message && <div
                        className="row"
                        style={{
                          marginLeft: "60px",
                        }}
                      >
                        <div
                          className={
                            item.senderUID == currentUser.userId
                              ? "col-md-12 d-flex justify-content-end"
                              : "col-md-12 d-flex"
                          }
                        >
                       
                          <Card
                            className="messageorignall mx-3"
                            style={
                              item.senderUID == currentUser.userId
                                ? {
                                    borderRadius: "20px",
                                    backgroundColor: "#3699FF",
                                    maxWidth: "70%",
                                  }
                                : { borderRadius: "20px", maxWidth: "70%" }
                            }
                          >
                            <CardContent
                              style={{
                                paddingTop: "5px",
                                paddingBottom: "5px",
                              }}
                            >
                              <p
                                className={
                                  item.senderUID == currentUser.userId
                                    ? "messagetext maramessage"
                                    : "messagetext"
                                }
                              >
                                {item.message}
                              </p>
                              <div ref={dummy} />
                            </CardContent>
                          </Card>
                        </div>
                      </div>}
                      <div ref={chatRef}></div>
                     </div>
                      :''
                     }
                     
                    </>
                  ))
                ) : (
                  <div className="noboardFound">
                    <Empty/>
                  </div>
                )}
              </Paper>
            </div>


          </div>
        </div>
      </div>
      <div className="">
        <div className="row">
          <div className="col-md-3"></div>
          <div className="col-md-9 sendBAr">
            <Divider style={{ margin: 0, marginLeft: "15px" }} />

            <div>
              <Card style={{ marginLeft: "18px" }}>
                <CardContent
                  style={{ paddingTop: "5px", paddingBottom: "5px" }}
                >
                  <div className="d-flex">
                    <label htmlFor='fileupload'>
                    <CloudUploadOutlined className='uploadicon'/>
                    </label>
                    <Input
                    disabled={globalLoading}
                      value={chatSendMessages}
                      size="large"
                      placeholder="input with clear icon"
                      allowClear
                      onChange={(e) =>{
                        e.preventDefault();
                        setchatSendMessages(e.target.value)}}
                    />
                    <Button
                      htmlType="button"
                      onClick={()=>sendMedia()}
                      type="primary"
                      icon={<SendOutlined />}
                      size="large"
                    />
                  </div>
                </CardContent>
              </Card>
            </div>
            {selectedType ?  <Paper
                className="orignalChatCont"
                style={{ height: "200px", width:"200px",marginLeft:"20px"}}
              >

          {
            selectedType=='image' ? 
            <img height={200} width={200} src={URL.createObjectURL(selectedItem)}/> : ''
          }
            {
            selectedType=='video' ? 
            <video controls height={180} width={200} src={URL.createObjectURL(selectedItem)}/> : ''
          }
           {
            selectedType=='file' ? 
            <div>
              <a href={URL.createObjectURL(selectedItem)} target="_blank">View </a>
              <iframe  height={190} width={200} src={URL.createObjectURL(selectedItem)}/>
            </div> : ''
          }
      </Paper> : ''}
            <Divider style={{ margin: 0, marginLeft: "15px" }} />
          </div>
        </div>
      </div>
      <input
           onChange={(e) => handleDataUpload(e.target.files[0])}
          style={{ display: "none" }}
          className="btn btn-base"
          type="file"
          id="fileupload"
          accept="image/*, video/*, .pdf"
          multiple
        />
    </>
  )
}

export default ChatBox