import { Paper } from '@material-ui/core'
import firebase from 'firebase';
import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { toAbsoluteUrl } from '../../../../_metronic/_helpers';
import { useHistory } from 'react-router-dom';

const ViewUserReports = () => {

    const backgroundImageUrl = toAbsoluteUrl("/media/bg/bg-9.jpg");
    const history = useHistory()
    const [projectList, setprojectList] = useState([]);

    useEffect(() => {
        // Function called to get data from the Firebase Database 
        getProjects();
    }, []);



    const getProjects = async () => {
        firebase.auth().onAuthStateChanged(user => {
            if (user) {
                firebase.database().ref('projects')
                    .orderByChild('projectCreatorId')
                    .equalTo(user.uid)
                    .on('value', snapshot => {
                        if (snapshot) {
                            let array = [];
                            snapshot.forEach((item, index) => {
                                let data = item.val();
                                if (data.reportStatus === 'yes') {
                                    data.key = item.key;
                                    array.push(data);
                                }
                            });

                            setprojectList(array);
                            // if (array.length === 0) {
                            //     setLoaderModal(false)
                            //     setDataEmpty(true);
                            // }
                        }
                    });
            }
        })
    };

    return (
        <>
            <Paper className="p-3">

                <div className='row p-3'>
                    {
                        projectList.map((project) => (
                            <div
                                onClick={() => {
                                    history.push(`/reportsdetails/${project.projectId}`)
                                }}
                                className={`col-md-4 card card-custom bgi-no-repeat bgi-no-repeat bgi-size-cover gutter-b`}
                                style={{
                                    height: '175px',
                                    backgroundImage: `url("${project.image ? project.image : backgroundImageUrl}")`,
                                    // backgroundImage: `${project.image ? project.image : `url(${backgroundImageUrl})`}`,
                                }}
                            >
                                {/* begin::Body */}
                                <div className="card-body d-flex flex-column">
                                    {/* begin::Title */}
                                    <div
                                        className="text-dark-75 text-hover-primary font-weight-bolder font-size-h3"
                                    >
                                        {project.projectTitle}
                                    </div>
                                    {/* <div
                                        className="text-dark-75 text-hover-primary font-size-h5"
                                    >
                                        {project.description.slice(0,100)}
                                    </div> */}

                                    {/* end::Title */}
                                </div>
                                {/* end::Body */}
                            </div>
                        ))
                    }

                </div>
            </Paper>
        </>
    )
}

export default ViewUserReports