import React, { useEffect, useState } from 'react'
import { Card, CardBody, CardHeader, CardHeaderToolbar, Pagination } from '../../../../_metronic/_partials/controls'
import firebase from 'firebase';
import { makeStyles } from '@material-ui/core/styles';
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
    PaginationProvider,
} from "react-bootstrap-table2-paginator";
import * as uiHelpers from '../../../modules/ECommerce/pages/customers/CustomersUIHelpers';
import { StatusColumnFormatter } from '../../../helpers/UIhelpers';
import { Modal as AntModal } from 'antd';
import { useHistory } from 'react-router-dom'
import { Modal } from 'react-bootstrap';
import * as columnFormatters from "../../../modules/ECommerce/pages/customers/customers-table/column-formatters";
import { toast } from 'react-hot-toast';
import './userControproject.css'
import {Link} from 'react-router-dom'
const UserConstruProjects = () => {


    const history = useHistory()
    const [boards, setBoards] = useState([])
    const [loading, setloading] = useState(false)
    ////for pagination
    const initialFilter = {
        pageNumber: 1,
        pageSize: 10,
    };


    const [queryParams, setQueryParamsBase] = useState(initialFilter);

    useEffect(() => {
        fetchUserBoards();
    }, []);

    const fetchUserBoards = () => {
        setloading(true);
        firebase
            .database()
            .ref("constructionprojects")
            .on("value", (snapshot) => {
                setloading(false);
                let arr = [];
                if (snapshot.exists()) {
                    snapshot.forEach((item) => {
                        let value = item.val();
                        value.id = item.key;
                        arr.push(value);
                    });
                }
            let resultant =   arr.filter((item)=>{
                    if(item.attachedUsers?.includes(firebase.auth().currentUser.uid)){
                        return item
                    }
                })
                setBoards(resultant.reverse());
            });
    };



    return (
        <>
            <Card>
                <CardHeader title="Construction Projects">
                    <CardHeaderToolbar>
                        {/* <button
                            type="button"
                            className="btn btn-primary"
                            onClick={() => {
                                seteditModal(false);
                                setaddModal(true);
                            }}
                        >
                            Create Project
                        </button> */}
                    </CardHeaderToolbar>
                </CardHeader>
                <CardBody>
<div className='row'>
{
    boards.map((item)=>(
    <Link to={`/construction-project-reports/${item.constructionprojectId}`}>
        <div className='col-md-4'>
        {/* <div class="cardmer">
         <div class="card_image"> <img src={item.image} /> </div>
      
         <div className='usercartitle'>
         <p>{item.name}</p>
         </div>
       </div> */}

                  <div class="card2" style={{ minHeight: "250px" }}>
                    <div class="slider">
                      <img src={item.image} alt="Image 1" />
                    </div>
                    <div class="card-content2">
                      <h3 style={{color:"black"}}>{item.name}</h3>
                    </div>
                  </div>
       
        </div>
    </Link>
    ))
}
</div>
                </CardBody>
            </Card>

 


        </>
    )
}

export default UserConstruProjects

